import { FlexContainer, Icon, Label } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const AnonymizedIcon = styled(Icon)`
  transition: none;
  > * {
    transition: none;
  }
`;

export const AnonymizedStyles = styled(FlexContainer)<{ checked?: boolean }>`
  ${AnonymizedIcon},
  ${Label} {
    color: ${(props) => props.checked && props.theme.color.primary[400]};
  }
`;
