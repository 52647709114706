import { useEffect, useState } from 'react';
import { useAuth } from '@backoffice/providers';
import { Tenant } from '@backoffice/state/model';
import {
  Button,
  ButtonIcon,
  Label,
  ToastType,
  useToast,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ModalTenantContent,
  ModalTenantElement,
  ModalTenantHeader,
  ModalTenantStyles,
  MODAL_TENANT_WIDTH,
} from './Styles';

type Props = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  onCloseModal(): void;
};

export const ModalTenant = ({
  show = false,
  onChangeShow,
  onCloseModal,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { changeToken, tenant, tenants } = useAuth();
  const { toastManager } = useToast();

  const [selectTenant, setSelectTenant] = useState<Tenant | null>();
  const [loading, setLoading] = useState(false);

  const handleSelectedItem = async () => {
    if (selectTenant) {
      try {
        setLoading(true);
        await changeToken(selectTenant.id);
        navigate('/');
        toastManager({
          $type: ToastType.SUCCESS,
          message: t('Successfully changed tenant'),
          duration: 3000,
          testId: 'update-tenant',
        });
      } catch {
        toastManager({
          $type: ToastType.ERROR,
          message: t('The tenant could not be changed'),
          duration: 3000,
          testId: 'update-tenant',
        });
      }
    }
    setLoading(false);
    onCloseModal();
  };

  useEffect(() => {
    if (!show) {
      setSelectTenant(null);
    }
  }, [show]);

  return (
    <ModalTenantStyles
      testId="modal-tenant"
      width={MODAL_TENANT_WIDTH}
      onChangeShow={onChangeShow}
      show={show}
      overlayBlur
    >
      <ModalTenantHeader
        as="section"
        alignItems="center"
        justifyContent="space-between"
      >
        <Label size="14" semibold negative>
          {t('Select an agency')}
        </Label>
        <ButtonIcon text iconName="ArrowRight" onClick={onCloseModal} />
      </ModalTenantHeader>
      <ModalTenantContent flexDirection="column" flex="1" as="ul">
        {tenants &&
          tenants.map((item: Tenant) => (
            <ModalTenantElement
              as="li"
              alignItems="center"
              justifyContent="space-between"
              role="button"
              onClick={() => setSelectTenant(item)}
              columnGap="1"
              key={item.id}
              isActive={(selectTenant?.id || tenant.id) === item.id}
            >
              <Label as="span" size="14" semibold negative ellipsis>
                {item.name}
              </Label>
              <Label as="span" size="12" negative ellipsis>
                {item.id}
              </Label>
            </ModalTenantElement>
          ))}
      </ModalTenantContent>
      <Button
        size="XL"
        color="primary"
        onClick={handleSelectedItem}
        disabled={loading || !selectTenant || tenant.id === selectTenant?.id}
      >
        {t('Apply')}
      </Button>
    </ModalTenantStyles>
  );
};
