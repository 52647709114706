import { useMemo } from 'react';
import { AppName, AppsWithIcon, RoutesName } from '@backoffice/state/constants';
import { AppsIconsType } from '@backoffice/state/model';
import { usePermissions, SCOPES } from '@facephi/inphinite-common';
import { FlexContainer, IconWrapper, Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppsAsideNavType, AsideNavProps, AsideNavType } from './model';
import {
  AsideNavBottomButton,
  AsideNavBottomPanel,
  AsideNavBottomStyles,
} from './Styles';

export const AsideNavBottom = ({ app }: AsideNavProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const apps: AsideNavType[] = [
    {
      app: AppName.operations,
      testId: 'dashboard-app',
      onClick: () => {
        navigate(RoutesName.dashboard);
      },
      visible: hasPermission([SCOPES.canViewOperations]),
    },
    {
      app: AppName.studio,
      testId: 'studio-app',
      onClick: () => {
        navigate(RoutesName.studio);
      },
      visible: hasPermission([SCOPES.canViewStudio]),
    },
    {
      app: AppName.ux,
      testId: 'ux-app',
      onClick: () => {
        navigate(RoutesName.uxAnalytic);
      },
      visible: hasPermission([SCOPES.canViewUX]),
    },
    {
      app: AppName.privacy,
      testId: 'privacy-app',
      onClick: () => {
        navigate(RoutesName.privacy);
      },
      visible: hasPermission([SCOPES.canViewPrivacy]),
    },
    {
      app: AppName.ai,
      testId: 'ai-app',
      onClick: () => {
        navigate(RoutesName.ai);
      },
      visible: hasPermission([SCOPES.canViewAI]),
    },
    {
      app: AppName.antiFraud,
      testId: 'anti-fraud-app',
      onClick: () => {
        navigate(RoutesName.antifraud);
      },
      visible: hasPermission([SCOPES.canViewAntifraud]),
    },
    {
      app: AppName.videoAssistance,
      testId: 'video-support-app',
      onClick: () => {
        navigate(RoutesName.videoCalls);
      },
      visible: hasPermission([SCOPES.canViewVideo]),
    },
    {
      app: AppName.audit,
      testId: 'audit-app',
      onClick: () => {
        navigate(RoutesName.audit);
      },
      visible: hasPermission([SCOPES.canViewAudit]),
    },
    {
      app: AppName.userManagement,
      testId: 'user-management-app',
      onClick: () => {
        navigate(RoutesName.userManagement);
      },
      visible: hasPermission([SCOPES.canViewUserManagment]),
    },
  ];

  const customApps: AppsAsideNavType[] = useMemo(
    () =>
      apps
        .filter((item) => item.visible)
        .map((item) => {
          const config = AppsWithIcon.find(
            (app) => app.label === item.app
          ) as AppsIconsType;
          return { ...item, ...config };
        }),
    [apps]
  );

  const currentApp = useMemo(
    () => customApps.find((item) => item.label === app),
    [app, customApps]
  );

  return (
    <AsideNavBottomStyles>
      <FlexContainer
        as="ul"
        flexDirection="column"
        justifyContent="center"
        rowGap="2.6"
      >
        {currentApp && (
          <FlexContainer
            flexDirection="column"
            alignItems="center"
            rowGap="0.6"
          >
            <IconWrapper
              iconName={currentApp.iconName}
              backgroundColor={currentApp.color}
              backgroundIntensity={400}
              size="XL"
            />
            <Label size="10" negative textAlign="center">
              {t(currentApp.label)}
            </Label>
          </FlexContainer>
        )}
        <AsideNavBottomPanel
          itemsLength={customApps.length}
          widthAuto
          hidePanelOnClick
          renderItem={
            <AsideNavBottomButton
              flexDirection="column"
              alignItems="center"
              as="button"
              rowGap="0.6"
              data-test="menu-application"
            >
              <IconWrapper
                iconName="SquaresFour"
                backgroundColor="greys"
                backgroundIntensity={600}
                size="XL"
              />
              <Label size="10" negative>
                {t('Application')}
              </Label>
            </AsideNavBottomButton>
          }
        >
          {customApps.map((item) => (
            <FlexContainer
              flexDirection="column"
              alignItems="center"
              as="li"
              rowGap="0.6"
              onClick={item.onClick}
              data-test={item.testId}
              key={item.label}
            >
              <IconWrapper
                iconName={item.iconName}
                backgroundColor={item.color}
                hasHoverStyle
                size="L"
              />

              <Label size="10" textAlign="center">
                {t(item.label)}
              </Label>
            </FlexContainer>
          ))}
        </AsideNavBottomPanel>
      </FlexContainer>
    </AsideNavBottomStyles>
  );
};
