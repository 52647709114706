import { FacialAuthenticateContent } from '@facephi/inphinite-common';
import {
  IconName,
  ThemeColorType,
  IconWeightType,
} from '@facephi/inphinite-ui';

export type tInfo = {
  iconName: IconName;
  iconColor: ThemeColorType;
  message: string;
  size: number;
  weight: IconWeightType;
};

export type LivenessIconInfo = {
  iconName: IconName;
  iconColor: ThemeColorType;
};

export type FilesOptionListInfo = {
  menuTitle: string;
  menuIcon?: IconName;
  menuIconLeft?: IconName;
  assetName: string;
  assetIcon?: IconName;
};

export type CustomDropdownAsset = {
  type: string;
  url: string;
  contentType: string;
  position: number;
};

export type FacialValidationState = (FacialAuthenticateContent & {
  id: string;
})[];

export const infoFacial: { [key in string]: tInfo } = {
  match: {
    message: 'The person on the document and the selfie match',
    iconName: 'Check',
    iconColor: 'success',
    weight: 'fill',
    size: 44,
  },
  notMatch: {
    message: 'The person on the document and the selfie dont match',
    iconName: 'X',
    iconColor: 'error',
    weight: 'fill',
    size: 44,
  },
  notAble: {
    message:
      'We have not been able to verify. The photo may have some reflection or low resolution.',
    iconName: 'WarningCircle',
    iconColor: 'warning',
    weight: 'light',
    size: 42,
  },
};

export const infoLiveness: { [key in string]: LivenessIconInfo } = {
  success: { iconName: 'Check', iconColor: 'success' },
  error: { iconName: 'X', iconColor: 'error' },
};

export const filesOptionMenuInfo: { [key in string]: FilesOptionListInfo } = {
  video: {
    menuTitle: 'Videos',
    menuIcon: 'CaretDown',
    menuIconLeft: 'VideoCamera',
    assetName: 'Video recording',
    assetIcon: 'Play',
  },
};
