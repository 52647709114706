/* eslint-disable @typescript-eslint/no-explicit-any */
import Chrome from '@backoffice/assets/img/chrome.png';
import Edge from '@backoffice/assets/img/edge.png';
import Mozilla from '@backoffice/assets/img/firefox.png';
import Safari from '@backoffice/assets/img/safari.png';
import { AppsIconsType } from './model';
import { BrowserListProps } from './model/browser';

export enum RoutesName {
  dashboard = '/dashboard',
  operations = '/operations',
  operationsId = '/operations/:id',
  workflows = '/workflows',
  workflow = '/workflow/:id',
  studio = '/studio',
  studioId = '/studio/:id',
  template = '/template',
  settings = '/settings/',
  general = '/settings/general',
  profile = '/settings/profile',
  aspect = '/settings/aspect',
  mainManagement = '/settings/management/',
  management = '/settings/management/:type/',
  permissions = '/settings/management/:type/:id/permissions',
  newPermissions = '/settings/management/permissions',
  myPlan = '/settings/my-plan',
  userProfile = '/settings/management/users/:id/profile',
  notFound = '/404',
  error = '/error',
  reporting = '/reporting',
  video = '/video',
  videoId = '/video/:id',
  ux = '/ux',
  uxTask = '/ux/task',
  uxAnalytic = '/ux/analytic',
  login = '/login',
  antifraud = '/antifraud',
  banned = '/antifraud/banned',
  bannedId = '/antifraud/banned/:id',
  rules = '/antifraud/rules',
  privacy = '/privacy',
  ai = '/ai',
  videoCalls = '/video-calls',
  audit = '/audit',
  auditId = '/audit/:id',
  userManagement = '/user-management',
  userManagementProfile = '/user-management/:id/profile',
}

export enum AppName {
  operations = 'Operations',
  studio = 'Studio',
  ux = 'UX',
  antiFraud = 'Anti fraud',
  privacy = 'Privacy',
  ai = 'AI',
  videoAssistance = 'Video assistance',
  audit = 'Audit',
  userManagement = 'Users management',
}

export const AppsWithIcon: AppsIconsType[] = [
  {
    iconName: 'ChartPie',
    color: 'yellow',
    label: AppName.operations,
  },
  {
    iconName: 'PencilCircle',
    color: 'violet',
    label: AppName.studio,
  },
  {
    iconName: 'Smiley',
    color: 'blue',
    label: AppName.ux,
  },
  {
    iconName: 'Lock',
    color: 'skyBlue',
    label: AppName.privacy,
  },
  {
    iconName: 'Brain',
    color: 'green',
    label: AppName.ai,
  },
  {
    iconName: 'FraudIcon',
    color: 'coral',
    label: AppName.antiFraud,
  },
  {
    iconName: 'Headset',
    color: 'purple',
    label: AppName.videoAssistance,
  },
  {
    iconName: 'Eyeglasses',
    color: 'cyan',
    label: AppName.audit,
  },
  {
    iconName: 'UsersThree',
    color: 'warning',
    label: AppName.userManagement,
  },
];

export const browserList: BrowserListProps[] = [
  {
    id: 1,
    image: Chrome,
    name: 'Chrome',
    version: '90.0.0',
    route: 'https://www.google.com/chrome/',
    installable: true,
  },
  {
    id: 1,
    image: Chrome,
    name: 'Chrome Headless',
    version: '90.0.0',
    route: 'https://www.google.com/chrome/',
    installable: false,
  },
  {
    id: 2,
    image: Mozilla,
    name: 'Firefox',
    version: '90.0.0',
    route: 'https://www.mozilla.org/es-ES/firefox/new/',
    installable: true,
  },
  {
    id: 3,
    image: Edge,
    name: 'Edge',
    version: '90.0.0',
    route: 'https://www.microsoft.com/es-es/edge',
    installable: true,
  },
  {
    id: 4,
    image: Safari,
    name: 'Safari',
    version: '14.1.0',
    route: 'https://support.apple.com/es_ES/downloads/safari',
    installable: true,
  },
];

export const ZOOM = {
  minScale: 0.25,
  minZoom: 25,
  defaultFlow: 25,
  defaultScale: 100,
  previewVideo: 1.5,
  maxScale: 100,
  defaultZoom: 100,
};

export const DEFAULT_FORMAT_DATE = 'DD/MM/YY HH:mm:ss';
export const DEFAULT_TIMEZONE = 'Europe/Madrid';

export enum LocalStorageEnum {
  previouslyVisited = 'PREVIOUSLY_VISITED',
}

export enum VideoCallTabs {
  Queue = 'Queue',
  Closed = 'Closed',
}
