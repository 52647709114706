import dayjs from 'dayjs';
import { LocaleUtils } from 'react-day-picker';

require('dayjs/locale/en');
require('dayjs/locale/es');

type LOCALES = 'en' | 'es';
type IWeekTranslations = {
  [key in LOCALES]: [string, string, string, string, string, string, string];
};
type IMonthTranslations = {
  [key in LOCALES]: [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ];
};
type INumberTranslations = { [key in LOCALES]: number };

const WEEKDAYS_LONG: IWeekTranslations = {
  en: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  es: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ],
};
const WEEKDAYS_SHORT: IWeekTranslations = {
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  es: ['do', 'lu', 'ma', 'mi', 'ju', 'vi', 'sá'],
};
const MONTHS: IMonthTranslations = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  es: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'aeptiembre',
    'ctubre',
    'noviembre',
    'diciembre',
  ],
};

const FIRST_DAY: INumberTranslations = {
  en: 0,
  es: 1, // Use Monday as first day of the week
};

function formatDay(d: Date, locale: LOCALES = 'en') {
  return `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${
    MONTHS[locale][d.getMonth()]
  } ${d.getFullYear()}`;
}

function formatMonthTitle(d: Date, locale: LOCALES = 'en') {
  return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
}

function formatWeekdayShort(i: number, locale: LOCALES) {
  return WEEKDAYS_SHORT[locale][i];
}

function formatWeekdayLong(i: number, locale: LOCALES) {
  return WEEKDAYS_SHORT[locale][i];
}

function getFirstDayOfWeek(locale: LOCALES) {
  return FIRST_DAY[locale];
}

function getMonths(locale: LOCALES) {
  return MONTHS[locale];
}

function handleFormatDate(
  d: Date,
  format?: string,
  locale: LOCALES = 'en'
): string {
  return dayjs(d)
    .locale(locale as string)
    .format(format);
}

function handleParseDate(
  str: string,
  format?: string,
  locale: LOCALES = 'en'
): Date {
  return dayjs(str, format, locale).toDate();
}

export const localeUtils: LocaleUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
  formatDate: handleFormatDate,
  parseDate: handleParseDate,
};
