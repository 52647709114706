import {
  OptionMenu,
  OptionMenuItem,
  PositionX,
  ButtonIcon,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
type IProps = {
  onViewDetail?(): void;
  onSendRules?(): void;
  onClick?(): void;
};

export const CustomActions = ({
  onViewDetail,
  onSendRules,
  onClick,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <OptionMenu
      hidePanelOnClick
      renderItem={<ButtonIcon text iconName="ListPlus" />}
      positionX={PositionX.end}
      testId="option-menu-rule"
      updateOpen={(open) => (open ? onClick && onClick() : undefined)}
    >
      {onViewDetail && (
        <OptionMenuItem
          testId="button-view-operation"
          onClick={onViewDetail}
          iconName="Eye"
        >
          {t('View operation')}
        </OptionMenuItem>
      )}
      {onSendRules && (
        <OptionMenuItem
          testId="button-send-rule"
          onClick={onSendRules}
          iconName="Clipboard"
        >
          {t('Send to rules')}
        </OptionMenuItem>
      )}
    </OptionMenu>
  );
};
