import { FlexContainer, Icon, Label, IconName } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { OperationResultReason } from '../../../state/model/operations';

type Props = {
  title: string;
  message?: string;
};

const getIcon = (message: string) => {
  let icon: IconName;

  switch (message) {
    case OperationResultReason.selphidTimeout:
    case OperationResultReason.selphiTimeout:
    case OperationResultReason.phingersTimeout:
      icon = 'Clock';
      break;
    case OperationResultReason.selphidCancelByUser:
    case OperationResultReason.selphiCancelByUser:
    case OperationResultReason.phingersCancelByUser:
    case OperationResultReason.agoraCancelByUser:
      icon = 'UserCircle';
      break;
    case OperationResultReason.facialAuthenticationNotPassed:
    case OperationResultReason.facialAuthenticationError:
      icon = 'SmileyBlank';
      break;
    case OperationResultReason.documentValidationNotPassed:
    case OperationResultReason.documentValidationError:
      icon = 'IdentificationCard';
      break;
    case OperationResultReason.facialLivenessNotPassed:
    case OperationResultReason.facialLivenessError:
      icon = 'BookmarkSimple';
      break;
    default:
      icon = 'Warning';
      break;
  }

  return icon;
};

export const DetailError = ({ title, message }: Props) => {
  const { t } = useTranslation();

  return (
    <FlexContainer flexDirection="column" rowGap="0.4">
      <FlexContainer columnGap="0.8">
        <Icon
          iconName={getIcon(message as string)}
          size="24"
          color="error"
          intensity={400}
        />
        <Label size="14" semibold color="error">
          {t(title)}
        </Label>
      </FlexContainer>
      {message && <Label size="12">{t(message)}</Label>}
    </FlexContainer>
  );
};
