import { Ref } from 'react';
import { ThemeColorType } from '../../themes';

export enum TagStatusType {
  STARTED = 'Started',
  LOGIN = 'Login',
  DISCLAIMER = 'Disclaimer',
  WELCOME = 'Welcome',
  MAIN = 'Main',
  SETTINGS = 'Settings',
  ADVANCED_SETTINGS = 'Advanced Settings',
  PREVIOUS_TIPS = 'Previous Tips',
  SELPHID_WIDGET = 'Selphid Widget',
  INTRO_CHIP = 'Intro Chip',
  CHIP_STATES = 'Chip States',
  INTRO_SELFIE = 'Intro Selfie',
  SELPHI_WIDGET = 'Selphi Widget',
  INTRO_RESULTS = 'Intro Results',
  RESULTS = 'Results',
  FINISHED = 'Finished',
  FINISH = 'Finish',
  START = 'Start',
  TERMS_WIDGET = 'Terms Widget',
  NFC_WIDGET = 'NFC',
  VIDEO_ID_WIDGET = 'Video ID',
  PHINGERS_WIDGET = 'Phingers',
  VIDEO_CALL_WIDGET = 'Video Call',
}

export type TagStatusStyleProps = {
  type: string;
  height?: 'L' | 'M';
};

export type TagStatusProps = TagStatusStyleProps & {
  testId?: string;
  className?: string;
  ref?: Ref<HTMLSpanElement>;
};

export const FlowSteps: {
  color: ThemeColorType;
  status: TagStatusType[];
}[] = [
  {
    color: 'violet',
    status: [
      TagStatusType.STARTED,
      TagStatusType.START,
      TagStatusType.LOGIN,
      TagStatusType.DISCLAIMER,
      TagStatusType.WELCOME,
      TagStatusType.MAIN,
      TagStatusType.SETTINGS,
      TagStatusType.ADVANCED_SETTINGS,
      TagStatusType.TERMS_WIDGET,
    ],
  },
  {
    color: 'yellow',
    status: [
      TagStatusType.PREVIOUS_TIPS,
      TagStatusType.SELPHID_WIDGET,
      TagStatusType.INTRO_CHIP,
      TagStatusType.CHIP_STATES,
    ],
  },
  {
    color: 'purple',
    status: [TagStatusType.INTRO_SELFIE, TagStatusType.SELPHI_WIDGET],
  },
  {
    color: 'skyBlue',
    status: [TagStatusType.NFC_WIDGET],
  },
  {
    color: 'green',
    status: [
      TagStatusType.INTRO_RESULTS,
      TagStatusType.RESULTS,
      TagStatusType.FINISHED,
      TagStatusType.FINISH,
      TagStatusType.PHINGERS_WIDGET,
    ],
  },
];
