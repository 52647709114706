import { gql } from '@apollo/client';

export const searchFaces = gql`
  query searchFaces(
    $limit: Float
    $offset: Float
    $updatedAt: timestamptz_comparison_exp
  ) {
    searchFaces(offset: $offset, limit: $limit, updatedAt: $updatedAt) {
      faces {
        id
        media_id
        face {
          name
          id
        }
        description
        tenant_id
        updated_at
        description
        created_at
      }
      count
    }
  }
`;
