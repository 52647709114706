import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useCommon } from '@facephi/inphinite-common';
import {
  Avatar,
  DropdownSearchController,
  DropdownSearchOption,
  Input,
  Spinner,
  ToastType,
  useToast,
} from '@facephi/inphinite-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  createGroupSchema,
  GroupCreateDto,
  UserListDto,
} from '../../state/model';
import { createGroup } from '../../state/mutations';
import { getUsers } from '../../state/queries';
import {
  ModalManagementNewUserStyles,
  SpinnerAbsolutePlacement,
} from './Styles';

type Props = {
  show: boolean;
  onChangeShow(show: boolean): void;
};

export const ModalManagementNewGroup = ({ show, onChangeShow }: Props) => {
  const { t, i18n } = useTranslation();
  const {
    tenant: { id: tenantId },
  } = useCommon();
  const { toastManager } = useToast();

  const { data: rawUsers } = useQuery<{
    listUsers: { users: [{ node: UserListDto }] };
  }>(getUsers, {
    variables: { username: '' },
    nextFetchPolicy: 'cache-first',
  });

  const users: DropdownSearchOption[] = useMemo(
    () =>
      rawUsers?.listUsers.users?.reduce(
        (acc, { node }: { node: UserListDto }) => {
          acc.push({
            name: node.username,
            value: node.id,
            icon: (
              <Avatar
                src={node.personalInformation.avatar || '/Avatar.png'}
                size={2}
              />
            ),
          });
          return acc;
        },
        [] as DropdownSearchOption[]
      ) || [],
    [rawUsers]
  );

  const {
    handleSubmit,
    register,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createGroupSchema),
  });

  useEffect(() => {
    if (!show) {
      setValue('groupName', undefined);
      setValue('users', undefined);
    }
  }, [show]);

  const [insertGroup, { loading }] = useMutation(createGroup, {
    onError: (error) => {
      setError('groupName', {
        type: 'manual',
        message: error.message,
      });
    },
    onCompleted: () => {
      onChangeShow(false);
      toastManager({
        $type: ToastType.SUCCESS,
        message: t('Group successfully created'),
        duration: 3000,
        testId: 'save-profile',
      });
    },
  });

  const onSumbit = async (data: GroupCreateDto) => {
    await insertGroup({
      variables: {
        name: data.groupName,
        users: data.users,
        tenantId,
      },
      refetchQueries: ['searchUsers', 'searchGroups'],
    });
  };

  return (
    <ModalManagementNewUserStyles
      show={show}
      title={t('Create a new group')}
      iconHeader="PlusCircle"
      onChangeShow={onChangeShow}
      onCreate={handleSubmit(onSumbit)}
      closeLabel={t('Cancel')}
      submitLabel={t('Save')}
      flexDirectionContent="column"
      rowGapContent="2.4"
    >
      {loading && (
        <SpinnerAbsolutePlacement>
          <Spinner />
        </SpinnerAbsolutePlacement>
      )}

      <Input
        type="text"
        label={t('Group name')}
        {...register('groupName')}
        errorLabel={errors.groupName?.message && t(errors.groupName.message)}
        testId="input-group-name"
      />

      <DropdownSearchController
        name="users"
        control={control}
        options={users}
        multiple
        label={t('Users')}
        placeholder={t('Select users')}
        locale={i18n.language}
        errorLabel={errors.users?.message && t(errors.users.message)}
      />
    </ModalManagementNewUserStyles>
  );
};
