import React, {
  ChangeEvent,
  forwardRef,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isEqual } from 'lodash';
import {
  DropdownSearchOption,
  DropdownSearchProps,
  PositionType,
} from './model';
import {
  DropdownEmpty,
  DropdownSearchStyles,
  TagButton,
  TagContent,
  TagListContent,
} from './Styles';
import { useDeviceSize } from '../../hooks';
import { stringMatches } from '../../utils/strings';
import { DEFAULT_LOCALE, translate } from '../../utils/translations';
import {
  DropdownInputSearchMobile,
  DropdownItem,
  DropdownWrapperStyles,
} from '../dropdown/Styles';
import { Label } from '../label';
import { Portal } from '../portal';

export const DropdownSearch = forwardRef(
  (
    {
      placeholder,
      options = [],
      value,
      onChange,
      testId,
      disabled,
      label,
      className,
      errorLabel,
      iconLeft,
      multiple = false,
      locale = DEFAULT_LOCALE,
      helpLabel,
      name,
      autoComplete = 'no',
      showListContent = true,
      onClose,
    }: DropdownSearchProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState<boolean>();

    const [searchText, setSearchText] = useState<string>('');
    const [isShowingSearch, setSearchVisibility] = useState<boolean>();
    const { isMobile } = useDeviceSize();

    const [innerValue, setInnerValue] = useState<string[] | number[]>(
      multiple
        ? (value as string[] | number[]) || []
        : ([value] as string[] | number[])
    );

    useEffect(() => {
      const newValue = multiple
        ? (value as string[] | number[]) || []
        : ([value] as string[] | number[]);
      setInnerValue((prev) => (isEqual(newValue, prev) ? prev : newValue));
    }, [value]);

    useEffect(() => {
      if (!show) {
        setSearchVisibility(false);
        setSearchText('');
      }
    }, [show]);

    const onSelectItem = (selected: string | number) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setInnerValue((values: any) =>
        values.includes(selected as never)
          ? values.filter((el: string | number) => el !== selected)
          : multiple
          ? [...values, selected]
          : [selected]
      );
      if (!multiple) {
        setSearchVisibility(false);
        setShow(false);
      }
    };

    const getValue = () => (multiple ? innerValue : innerValue[0]);

    useEffect(() => {
      onChange && onChange(getValue());
    }, [innerValue]);

    const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
      setSearchVisibility(true);
      setSearchText(ev.target.value);
    };

    const list = useMemo(() => {
      const selectedItem = !searchText
        ? options.find((item) => item.value === innerValue[0])
        : undefined;
      const firstItems: DropdownSearchOption[] =
        multiple || !selectedItem ? [] : [selectedItem];
      return firstItems.concat(
        options.filter((item) => {
          if (!isShowingSearch || !searchText) {
            return multiple ? true : item.value !== innerValue[0];
          }
          return stringMatches(item.name, searchText);
        })
      );
    }, [isShowingSearch, searchText, options, innerValue]);

    const itemToShow = useMemo(() => {
      if (multiple || isShowingSearch) {
        return;
      }

      return options.find((item) => item.value === innerValue[0]);
    }, [multiple, isShowingSearch, options, innerValue]);

    const handleClickOutside = (ev: globalThis.MouseEvent) => {
      const position: PositionType | undefined =
        inputRef.current?.getBoundingClientRect();
      if (
        !(
          position &&
          ev.x > position.x &&
          ev.x < position.right &&
          ev.y > position.y &&
          ev.y < position.bottom
        )
      ) {
        setShow(false);
      }
    };

    useEffect(() => {
      if (show !== undefined && !show) {
        onClose && onClose(getValue());
      }
    }, [show]);

    return (
      <div className={className}>
        <DropdownSearchStyles
          ref={ref}
          label={label}
          iconLeft={iconLeft && !itemToShow?.icon ? iconLeft : undefined}
          elementLeft={itemToShow?.icon}
          iconRight="CaretDown"
          onClickIconRight={() => setShow(!show)}
          placeholder={placeholder}
          onClick={() => !disabled && setShow(true)}
          divRef={inputRef}
          value={itemToShow?.name || searchText}
          testId={testId}
          disabled={disabled}
          errorLabel={errorLabel}
          onChange={handleInputChange}
          helpLabel={helpLabel}
          name={name}
          autoComplete={autoComplete}
        />
        <Portal
          actionRef={inputRef}
          show={show || false}
          onClickOutside={handleClickOutside}
          isMobile={isMobile}
          overlayBlur={isMobile}
        >
          <DropdownWrapperStyles data-test="dropdown-list">
            {isMobile && (
              <DropdownInputSearchMobile
                autoFocus
                value={itemToShow?.name || searchText}
                onChange={handleInputChange}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete={autoComplete}
                data-test={`${testId}-mobile`}
              />
            )}
            {!list.length ? (
              <DropdownEmpty flexDirection="column" rowGap="1">
                <Label size="12" semibold>
                  {translate('No results found', locale)}
                </Label>
                <Label size="12">
                  {translate('Try again by changing the search text', locale)}
                </Label>
              </DropdownEmpty>
            ) : (
              <ul>
                {list.map((item: DropdownSearchOption) => (
                  <DropdownItem
                    onClick={() => onSelectItem(item.value)}
                    alignItems="center"
                    columnGap="0.8"
                    data-test={`option-${item.value}`}
                    key={item.value}
                    as="li"
                    active={innerValue.includes(item.value as never)}
                  >
                    {item.icon}
                    {item.name}
                  </DropdownItem>
                ))}
              </ul>
            )}
          </DropdownWrapperStyles>
        </Portal>
        {showListContent && multiple && !!innerValue.length && (
          <TagListContent
            wrap="wrap"
            rowGap="0.6"
            columnGap="0.4"
            as="ul"
            data-test="tag-list"
          >
            {innerValue.map((item: number | string) => {
              const element = options.find(({ value }) => item === value);
              return (
                element && (
                  <TagContent
                    key={element.value}
                    columnGap="0.6"
                    alignItems="center"
                    data-test={`tag-${element.value}`}
                  >
                    {element.icon}
                    <Label size="10">{element.name}</Label>
                    <TagButton
                      iconName="XCircle"
                      onClick={() => onSelectItem(item)}
                      text
                      size="S"
                    />
                  </TagContent>
                )
              );
            })}
          </TagListContent>
        )}
      </div>
    );
  }
);
