import { Asset, MimeType } from '@facephi/inphinite-common';
import {
  ButtonIcon,
  FlexContainer,
  IconName,
  OptionMenu,
  OptionMenuItem,
  Chips,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  TableAssetsContainer,
  TableAssetsDropdown,
  TableAssetsStyles,
  TableAssetsVideoCall,
} from './Styles';

type IProps = {
  assets?: Asset[];
  onVideoCall?(): void;
  disabled?: boolean;
  onClick?(position: number): void;
  dataTour?: string;
};

export const TableAssets = ({
  assets = [],
  onVideoCall,
  disabled,
  onClick,
  dataTour,
}: IProps) => {
  const { t } = useTranslation();

  const icon: Record<MimeType, IconName> = {
    [MimeType.jpg]: 'FileArrowDown',
    [MimeType.jpeg]: 'FileArrowDown',
    [MimeType.png]: 'FileArrowDown',
    [MimeType.pdf]: 'FilePdf',
    [MimeType.mp4]: 'VideoCamera',
  };

  const MAX_ASSETS = 2;

  const getIcon = (contentType: MimeType): IconName => {
    return icon[contentType] ? icon[contentType] : icon[MimeType.jpg];
  };

  const onClickAsset = (position: number) => {
    onClick && onClick(position);
  };

  const renderAssets = (arr: Asset[]) => {
    return arr.map((item: Asset, index) => {
      const iconName = getIcon(item.contentType);
      return (
        <ButtonIcon
          key={item.url}
          iconName={iconName as IconName}
          text
          onClick={() => onClickAsset(index)}
          testId={`button-asset-${index}`}
          disabled={disabled}
          size="S"
        />
      );
    });
  };

  return (
    <TableAssetsContainer>
      <TableAssetsStyles alignItems="center" justifyContent="center">
        <FlexContainer data-tour={assets.length ? dataTour : ''}>
          {assets.length > MAX_ASSETS ? (
            <FlexContainer alignItems="center">
              {renderAssets(assets.slice(0, MAX_ASSETS))}
              <OptionMenu
                renderItem={
                  <TableAssetsDropdown alignItems="center" columnGap="0.8">
                    <Chips
                      iconName="Plus"
                      label={`${assets.length - MAX_ASSETS}`}
                    />
                  </TableAssetsDropdown>
                }
              >
                {assets.slice(MAX_ASSETS, assets.length).map((item, index) => (
                  <OptionMenuItem
                    key={index}
                    iconName={getIcon(item.contentType)}
                    onClick={() => onClickAsset(index + MAX_ASSETS)}
                    disabled={disabled}
                    size="10"
                  >
                    {t(item.type)}
                  </OptionMenuItem>
                ))}
              </OptionMenu>
            </FlexContainer>
          ) : (
            renderAssets(assets)
          )}
        </FlexContainer>

        {onVideoCall && (
          <TableAssetsVideoCall iconName="PhoneCall" onClick={onVideoCall} />
        )}
      </TableAssetsStyles>
    </TableAssetsContainer>
  );
};
