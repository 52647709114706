import React from 'react';
import {
  ButtonIcon,
  FlexContainer,
  InputDatePicker,
} from '@facephi/inphinite-ui';

export const HeaderUX = () => {
  return (
    <>
      <FlexContainer alignItems="center" justifyContent="space-between">
        <FlexContainer columnGap="0.8">
          <InputDatePicker />
        </FlexContainer>
        <FlexContainer columnGap="1.6">
          <ButtonIcon iconName="Printer" />
          <ButtonIcon iconName="DownloadSimple" />
          <ButtonIcon iconName="Copy" />
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default HeaderUX;
