import { useEffect, useState } from 'react';
import { NoFilesUploaded } from '@facephi/inphinite-common';
import {
  CardBaseContent,
  FlexContainer,
  Label,
  Spinner,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../hooks';
import { TimingResponse } from '../../../state/model';
import { TimingLabel, TimingFooter } from '../Styles';
import { TimingImage } from './TimingImage';

export const GlobalTimeCard = () => {
  const [timing, setTiming] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [operations, setOperations] = useState<number>(0);
  const { t } = useTranslation();
  const { getData } = useApi();

  useEffect(() => {
    getData('timings')
      .then((response: TimingResponse) => {
        const globalTime =
          response.data.timing > 0
            ? Number(response.data.timing) / Number(response.data.operations)
            : 0;
        setTiming(globalTime);
        setError(false);
        setLoading(false);
        setOperations(response.data.operations);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  if (loading)
    return (
      <>
        <Spinner size="M" genericSpinner insideComponent />
      </>
    );

  if (!loading && error) return <NoFilesUploaded messageType="information" />;

  return (
    <>
      <CardBaseContent columnGap="2.6" justifyContent="center">
        {loading && <Spinner insideComponent />}
        {!loading && (
          <>
            <TimingImage />
            <FlexContainer flexDirection="column">
              <TimingLabel size="32" data-test="timing">
                {Math.round(timing)}s
              </TimingLabel>
            </FlexContainer>
          </>
        )}
      </CardBaseContent>
      <TimingFooter>
        <Label size="14" semibold success>
          {operations} {t('Operations')}
        </Label>
      </TimingFooter>
    </>
  );
};
