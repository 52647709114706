import { gql } from '@apollo/client';

export const insertFace = gql`
  mutation createFace($face: faces_insert_input!) {
    insertFace(face: $face) {
      id
      media_id
      face {
        id
        name
      }
      description
      tenant_id
      updated_at
      created_at
    }
  }
`;

export const updateFace = gql`
  mutation updateFace($id: uuid!, $face: faces_set_input!) {
    updateFace(id: $id, face: $face) {
      id
      media_id
      face {
        id
        name
      }
      tenant_id
      description
      updated_at
      created_at
    }
  }
`;

export const removeFace = gql`
  mutation deleteFaces($ids: [uuid!]!) {
    deleteFaces(ids: $ids) {
      returning {
        id
      }
    }
  }
`;

export const undoRemoveFace = gql`
  mutation undoDeleteFaces($ids: [uuid!]!) {
    undoDeleteFaces(ids: $ids) {
      returning {
        id
      }
    }
  }
`;
