import { LayoutCorner } from '@facephi/inphinite-common';
import styled from 'styled-components/macro';

export const LayoutCornerBackOffice = styled(LayoutCorner)`
  a {
    color: ${(props) => props.theme.color.blue[400]};
  }
`;

export const BrowserImage = styled.img`
  width: 3.2rem;
  height: 3.2rem;
`;

export const BrowserLogo = styled.img`
  width: 20rem;
  height: 7rem;
  object-fit: cover;
`;
