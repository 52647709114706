import styled from 'styled-components';
import { IconWrapperPropsStyle, IconWrapperSize } from './model';

export const ICONWRAPPER_SIZE_L = '4.8rem';
export const ICONWRAPPER_SIZE_M = '4rem';

const getSize = (size?: IconWrapperSize) => {
  switch (size) {
    case 'XL':
      return `min-width: 5.6rem; width: 5.6rem; height: 5.6rem;`;
    case 'L':
      return `min-width: ${ICONWRAPPER_SIZE_L}; width: ${ICONWRAPPER_SIZE_L}; height: ${ICONWRAPPER_SIZE_L};`;
    case 'M':
      return `min-width: ${ICONWRAPPER_SIZE_M}; width: ${ICONWRAPPER_SIZE_M}; height: ${ICONWRAPPER_SIZE_M};`;
    case 'S':
      return `min-width: 3rem; width: 3rem; height: 3rem;`;
    case 'XS':
      return `min-width: 2.4rem; width: 2.4rem; height: 2.4rem;`;
  }
};

const getSVGSize = (size?: IconWrapperSize) => {
  switch (size) {
    case 'XL':
      return `min-width: 3.2rem; width: 3.2rem; height: 3.2rem;`;
    case 'L':
      return `min-width: 3.2rem; width: 3.2rem; height: 3.2rem;`;
    case 'M':
      return `min-width: 2.4rem; width: 2.4rem; height: 2.4rem;`;
    case 'S':
      return `min-width: 1.8rem; width: 1.8rem; height: 1.8rem;`;
    case 'XS':
      return `min-width: 1.6rem; width: 1.6rem; height: 1.6rem;`;
  }
};

export const IconWrapperStyle = styled.div<IconWrapperPropsStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => getSize(props.size)};
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '0.8rem')};
  background-color: ${(props) =>
    props.theme.color[props.backgroundColor][
      props.backgroundIntensity ? props.backgroundIntensity : 100
    ]};
  svg {
    ${(props) => getSVGSize(props.size)};
  }
`;
