import * as React from 'react';
import { IconCustomProps } from '../model';

export const Office365Icon = ({ className, size, color }: IconCustomProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      className={className}
      width={size ? (size as number) : undefined}
      height={size ? (size as number) : undefined}
    >
      <path
        d="M20.4926 20.1241L20.4909 20.1232V3.69803L14.6059 2L4.02655 5.97241L4 5.97752L4.00125 17.8541L7.61149 16.4411V6.77812L14.6059 5.10588L14.605 19.3129L4.0072 17.854L14.605 21.7674V21.7717L20.4909 20.1428V20.1241H20.4926V20.1241Z"
        fill={color}
      />
    </svg>
  );
};
