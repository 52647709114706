import { gql } from '@apollo/client';

export const searchIPs = gql`
  query searchIPs(
    $limit: Float
    $offset: Float
    $updatedAt: timestamptz_comparison_exp
  ) {
    searchIPs(offset: $offset, limit: $limit, updatedAt: $updatedAt) {
      ips {
        id
        ip
        description
        updated_at
      }
      count
    }
  }
`;
