import React from 'react';
import { CardBaseProps } from './model';
import { CardBaseStyle } from './Styles';

export const CardBase = ({
  children,
  className,
  style,
  asRow,
  testId,
  ref,
  as = 'section',
  onClick,
  noPadding = false,
  ...rest
}: CardBaseProps) => {
  return (
    <CardBaseStyle
      className={className}
      flexDirection={asRow ? 'row' : 'column'}
      as={as}
      style={style}
      data-test={testId}
      ref={ref}
      noPadding={noPadding}
      onClick={onClick}
      role={onClick && 'button'}
      {...rest}
    >
      {children}
    </CardBaseStyle>
  );
};
