import { Label } from '@facephi/inphinite-ui';
import { RoleInfoLabelProps, RoleProperties } from './model';

export const RoleInfoLabel = ({
  role,
  size,
  children,
  ...rest
}: RoleInfoLabelProps) => {
  return (
    <Label size={size} color={RoleProperties[role]?.color || 'greys'} {...rest}>
      {children}
    </Label>
  );
};
