import { Ref } from 'react';
import { ThemeColorType } from '../../themes';
import { IconName } from '../icon';

export enum FlowType {
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  STEP3 = 'STEP3',
  STEP4 = 'STEP4',
}

export type FlowBoxType = {
  step: FlowType;
  iconName: IconName;
  color: ThemeColorType;
};

export type FlowProps = {
  testId?: string;
  className?: string;
  ref?: Ref<HTMLDivElement>;
};

export type SquareProps = FlowProps & {
  step: FlowType;
};
