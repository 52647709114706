import React, { ElementType, useEffect, useState } from 'react';
import { MimeType, useLoadAsset } from '@facephi/inphinite-common';
import {
  CardBaseHeader,
  IconName,
  Label,
  IconWrapper,
  Spinner,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
  OperationsCardDocumentsStyles,
  ImageWrapper,
  ItemWrapper,
  TextWrapper,
} from './Styles';

type Props = {
  title?: string;
  children?: React.ReactNode;
  contentElementType?: ElementType;
  className?: string;
  iconName?: IconName;
  icon?: React.ReactNode;
  loading?: boolean;
  hasError?: boolean;
  iconLeft?: IconName;
  onClick: () => void;
  image?: boolean;
  mimeType?: MimeType;
  source: string;
  resource?: string;
};

export const OperationsFileCard = ({
  title,
  children,
  contentElementType,
  className,
  iconName,
  icon,
  loading,
  hasError,
  iconLeft,
  onClick,
  image,
  mimeType = MimeType.png,
  source,
  resource,
}: Props) => {
  const { t } = useTranslation();
  const [asset, setAsset] = useState<string>();
  const { resourceUrl, loadingResource } = useLoadAsset(source, mimeType);

  useEffect(() => {
    const newAsset = source ? resourceUrl : resource;
    setAsset(newAsset);
  }, [source, resourceUrl, resource]);

  const getLoadingItem = () => (
    <>
      <div style={{ width: '100%' }}>
        <Skeleton height={18} width="40%" borderRadius={8} />
      </div>
      {iconName && <Skeleton height={18} width={18} borderRadius={8} />}
    </>
  );

  const getItem = () =>
    loadingResource ? (
      <Spinner insideComponent size={'S'} />
    ) : (
      <>
        <ItemWrapper>
          {iconLeft && !image && (
            <>
              <IconWrapper
                backgroundColor="greys"
                iconName={iconLeft}
                size={'M'}
              />
            </>
          )}
          {image && (
            <>
              <ImageWrapper src={asset} alt={'associated capture'} />
            </>
          )}
          <TextWrapper>
            {title && (
              <Label size="14" semibold ellipsis>
                {t(title)}
              </Label>
            )}
          </TextWrapper>
        </ItemWrapper>
        {icon && <>{icon}</>}
      </>
    );

  return (
    <OperationsCardDocumentsStyles
      flexDirection="row"
      className={className}
      hasError={hasError}
      onClick={onClick}
    >
      {title && (
        <CardBaseHeader
          alignItems="center"
          justifyContent="space-between"
          columnGap="0.8"
        >
          {loading ? getLoadingItem() : getItem()}
        </CardBaseHeader>
      )}
    </OperationsCardDocumentsStyles>
  );
};
