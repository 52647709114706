import React, { ForwardedRef } from 'react';
import {
  CardBaseHeader,
  IconName,
  Label,
  IconWrapper,
  Spinner,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
  OperationsCardDocumentsStyles,
  ItemWrapper,
  TextWrapper,
} from './Styles';

type Props = {
  assetsNumber: number;
  className?: string;
  iconName?: IconName;
  icon?: React.ReactNode;
  iconLeft?: IconName | null;
  innerRef: ForwardedRef<null>;
  loading?: boolean;
  title: string;
  onClick?: () => void;
};

export const OperationsFilesCardMenu = ({
  assetsNumber,
  className,
  icon,
  iconLeft,
  iconName,
  innerRef,
  loading,
  title,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const getLoadingItem = () => {
    return (
      <>
        <div style={{ width: '100%' }}>
          <Skeleton height={18} width="40%" borderRadius={8} />
        </div>
        {iconName && <Skeleton height={18} width={18} borderRadius={8} />}
      </>
    );
  };

  const getItem = () =>
    loading ? (
      <Spinner insideComponent size={'S'} />
    ) : (
      <>
        <ItemWrapper>
          {iconLeft && (
            <IconWrapper
              backgroundColor="greys"
              iconName={iconLeft}
              size={'M'}
            />
          )}
          <TextWrapper>
            {title && (
              <Label size="14" semibold ellipsis>
                {`${t(title)} (${assetsNumber})`}
              </Label>
            )}
          </TextWrapper>
        </ItemWrapper>
        {icon && <>{icon}</>}
      </>
    );

  return (
    <OperationsCardDocumentsStyles
      flexDirection="row"
      className={className}
      onClick={props.onClick}
      ref={innerRef}
    >
      {title && (
        <CardBaseHeader
          alignItems="center"
          justifyContent="space-between"
          columnGap="0.8"
        >
          {loading ? getLoadingItem() : getItem()}
        </CardBaseHeader>
      )}
    </OperationsCardDocumentsStyles>
  );
};
