import React, { createContext, useEffect, useState } from 'react';
import { Layout, LayoutProps } from '@backoffice/components';
import { AppName, RoutesName } from '@backoffice/state/constants';
import { useAntifraud } from '@facephi/inphinite-antifraud';
import {
  AsideNavItem,
  Menu,
  SCOPES,
  useFlags,
  usePermissions,
} from '@facephi/inphinite-common';
import { useTranslation } from 'react-i18next';

type IProps = {
  children: React.ReactNode;
  app: AppName;
};

type ContextProps = {
  goBackRoute?: LayoutProps['goBackRoute'];
  setGoBackRoute(goBackRoute: LayoutProps['goBackRoute']): void;
  title?: string;
  setTitle(title: string): void;
  leftPanel?: boolean;
  activeLeftPanel(leftPanel: boolean): void;
};

const Ctx = createContext<ContextProps>({
  setTitle: () => {},
  activeLeftPanel: () => {},
  setGoBackRoute: () => {},
});

export const LayoutProvider = ({ children, app }: IProps) => {
  const { demo } = useFlags();
  const [leftPanel, activeLeftPanel] = useState<boolean>(false);
  const [title, setTitle] = useState<string | undefined>();
  const [goBackRoute, setGoBackRoute] = useState<LayoutProps['goBackRoute']>();

  const { t } = useTranslation();
  const { submenuAntifraud } = useAntifraud();
  const { hasPermission } = usePermissions();

  const menus: Menu = {
    [AppName.operations]: [
      {
        icon: 'Gauge',
        link: RoutesName.dashboard,
        id: '1',
        label: t('Overview'),
        visible: hasPermission([SCOPES.canAccessOperations]),
      },
      {
        icon: 'ListBullets',
        link: RoutesName.operations,
        id: '2',
        label: t('List'),
        visible: hasPermission([SCOPES.canAccessOperations]),
      },
      {
        icon: 'FileText',
        link: RoutesName.reporting,
        label: t('Reporting'),
        id: '3',
        visible: true,
        flags: [demo],
      },
    ],
    [AppName.studio]: [
      {
        icon: 'Pencil',
        link: RoutesName.studio,
        id: '1',
        visible: true,
        label: t('Design'),
      },
      {
        icon: 'GitBranch',
        link: RoutesName.workflows,
        id: '2',
        visible: true,
        label: t('Flows'),
      },
      {
        icon: 'Layout',
        link: RoutesName.template,
        visible: true,
        id: '3',
        label: t('Template'),
      },
    ],
    [AppName.ux]: [],
    [AppName.antiFraud]: submenuAntifraud,
    [AppName.ai]: [
      {
        icon: 'Table',
        link: RoutesName.ai,
        visible: true,
        id: '1',
        label: t('List'),
      },
    ],
    [AppName.videoAssistance]: [
      {
        icon: 'Table',
        link: RoutesName.videoCalls,
        visible: true,
        id: '1',
        label: t('Call list'),
      },
    ],
    [AppName.audit]: [],
    [AppName.userManagement]: [
      {
        icon: 'Table',
        link: RoutesName.userManagement,
        visible: hasPermission([SCOPES.canViewUserManagment]),
        id: '1',
        label: t('User list'),
      },
    ],
  };

  const [menu, setMenu] = useState<AsideNavItem[]>([]);

  useEffect(() => {
    setMenu(menus[app] || []);
  }, [app]);

  return (
    <Ctx.Provider
      value={{
        title,
        setTitle,
        activeLeftPanel,
        goBackRoute,
        setGoBackRoute,
      }}
    >
      <Layout
        menu={menu}
        title={title}
        goBackRoute={goBackRoute}
        leftPanel={leftPanel}
        app={app}
      >
        {children}
      </Layout>
    </Ctx.Provider>
  );
};
export const useLayout = () => React.useContext(Ctx);
