import styled from 'styled-components';
import { Button } from '../button';
import { FlexContainer } from '../flexContainer';

export const EmptyDataStyles = styled(FlexContainer)`
  padding: 6rem 0;

  @media (min-resolution: 1.5dppx) {
    padding: 3rem 0;
  }
`;

export const ReloadButton = styled(Button)`
  min-height: 4.8rem;
`;
