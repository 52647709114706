import styled from 'styled-components';
import { ThemeColorType } from '../../../themes';
import { FlexContainer } from '../../flexContainer';
import { SquareProps } from '../model';

export const SquareFlowStyles = styled(FlexContainer)<SquareProps>``;

export const SquareFlowBox = styled(FlexContainer)<{
  color: ThemeColorType;
}>`
  border-radius: 0.8em;
  height: 3rem;
  width: 4rem;
  border-left-width: 0.8rem;
  border-left-style: solid;
`;
