import { AlignItemsProps } from '../flexContainer/model';
import { IconName } from '../icon';

export type ToastStyleProps = {
  $type?: ToastType;
};
export type ToastProps = ToastStyleProps & {
  message: string;
  messageSemiBold?: boolean;
  subMessage?: string;
  id?: string;
  testId?: string;
  onClick?(): void;
  duration?: number;
  hasIcon?: boolean;
  canClose?: boolean;
  buttonMessage?: string;
  iconName?: IconName;
  alignItems?: AlignItemsProps;
};

export enum ToastType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
}
