import React from 'react';
import { FilterMenuProps } from './model';
import { FilterMenuStyles } from './Styles';
import { ButtonIcon } from '../buttonIcon/ButtonIcon';
import { PositionY, PositionX } from '../portal';

export const FilterMenu = ({
  children,
  testId,
  className,
  labelTooltip,
  size = 'M',
  positionX = PositionX.right,
  positionY = PositionY.bottom,
}: FilterMenuProps) => {
  return (
    <FilterMenuStyles
      testId={testId}
      positionX={positionX}
      positionY={positionY}
      renderItem={
        <ButtonIcon
          className={className}
          iconName="Faders"
          text
          labelTooltip={labelTooltip}
          size={size}
        />
      }
    >
      {children}
    </FilterMenuStyles>
  );
};
