import styled from 'styled-components';
import { FlexContainer } from '../flexContainer';

export const LabelBaseStyles = styled(FlexContainer)`
  height: 3.2rem;
  border-radius: 3.2rem;
  background-color: ${(props) => props.theme.color.greys[100]};
  padding: 0 1.6rem;

  &:hover {
    box-shadow: 0px 2px 14px ${(props) => props.theme.color.greys[100]};
    transition: all 0.2s ease-in-out;
  }

  p {
    white-space: nowrap;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;
