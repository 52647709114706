import { useMemo } from 'react';
import { FacialLivenessDiagnostic } from '@facephi/inphinite-common';
import { useTranslation } from 'react-i18next';
import { infoLiveness, LivenessIconInfo } from './model';
import { OperationsCardStatus } from './operationsCards/OperationsCardStatus';

type Props = {
  type?: FacialLivenessDiagnostic;
  loading?: boolean;
};

export const FacialLiveness = ({ type, loading }: Props) => {
  const { t } = useTranslation();
  const iconType: LivenessIconInfo = useMemo(() => {
    const iconProps =
      type === FacialLivenessDiagnostic.live ? 'success' : 'error';
    return infoLiveness[iconProps];
  }, [type]);

  return (
    <OperationsCardStatus
      loading={loading}
      type={iconType}
      label={t('Liveness passive')}
    />
  );
};
