import { AppName, RoutesName } from '@backoffice/state/constants';
import { Roles } from '@facephi/inphinite-common';
import {
  Icon,
  IconName,
  IThemeProps,
  Label,
  ThemeColorType,
} from '@facephi/inphinite-ui';
import { Trans } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { ReactourStep } from 'reactour';
import { ModalGuideAnimation } from '../ModalGuideAnimation';
import { CardTour, ContentTour, StepTour } from '../Styles';

export type TutorialType = {
  title: string;
  description: string;
  backgroundColor: ThemeColorType;
  iconName: IconName;
  steps: ReactourStep[];
  demo?: boolean;
  roles?: Roles[];
};

export type TourParams = {
  theme: IThemeProps;
  t(text: string): string;
  historyPush?(route: string): void;
  getLottie(text: string): Record<string, unknown>;
  onCloseModal?(): void;
};

export const generalTour = ({
  theme,
  t,
  historyPush,
  getLottie,
  onCloseModal,
}: TourParams): TutorialType[] => [
  {
    title: t('Profile & Account'),
    description: t('Change your profile information'),
    backgroundColor: 'violet',
    iconName: 'UserGear',
    steps: [
      {
        stepInteraction: false,
        action: () => historyPush?.(RoutesName.profile),
        selector: '[data-tour="operations_download-assets"]',
        resizeObservables: ['[data-tour="operations_download-assets"]'],
        content: () => (
          <CardTour
            titleHeader={t('Profile & Account')}
            iconHeader="UserGear"
            iconBackgroundColor="violet"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <ModalGuideAnimation animation={getLottie('profileAccount')} />
              <Label size="14">
                {t(
                  'Setup all your profile information -> name, email, languages and timezone'
                )}
              </Label>
            </ContentTour>
          </CardTour>
        ),
      },
    ],
  },
  {
    title: t('Users management'),
    description: t('Set up permissions of your users'),
    backgroundColor: 'green',
    iconName: 'CrownSimple',
    demo: true,
    steps: [
      {
        stepInteraction: false,
        action: () =>
          historyPush?.(generatePath(RoutesName.management, { type: 'users' })),
        selector: '[data-tour="settings_add-new"]',
        resizeObservables: ['[data-tour="settings_add-new"]'],
        content: () => (
          <CardTour
            titleHeader={t('Users management')}
            iconHeader="CrownSimple"
            iconBackgroundColor="green"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <Label size="14">
                {t(
                  'Click on "Add new" to add a new user or create a new group.'
                )}
              </Label>
            </ContentTour>
          </CardTour>
        ),
      },
      {
        stepInteraction: false,
        action: () =>
          historyPush?.(generatePath(RoutesName.management, { type: 'users' })),
        selector: '[data-tour="settings_permissions"]',
        resizeObservables: ['[data-tour="settings_permissions"]'],
        content: () => (
          <CardTour
            titleHeader={t('Users management')}
            iconHeader="CrownSimple"
            iconBackgroundColor="green"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <Label size="14">
                {t(
                  'Click on "Set up permissions" to assign a user a permission.'
                )}
              </Label>
            </ContentTour>
          </CardTour>
        ),
      },
      {
        action: () => historyPush?.(RoutesName.newPermissions),
        selector: '[data-tour="settings_add-item"]',
        resizeObservables: [
          '[data-tour="settings_add-item"]',
          '[data-tour="settings_new-item"]',
        ],
        highlightedSelectors: ['[data-tour="settings_new-item"]'],
        mutationObservables: ['[data-tour="settings_new-item"]'],
        content: () => (
          <CardTour
            titleHeader={t('Users management')}
            iconHeader="CrownSimple"
            iconBackgroundColor="green"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <Label size="14">
                {t(
                  'Click on "Add user or group" and a menu swipes up. Start writing a name of a user or a group.'
                )}
              </Label>
            </ContentTour>
          </CardTour>
        ),
      },
      {
        stepInteraction: false,
        action: () => historyPush?.(RoutesName.newPermissions),
        selector: '[data-tour="settings_new-item"]',
        resizeObservables: ['[data-tour="settings_new-item"]'],
        content: () => (
          <CardTour
            titleHeader={t('Users management')}
            iconHeader="CrownSimple"
            iconBackgroundColor="green"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <img src="/noUsers.svg" alt="no-users" width="500" />
              <Label size="14">
                {t(
                  'If the user or group don’t exist, click on "User" to add user. Click on “Group” to add group.'
                )}
              </Label>
            </ContentTour>
          </CardTour>
        ),
      },
      {
        action: () => historyPush?.(RoutesName.newPermissions),
        selector: '[data-tour="settings_add-permission"]',
        resizeObservables: ['[data-tour="settings_add-permission"]'],
        content: () => (
          <CardTour
            titleHeader={t('Users management')}
            iconHeader="CrownSimple"
            iconBackgroundColor="green"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <img src="/noUsers.svg" alt="no-users" width="500" />
              <Label size="14">
                {t(
                  'To add permissions, drag and drop the box on the left in the center of the page.'
                )}
              </Label>
            </ContentTour>
          </CardTour>
        ),
      },
      {
        stepInteraction: false,
        action: () => historyPush?.(RoutesName.newPermissions),
        selector: '[data-tour="settings_save-permissions"]',
        resizeObservables: ['[data-tour="settings_save-permissions"]'],
        content: () => (
          <CardTour
            titleHeader={t('Users management')}
            iconHeader="CrownSimple"
            iconBackgroundColor="green"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <Label size="14">{t('Click here to Save.')}</Label>
            </ContentTour>
          </CardTour>
        ),
      },
    ],
  },
  {
    title: t('Edit profile'),
    description: t("Change user's profile information"),
    backgroundColor: 'yellow',
    iconName: 'Pencil',
    roles: [Roles.Admin, Roles.UserManagement],
    steps: [
      {
        stepInteraction: false,
        action: () =>
          historyPush?.(
            generatePath(RoutesName.userManagement, { type: 'users' })
          ),
        selector: '[data-tour="settings_user-action"]',
        resizeObservables: ['[data-tour="settings_user-action"]'],
        mutationObservables: ['[data-tour="settings_user-action"]'],
        content: () => (
          <CardTour
            titleHeader={t('Edit profile')}
            iconHeader="Pencil"
            iconBackgroundColor="yellow"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <Label size="14">
                {t(
                  'In the user table click on Action icon to see the option menu'
                )}
              </Label>
              <Label size="14">
                {t('Then click on See profile to edit profile')}
              </Label>
            </ContentTour>
          </CardTour>
        ),
      },
      {
        stepInteraction: false,
        action: () =>
          historyPush?.(
            generatePath(RoutesName.userManagement, { type: 'users' })
          ),
        position: 'center',
        observe: 'body',
        content: () => (
          <CardTour
            titleHeader={t('Edit profile')}
            iconHeader="Pencil"
            iconBackgroundColor="yellow"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <ModalGuideAnimation animation={getLottie('editProfile')} />
              <Label size="14">
                {t(
                  'Click on Edit to setup the profile information or permissions of a user and then save it'
                )}
              </Label>
            </ContentTour>
          </CardTour>
        ),
      },
    ],
  },
  {
    title: t('Your plan'),
    description: t('Review features'),
    backgroundColor: 'purple',
    iconName: 'ArrowFatLinesUp',
    steps: [
      {
        stepInteraction: false,
        action: () => historyPush?.(RoutesName.myPlan),
        position: 'center',
        observe: 'body',
        content: () => (
          <CardTour
            titleHeader={t('Your plan')}
            iconHeader="ArrowFatLinesUp"
            iconBackgroundColor="purple"
            noPaddingContent
            onCloseCard={onCloseModal}
          >
            <ContentTour>
              <StepTour size="18" semibold>
                {t('Review features')}
              </StepTour>
              <Label size="14">
                {t('In this section you can see all activated applications')}
              </Label>
            </ContentTour>
          </CardTour>
        ),
      },
    ],
  },
];

export const tours: {
  [key in AppName]: ({
    theme,
    t,
    historyPush,
    getLottie,
    onCloseModal,
  }: TourParams) => TutorialType[];
} = {
  [AppName.operations]: ({
    theme,
    t,
    historyPush,
    getLottie,
    onCloseModal,
  }) => [
    {
      title: t('Download assets'),
      description: t('How to download assets'),
      backgroundColor: 'violet',
      iconName: 'DownloadSimple',
      steps: [
        {
          stepInteraction: false,
          action: () => historyPush?.(RoutesName.operations),
          position: 'center',
          observe: 'body',
          selector: '[data-tour="operations_download-assets"]',
          resizeObservables: ['[data-tour="operations_download-assets"]'],
          content: () => (
            <CardTour
              titleHeader={t('How to download assets')}
              iconHeader="DownloadSimple"
              iconBackgroundColor="violet"
              noPaddingContent
              onCloseCard={onCloseModal}
            >
              <ContentTour>
                <ModalGuideAnimation
                  animation={getLottie('operationDownload')}
                />

                <Label size="14">
                  {t(
                    'Some operations could generate different assets like video, documents and photos. You can see them here.'
                  )}
                </Label>
              </ContentTour>
            </CardTour>
          ),
        },
        {
          stepInteraction: false,
          selector: '[data-tour="operations_download-assets"]',
          resizeObservables: ['[data-tour="operations_download-assets"]'],
          content: () => (
            <CardTour
              titleHeader={t('How to download assets')}
              iconHeader="DownloadSimple"
              iconBackgroundColor="violet"
              noPaddingContent
              onCloseCard={onCloseModal}
            >
              <ContentTour>
                <ModalGuideAnimation animation={getLottie('operationAssets')} />

                <Label size="14">
                  {t(
                    'If the operation generates more than 2 files, it will appear +n.'
                  )}
                </Label>
                <Label size="14">
                  {t(
                    'By clicking it will shows all your files or click on the correspondent icons to review them.'
                  )}
                </Label>
              </ContentTour>
            </CardTour>
          ),
        },
      ],
    },
    {
      title: t('Filter operations'),
      description: t('How to search for an operation'),
      backgroundColor: 'green',
      iconName: 'FunnelSimple',
      steps: [
        {
          stepInteraction: false,
          position: 'center',
          observe: 'body',
          action: () => historyPush?.(RoutesName.operations),
          content: () => (
            <CardTour
              titleHeader={t('How to search for an operation')}
              iconHeader="FunnelSimple"
              iconBackgroundColor="green"
              noPaddingContent
              onCloseCard={onCloseModal}
            >
              <ContentTour>
                <ModalGuideAnimation animation={getLottie('operationFilter')} />
                <Label size="14">
                  {t(
                    'In this part of the table you will find filters. You can filter by date or choosing between type (onboarding or authentication).'
                  )}
                </Label>
              </ContentTour>
            </CardTour>
          ),
        },
        {
          selector: '[data-tour="filter-1"]',
          resizeObservables: ['[data-tour="filter-1"]'],
          content: () => (
            <CardTour
              titleHeader={t('How to search for an operation')}
              iconHeader="FunnelSimple"
              iconBackgroundColor="green"
              noPaddingContent
              onCloseCard={onCloseModal}
            >
              <ContentTour>
                <StepTour size="18" semibold>
                  {t('1. Filter by date')}
                </StepTour>
                <Label size="14">
                  <Trans i18nKey="Click on the calendar icon <1/> and select the date">
                    Click on the calendar icon{' '}
                    <Icon size="18" iconName="CalendarBlank" /> and select the{' '}
                    date.
                  </Trans>
                </Label>
              </ContentTour>
            </CardTour>
          ),
        },
        {
          selector: '[data-tour="filter-2"]',
          resizeObservables: ['[data-tour="filter-2"]'],
          content: () => (
            <CardTour
              titleHeader={t('How to search for an operation')}
              iconHeader="FunnelSimple"
              iconBackgroundColor="green"
              noPaddingContent
              onCloseCard={onCloseModal}
            >
              <ContentTour>
                <StepTour size="18" semibold>
                  {t('2 Filter by type')}
                </StepTour>
                <Label size="14">
                  <Trans i18nKey="Click on the filter icon <1/> and check the type">
                    Click on the filter icon{' '}
                    <Icon size="18" iconName="Faders" /> and check the type.
                  </Trans>
                </Label>
              </ContentTour>
            </CardTour>
          ),
        },
      ],
    },
    {
      title: t('Review detail operation'),
      description: t('How to review detail operation'),
      backgroundColor: 'blue',
      iconName: 'FileText',
      steps: [
        {
          action: () => historyPush?.(RoutesName.operations),
          selector: '[data-tour="operations_detail"]',
          resizeObservables: ['[data-tour="operations_detail"]'],
          stepInteraction: false,
          content: () => (
            <CardTour
              titleHeader={t('How to review detail operation')}
              iconHeader="FileText"
              iconBackgroundColor="blue"
              noPaddingContent
              onCloseCard={onCloseModal}
            >
              <ContentTour>
                <StepTour size="18" semibold>
                  {t('1 Access the operation detail')}
                </StepTour>
                <Label size="14">
                  <Trans i18nKey="You access directly on the detail of the operation, by clicking here <1/>">
                    You access directly on the detail of the operation, by
                    clicking here <Icon size="18" iconName="Eye" />
                  </Trans>
                </Label>
              </ContentTour>
            </CardTour>
          ),
        },
        {
          selector: '[data-tour="operation-detail_tabs]',
          resizeObservables: ['[data-tour="operation-detail_tabs]'],
          content: () => (
            <CardTour
              titleHeader={t('How to review detail operation')}
              iconHeader="FileText"
              iconBackgroundColor="blue"
              noPaddingContent
              onCloseCard={onCloseModal}
            >
              <ContentTour>
                <ModalGuideAnimation animation={getLottie('operationDetail')} />
                <Label size="14">
                  {t(
                    'Navigate in the different tabs to discovery more details about your operation'
                  )}
                </Label>
              </ContentTour>
            </CardTour>
          ),
        },
        {
          selector: '[data-tour="operation-detail_tabs]',
          resizeObservables: ['[data-tour="operation-detail_tabs]'],
          content: () => (
            <CardTour
              titleHeader={t('How to review detail operation')}
              iconHeader="FileText"
              iconBackgroundColor="blue"
              noPaddingContent
              onCloseCard={onCloseModal}
            >
              <ContentTour>
                <ModalGuideAnimation
                  animation={getLottie('operationDetailAssets')}
                />
                <Label size="14">
                  <Trans i18nKey="To explore a document, click here <1/>">
                    To explore a document, click here{' '}
                    <Icon size="18" iconName="MagnifyingGlassPlus" />
                  </Trans>
                </Label>
                <Label size="14">
                  {t(
                    'It will open a modal where you can see the document and zoom to details'
                  )}
                </Label>
              </ContentTour>
            </CardTour>
          ),
        },
      ],
    },
  ],
  [AppName.studio]: () => [],
  [AppName.ux]: () => [],
  [AppName.privacy]: () => [],
  [AppName.antiFraud]: () => [],
  [AppName.ai]: () => [],
  [AppName.videoAssistance]: () => [],
  [AppName.userManagement]: () => [],
  [AppName.audit]: () => [],
};
