import React from 'react';
import { IconCustomProps } from '../model';

export const HappySelfie = ({ className, size, color }: IconCustomProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      className={className}
      width={size ? (size as number) : undefined}
      height={size ? (size as number) : undefined}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40349 21H4.0478C3.47008 21 3 20.53 3 19.9522V16.5965C3 16.4199 3.14329 16.2766 3.32017 16.2766C3.49682 16.2766 3.63988 16.4199 3.63988 16.5965V19.9522C3.63988 20.1771 3.82293 20.3602 4.0478 20.3602H7.40349C7.58014 20.3602 7.72343 20.5032 7.72343 20.6801C7.72343 20.8568 7.58014 21 7.40349 21Z"
        fill={color}
      />
      <path
        d="M7.40349 21H4.0478C3.47008 21 3 20.53 3 19.9522V16.5965C3 16.4199 3.14329 16.2766 3.32017 16.2766C3.49682 16.2766 3.63988 16.4199 3.63988 16.5965V19.9522C3.63988 20.1771 3.82293 20.3602 4.0478 20.3602H7.40349C7.58014 20.3602 7.72343 20.5032 7.72343 20.6801C7.72343 20.8568 7.58014 21 7.40349 21"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9525 21H16.5965C16.4199 21 16.2766 20.8568 16.2766 20.6801C16.2766 20.5032 16.4199 20.3602 16.5965 20.3602H19.9525C20.1771 20.3602 20.3602 20.1771 20.3602 19.9522V16.5965C20.3602 16.4199 20.5032 16.2766 20.6801 16.2766C20.8568 16.2766 21 16.4199 21 16.5965V19.9522C21 20.53 20.53 21 19.9525 21Z"
        fill={color}
      />
      <path
        d="M19.9525 21H16.5965C16.4199 21 16.2766 20.8568 16.2766 20.6801C16.2766 20.5032 16.4199 20.3602 16.5965 20.3602H19.9525C20.1771 20.3602 20.3602 20.1771 20.3602 19.9522V16.5965C20.3602 16.4199 20.5032 16.2766 20.6801 16.2766C20.8568 16.2766 21 16.4199 21 16.5965V19.9522C21 20.53 20.53 21 19.9525 21"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6801 7.72343C20.5035 7.72343 20.3604 7.58014 20.3604 7.40349V4.0478C20.3604 3.82293 20.1773 3.63988 19.9522 3.63988H16.5968C16.4199 3.63988 16.2766 3.49659 16.2766 3.31994C16.2766 3.14329 16.4199 3 16.5968 3H19.9522C20.5302 3 21 3.47008 21 4.0478V7.40349C21 7.58014 20.8568 7.72343 20.6801 7.72343Z"
        fill={color}
      />
      <path
        d="M20.6801 7.72343C20.5035 7.72343 20.3604 7.58014 20.3604 7.40349V4.0478C20.3604 3.82293 20.1773 3.63988 19.9522 3.63988H16.5968C16.4199 3.63988 16.2766 3.49659 16.2766 3.31994C16.2766 3.14329 16.4199 3 16.5968 3H19.9522C20.5302 3 21 3.47008 21 4.0478V7.40349C21 7.58014 20.8568 7.72343 20.6801 7.72343"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.31994 7.72345C3.14329 7.72345 3 7.58016 3 7.40351V4.04781C3 3.4701 3.46985 3.00024 4.0478 3.00024H7.40326C7.58014 3.00024 7.72343 3.1433 7.72343 3.31995C7.72343 3.49683 7.58014 3.64012 7.40326 3.64012H4.0478C3.8227 3.64012 3.63965 3.82294 3.63965 4.04781V7.40351C3.63965 7.58016 3.49659 7.72345 3.31994 7.72345Z"
        fill={color}
      />
      <path
        d="M3.31994 7.72345C3.14329 7.72345 3 7.58016 3 7.40351V4.04781C3 3.4701 3.46985 3.00024 4.0478 3.00024H7.40326C7.58014 3.00024 7.72343 3.1433 7.72343 3.31995C7.72343 3.49683 7.58014 3.64012 7.40326 3.64012H4.0478C3.8227 3.64012 3.63965 3.82294 3.63965 4.04781V7.40351C3.63965 7.58016 3.49659 7.72345 3.31994 7.72345"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12ZM17.3684 12C17.3684 9.0351 14.9649 6.63158 12 6.63158C9.0351 6.63158 6.63158 9.0351 6.63158 12C6.63158 14.9649 9.0351 17.3684 12 17.3684C14.9649 17.3684 17.3684 14.9649 17.3684 12Z"
        fill={color}
      />
      <path
        d="M14.1886 13.2628C14.2759 13.1118 14.4691 13.0601 14.62 13.1474C14.771 13.2347 14.8227 13.4279 14.7354 13.5789C14.1707 14.5555 13.1282 15.157 12.0001 15.157C10.872 15.157 9.82952 14.5555 9.26487 13.5789C9.17757 13.4279 9.2292 13.2348 9.38019 13.1475C9.53117 13.0602 9.72434 13.1118 9.81164 13.2628C10.2634 14.0442 11.0975 14.5254 12.0001 14.5254C12.9027 14.5254 13.7368 14.0442 14.1886 13.2628Z"
        fill={color}
      />
      <circle cx="9.86846" cy="10.8158" r="0.473684" fill={color} />
      <circle cx="14.1316" cy="10.8158" r="0.473684" fill={color} />
    </svg>
  );
};
