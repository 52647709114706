import React from 'react';
import { SpinnerProps } from './model';
import spinnerDark from './spinner-dark.json';
import spinnerWhite from './spinner-white.json';
import { SpinnerGeneric, SpinnerStyles, SpinnerWrapper } from './Styles';

export const Spinner = ({
  className,
  size = 'M',
  insideComponent,
  animation,
  genericSpinner = false,
  type = 'full-width',
  white = false,
}: SpinnerProps) => {
  const renderSpinner = () => {
    return genericSpinner ? (
      <SpinnerGeneric size={size} insideComponent={insideComponent} type={type}>
        <span />
      </SpinnerGeneric>
    ) : (
      <SpinnerStyles
        className={className}
        size={size}
        insideComponent={insideComponent}
        animation={animation ? animation : white ? spinnerWhite : spinnerDark}
        type={type}
      />
    );
  };

  return insideComponent ? (
    <SpinnerWrapper className={className}>{renderSpinner()}</SpinnerWrapper>
  ) : (
    renderSpinner()
  );
};
