import React, { ChangeEvent, forwardRef, Ref, useRef, useState } from 'react';
import { animated, Transition } from 'react-spring';
import { InputProps } from './model';
import {
  ErrorLabel,
  HelpLabelStyles,
  InputContainer,
  InputIcon,
  InputStyles,
} from './Styles';
import { useThemeProvider } from '../../providers';
import { ButtonIcon } from '../buttonIcon';
import { FlexContainer } from '../flexContainer';
import { Icon } from '../icon';
import { Label } from '../label';
import { Portal } from '../portal';

export const Input = forwardRef(
  (
    {
      type,
      name,
      className,
      placeholder,
      value,
      onChange,
      disabled = false,
      testId,
      readOnly = false,
      onClick,
      divRef,
      label,
      errorLabel,
      iconLeft,
      iconRight,
      onClickIconRight,
      autoFocus,
      onRemoveValue,
      children,
      elementLeft,
      variant = 'primary',
      helpLabel,
      size = 'M',
      autoComplete = 'no',
      onBlur,
      onKeyDown,
      onKeyUp,
    }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [showHelpLabel, setShowHelpLabel] = useState<boolean>(false);

    const togglePasswordVisiblity = () => {
      setPasswordShow(!passwordShow);
    };

    const onChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
    };

    const { isLightTheme } = useThemeProvider();

    const helpLabelRef = useRef(null);

    const closeHelpLabel = () => {
      setShowHelpLabel(false);
    };

    return (
      <InputContainer className={className}>
        {(label || helpLabel) && (
          <FlexContainer>
            {label && (
              <Label
                as="label"
                size="12"
                semibold
                negative={
                  !isLightTheme || variant === 'secondary' ? true : false
                }
              >
                {label}
              </Label>
            )}

            {helpLabel && (
              <>
                <button
                  type="button"
                  onClick={() => setShowHelpLabel(!showHelpLabel)}
                  ref={helpLabelRef}
                  data-test="help-button"
                >
                  <Icon
                    iconName="Info"
                    size="14"
                    color="greys"
                    intensity={500}
                  />
                </button>
                <Transition
                  items={showHelpLabel}
                  from={{
                    opacity: 0,
                    transform: 'translate3d(0%, 20px, 0px)',
                  }}
                  enter={{
                    opacity: 1,
                    transform: 'translate3d(0%, 0%, 0px)',
                  }}
                  leave={{
                    opacity: 0,
                    transform: 'translate3d(0%, 20px, 0px)',
                  }}
                >
                  {(styles, showHelpLabel) =>
                    showHelpLabel && (
                      <Portal
                        actionRef={helpLabelRef}
                        show
                        widthAuto
                        overlay
                        onClickOutside={closeHelpLabel}
                      >
                        <animated.div style={{ ...styles }}>
                          <HelpLabelStyles size="12" data-test="help-label">
                            {helpLabel}
                          </HelpLabelStyles>
                        </animated.div>
                      </Portal>
                    )
                  }
                </Transition>
              </>
            )}
          </FlexContainer>
        )}

        <InputStyles
          disabled={disabled}
          onClick={() => !onClickIconRight && onClick?.()}
          ref={divRef}
          alignItems="center"
          justifyContent="center"
          data-test="input-container"
          columnGap="0.6"
          size={size}
          variant={
            !isLightTheme && variant === 'primary' ? 'secondary' : variant
          }
        >
          {!iconLeft && elementLeft}
          {iconLeft && (
            <InputIcon
              iconName={iconLeft}
              size={24}
              color="greys"
              intensity={variant === 'secondary' || disabled ? 200 : 400}
            />
          )}

          <input
            autoFocus={autoFocus}
            name={name}
            type={passwordShow ? 'text' : type}
            ref={ref}
            disabled={disabled}
            onChange={onChangeHandle}
            onBlur={onBlur}
            placeholder={placeholder}
            data-test={testId}
            value={value}
            readOnly={readOnly}
            onClick={() => onClickIconRight && onClick?.()}
            autoComplete={autoComplete}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />

          {type === 'password' ? (
            <ButtonIcon
              iconName={passwordShow ? 'EyeSlash' : 'Eye'}
              text
              disabled={disabled}
              type="button"
              onClick={disabled ? undefined : togglePasswordVisiblity}
              testId="button-password"
              variant={variant}
            />
          ) : iconRight ? (
            <>
              {onClickIconRight ? (
                <ButtonIcon
                  iconName={iconRight}
                  disabled={disabled}
                  text
                  onClick={onClickIconRight}
                  variant={variant}
                />
              ) : (
                <Icon
                  iconName={iconRight}
                  size={24}
                  color="greys"
                  intensity={variant === 'secondary' ? 200 : 400}
                />
              )}
            </>
          ) : (
            value &&
            !disabled &&
            onRemoveValue && (
              <ButtonIcon
                iconName="X"
                onClick={onRemoveValue}
                text
                size="S"
                testId="input-remove-value"
              />
            )
          )}
        </InputStyles>

        {children}
        {errorLabel && <ErrorLabel size="10">{errorLabel}</ErrorLabel>}
      </InputContainer>
    );
  }
);
