import styled from 'styled-components';
import { TableRowStyleProps, TableWrapperProps } from './model';
import { Checkbox } from '../checkbox';
import { FlexContainer } from '../flexContainer';
import { HelpInfoTooltip } from '../helpInfoTooltip';

const PADDING_ROW_X = '0.8rem';
const PADDING_ROW_X_SCROLL = '1.6rem';

export const TableOptionsStyles = styled(FlexContainer)`
  padding-bottom: 2.7rem;

  > div:first-child {
    margin-right: auto;
    display: flex;
    grid-gap: 1rem;
  }

  > div:last-child {
    margin-left: auto;
    display: flex;
    grid-gap: 1.5rem;
  }
`;

export const TableStyles = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.greys.white};
  border-radius: 0.8rem;
  & .text-right {
    text-align: right;
    justify-content: right;
  }
  & .text-center {
    text-align: center;
    justify-content: center;
  }
  & .text-left {
    text-align: left;
    justify-content: left;
  }
`;

const TABLE_HEADER_HEIGHT = '4rem';
const TABLE_FOOTER_HEIGHT = TABLE_HEADER_HEIGHT;
const TABLE_MARGIN = '0.5rem';

export const TableMainHeader = styled(FlexContainer)<{ hasScroll?: boolean }>`
  background-color: ${({ theme }) => theme.color.greys[100]};
  height: ${TABLE_HEADER_HEIGHT};
  border-radius: 0.8rem 0.8rem 0 0;
  padding: 0
    ${(props) => (props.hasScroll ? PADDING_ROW_X_SCROLL : PADDING_ROW_X)} 0
    ${PADDING_ROW_X};
  margin-bottom: ${TABLE_MARGIN};

  div[role='columnheader'] {
    flex: 1;
    padding: 0 0.5rem;
  }
`;

export const TableRow = styled.div<TableRowStyleProps>`
  box-shadow: 0 0.5rem 1rem 0 ${(props) => props.theme.color.greys[100]};
  border-radius: 0.8rem;
  align-items: center;
  margin: 0.5rem ${PADDING_ROW_X};
  width: calc(100% - (${PADDING_ROW_X} * 2)) !important;
  display: flex;
  align-items: center;
  position: relative;
  overflow: ${({ withTooltip }) => (withTooltip ? 'visible' : 'hidden')};
  ${(props) =>
    props.newRow &&
    `background-color: ${props.theme.color.green[200]};
    box-shadow: none;
    border-left: 6px solid ${props.theme.color.green[400]};
 `}
  ${(props) =>
    props.isSelected &&
    `
    background-color: ${props.theme.color.primary[100]};
    box-shadow: none;
 `}

${(props) =>
    props.error &&
    `
    border-left: 6px solid ${props.theme.color.error[400]};
 `}

  div[role='cell'] {
    display: flex !important;
    align-items: center;
    flex: 1;
    font-size: 1rem;
    line-height: 1.4rem;
    height: 100%;
    padding: 0 0.5rem;
    color: ${({ theme }) => theme.color.greys.black};
    > * {
      font-size: inherit;
      line-height: inherit;
    }
  }
`;

export const TableFooter = styled.div`
  background-color: ${({ theme }) => theme.color.greys[100]};
  height: ${TABLE_FOOTER_HEIGHT};
  border-radius: 0 0 0.8rem 0.8rem;
  margin-top: ${TABLE_MARGIN};
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled.div<TableWrapperProps>`
  flex-grow: 1;
  margin-bottom: ${(props) =>
    props.hiddenHeader
      ? 0
      : `calc(${TABLE_HEADER_HEIGHT} + ${TABLE_FOOTER_HEIGHT} + ${TABLE_MARGIN} + ${TABLE_MARGIN})`};
`;

export const TableCell = styled.div<{ active?: boolean }>`
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
`;

export const CheckboxStyles = styled(Checkbox)`
  margin: auto;
`;

export const TableActionsError = styled(HelpInfoTooltip)`
  box-shadow: 0 2px 14px 0 ${(props) => props.theme.color.greys[100]};
  width: 31rem;
  padding: 1.6rem 2.1rem 0.8rem;
`;

export const TableHeaderStyles = styled(FlexContainer)<{
  hasPadding?: boolean;
}>`
  width: 100%;
  ${(props) => props.hasPadding && `padding: 1.6rem 2.4rem;`}
`;
