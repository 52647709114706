/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { changeThemeSchema } from '@backoffice/state/model';
import {
  Button,
  CardBaseContent,
  CardBaseFooter,
  CardBaseStyle,
  CardCollapsable,
  FlexContainer,
  Input,
  InputUpload,
  Label,
  ThemeDto,
  useTheme,
  useThemeProvider,
} from '@facephi/inphinite-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AspectChangeHeader,
  AspectColorPicker,
  AspectInputUpload,
} from './Styles';

export const ChangeAspect = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { changeTheme } = useThemeProvider();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<ThemeDto>({
    resolver: yupResolver(changeThemeSchema),
    defaultValues: {
      primary: theme.color.primary[400],
      secondary: theme.color.secondary[400],
      logoAside: theme.images?.logoAside,
      favicon: theme.images?.favicon,
      title: theme.title,
    },
  });

  const onSubmit = (data: ThemeDto) => {
    changeTheme(data);
  };

  useEffect(() => {
    setValue('primary', theme.color.primary[400]);
    setValue('secondary', theme.color.secondary[400]);
  }, [theme.color]);

  return (
    <CardBaseStyle flexDirection="column">
      <AspectChangeHeader
        alignItems="center"
        justifyContent="flex-end"
        columnGap="2"
        as="section"
      >
        <AspectInputUpload base64 hasInfoMessage={false} />
      </AspectChangeHeader>
      <FlexContainer
        as="form"
        flexDirection="column"
        flex="1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CardBaseContent flexDirection="column" rowGap="1.6">
          <CardCollapsable
            title={t('Colors')}
            iconLeft="PaintBucket"
            withShadow
          >
            <FlexContainer flexDirection="column" rowGap="1.4" as="section">
              <FlexContainer alignItems="center" justifyContent="space-between">
                <Label size="12" semibold>
                  {t('Primary')}
                </Label>

                <Controller
                  control={control}
                  name="primary"
                  render={({ field, fieldState }) => (
                    <AspectColorPicker
                      {...field}
                      {...fieldState}
                      errorLabel={t(`${errors.primary?.message}`)}
                    />
                  )}
                />
              </FlexContainer>
              <FlexContainer alignItems="center" justifyContent="space-between">
                <Label size="12" semibold>
                  {t('Secondary')}
                </Label>
                <Controller
                  control={control}
                  name="secondary"
                  render={({ field, fieldState }) => (
                    <AspectColorPicker
                      {...field}
                      {...fieldState}
                      errorLabel={t(`${errors.secondary?.message}`)}
                    />
                  )}
                />
              </FlexContainer>
            </FlexContainer>
          </CardCollapsable>
          <FlexContainer alignItems="center" justifyContent="space-between">
            <Label size="12" semibold>
              {t('Logo')}
            </Label>
            <Controller
              control={control}
              name="logoAside"
              render={({ field, fieldState }) => (
                <InputUpload
                  {...field}
                  {...fieldState}
                  withUserLabel={false}
                  base64
                  hasInfoMessage={false}
                  acceptFiles={['image/jpeg', 'image/png', '.svg']}
                  errorLabel={errors.logoAside?.message}
                />
              )}
            />
          </FlexContainer>
          <FlexContainer alignItems="center" justifyContent="space-between">
            <Label size="12" semibold>
              {t('Favicon')}
            </Label>
            <Controller
              control={control}
              name="favicon"
              render={({ field, fieldState }) => (
                <InputUpload
                  {...field}
                  {...fieldState}
                  withUserLabel={false}
                  base64
                  hasInfoMessage={false}
                  acceptFiles={['.ico']}
                  errorLabel={errors.favicon?.message}
                />
              )}
            />
          </FlexContainer>
          <FlexContainer alignItems="center" justifyContent="space-between">
            <Label size="12" semibold>
              {t('Title')}
            </Label>
            <Controller
              control={control}
              name="title"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  errorLabel={errors.title?.message}
                />
              )}
            />
          </FlexContainer>
        </CardBaseContent>

        <CardBaseFooter as="section" columnGap="1.6">
          <Button type="submit">{t('Save')}</Button>
        </CardBaseFooter>
      </FlexContainer>
    </CardBaseStyle>
  );
};
