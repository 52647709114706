import styled from 'styled-components';
import { ButtonIcon } from '../buttonIcon';
import { FlexContainer } from '../flexContainer';
import { Input } from '../input';

export const DropdownEmpty = styled(FlexContainer)`
  text-align: center;
  padding: 2rem;
`;

export const DropdownSearchStyles = styled(Input)`
  input {
    cursor: pointer;
    z-index: 3;
  }
`;

export const TagListContent = styled(FlexContainer)`
  padding: 1.2rem 0;
  max-height: 12rem;
  overflow-y: auto;
`;

export const TagButton = styled(ButtonIcon)`
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
`;

export const TagContent = styled(FlexContainer)`
  border: 1px solid ${(props) => props.theme.color.greys[100]};
  padding: 0.2rem 0.6rem 0.2rem 1rem;
  border-radius: 2rem;
  &:hover {
    ${TagButton} {
      opacity: 1;
    }
  }
`;
