import React, {
  forwardRef,
  MouseEvent,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import {
  DateUtils,
  DayModifiers,
  Modifiers,
  RangeModifier,
} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { localeUtils } from './locales';
import { InputDateProps, RangeType } from './model';
import { StyledDatePicker } from './Styles';
import { useDeviceSize } from '../../hooks';
import { Input } from '../input';
import { Portal } from '../portal';

export const InputDatePicker = forwardRef(
  (
    {
      onChange,
      value,
      minDate,
      maxDate,
      disabled,
      isRange,
      ...props
    }: InputDateProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const setHour = (
      dateToSetter: Date | undefined,
      type?: RangeType
    ): Date | undefined => {
      if (!dateToSetter) return dateToSetter;

      const newDate = new Date(dateToSetter);
      type === RangeType.to
        ? newDate.setHours(23, 59, 59, 999)
        : newDate.setHours(0, 0, 0, 0);
      return newDate;
    };

    const getRange = (values?: RangeModifier): RangeModifier =>
      ({
        [RangeType.from]: setHour(values?.from || undefined, RangeType.from),
        [RangeType.to]: setHour(values?.to || undefined, RangeType.to),
      } as RangeModifier);
    const { isMobile } = useDeviceSize();

    const [show, setShow] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [date, setDate] = useState<Date | undefined>(
      value instanceof Date ? value : undefined
    );
    const [range, setRange] = useState<RangeModifier>(
      getRange(!(value instanceof Date) ? value : undefined)
    );
    const [innerRange, setInnerRange] = useState<RangeModifier>(getRange());

    const dateToString = (dateToFormat?: Date | null): string =>
      dateToFormat ? dayjs(dateToFormat).format('DD/MM/YYYY') : '';

    const isRangeCompleted = (newRange?: RangeModifier): boolean =>
      !Object.values(newRange ?? range).some((value) => !value);

    const onSelectDay = (newDate: Date, modifiers: DayModifiers) => {
      if (!modifiers.disabled) {
        if (isRange) {
          const newRange = DateUtils.addDayToRange(newDate, innerRange);
          Object.entries(newRange).forEach(([key, value]) => {
            const type = key as RangeType;
            newRange[type] = setHour(value, type);
          });
          if (isRangeCompleted(newRange)) {
            setRange(newRange);
            setShow(false);
          }
          if (
            !newRange.to &&
            range.to &&
            DateUtils.isDayAfter(range.to, newDate)
          ) {
            setInnerRange(DateUtils.addDayToRange(range.to, newRange));
          } else {
            setInnerRange(newRange);
          }
        } else {
          setDate(setHour(newDate));
          setShow(false);
        }
      }
    };

    const getInputValue = (): string => {
      if (isRange) {
        return isRangeCompleted()
          ? `${dateToString(range.from)} - ${dateToString(range.to)}`
          : '';
      }
      return dateToString(date);
    };

    useEffect(() => {
      !isRange && date && onChange && onChange(date);
    }, [date]);

    useEffect(() => {
      if (isRange && isRangeCompleted()) {
        onChange && onChange(range);
      }
    }, [range]);

    useEffect(() => {
      if (!show && isRange) {
        if (!isRangeCompleted()) {
          setRange(getRange());
        } else if (isRangeCompleted(innerRange)) {
          setRange(innerRange);
        }
        setInnerRange(getRange());
      }
    }, [show]);

    const getRangeValue = (): RangeModifier =>
      innerRange.from ? innerRange : range;

    const getSelectedRange = () => {
      const value = getRangeValue();
      return [value.from || undefined, value];
    };

    const handleRemoveValue = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setDate(undefined);
      setRange(getRange(undefined));
      onChange && onChange(undefined);
    };

    return (
      <>
        <Input
          {...props}
          type="text"
          iconLeft="CalendarBlank"
          readOnly
          onClick={() => !disabled && setShow(true)}
          divRef={inputRef}
          disabled={disabled}
          ref={ref}
          value={getInputValue()}
          onRemoveValue={handleRemoveValue}
        />
        <Portal
          show={show}
          onClickOutside={() => setShow(false)}
          actionRef={inputRef}
          isMobile={isMobile}
          overlayBlur={isMobile}
        >
          <StyledDatePicker
            localeUtils={localeUtils}
            locale={dayjs.locale()}
            onDayClick={onSelectDay}
            selectedDays={isRange ? getSelectedRange() : date}
            disabledDays={[
              minDate ? { before: minDate } : undefined,
              maxDate ? { after: maxDate } : undefined,
            ]}
            modifiers={
              {
                start: isRange ? getRangeValue().from : date,
                end: isRange ? getRangeValue().to : date,
              } as Partial<Modifiers>
            }
          />
        </Portal>
      </>
    );
  }
);
