import { IThemeProps } from './model';

export const InphiniteTheme: IThemeProps = {
  color: {
    primary: {
      100: '#DFDCF4',
      200: '#AFA7E2',
      300: '#8072D1',
      400: '#604FC6',
      500: '#4D3F9E',
      600: '#302863',
      700: '#221D41',
    },
    secondary: {
      100: '#D5DAFA',
      200: '#95A3F2',
      300: '#556CEB',
      400: '#2B47E6',
      500: '#2239B8',
      600: '#151F57',
      700: '#141A3B',
    },
    coral: {
      100: '#FCDADC',
      200: '#FBA7AC',
      300: '#FD7D7D',
      400: '#FF625E',
      500: '#CC4E4B',
      600: '#602827',
      700: '#33242A',
    },
    purple: {
      100: '#DFDCF4',
      200: '#AFA7E2',
      300: '#8072D1',
      400: '#604FC6',
      500: '#4D3F9E',
      600: '#302863',
      700: '#221D41',
    },
    cyan: {
      100: '#C0E6EF',
      200: '#80CDDF',
      300: '#40B4CF',
      400: '#009ABF',
      500: '#00748F',
      600: '#004D60',
      700: '#001E26',
    },
    pink: {
      100: '#FDE1FF',
      200: '#F9B5FE',
      300: '#F589FE',
      400: '#F36BFE',
      500: '#C256CB',
      600: '#662B6A',
      700: '#311533',
    },
    yellow: {
      100: '#FEF2DB',
      200: '#FCDFA6',
      300: '#FACD71',
      400: '#F9C04D',
      500: '#CDA044',
      600: '#67542C',
      700: '#322D29',
    },
    green: {
      100: '#CCF4F0',
      200: '#7FE3D9',
      300: '#33D3C3',
      400: '#00C8B4',
      500: '#05A697',
      600: '#0F6058',
      700: '#192E33',
    },
    blue: {
      100: '#D5DAFA',
      200: '#95A3F2',
      300: '#556CEB',
      400: '#2B47E6',
      500: '#2239B8',
      600: '#151F57',
      700: '#141A3B',
    },
    violet: {
      100: '#F7EDFB',
      200: '#EFDCF8',
      300: '#D8A7EE',
      400: '#C172E4',
      500: '#B14FDD',
      600: '#653681',
      700: '#2B2237',
    },
    skyBlue: {
      100: '#D1F2FE',
      200: '#8DDEFC',
      300: '#49CAFA',
      400: '#1BBDF9',
      500: '#1697C7',
      600: '#0E5F7D',
      700: '#052632',
    },
    greys: {
      white: '#FFFFFF',
      50: '#F3F4F5',
      100: '#EAEEF6',
      200: '#D3D9ED',
      300: '#A4ABC3',
      400: '#7C879A',
      500: '#5A6476',
      600: '#3A4151',
      700: '#2B303C',
      800: '#20222C',
      900: '#181922',
      black: '#000000',
      android: '#121212',
      ios: '#1C1C1E',
    },
    success: {
      100: '#D6F1DA',
      200: '#99DDA1',
      300: '#31D16C',
      400: '#2CC13D',
      500: '#239A31',
      600: '#16611F',
      700: '#15421B',
    },
    error: {
      100: '#FBD1DB',
      200: '#F58BA5',
      300: '#EF4670',
      400: '#EB184C',
      500: '#9B1436',
      600: '#610D22',
      700: '#451D2C',
    },
    warning: {
      100: '#FFE7D3',
      200: '#FFC291',
      300: '#FF9D4F',
      400: '#FF7F00',
      500: '#CC6600',
      600: '#804000',
      700: '#552E08',
    },
  },
  gradients: {
    primary: 'linear-gradient(to left top, #8839FD, #7189FC)',
    lightGreen: 'linear-gradient(to left bottom, #54CDFF, #BAFF77)',
    lightBlue: 'linear-gradient(to left bottom, #5AB6FA, #5F7EFE)',
    peach: 'linear-gradient(to left top, #CC52EA, #FF7D90)',
    pinkyPurple: 'linear-gradient(to right bottom, #E746E9, #8463D4)',
    blueViolet: 'linear-gradient(to right bottom, #8839FD, #7189FC )',
    violet: 'linear-gradient(to right bottom, #5D3EA0, #9A77E3)',
    blue: 'linear-gradient(to top, #494eed, #009ABF)',
    darkBlue: 'linear-gradient(to top, #020207, #464BE5)',
  },
  images: {
    logoAside: '',
    favicon: '',
    bigLogoLight: '',
    bigLogoDark: '',
  },
  title: 'FacePhi Identity Platform',
};

export const InphiniteDarkTheme: IThemeProps = {
  ...InphiniteTheme,
};
