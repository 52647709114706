/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useCommon } from '@facephi/inphinite-common';
import {
  CardBaseContent,
  CardBaseStyle,
  FlexContainer,
  Label,
  useTimezone,
  Icon,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useOperationDetails } from '../../hooks';
import {
  OperationStatus,
  StatusMessages,
  ReviewTimelineElement,
  StatusChange,
} from '../../state/model';
import { IconReview, InfoComment, InfoReview } from './Styles';

enum ValuesStatus {
  denied = 'DENIED',
  succeeded = 'SUCCEEDED',
}

export const ReviewTimeline = () => {
  const {
    tenant: { id: tenantId },
  } = useCommon();
  const { t } = useTranslation();
  const [resultListStatus, setResultListStatus] = useState<
    ReviewTimelineElement[]
  >([]);
  const { id } = useParams<{ id: string }>() as { id: string };
  const { formatTimezone } = useTimezone();

  const { detailsData } = useOperationDetails(id, tenantId);

  useEffect(() => {
    const details = detailsData?.details;
    if (details) {
      const changeList = details?.statusChangeList || [];

      const dataList = changeList
        .filter(
          (element) =>
            element.status === OperationStatus.denied ||
            element.status === OperationStatus.succeeded
        )
        // eslint-disable-next-line array-callback-return
        .map((item: StatusChange, index) => {
          const status =
            (StatusMessages[
              item?.status as unknown as ValuesStatus
            ] as string) || '';

          return {
            comment: item?.comment,
            text: `${status ? t(status) : ''}
              ${item?.timestamp ? formatTimezone(item?.timestamp) : ''}`,
          };
        });

      dataList.pop();
      setResultListStatus([...(dataList as ReviewTimelineElement[])]);
    }
  }, [detailsData]);

  return (
    <>
      {resultListStatus?.length > 0 && (
        <FlexContainer alignItems="start" justifyContent="space-between">
          <CardBaseStyle flexDirection="column" noShadow>
            <CardBaseContent flexDirection="column" rowGap="2.6">
              {resultListStatus.map(
                (item: ReviewTimelineElement, index: number) => {
                  return (
                    <div key={index}>
                      <InfoReview>
                        <IconReview>
                          <Icon iconName="ArrowArcRight" size={14} />
                        </IconReview>
                        <div>
                          <Label black semibold size="14">
                            {item.text}
                          </Label>
                        </div>
                      </InfoReview>
                      <InfoComment>
                        <Label color="greys" size="12">
                          {item.comment}
                        </Label>
                      </InfoComment>
                    </div>
                  );
                }
              )}
            </CardBaseContent>
          </CardBaseStyle>
        </FlexContainer>
      )}
    </>
  );
};
