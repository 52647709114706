import {
  Button,
  CardBase,
  CardBaseContent,
  CardTabs,
  Dropdown,
  FlexContainer,
  Label,
  ProgresBar,
  Table,
} from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const DropdownUx = styled(Dropdown)`
  width: 7.4rem;
`;

export const UXGridBase = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 0.5rem;
  column-gap: 3.6rem;
  margin-top: 1.6rem;
  flex: 1;
`;

export const UXGrid = styled(UXGridBase)`
  > *:nth-child(8) {
    grid-column: 4/5;
    grid-row: 2/4;
  }

  > *:nth-child(9) {
    grid-column: 1/4;
  }
`;

export const UXAnalyticGrid = styled(UXGridBase)`
  grid-template-columns: repeat(12, 1fr);

  > *:nth-child(1) {
    grid-column: 1/6;
    grid-row: 1/3;
    min-height: 16rem;
  }

  > *:nth-child(2) {
    grid-column: 6/13;
    grid-row: 1/3;
  }

  > *:nth-child(3) {
    grid-column: 6/13;
    grid-row: 8/13;
  }

  > *:nth-child(4) {
    grid-column: 1/6;
    grid-row: 8/13;
  }
`;

export const UXTaskGrid = styled(UXGridBase)`
  > *:nth-child(1) {
    grid-column: 1/4;
    grid-row: 1/4;
  }

  > *:nth-child(2) {
    grid-column: 4/5;
  }
  > *:nth-child(3) {
    grid-column: 4/5;
  }
  > *:nth-child(4) {
    grid-column: 4/5;
  }
`;

export const CardAnalyticBar = styled(ProgresBar)`
  max-width: 14rem;
`;

export const CardAnalyticContent = styled(CardBaseContent)`
  padding: 1.2rem 2.4rem 2.8rem;
`;

export const CardAnalyticMessage = styled(Label)`
  margin-bottom: 4rem;
`;

export const CardAnalyticList = styled(FlexContainer)`
  margin-bottom: 4rem;
`;

export const CardAnalyticButton = styled(Button)`
  margin-top: auto;
`;

export const CardTipsContent = styled(CardBaseContent)`
  padding: 2.1rem 1.8rem;
`;

export const CardTipsSubtitle = styled(Label)`
  margin: 1rem 0 auto;
`;

export const CardTipsButton = styled(Button)`
  margin-top: 1.6rem;
`;

export const CardTipsBullet = styled.span`
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: ${(props) => props.theme.color.greys[100]};
  &:first-of-type {
    background-color: ${(props) => props.theme.color.greys[300]};
  }
`;

export const TableKPIsStyle = styled(Table)`
  * [role='cell']:nth-child(3) {
    justify-content: flex-end;
    flex: 2;
  }
`;

export const TableKPIsBar = styled(ProgresBar)`
  max-width: 25rem;
`;

export const CardUXAnalyticImg = styled(CardBase)`
  column-gap: 4.4rem;
  padding: 2.4rem;

  img {
    max-width: 29rem;
    height: auto;
    min-width: 0;
  }

  strong {
    color: ${(props) => props.theme.color.primary[400]};
  }
`;

export const CardUXChart = styled(CardBase)`
  p:nth-child(1) {
    text-align: center;
    margin: auto;
  }

  p:nth-child(3) {
    margin-top: 1rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  button,
  a {
    margin-top: auto;
    text-align: center;
  }
`;

export const CardTabPanelWrapper = styled(FlexContainer)`
  height: 100%;
`;

export const CardTabButton = styled(Button)`
  margin-top: auto;
  text-align: center;
`;

export const MapDetailBoxUXStyle = styled(FlexContainer)`
  height: 4.4rem;
  border-radius: 0.8rem;
  border: 1px solid ${(props) => props.theme.color.greys[100]};
  overflow: hidden;
  padding-right: 2rem;

  p:first-of-type {
    background-color: ${(props) => props.theme.color.greys[100]};
    height: 100%;
    width: 3rem;
    min-width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p:last-of-type {
    flex: 1;
  }
`;

export const MapDetailBoxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: auto;
  ${MapDetailBoxUXStyle} {
    margin-left: -1rem;

    &:nth-child(1),
    &:nth-child(4) {
      margin-left: 0;
    }
    &:nth-child(-n + 3) {
      border-bottom: solid 1px ${(props) => props.theme.color.greys[200]};
      border-bottom-left-radius: 0;
    }
    &:nth-child(n + 4) {
      border-top: none;
      border-top-left-radius: 0;
    }

    &:nth-child(3) {
      border-bottom-right-radius: 0;
    }

    &:nth-child(6) {
      border-top-right-radius: 0;
    }
  }
`;

export const MapContainer = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  padding-bottom: 2rem;
`;

export const CardTabsStyles = styled(CardTabs)`
  ul {
    height: 4.8rem;

    li[role='tab'] button {
      height: 4.8rem;
      width: 100%;
      justify-content: center;
      padding-bottom: 0;
    }
  }
`;

export const DeviceToggleStyles = styled(FlexContainer)`
  height: 4.8rem;

  button.active {
    color: ${(props) => props.theme.color.primary[400]};
  }
`;

export const DeviceTableCard = styled(CardBase)``;

export const DeviceTableCardHeader = styled(FlexContainer)`
  padding: 0.6rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.color.greys[100]};
`;

export const TimingLabel = styled(Label)`
  font-size: 4rem;
  line-height: 6rem;
`;

export const TimingContainer = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  padding-bottom: 2rem;
  padding: 4rem 0;
`;

export const TimingFooter = styled.div`
  text-align: center;
`;
