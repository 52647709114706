import React from 'react';
import { TimelineStepProps } from './model';
import { TimelineStepStyles, TimelineSubtitle, IconResult } from './Styles';
import { FlexContainer } from '../flexContainer';
import { Label } from '../label';

export const TimelineStep = ({
  message,
  timeUpdate,
  children,
  className,
  testId,
  separation,
  isError,
  isSuccess,
}: TimelineStepProps) => {
  return (
    <TimelineStepStyles
      style={{ paddingBottom: separation }}
      className={className}
      data-test={testId}
      isError={isError}
      isSuccess={isSuccess}
    >
      {(isSuccess || isError) && (
        <IconResult
          iconName={isError ? 'XCircle' : 'CheckCircle'}
          color={isError ? 'error' : 'success'}
          size="14"
        />
      )}
      <Label
        color={isError ? 'error' : isSuccess ? 'success' : undefined}
        size="12"
        semibold={isError}
      >
        {message}
      </Label>
      {!isError && (
        <TimelineSubtitle size="10" semibold>
          {timeUpdate}
        </TimelineSubtitle>
      )}
      {children && (
        <FlexContainer wrap="wrap" rowGap="1" columnGap="1">
          {children}
        </FlexContainer>
      )}
    </TimelineStepStyles>
  );
};
