import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { DropdownSearch } from './DropdownSearch';
import { DropdownSearchControllerProps } from './model';

export const DropdownSearchController = <T extends FieldValues>({
  name,
  control,
  ...rest
}: DropdownSearchControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render={({ field }: any) => (
      <DropdownSearch {...field} {...rest} name={name} />
    )}
  />
);
