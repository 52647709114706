import styled from 'styled-components';
import { FlexContainer } from '../flexContainer';
import { OptionMenu } from '../optionMenu';

export const UserTagListBullet = styled(FlexContainer)`
  background-color: ${(props) => props.theme.color.greys[100]};
  border-radius: 100%;
  height: 1.6rem;
  width: 1.6rem;
  min-width: 1.6rem;
`;

export const UserTagListMenu = styled(OptionMenu)`
  padding: 0.8rem 0;
  max-width: 25rem;
`;

export const UserTagListContent = styled(FlexContainer)`
  padding: 0 1rem;
  max-height: 13rem;
  overflow-y: auto;
`;
