import React, { forwardRef, Ref } from 'react';
import { ButtonIconProps, ButtonIconSizes } from './model';
import { ButtonIconStyle } from './Styles';
import { Icon } from '../icon';
import { Tooltip } from '../tooltip';

const IconSizes: { [key in ButtonIconSizes]: number } = {
  XS: 14,
  S: 18,
  M: 24,
  L: 32,
};

export const ButtonIcon = forwardRef(
  (
    {
      disabled,
      type = 'button',
      testId,
      onClick,
      className,
      text,
      iconName,
      size = 'M',
      labelTooltip,
      tooltipPlace,
      color,
      intensity,
      variant = 'primary',
    }: ButtonIconProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const renderButton = () => {
      return (
        <ButtonIconStyle
          disabled={disabled}
          type={type}
          data-test={testId}
          onClick={onClick}
          className={className}
          text={text}
          ref={ref}
          size={size}
          variant={variant}
        >
          <Icon
            size={IconSizes[size]}
            iconName={iconName}
            color={color}
            intensity={intensity}
          />
        </ButtonIconStyle>
      );
    };
    return labelTooltip ? (
      <Tooltip id={labelTooltip} label={labelTooltip} place={tooltipPlace}>
        {renderButton()}
      </Tooltip>
    ) : (
      renderButton()
    );
  }
);
