import { animated } from 'react-spring';
import styled from 'styled-components';
import { ModalLateralStyleProps } from './model';
import { FlexContainer } from '../flexContainer';

const HEIGHT_HEADER = '6rem';
const PADDING_X = '2.4rem';

export const ModalStyles = styled(animated.div)`
  z-index: 9999;
  padding: 0;
  background: ${(props) => props.theme.color.greys.white};
  border-radius: 0.8rem;
  display: flex;
  flex: 1;
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 34.5rem;
  max-height: 100vh;
  overflow-y: auto;
`;

export const ModalHeader = styled(FlexContainer)`
  border-bottom: 1px solid ${(props) => props.theme.color.greys[200]};
  height: ${HEIGHT_HEADER};
  padding: 0 ${PADDING_X};
`;

export const ModalLateralStyles = styled(animated.div)<ModalLateralStyleProps>`
  width: ${(props) => props.width}rem;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
`;
