import styled from 'styled-components';
import { LabelProps, LabelSizeProps } from './model';
import { ThemeColorType } from '../../themes';

export const getSizeLabel = (size: LabelSizeProps) => {
  switch (size) {
    case '8':
      return `font-size: 0.8rem; line-height: 1.2rem `;
    case '10':
      return `font-size: 1rem; line-height: 1.4rem `;
    case '12':
      return `font-size: 1.2rem; line-height: 1.6rem `;
    case '14':
      return `font-size: 1.4rem; line-height: 2rem `;
    case '16':
      return `font-size: 1.6rem; line-height: 2.1rem `;
    case '18':
      return `font-size: 1.8rem; line-height: 2.7rem `;
    case '21':
      return `font-size: 2.1rem; line-height: 3rem `;
    case '27':
      return `font-size: 2.7rem; line-height: 3.7rem `;
    case '32':
      return `font-size: 3.2rem; line-height: 4.4rem `;
  }
};

export const Label = styled.p<LabelProps>`
  font-family: inherit;
  font-weight: ${(props) => (props.semibold ? 600 : 400)};
  color: ${(props) =>
    props.negative
      ? props.theme.color.greys.white
      : props.black
      ? props.theme.color.greys.black
      : props.success
      ? props.theme.color.green[400]
      : props.color
      ? props.theme.color[props.color as ThemeColorType][400]
      : props.theme.color.greys[700]};
  ${(props) => getSizeLabel(props.size)};
  ${(props) =>
    props.ellipsis &&
    `
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  `}

  ${(props) => props.noWrap && `white-space: nowrap;`}

  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
  ${(props) =>
    props.capitalize &&
    `
      text-transform: capitalize
  `};
  ${(props) =>
    props.upperCase &&
    `
      text-transform: upperCase
  `};
  ${(props) => props.href && `cursor:pointer`};
`;
