import { useCallback } from 'react';
import axios from 'axios';

import { useCommon } from '../providers';

export enum RequestMethods {
  post = 'post',
  get = 'get',
}

function blobToFile(theBlob: Blob, fileName: string): File {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return theBlob as File;
}

export function useAssets() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const apiUrl: string = (window as any)._env_.REACT_APP_ASSET_URL;

  const { tenant, token } = useCommon();

  const uploadAsset = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('tenantId', tenant.id);

    return axios({
      method: RequestMethods.post,
      url: `${apiUrl}v1/api/assets/upload`,
      data: formData,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error);
      });
  };

  const getAsset = (id: string): Promise<Blob> => {
    return axios({
      method: RequestMethods.get,
      url: `${apiUrl}v1/api/assets/media/${id}`,
      responseType: 'blob',
    })
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error);
      });
  };

  const getAssetToFile = (url: string, fileName: string): Promise<File> => {
    return axios({
      method: RequestMethods.get,
      url: url,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => blobToFile(response.data, fileName))
      .catch((error) => {
        throw new Error(error);
      });
  };

  const urlAsset = useCallback(
    (id: string | number) => `${apiUrl}v1/api/assets/media/${id}`,
    []
  );

  return {
    uploadAsset,
    getAsset,
    urlAsset,
    getAssetToFile,
  };
}
