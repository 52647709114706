import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from 'react-simple-maps';
import { IMapProps, MarkerDto } from './model';
import world110m from '../../assets/json/world-110m.json';
import { useTheme } from '../../providers';

export const Map = ({ markers = [], testId, width, height }: IMapProps) => {
  const theme = useTheme();

  return (
    <ComposableMap
      id={testId}
      width={width}
      height={height}
      style={{ width: '100%', height: '100%' }}
    >
      <ZoomableGroup>
        <Geographies geography={world110m} fill={theme.color.greys[200]}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography key={geo.rsmKey} geography={geo} />
            ))
          }
        </Geographies>

        {markers?.map((item: MarkerDto) => (
          <Marker
            coordinates={[item.lng, item.lat]}
            id={item.name}
            key={item.name}
          >
            <circle r={5} fill={theme.color.error[400]} />
          </Marker>
        ))}
      </ZoomableGroup>
    </ComposableMap>
  );
};
