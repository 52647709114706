/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ThemeContext, ThemeProvider } from 'styled-components';
import { usePrevious } from '../hooks/usePrevious';
import { ThemeDto } from '../model';
import { IThemeProps, ThemeColorScheme, ThemeImageScheme } from '../themes';
import { darken, lighten } from '../utils/lightenDarkenColor';

type Props = {
  children?: React.ReactNode;
  theme: IThemeProps;
  themeDark?: IThemeProps;
};

enum ThemeType {
  light = 'light',
  dark = 'dark',
}

type InphiniteThemeProviderProps = {
  darkTheme(): void;
  lightTheme(): void;
  isLightTheme(): boolean;
  selectPrevTheme(): void;
  toggleLightDarkTheme(value: boolean): void;
  changeTheme(data: ThemeDto): void;
  resetTheme(): void;
};

const InphiniteThemeContext = createContext<InphiniteThemeProviderProps>({
  darkTheme: () => {},
  lightTheme: () => {},
  isLightTheme: () => true,
  selectPrevTheme: () => {},
  changeTheme: () => {},
  toggleLightDarkTheme: () => {},
  resetTheme: () => {},
});

export const InphiniteThemeProvider = ({
  children,
  theme,
  themeDark,
}: Props) => {
  const [themeLayout, setThemeLayout] = useState<ThemeType>(ThemeType.light);

  const isLightTheme = () => themeLayout === ThemeType.light;

  const [innerLightTheme, setInnerLightTheme] = useState<ThemeColorScheme>(
    theme.color
  );

  const [innerDarkTheme, setInnerDarkTheme] = useState<ThemeColorScheme>(
    themeDark?.color || theme.color
  );

  const [innerTitle, setInnerTitle] = useState<string | undefined>(theme.title);

  const [innerImage, setInnerImage] = useState<ThemeImageScheme>(
    themeDark?.images || theme.images
  );

  const getUpdateTheme = (): IThemeProps => {
    return {
      color: isLightTheme() ? innerLightTheme : innerDarkTheme,
      images: innerImage,
      gradients: theme.gradients,
      title: innerTitle,
    };
  };

  const [updateTheme, setUpdatetheme] = useState<IThemeProps>(getUpdateTheme());

  const darkTheme = () => setThemeLayout(ThemeType.dark);
  const lightTheme = () => setThemeLayout(ThemeType.light);

  const toggleLightDarkTheme = (value: boolean) => {
    value ? lightTheme() : darkTheme();
  };

  const getPrevTheme = usePrevious<ThemeType>(themeLayout);

  const selectPrevTheme = () => {
    getPrevTheme === ThemeType.light || getPrevTheme === undefined
      ? lightTheme()
      : darkTheme();
  };

  useEffect(() => {
    setUpdatetheme(getUpdateTheme());
  }, [themeLayout, innerLightTheme, innerDarkTheme, innerImage, innerTitle]);

  const changeThemeColor = (data: ThemeDto): IThemeProps => {
    return {
      ...updateTheme,
      color: {
        ...updateTheme.color,
        primary: {
          ...updateTheme.color.primary,
          100: lighten(data.primary as string, 20),
          400: data.primary as string,
          500: darken(data.primary as string, 20),
        },
        secondary: {
          ...updateTheme.color.secondary,
          100: lighten(data.secondary as string, 20),
          400: data.secondary as string,
          500: darken(data.secondary as string, 20),
        },
      },
      images: {
        logoAside: data.logoAside,
        favicon: data.favicon,
        bigLogoDark: data.bigLogoDark,
        bigLogoLight: data.bigLogoLight,
      },
      title: data.title,
    };
  };

  const changeTheme = (data: ThemeDto) => {
    const newTheme = changeThemeColor(data);

    setInnerImage({
      ...newTheme.images,
    });

    setInnerTitle(newTheme.title);

    isLightTheme()
      ? setInnerLightTheme({ ...newTheme.color })
      : setInnerDarkTheme({ ...newTheme.color });
  };

  const resetTheme = () => {
    isLightTheme()
      ? setInnerLightTheme(theme.color)
      : setInnerDarkTheme(themeDark?.color || theme.color);

    setInnerImage(theme.images);
    setInnerTitle(theme.title);
  };

  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;

    if (favicon) {
      favicon.href = updateTheme.images?.favicon
        ? updateTheme.images.favicon
        : '/favicon.ico';
    }

    if (updateTheme.title) {
      document.title = updateTheme.title;
    }
  }, [updateTheme.images?.favicon, updateTheme.title]);

  return (
    <InphiniteThemeContext.Provider
      value={{
        darkTheme,
        lightTheme,
        isLightTheme,
        selectPrevTheme,
        toggleLightDarkTheme,
        resetTheme,
        changeTheme,
      }}
    >
      <ThemeProvider theme={updateTheme}>{children}</ThemeProvider>
    </InphiniteThemeContext.Provider>
  );
};

export function useTheme() {
  const theme: IThemeProps = useContext(ThemeContext);

  return theme;
}

export const useThemeProvider = () => React.useContext(InphiniteThemeContext);
