import { Ref } from 'react';

export enum StatusType {
  BLACKLISTED = 'BLACKLISTED',
  ACTIVE = 'ACTIVATED',
  EXPIRED = 'EXPIRED',
  IN_PROGRESS = 'IN_PROGRESS',
  STARTED = 'STARTED',
  NOT_ACTIVE = 'NOT_ACTIVATED',
  PENDENT = 'PENDING',
  DENIED = 'DENIED',
  SUCCEEDED = 'SUCCEEDED',
  VALIDATE = 'VALIDATED',
  READ_ONLY = 'READ_ONLY',
  SAVED = 'SAVED',
  CANCELLED = 'CANCELLED',
}

export type StatusStyleProps = {
  type: StatusType;
  withShadow?: boolean;
};

export type StatusProps = StatusStyleProps & {
  withLabel?: boolean;
  testId?: string;
  className?: string;
  ref?: Ref<HTMLDivElement>;
  locale?: string;
};
