import { AssetImage } from '@facephi/inphinite-common';
import { Dropdown, FlexContainer, Input, Modal } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const DropdownAntiFraud = styled(Dropdown)`
  width: 7.4rem;
`;

export const HeaderBanned = styled(FlexContainer)`
  margin-bottom: 6rem;
`;

export const HeaderRulesStyles = styled(FlexContainer)`
  margin: 2rem 1.6rem;
`;

export const RulesTabsWrapper = styled(FlexContainer)`
  height: 100%;
`;

export const ModalIPStyles = styled(Modal)`
  width: 52rem;
`;

export const ModalFaceStyles = styled(ModalIPStyles)``;

export const ModalFaceInput = styled(Input)`
  width: 100%;
`;

export const TableFaceImg = styled(AssetImage)`
  width: 5rem;
  aspect-ratio: 1;
  object-fit: cover;
`;

export const TablePopImg = styled.img`
  width: 5rem;
  object-fit: cover;
`;
