export const EmptyFilesUpload = () => {
  return (
    <svg width="161" height="136" viewBox="0 0 161 136">
      <g clipPath="url(#clip0_2212_252155)">
        <path
          d="M29.4944 65.0933C31.1707 62.8817 33.7248 61.6138 36.5002 61.6138H45.6322C46.2769 57.3972 49.9286 54.155 54.3232 54.155H117.558L118.213 52.6131C119.595 49.3608 122.772 47.2606 126.304 47.2606H131.428C122.922 19.8818 97.3924 0 67.2225 0C30.0967 0 0 30.1034 0 67.2376C0 96.271 18.3988 121.006 44.1698 130.415L28.0349 72.7792C27.2861 70.1059 27.8195 67.305 29.4958 65.0933H29.4944Z"
          fill="#EFF0F6"
        />
        <path
          d="M156.604 60.0181H151.021C149.846 60.0181 148.783 59.316 148.324 58.2344L146.909 54.9087C146.45 53.827 145.388 53.125 144.212 53.125H126.303C125.129 53.125 124.066 53.827 123.606 54.9087L122.192 58.2344C121.732 59.316 120.671 60.0181 119.494 60.0181H54.3233C52.7041 60.0181 51.3926 61.3298 51.3926 62.9493V67.4768H36.5003C34.5602 67.4768 33.1549 69.3294 33.6781 71.1982L50.7933 132.333C51.1478 133.599 52.3011 134.474 53.6155 134.474H156.604C158.223 134.474 159.535 133.162 159.535 131.543V62.9493C159.535 61.3298 158.223 60.0181 156.604 60.0181Z"
          fill="white"
        />
        <path
          d="M156.604 58.5528H151.021C150.432 58.5528 149.904 58.2024 149.673 57.6602L148.258 54.3345C147.568 52.7091 145.98 51.6582 144.213 51.6582H126.304C124.539 51.6582 122.951 52.7091 122.259 54.3345L120.845 57.6602C120.615 58.2024 120.086 58.5528 119.497 58.5528H54.3232C51.8995 58.5528 49.9271 60.5255 49.9271 62.9497V66.0116H36.5002C35.1125 66.0116 33.8362 66.6463 32.9981 67.7514C32.1599 68.8565 31.8932 70.2576 32.2668 71.5943L49.382 132.728C49.911 134.619 51.6518 135.94 53.6154 135.94V135.882C53.8454 135.921 54.0828 135.94 54.3232 135.94H156.604C159.028 135.94 161 133.968 161 131.543V62.9497C161 60.5255 159.028 58.5528 156.604 58.5528ZM49.9271 123.804L35.0891 70.8043C34.9645 70.3588 35.0525 69.8926 35.3323 69.5233C35.6122 69.154 36.0371 68.9429 36.5002 68.9429H49.9271V123.804ZM158.069 131.543C158.069 132.351 157.411 133.009 156.604 133.009H54.3232C53.5158 133.009 52.8578 132.351 52.8578 131.543V62.9497C52.8578 62.1422 53.5158 61.484 54.3232 61.484H119.497C121.263 61.484 122.851 60.4333 123.543 58.8078L124.957 55.4822C125.187 54.9399 125.716 54.5896 126.304 54.5896H144.213C144.802 54.5896 145.331 54.9399 145.562 55.4822L146.975 58.8078C147.667 60.4333 149.253 61.484 151.021 61.484H156.604C157.411 61.484 158.069 62.1422 158.069 62.9497V131.543Z"
          fill="#EFF0F6"
        />
        <path
          d="M125.016 94.4753L106.5 75.9552C105.927 75.3821 104.999 75.3821 104.428 75.9552L85.9117 94.4753C84.9886 95.3988 85.6421 96.9773 86.9477 96.9773H94.5469C95.3558 96.9773 96.0123 97.6339 96.0123 98.4429V117.969C96.0123 118.778 96.6687 119.434 97.4776 119.434H113.45C114.259 119.434 114.915 118.778 114.915 117.969V98.4429C114.915 97.6339 115.572 96.9773 116.381 96.9773H123.98C125.285 96.9773 125.939 95.3988 125.016 94.4753Z"
          fill="#EFF0F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_2212_252155">
          <rect width="161" height="136" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
