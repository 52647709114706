import React from 'react';
import { ButtonIcon, Table } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { updatesField } from './../antiFraud/dataRules';
import { TableFaceImg } from './../antiFraud/Styles';

export const TableUnrecognised = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t('Similar images'),
        accessor: 'images',
        maxWidth: 150,
      },
      {
        Header: t('Update'),
        accessor: 'update',
      },
      {
        Header: t('Actions'),
        accessor: 'action',
        Cell: <ButtonIcon text iconName="ArrowRight" onClick={() => {}} />,
        maxWidth: 150,
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    const data = [];
    for (let i = 0; i < 7; i++) {
      data.push({
        images: <TableFaceImg src={`/ia-rec-${i}.png`} alt="face-user" />,
        update: updatesField[i],
        description: '',
        action: '',
      });
    }
    return data;
  }, []);

  return <Table columns={columns} data={data} />;
};
