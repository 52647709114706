import React, { forwardRef, Ref, useEffect, useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import {
  PresetColor,
  SketchPickerStylesProps,
} from 'react-color/lib/components/sketch/Sketch';
import { Classes } from 'reactcss';
import { useTheme } from 'styled-components';
import {
  InputColorPickerProps,
  reservedColor,
  ReservedColorType,
} from './model';
import {
  InputColorPickerBox,
  InputColorPickerInput,
  InputColorPickerPortal,
} from './Styles';
import { FlexContainer } from '../flexContainer';
import { Label } from '../label';

export const InputColorPicker = forwardRef(
  (
    {
      value = '',
      className,
      onChange,
      testId,
      presetColors,
      name,
      positionX,
      positionY,
      disabled,
      errorLabel,
    }: InputColorPickerProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [innerValue, setInnerValue] = useState<string>(value);
    const theme = useTheme();
    const bulletRef = useRef<HTMLSpanElement>(null);

    const defaultColors: PresetColor[] = [
      { color: theme.color.purple[400], title: 'purple' },
      { color: theme.color.blue[400], title: 'blue' },
      { color: theme.color.skyBlue[400], title: 'skyBlue' },
      { color: theme.color.coral[400], title: 'coral' },
      { color: theme.color.green[400], title: 'green' },
      { color: theme.color.yellow[400], title: 'yellow' },
      { color: theme.color.pink[400], title: 'pink' },
      { color: theme.color.error[400], title: 'error' },
      { color: theme.color.success[400], title: 'success' },
      { color: theme.color.warning[400], title: 'warning' },
      { color: theme.color.greys[400], title: 'greys' },
      { color: theme.color.greys[200], title: 'greys' },
      { color: theme.color.greys.white, title: 'white' },
      { color: theme.color.greys.black, title: 'black' },
    ];

    const customStyles: Partial<Classes<SketchPickerStylesProps>> = {
      default: {
        picker: {
          boxShadow: `0 2px 14px 0 ${theme.color.greys[100]}`,
          borderRadius: '0.8rem',
          padding: '1.2rem 1rem 1rem 1rem',
        },
        sliders: {
          padding: '2.1rem 0 1.6rem',
        },
        saturation: {
          borderRadius: '0.8rem',
          height: '13rem',
          paddingBottom: '0',
        },
      },
    };

    const colorRegex = /^#(?:[0-9a-f]{3}){1,2}$/i;

    const checkIsColor = (value: string) => colorRegex.test(value);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInnerValue(value);
      onChange && onChange(value);
    };

    const handleComponentChange = (color: ColorResult) => {
      setInnerValue(color.hex);
      onChange && onChange(color.hex);
    };

    const handleOpenColorPicker = () => {
      !disabled && setShowPanel(!showPanel);
    };

    const handleBlurInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      const hexColor = reservedColor[value as ReservedColorType] || value;

      setInnerValue(hexColor);
    };

    useEffect(() => {
      if (innerValue && checkIsColor(innerValue)) {
        onChange && onChange(innerValue);
      }
    }, [innerValue]);

    useEffect(() => {
      setInnerValue(value);
    }, [value]);

    return (
      <FlexContainer
        as="section"
        flexDirection="column"
        rowGap="0.4"
        className={className}
        data-test={testId}
      >
        <FlexContainer alignItems="center" columnGap="1.6">
          <InputColorPickerBox
            style={{ backgroundColor: innerValue ? innerValue : value }}
            ref={bulletRef}
            data-test="color-picker-box"
          />
          <InputColorPickerInput
            onClick={handleOpenColorPicker}
            onChange={handleInputChange}
            onBlur={handleBlurInput}
            value={innerValue !== undefined ? innerValue : value}
            name={name}
            testId="color-picker-input"
            disabled={disabled}
            ref={ref}
          />
          <InputColorPickerPortal
            show={showPanel}
            actionRef={bulletRef}
            onClickOutside={handleOpenColorPicker}
            widthAuto
            testId="color-picker-portal"
            positionX={positionX}
            positionY={positionY}
          >
            <SketchPicker
              presetColors={presetColors ? presetColors : defaultColors}
              color={innerValue}
              onChangeComplete={handleComponentChange}
              onChange={handleComponentChange}
              styles={customStyles}
              disableAlpha
            />
          </InputColorPickerPortal>
        </FlexContainer>
        {errorLabel && (
          <Label size="10" color="error">
            {errorLabel}
          </Label>
        )}
      </FlexContainer>
    );
  }
);
