import React from 'react';
import { FilterMenuHeaderProps } from './model';
import { FilterMenuHeaderStyles } from './Styles';

export const FilterMenuHeader = ({ label }: FilterMenuHeaderProps) => {
  return (
    <FilterMenuHeaderStyles size="12" semibold>
      {label}
    </FilterMenuHeaderStyles>
  );
};
