import {
  CardBase,
  CardBaseHeader,
  FlexContainer,
  Label,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  CardAnalyticBar,
  CardAnalyticButton,
  CardAnalyticContent,
  CardAnalyticList,
  CardAnalyticMessage,
} from './Styles';

type Props = {
  showProgresBar?: boolean;
};

export const CardAnalytic = ({ showProgresBar = true }: Props) => {
  const { t } = useTranslation();

  return (
    <CardBase>
      <CardBaseHeader alignItems="center">
        <Label size="14" semibold>
          {t('Complete UX analytic')}
        </Label>
      </CardBaseHeader>
      <CardAnalyticContent flexDirection="column">
        <CardAnalyticMessage size="12">
          {t(
            'Grow revenue and user engagement by providing a seamless user experience. Check it out!'
          )}
        </CardAnalyticMessage>
        {showProgresBar && (
          <CardAnalyticList flexDirection="column" rowGap="3.6" as="ul">
            <FlexContainer
              as="li"
              columnGap="0.8"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Label size="10" semibold>
                {t('Readability')}
              </Label>
              <CardAnalyticBar percentage="20" />
            </FlexContainer>
            <FlexContainer
              as="li"
              columnGap="0.8"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Label size="10" semibold>
                {t('Timing')}
              </Label>
              <CardAnalyticBar percentage="80" />
            </FlexContainer>
            <FlexContainer
              as="li"
              columnGap="0.8"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Label size="10" semibold>
                {t('Connectivity')}
              </Label>
              <CardAnalyticBar percentage="30" />
            </FlexContainer>
            <FlexContainer
              as="li"
              columnGap="0.8"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Label size="10" semibold>
                {t('Device')}
              </Label>
              <CardAnalyticBar percentage="50" />
            </FlexContainer>
            <FlexContainer
              as="li"
              columnGap="0.8"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Label size="10" semibold>
                {t('Journey')}
              </Label>
              <CardAnalyticBar percentage="20" />
            </FlexContainer>
            <FlexContainer
              as="li"
              columnGap="0.8"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Label size="10" semibold>
                {t('Difficulty')}
              </Label>
              <CardAnalyticBar percentage="10" />
            </FlexContainer>
          </CardAnalyticList>
        )}
        <CardAnalyticButton
          color="primary"
          iconName="FileText"
          variant="outline"
          ellipsis
        >
          {t('Access to complete study')}
        </CardAnalyticButton>
      </CardAnalyticContent>
    </CardBase>
  );
};
