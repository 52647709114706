import { ReactNode } from 'react';
import { ThemeColorType } from '@facephi/inphinite-ui';
import { LabelProps } from '@facephi/inphinite-ui/src/components/label/model';
import { Roles } from '../../state/constants';

type RolePropertiesType = {
  [key in string]: {
    color: ThemeColorType;
  };
};

export const RoleProperties: RolePropertiesType = {
  [Roles.Admin]: {
    color: 'purple',
  },
  [Roles.User]: {
    color: 'green',
  },
  [Roles.UserManagement]: {
    color: 'cyan',
  },
};

export type RoleInfoBoxStyleProps = {
  paddingBig?: boolean;
};

export type RoleInfoBoxProps = RoleInfoBoxStyleProps & {
  role: string;
  smallLabel?: boolean;
  className?: string;
};

export type RoleInfoLabelProps = LabelProps & {
  role: string;
  children: ReactNode;
};
