import { gql } from '@apollo/client';

export const updateAggregateStatistics = gql`
  subscription aggregateStatistics(
    $timeZone: String!
    $fromTimestamp: String
    $toTimestamp: String
  ) {
    statistics: aggregateStatistics(
      parameters: {
        timeZone: $timeZone
        fromTimestamp: $fromTimestamp
        toTimestamp: $toTimestamp
      }
    ) {
      newOnboardings
      totalSucceededOnboardings
      totalSucceededAuthentications
      conversionRate
      overallPercentage {
        percentages {
          status
          percentage
        }
      }
      byPeriodPercentage {
        percentages {
          fromTimestamp
          toTimestamp
          percentages {
            status
            value
          }
        }
      }
    }
  }
`;
