import { useEffect, useMemo, useState } from 'react';
import { NoFilesUploaded } from '@facephi/inphinite-common';
import {
  CardBaseContent,
  FilterMenu,
  FilterMenuHeader,
  FilterMenuItem,
  FlexContainer,
  Label,
  Spinner,
  Table,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../hooks';
import { UXDevices } from '../../../state/constants';
import { DeviceItem, DevicesResponse } from '../../../state/model';
import { DeviceTableCard, DeviceTableCardHeader } from '../Styles';
import { DeviceToggle } from './DeviceToggle';

export const DevicesCard = () => {
  const [devices, setDevices] = useState<DeviceItem[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<DeviceItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(true);
  const [deviceList, setDeviceList] = useState(UXDevices.desktop);
  const [filters, setFilters] = useState<string[]>([]);
  const { t } = useTranslation();
  const { getData } = useApi();

  const handleFilter = (value: string) => {
    setFilters((list) =>
      !list.includes(value)
        ? [...list, value]
        : list.filter((item) => item !== value)
    );
  };

  useEffect(() => {
    setFilteredDevices(
      filters.length === 0
        ? devices
        : devices?.filter((item) => filters.includes(item.os))
    );
  }, [filters]);

  const columns = useMemo(
    () => [
      {
        Header: t('Device type'),
        accessor: 'deviceType',
      },
      {
        Header: t('OS'),
        accessor: 'os',
      },
      {
        Header: t('Browser version'),
        accessor: 'browserVersion',
      },
      {
        Header: t('Browser'),
        accessor: 'browser',
        maxWidth: 130,
      },
    ],
    []
  );

  const desktopFilters = useMemo(() => {
    return (
      <>
        <FilterMenuHeader label={t('OS')} />
        <FilterMenuItem
          label={t('Mac OS X')}
          onChange={() => handleFilter(t('Mac OS X'))}
          checked={filters?.includes(t('Mac OS X'))}
        />
        <FilterMenuItem
          label={t('Windows')}
          onChange={() => handleFilter(t('Windows'))}
          checked={filters?.includes(t('Windows'))}
        />
        <FilterMenuItem
          label={t('Linux')}
          onChange={() => handleFilter(t('Linux'))}
          checked={filters?.includes(t('Linux'))}
        />
      </>
    );
  }, []);

  const mobileFilters = useMemo(() => {
    return (
      <>
        <FilterMenuHeader label={t('OS')} />
        <FilterMenuItem
          label={t('Android')}
          checked={filters?.includes(t('Android'))}
          onChange={() => handleFilter(t('Android'))}
        />
        <FilterMenuItem
          label={t('iOS')}
          checked={filters?.includes(t('iOS'))}
          onChange={() => handleFilter(t('iOS'))}
        />
      </>
    );
  }, []);

  useEffect(() => {
    getData('devices')
      .then((response: DevicesResponse) => {
        setDevices(response.data);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(false);
    setError(false);
    if (devices?.length === 0) {
      setError(true);
    }
    setFilteredDevices(devices);
  }, [devices]);

  const tableData = useMemo(() => {
    setLoading(false);
    devices && devices?.length > 0 && setError(false);
    return (
      filteredDevices?.filter(
        (item) => item.deviceType.toLowerCase() === deviceList
      ) || []
    );
  }, [filteredDevices, deviceList]);

  const tableFilters = useMemo(() => {
    return deviceList === UXDevices.desktop ? desktopFilters : mobileFilters;
  }, [deviceList]);

  const checkEmptyState = (
    search: string,
    loading: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    length?: number
  ) => {
    const hasEmptyData = () => !search && !loading && !error && !length;

    const hasFilterActive = () => search && !loading && !error && !length;

    return error
      ? 'error'
      : hasFilterActive()
      ? 'filter'
      : hasEmptyData()
      ? 'data'
      : undefined;
  };

  return (
    <DeviceTableCard testId="devicesContainer">
      <DeviceTableCardHeader alignItems="center" justifyContent="space-between">
        <Label size="14" semibold>
          {t('Device')}
        </Label>

        <FlexContainer alignItems="center" columnGap="1.2">
          <DeviceToggle
            onChange={(device) => {
              setFilters([]);
              setDeviceList(device);
            }}
          ></DeviceToggle>

          <FilterMenu size="S">{tableFilters}</FilterMenu>
        </FlexContainer>
      </DeviceTableCardHeader>

      <CardBaseContent flexDirection="column">
        {loading && <Spinner size="M" genericSpinner insideComponent />}
        {!loading && error && <NoFilesUploaded messageType="information" />}
        <Table
          columns={columns}
          data={tableData}
          hasMore={false}
          loading={loading}
          emptyState={checkEmptyState('', loading, error, tableData?.length)}
          testId="devicesContainer"
        />
      </CardBaseContent>
    </DeviceTableCard>
  );
};
