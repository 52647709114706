/* eslint-disable @typescript-eslint/no-explicit-any */
import Keycloak from 'keycloak-js';

const apiUrl: string = (window as any)._env_.REACT_APP_AUTH_API_URL;
const clientId: string = (window as any)._env_.REACT_APP_AUTH_CLIENT_ID;
const realm: string = (window as any)._env_.REACT_APP_AUTH_REALM;

const keycloak = Keycloak({
  url: `${apiUrl}/auth`,
  realm: realm,
  clientId: clientId,
});

export default keycloak;
