import MailPNG from '@backoffice/assets/img/modalMail.png';
import { Button } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { ModalMailLabel, ModalMailStyles } from './Styles';

type Props = {
  show: boolean;
  onChangeShow(show: boolean): void;
  onCreate(): void;
  onResend(): void;
};

export const ModalMail = ({
  show,
  onChangeShow,
  onCreate,
  onResend,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalMailStyles
      show={show}
      title={t('Email sent')}
      iconHeader="Envelope"
      onChangeShow={onChangeShow}
      onCreate={onCreate}
      closeLabel={t('Cancel')}
      submitLabel={t('OK')}
      flexDirectionContent="column"
      alignItemsContent="center"
      rowGapContent="2.4"
    >
      <img src={MailPNG} alt="email" />
      <ModalMailLabel size="14">
        {t(
          'We have sent an email to reset your pasword. \n Check your inbox. \n If you have not received an email, please click on “Resend email”'
        )}
      </ModalMailLabel>
      <Button variant="text" iconName="ArrowClockwise" onClick={onResend}>
        {t('Resend email')}
      </Button>
    </ModalMailStyles>
  );
};
