import {
  IconName,
  Label,
  Status,
  StatusType,
  ThemeColorType,
} from '@facephi/inphinite-ui';
import { CardReportingProps, CardReportingStatus } from './model';

import {
  CardReportingContent,
  CardReportingFooter,
  CardReportingIconWrapper,
  CardReportingStyles,
} from './Styles';

export const CardReporting = ({
  totalItems,
  type,
  newItems,
  status,
  testId,
  className,
  dataTour,
}: CardReportingProps) => {
  const reportingType = {
    BLACKLISTED: ['Blacklist', 'ClipboardText', 'greys'],
    STARTED: ['Started', 'Play', 'skyBlue'],
    ACCEPTED: ['Succeeded', 'CheckSquareOffset', 'green'],
    EXPIRED: ['Expired', 'Timer', 'violet'],
    INPROCESS: ['In process', 'PaperPlaneTilt', 'purple'],
    VALIDATE: ['Validations', 'Shield', 'blue'],
    PENDENT: ['Pending', 'HourglassLow', 'yellow'],
    REJECTED: ['Denied', 'FileX', 'error'],
  };

  const getTitle = (value: keyof typeof reportingType) => {
    return reportingType[value][0];
  };

  const getIcon = (value: keyof typeof reportingType) => {
    return reportingType[value][1] as IconName;
  };

  const getBackgroundColor = (value: keyof typeof reportingType) => {
    return reportingType[value][2] as ThemeColorType;
  };

  return (
    <>
      <CardReportingStyles data-test={testId} className={className}>
        <CardReportingIconWrapper
          backgroundColor={getBackgroundColor(type)}
          iconName={getIcon(type)}
          type={type}
        />
        <CardReportingContent flexDirection="column" rowGap="2">
          <Label size="21" semibold data-tour={dataTour}>
            {getTitle(type)}
          </Label>
          <Label size="14">Total: {totalItems}</Label>
          <Label size="14">New: {newItems}</Label>
        </CardReportingContent>

        <CardReportingFooter
          alignItems="center"
          justifyContent="center"
          columnGap="0.6"
        >
          <Status
            type={
              status === CardReportingStatus.AVAILABLE
                ? StatusType.ACTIVE
                : status === CardReportingStatus.UPDATE
                ? StatusType.SUCCEEDED
                : StatusType.PENDENT
            }
          />
          <Label size="10" semibold>
            {status}
          </Label>
        </CardReportingFooter>
      </CardReportingStyles>
    </>
  );
};
