import styled from 'styled-components';
import { CheckBoxStylesProps } from './model';
import { FlexContainer } from '../flexContainer';

export const CheckedBullet = styled.span`
  aspect-ratio: 1;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.color.primary[400]};
`;

export const IndeterminateBullet = styled.span`
  height: 0.5rem;
  width: 1.4rem;
  aspect-ratio: 1;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.color.greys[300]};
`;

export const CheckboxStyles = styled(FlexContainer)<{
  checked: boolean;
  small?: boolean;
}>`
  background-color: ${(props) => props.theme.color.greys.white};
  width: ${(props) => (props.small ? '1.6rem' : '2.4rem')};
  min-width: ${(props) => (props.small ? '1.6rem' : '2.4rem')};
  height: ${(props) => (props.small ? '1.6rem' : '2.4rem')};
  border-radius: 0.4rem;
  border: 1px solid ${(props) => props.theme.color.greys[100]};

  ${CheckedBullet} {
    height: ${(props) => (props.small ? '0.7rem' : '1.4rem')};
  }

  ${IndeterminateBullet} {
    width: ${(props) => (props.small ? '0.7rem' : '1.4rem')};
  }
`;

export const CheckBoxRow = styled(FlexContainer)`
  width: 100%;
  align-items: center;

  label {
    cursor: inherit;
  }
`;

export const CheckboxContainer = styled.button<CheckBoxStylesProps>`
  display: flex;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.5' : 1)};
  padding: 0;

  ${CheckBoxRow} {
    ${(props) => props.spaceBetween && `justify-content: space-between`};
  }
`;
