import { TabPanel, Tabs } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { RulesFace, RulesIP, RulesTabsWrapper } from '../components';

export const RulesPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const currentTab = location.state ? (location.state as any).tab : 'ip';

  const TabsActive: { [key: string]: string } = {
    ip: '1',
    face: '2',
  };

  return (
    <>
      <Tabs noPadding active={TabsActive[currentTab] as string}>
        <TabPanel testIdTab="IP" name={t('IP')} id="1">
          <RulesTabsWrapper flexDirection="column">
            <RulesIP />
          </RulesTabsWrapper>
        </TabPanel>
        <TabPanel testIdTab="face" name={t('Face')} id="2">
          <RulesTabsWrapper flexDirection="column">
            <RulesFace />
          </RulesTabsWrapper>
        </TabPanel>
      </Tabs>
    </>
  );
};
