import React from 'react';
import {
  ButtonIcon,
  FlexContainer,
  Icon,
  IconName,
  IconWrapper,
  Label,
  ThemeColorType,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { TableKPIsBar, TableKPIsStyle } from './Styles';

type StatusType = {
  label: string;
  color: ThemeColorType;
};

type ActivityTypeType = StatusType & { iconName: IconName };

export const ActivityType = ({ iconName, label, color }: ActivityTypeType) => {
  return (
    <FlexContainer columnGap="3.2" alignItems="center">
      <IconWrapper iconName={iconName} backgroundColor={color} size="S" />
      <Label size="10">{label}</Label>
    </FlexContainer>
  );
};

export const Status = ({ label, color }: StatusType) => (
  <Label size="10" semibold style={{ color: color }}>
    {label}
  </Label>
);

export const ValueStatus = ({ iconName, label, color }: ActivityTypeType) => {
  return (
    <FlexContainer columnGap="0.8" alignItems="center">
      <Icon iconName="Timer" size="24" color="greys" />
      <Label size="14">{label}</Label>
      <Icon iconName={iconName} size="24" color={color} />
    </FlexContainer>
  );
};

export const TableKPIs = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t('Username'),
        accessor: 'username',
      },
      {
        Header: t('File name'),
        accessor: 'filename',
      },
      {
        Header: t('Group name'),
        accessor: 'groupname',
      },
      {
        Header: t('Action'),
        accessor: 'action',
        Cell: <ButtonIcon text iconName="ListPlus" />,
      },
    ],
    []
  );

  const data = React.useMemo(
    () => [
      {
        username: (
          <ActivityType iconName="Timer" color="primary" label="20/05/21" />
        ),
        filename: <Status label="Update" color="green" />,
        groupname: (
          <ValueStatus
            label="00.19 sec"
            color="green"
            iconName="ArrowFatLinesUp"
          />
        ),
        action: '',
      },
      {
        username: (
          <ActivityType iconName="ThumbsUp" color="green" label="20/05/21" />
        ),
        filename: <Status label="Update" color="green" />,
        groupname: <TableKPIsBar percentage="20" big />,
        action: '',
      },
      {
        username: (
          <ActivityType iconName="SmileyXEyes" color="coral" label="20/05/21" />
        ),
        filename: <Status label="Available" color="coral" />,
        groupname: (
          <ValueStatus label="239 users" color="coral" iconName="UserCircle" />
        ),
        action: '',
      },
    ],
    []
  );
  return <TableKPIsStyle columns={columns} data={data} hiddenHeader />;
};
