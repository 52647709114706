import React, { forwardRef, Ref } from 'react';
import { LabelBaseProps } from './model';
import { LabelBaseStyles } from './Styles';
import { ButtonIcon } from '../buttonIcon';
import { Icon } from '../icon';
import { Label } from '../label';

export const LabelBase = forwardRef(
  (
    { label, testId, className, onClick, iconName }: LabelBaseProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <LabelBaseStyles
        alignItems="center"
        columnGap="0.4"
        className={className}
        ref={ref}
        data-test={testId}
      >
        {onClick && (
          <ButtonIcon text iconName="XCircle" onClick={onClick} size="S" />
        )}
        {iconName && <Icon iconName={iconName} size="16" />}
        <Label size="12" semibold textAlign="center">
          {label}
        </Label>
      </LabelBaseStyles>
    );
  }
);
