import { ThemeColorType } from '../../../themes';
import { IconName } from '../../icon';
import { FlowProps } from '../model';

export type StepName =
  | 'STARTED'
  | 'SELPHID_WIDGET'
  | 'SELPHI_WIDGET'
  | 'PHINGERS_WIDGET'
  | 'NFC_WIDGET'
  | 'FINISHED'
  | 'UNKNOWN'
  | 'START'
  | 'FINISH'
  | 'TERMS_WIDGET'
  | 'VIDEO_CALL_WIDGET';

export const UNKNOWN_STEP: StepName[] = ['UNKNOWN', 'FINISHED'];

export const UNKNOWN_ICON: IconName = 'BracketsSquare';

export const FlowIcons: { [key in StepName]: IconName } = {
  STARTED: 'Flag',
  START: 'Flag',
  SELPHID_WIDGET: 'ChalkboardTeacher',
  SELPHI_WIDGET: 'UserCircle',
  PHINGERS_WIDGET: 'RenaperIcon',
  NFC_WIDGET: 'NFCIcon',
  FINISHED: 'ShieldCheck',
  FINISH: 'ShieldCheck',
  UNKNOWN: 'DotsThree',
  TERMS_WIDGET: 'Checks',
  VIDEO_CALL_WIDGET: 'Headset',
};

export type ArrowProps = FlowProps & {
  currentStep: StepName;
  flow: StepName[];
  color: ThemeColorType;
  unknownSteps?: boolean;
};
