import Tooltip from 'react-tooltip';
import styled from 'styled-components';

export const TooltipWrapper = styled.span<{ displayBlock?: boolean }>`
  display: ${({ displayBlock }) => (displayBlock ? 'block' : 'inline-block')};
`;

export const TooltipStyles = styled(Tooltip)`
  min-height: 2.1rem;
  border-radius: 0.4rem !important;
  padding: 0.4rem 0.8rem !important;
  display: flex !important;
  justify-content: center;
  align-items: center;

  &:after,
  &:before {
    transition: none !important;
  }

  &.show {
    opacity: 1 !important;
  }
`;
