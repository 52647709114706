import React from 'react';
import { TabHeaderActionsProps } from './model';
import { TabHeaderActionsStyles } from './Styles';

const TabHeaderActions = ({ children }: TabHeaderActionsProps) => {
  return (
    <TabHeaderActionsStyles alignItems="center" columnGap="2">
      {children}
    </TabHeaderActionsStyles>
  );
};

TabHeaderActions.displayName = 'TabHeaderActions';

export { TabHeaderActions };
