import React, { DragEvent } from 'react';
import { CardBaseHeader, FlexContainer, Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  PermissionApplicationCollapsable,
  PermissionApplicationContent,
  PermissionApplicationStyles,
} from './Styles';
import { PermissionType, RawPermissionType } from '../model';
import { PermissionsBox } from '../PermissionsBox';

type Props = {
  apps?: PermissionType[];
  permissions?: RawPermissionType[];
};

export const PermissionApplication = ({ apps, permissions }: Props) => {
  const { t } = useTranslation();

  const onDragStart = (event: DragEvent<HTMLElement>, node = '') => {
    event.dataTransfer.setData('application/permissions', node);
    event.dataTransfer.effectAllowed = 'move';
  };

  const drawPermissions = (
    nodes: RawPermissionType[] | undefined,
    position = 0
  ): JSX.Element[] | undefined =>
    nodes?.map((option) => (
      <>
        <PermissionsBox
          level={position}
          title={t(`${option.label}`)}
          disabled={permissions?.some((item) => item.id === option.id)}
          isDraggable
          onDragStart={(event) => onDragStart(event, option.id)}
        />
        {option.permissions &&
          drawPermissions(option.permissions, position + 1)}
      </>
    ));

  return (
    <>
      <PermissionApplicationStyles>
        <CardBaseHeader alignItems="center">
          <Label size="14" semibold>
            {t('All application')}
          </Label>
        </CardBaseHeader>
        <PermissionApplicationContent flexDirection="column">
          <FlexContainer
            data-tour="settings_add-permission"
            flexDirection="column"
            rowGap="1.2"
          >
            {apps?.map((app) => (
              <PermissionApplicationCollapsable
                key={app.label}
                iconLeft={app.iconName}
                title={app.label}
                iconLeftBackground={app.color}
                small
              >
                <FlexContainer flexDirection="column" rowGap="0.6">
                  {drawPermissions(app.permissions)}
                </FlexContainer>
              </PermissionApplicationCollapsable>
            ))}
          </FlexContainer>
        </PermissionApplicationContent>
      </PermissionApplicationStyles>
    </>
  );
};
