import React from 'react';
import { FilterMenuItemProps } from './model';
import { FilterMenuItemStyles } from './Styles';

export const FilterMenuItem = ({
  label,
  onChange,
  checked,
  testId,
}: FilterMenuItemProps) => {
  return (
    <FilterMenuItemStyles
      centered
      small
      testId={testId}
      labelRight={label}
      checked={checked}
      onChange={onChange}
    />
  );
};
