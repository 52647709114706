export type OperationsFilter = {
  text?: string;
  fromTimeStamp?: string;
  status?: OperationStatus;
  family?: OperationType;
};

export enum OperationStatus {
  started = 'STARTED',
  denied = 'DENIED',
  expired = 'EXPIRED',
  blacklisted = 'BLACKLISTED',
  succeeded = 'SUCCEEDED',
  cancelled = 'CANCELLED',
  error = 'ERROR',
}

export type OperationResultDto = {
  status: OperationStatus;
  reason: string;
  type: string;
};

export enum StatusMessages {
  'SUCCEEDED' = 'The operation has been approved on',
  'DENIED' = 'The operation has been rejected on',
}

export enum OperationType {
  onboarding = 'ONBOARDING',
  authentication = 'AUTHENTICATION',
}

export enum OperationResultReason {
  selphidInternalError = 'SELPHID_INTERNAL_ERROR',
  selphidCancelByUser = 'SELPHID_CANCEL_BY_USER',
  selphidTimeout = 'SELPHID_TIMEOUT',
  selphiInternalError = 'SELPHI_INTERNAL_ERROR',
  selphiCancelByUser = 'SELPHI_CANCEL_BY_USER',
  selphiTimeout = 'SELPHI_TIMEOUT',
  phingersInternalError = 'PHINGERS_INTERNAL_ERROR',
  phingersCancelByUser = 'PHINGERS_CANCEL_BY_USER',
  phingersTimeout = 'PHINGERS_TIMEOUT',
  agoraInternalError = 'AGORA_INTERNAL_ERROR',
  agoraCancelByUser = 'AGORA_CANCEL_BY_USER',
  sdkMobileError = 'SDK_MOBILE_ERROR',
  documentValidationNotPassed = 'DOCUMENT_VALIDATION_NOT_PASSED',
  documentValidationError = 'DOCUMENT_VALIDATION_ERROR',
  facialAuthenticationNotPassed = 'FACIAL_AUTHENTICATION_NOT_PASSED',
  facialLivenessNotPassed = 'FACIAL_LIVENESS_NOT_PASSED',
  facialAuthenticationError = 'FACIAL_AUTHENTICATION_ERROR',
  facialLivenessError = 'FACIAL_LIVENESS_ERROR',
  nfcInternalError = 'NFC_INTERNAL_ERROR',
  blackListedFaceTemplate = 'BLACKLISTED_FACE_TEMPLATE',
  alreadyRegistered = 'ALREADY_REGISTERED',
  manualStatusChange = 'MANUAL_STATUS_CHANGE',
}

export type ServiceManualCheck = {
  type: string;
  name: string;
  isChecked: boolean;
};

type OperationResult = {
  type: string | null;
  status: OperationStatus;
  reason: OperationResultReason | null;
};

export type StatusChange = {
  type: string;
  status: OperationStatus;
  comment: string | null;
  previousStatus: OperationStatus;
  timestamp: string | number | Date;
};

export type DetailsType = {
  operationResults: OperationResult[] | null;
  statusChangeList: StatusChange[] | null;
  type: OperationType;
  extServiceManualChecklist: ServiceManualCheck[] | null;
};

export type OperationDetailsDto = {
  operationId: string;
  type: OperationType;
  details: DetailsType;
};
