export type RulesModalDto = {
  type: TypeRulesDto;
  description?: string;
};

export enum TypeRulesDto {
  face = 'face',
}

export type TypeDto = {
  id: string;
  name: string;
};
