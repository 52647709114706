import React, { createContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Toast } from './../components/toast';
import { ToastProps } from './../components/toast/model';
import { ZINDEX_TOAST } from '../styles';

type IProps = {
  children: React.ReactNode;
  duration?: number;
};

type ContextProps = {
  toastManager(toastProps: ToastProps): void;
};

const ToastContext = createContext<ContextProps>({
  toastManager: () => {},
});

export const ToastProvider = ({ children, duration = 10000 }: IProps) => {
  const toastManager = ({
    $type,
    message,
    subMessage = '',
    duration = 10000,
    onClick,
    iconName,
    testId,
    buttonMessage,
    canClose,
    hasIcon,
  }: ToastProps) => {
    toast(
      (t) => {
        return (
          <Toast
            $type={$type}
            message={message}
            subMessage={subMessage}
            id={t.id}
            onClick={onClick}
            iconName={iconName}
            testId={testId}
            buttonMessage={buttonMessage}
            canClose={canClose}
            hasIcon={hasIcon}
          />
        );
      },
      { duration }
    );
  };

  return (
    <ToastContext.Provider
      value={{
        toastManager,
      }}
    >
      {children}
      <Toaster
        position="bottom-right"
        reverseOrder={true}
        toastOptions={{
          duration: duration,
          style: {
            maxWidth: '50rem',
            padding: 0,
            background: 'transparent',
            boxShadow: 'none',
            zIndex: ZINDEX_TOAST,
          },
        }}
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => React.useContext(ToastContext);
