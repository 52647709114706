import { useMemo } from 'react';
import { FlexContainer, useTheme } from '@facephi/inphinite-ui';
import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { PercentageStatictics } from '../../state/model';
import { COLORS } from './model';
import { StackedContainer } from './Styles';

export type DataBarChart = {
  periods: string[];
  data: Record<PercentageStatictics['status'], PercentageStatictics['value'][]>;
  loading?: boolean;
};

export const StackedBarChart = ({
  data = {},
  periods = [],
  loading,
}: DataBarChart) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const styleSeries = {
    borderRadius: 10,
    borderWidth: 5,
    borderColor: 'transparent',
  };

  const series = useMemo(() => {
    if (loading) return [];
    return Object.entries(data).reduce(
      (acc: EChartsOption, [key, value], index) => {
        acc.push({
          name: t(key),
          type: 'bar',
          barWidth: 20,
          stack: 'Operations',
          emphasis: {
            focus: 'series',
          },
          data: value,
          itemStyle: {
            color: COLORS[index],
            ...styleSeries,
          },
        });
        return acc;
      },
      []
    );
  }, [loading, data]);

  const options = {
    tooltip: {
      className: 'echarts-tooltip',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        show: false,
      },
      textStyle: {
        color: theme.color.greys[700],
        fontWeight: 700,
      },
    },
    textStyle: {
      fontFamily: 'Poppins',
      color: theme.color.greys[700],
      fontSize: 10,
    },
    legend: {
      data: loading ? {} : Object.keys(data).map((text) => t(text)),
      orient: 'horizontal',
      top: 'top',
      itemGap: 50,
      itemHeight: 20,
      itemWidth: 30,
      icon: 'path://M98.139 23.117C78.524 25.167 61.737 31.93 46.987 43.728 29.347 57.837 16.973 78.296 12.413 100.893 9.312 116.265 10.489 134.278 15.581 149.34 18.999 159.453 24.875 170.675 30.242 177.344 31.23 178.571 32.738 180.48 33.593 181.585 39.555 189.294 52.464 199.468 63.17 204.895 73.858 210.313 85.576 213.874 96.429 215 101.466 215.524 305.844 215.416 310.826 214.888 332.519 212.587 351.769 203.186 368 186.968 374.701 180.272 375.631 179.153 380.631 171.763 387.265 161.96 392.77 148.494 394.505 137.826 394.683 136.726 395.087 134.369 395.401 132.589 397.118 122.861 397.039 115.992 395.07 103.683 390.658 76.109 374.123 51.503 350.262 37.003 338.057 29.587 326.488 25.541 311.607 23.486L307.254 22.885 204.129 22.851C119.284 22.824 100.497 22.871 98.139 23.117',
      textStyle: {
        color: theme.color.greys[700],
        fontFamily: 'inherit',
        fontSize: 12,
      },
      itemStyle: {
        borderType: 'solid',
        borderWidth: 2,
        borderColor: theme.color.greys.white,
      },
    },
    xAxis: [
      {
        type: 'category',
        data: periods,
        axisLabel: {
          interval: 0,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series,
  };

  return (
    <StackedContainer>
      {loading && (
        <FlexContainer
          flexDirection="row"
          columnGap="2.2"
          justifyContent="center"
        >
          <Skeleton height={12} width={70} borderRadius={14} />
          <Skeleton height={12} width={70} borderRadius={14} />
          <Skeleton height={12} width={70} borderRadius={14} />
        </FlexContainer>
      )}
      <ReactECharts option={options} style={{ height: '45rem' }} />
    </StackedContainer>
  );
};
