/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { NoFilesUploaded } from '@facephi/inphinite-common';
import { Spinner } from '@facephi/inphinite-ui';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useApi } from '../../../hooks';

type Point = { latitude: any; longitude: any };
type AddressPoint = Array<Point>;

export const MapChart = () => {
  const { getData } = useApi();
  const [locations, setLocations] = useState<AddressPoint>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [centerPosition, setCenterPosition] = useState<[number, number]>();

  useEffect(() => {
    getData('locations')
      .then((response) => {
        setLocations(
          response.data.filter(
            (element: { latitude: any; longitude: any }) =>
              Object.values(element).length === 2
          )
        );
        setError(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    locations[0] &&
      setCenterPosition([locations[0].latitude, locations[0].longitude]);
    setLoading(false);
    if (locations.length === 0) {
      setError(true);
    }
  }, [locations]);

  if (loading)
    return (
      <>
        <Spinner genericSpinner insideComponent />
      </>
    );

  if (!loading && error)
    return (
      <>
        <div style={{ justifyContent: 'start' }}>
          <NoFilesUploaded messageType="information" />
        </div>
      </>
    );

  return (
    <>
      {centerPosition && (
        <div data-test="mapContainer">
          <MapContainer
            center={centerPosition || [0, 0]}
            zoom={15}
            scrollWheelZoom={true}
            style={{ height: '300px', width: '100%' }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup chunkedLoading>
              {locations.map((element, index) => {
                return (
                  <Marker
                    key={index}
                    position={[element.latitude, element.longitude]}
                  >
                    <Popup>Info Popup.</Popup>
                  </Marker>
                );
              })}
            </MarkerClusterGroup>
          </MapContainer>
        </div>
      )}
    </>
  );
};
