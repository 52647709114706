import React from 'react';
import { LogoProps } from './model';

export const Logo = ({
  className,
  black = false,
  height = '3.4rem',
  width = '3.4rem',
}: LogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 72"
      fill="none"
      className={className}
    >
      <path
        d="M32.349 9.46308V70.7516C36.3758 70.7516 39.651 67.4228 39.651 62.349V2C35.6242 2 32.349 5.3557 32.349 9.46308Z"
        fill="url(#paint0_linear_873_25749)"
      />
      <path
        d="M24.4832 49.302C24.4832 49.302 20.9396 47.6645 19.7852 47.047C11.2752 42.5638 8.67114 35.6913 8.67114 32.3087C8.67114 28.1208 9.74496 24.953 11.4899 22.4027C10.953 25.8121 10.7383 29.5168 11.9195 32.7651C11.9195 32.7651 12.9933 31.9061 16.6443 29.4363C18.7382 28.0403 25.2349 24.1477 25.2349 24.1477C29.1544 21.4631 28.9127 20.6041 28.9127 18.0537V9.40942C28.9127 9.40942 13.5034 18.4564 8.67114 21.8926C3.78523 25.3826 0 30.4027 0 38.1342C0 45.9463 4.91275 51.1812 15.1141 56.3893C18.094 57.9195 28.9396 63.047 28.9396 63.047V54.5369C28.9396 51.5034 27.9731 51.1275 24.4832 49.302Z"
        fill={black ? '#243760' : '#fff'}
      />
      <path
        d="M63.3289 21.8926C58.4967 18.4296 43.0873 9.40942 43.0873 9.40942V18.0537C43.0873 20.6041 42.8456 21.49 46.7651 24.1477C46.7651 24.1477 53.2617 28.0403 55.3557 29.4363C59.0067 31.8792 63.3557 35.2886 63.3557 43.1544C63.3557 46.9665 62.255 49.4631 59.9732 52.3624C60.7248 48.3624 60.7517 46.4027 60.2416 43.047C57.7987 44.4161 55.8389 45.3289 52.2148 47.047L47.5168 49.302C44.0269 51.1275 43.0604 51.5302 43.0604 54.5369V63.047C43.0604 63.047 53.906 57.9195 56.8859 56.3893C67.0873 51.1812 72 45.9732 72 38.1342C72 30.3759 68.2148 25.3826 63.3289 21.8926Z"
        fill={black ? '#243760' : '#fff'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_873_25749"
          x1="36.1367"
          y1="15.5296"
          x2="35.8001"
          y2="66.0237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={black ? '#4C7CFC' : '#fff'} />
          <stop offset="1" stopColor={black ? '#7636FC' : '#fff'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
