import React, { useEffect, useState } from 'react';
import { Transition } from 'react-spring';
import { ModalProps } from './model';
import { ModalStyles } from './Styles';
import { Card } from '../card/Card';
import { Portal } from '../portal';

export const Modal = ({
  title,
  onCreate,
  children,
  show = false,
  overlay = true,
  testId,
  onChangeShow,
  closeLabel,
  submitLabel,
  className,
  iconHeader,
  testIdSave,
  noPaddingContent,
  noBorderHeader,
  flexDirectionContent,
  alignItemsContent,
  justifyContent,
  isForm,
  rowGapContent,
  columnGapContent,
}: ModalProps) => {
  const [innerShow, setInnerShow] = useState<boolean>(show);

  useEffect(() => {
    setInnerShow(show);
  }, [show]);

  useEffect(() => {
    onChangeShow && onChangeShow(innerShow);
  }, [innerShow]);

  const onCloseModal = () => {
    setInnerShow(false);
  };

  return (
    <Transition
      items={innerShow}
      from={{ opacity: 0, transform: 'translate(-50%, -50%) scale(0.5)' }}
      enter={{ opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }}
      leave={{ opacity: 0, transform: 'translate(-50%, -50%) scale(0.5)' }}
    >
      {(styles, innerShow) =>
        innerShow && (
          <Portal
            show={innerShow}
            onClickOutside={onCloseModal}
            actionRef={null}
            overlayBlur
            overlay={overlay}
            testId="modal"
          >
            <ModalStyles
              style={{ ...styles }}
              data-test="modal-wrapper"
              className={className}
            >
              <Card
                testId={testId}
                children={children}
                iconHeader={iconHeader}
                noBorder={noBorderHeader}
                titleHeader={title}
                onCloseCard={onCloseModal}
                buttonFooterLeftLabel={closeLabel}
                onButtonFooterLeft={onCloseModal}
                buttonFooterRightLabel={submitLabel}
                onButtonFooterRight={onCreate}
                testIdButton={testIdSave}
                columnGapContent={columnGapContent}
                rowGapContent={rowGapContent}
                noPaddingContent={noPaddingContent}
                flexDirectionContent={flexDirectionContent}
                alignItemsContent={alignItemsContent}
                justifyContent={justifyContent}
                isForm={isForm}
              />
            </ModalStyles>
          </Portal>
        )
      }
    </Transition>
  );
};
