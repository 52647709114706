import React, { forwardRef, Ref, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { SquareFlowBox, SquareFlowStyles } from './Styles';
import { Icon } from '../../icon';
import { FlowBoxType, FlowType, SquareProps } from '../model';

export const SquareFlow = forwardRef(
  ({ step, testId, className }: SquareProps, ref: Ref<HTMLDivElement>) => {
    const theme = useTheme();
    const squareFlowObject: FlowBoxType[] = [
      {
        step: FlowType.STEP1,
        iconName: 'Flag',
        color: 'yellow',
      },
      {
        step: FlowType.STEP2,
        iconName: 'ChalkboardTeacher',
        color: 'green',
      },
      {
        step: FlowType.STEP3,
        iconName: 'UserCircle',
        color: 'purple',
      },
      {
        step: FlowType.STEP4,
        iconName: 'ShieldCheck',
        color: 'blue',
      },
    ];

    const validateSteps = useMemo(() => {
      let isValidate = false;
      const stepsValidate = [];
      for (let i = 0; i < squareFlowObject.length && !isValidate; i++) {
        const stepName = squareFlowObject[i].step;
        stepsValidate.push(stepName);
        isValidate = stepName === step;
      }
      return stepsValidate;
    }, [step]);

    return (
      <SquareFlowStyles
        step={step}
        className={className}
        data-test={testId}
        ref={ref}
        alignItems="center"
      >
        {squareFlowObject.map((item: FlowBoxType, index) => {
          const isActive = validateSteps.includes(item.step);

          return (
            <SquareFlowBox
              key={index}
              alignItems="center"
              justifyContent="center"
              color={item.color}
              style={{
                backgroundColor: isActive
                  ? theme.color[item.color][100]
                  : theme.color.greys[100],
                borderLeftColor: isActive
                  ? theme.color[item.color][500]
                  : theme.color.greys[500],
                color: isActive
                  ? theme.color[item.color][500]
                  : theme.color.greys[500],
              }}
            >
              <Icon iconName={item.iconName} size="18" />
            </SquareFlowBox>
          );
        })}
      </SquareFlowStyles>
    );
  }
);
