import {
  CardBase,
  CardBaseContent,
  CardBaseHeader,
  FlexContainer,
  IconWrapper,
  Label,
  Status,
  StatusType,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  CardAnalytic,
  CardTipsButton,
  CardTipsSubtitle,
  CardUXTips,
  HeaderUX,
  TableKPIs,
  TableUXTask,
  UXTaskGrid,
} from '../components';

export const UXTaskPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderUX />
      <UXTaskGrid>
        <FlexContainer flexDirection="column" rowGap="1.6">
          <Label size="18" semibold>
            {t('Time on task')}
          </Label>
          <TableUXTask />
        </FlexContainer>
        <CardBase>
          <CardBaseHeader alignItems="center" justifyContent="space-between">
            <FlexContainer columnGap="1.8" alignItems="center">
              <IconWrapper
                iconName="CircleWavyCheck"
                backgroundColor="violet"
                size="M"
              />
              <Label size="18" semibold>
                {t('Surveys')}
              </Label>
              <TableKPIs />
            </FlexContainer>
            <Status withLabel type={StatusType.ACTIVE} />
          </CardBaseHeader>
          <CardBaseContent flexDirection="column">
            <Label size="14" semibold>
              {t('Ask for client feedback')}
            </Label>
            <CardTipsSubtitle size="12">
              {t(
                'Build your NPS to discover your clients’ opinions. Customize your questions and visualize the results'
              )}
            </CardTipsSubtitle>
            <CardTipsButton variant="outline">
              {t('Start your survey')}
            </CardTipsButton>
          </CardBaseContent>
        </CardBase>
        <CardUXTips />
        <CardAnalytic showProgresBar={false} />
      </UXTaskGrid>
    </>
  );
};
