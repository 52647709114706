import styled from 'styled-components';
import { Label } from '../../label';
import { CardBase } from '../CardBase';

export const CardUserStyles = styled(CardBase)`
  position: relative;
  padding: 7.6rem 1.6rem 1.2rem;
  margin-top: 5rem;

  img {
    position: absolute;
    left: 50%;
    top: -5rem;
    transform: translateX(-50%);
    border-radius: 100%;
    width: 10rem;
    aspect-ratio: 1;
  }
`;

export const CardUserLink = styled(Label)`
  color: ${(props) => props.theme.color.primary[400]};
`;
