import styled from 'styled-components';
import { ItemProps } from './model';
import { Button } from '../button';
import { DROPDOWN_ITEM_HEIGHT } from '../dropdown/Styles';
import { FlexContainer } from '../flexContainer';
import { getSizeLabel } from '../label';
import { LabelSizeProps } from '../label/model';

export const OptionsMenuWrapper = styled.ul<{ noPadding?: boolean }>`
  border-radius: 0.8rem;
  padding: 0 ${(props) => (props.noPadding ? '0' : '0.8rem')};
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};
  border: 1px solid ${(props) => props.theme.color.greys[100]};
  background-color: ${(props) => props.theme.color.greys.white};
  overflow: hidden;
`;

export const OptionMenuItemList = styled(FlexContainer)<ItemProps>`
  height: ${DROPDOWN_ITEM_HEIGHT};
  border-bottom: 1px solid ${(props) => props.theme.color.greys[100]};
  width: 100%;
  &:last-of-type {
    border-bottom: none;
  }
`;

export const OptionMenuItemStyles = styled(Button)<{ $size?: LabelSizeProps }>`
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${(props) => props.$size && `${getSizeLabel(props.$size)};`}
`;
