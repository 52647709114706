import React from 'react';
import { Logo } from './Logo';
import { LogoSquareProps } from './model';
import { LogoContainer } from './Styles';
import { useTheme } from '../../providers/ThemeProvider';
import { ThemeGradientsScheme } from '../../themes';

export const LogoSquare = ({
  className,
  size = 'L',
  gradientColor = 'primary',
}: LogoSquareProps) => {
  const theme = useTheme();

  return (
    <LogoContainer
      className={className}
      size={size}
      gradientColor={theme.gradients[gradientColor] as ThemeGradientsScheme}
    >
      <Logo />
    </LogoContainer>
  );
};
