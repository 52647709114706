import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  TimelineEvent,
  TimeLineEventType,
  TimeLineType,
  TrackingFamily,
  FacialAuthenticationStatus,
  FacialLivenessDiagnostic,
} from '../state/model';
import { getTimeLine, getTimeLineAuthentication } from '../state/queries';

export function useTimeLine(operationId: string, family?: string) {
  const { data, loading } = useQuery(
    family === TrackingFamily.AUTHENTICATION
      ? getTimeLineAuthentication
      : getTimeLine,
    {
      variables: { parameters: { operationId, family } },
    }
  );

  const { t } = useTranslation();

  const events = useMemo(
    () =>
      data
        ? data.timeline.events
            .filter(
              (item: TimelineEvent) =>
                item.metadata.type === TimeLineType.onboarding ||
                item.metadata.type === TimeLineType.asset ||
                item.metadata.type === TimeLineType.facialAuthenticate ||
                item.metadata.type === TimeLineType.facialLiveness ||
                item.metadata.type === TimeLineType.authentication ||
                item.metadata.type === TimeLineType.operationResult ||
                item.metadata.type === TimeLineType.securityInfoData
            )
            .map((item: TimelineEvent) => ({
              ...item,
              metadata:
                item.metadata.type === TimeLineType.asset
                  ? { ...item.metadata, event: TimeLineEventType.asset }
                  : item.metadata,
            }))
            .filter((item: TimelineEvent) => {
              return (
                item.metadata.event === TimeLineEventType.screenAccess ||
                item.metadata.event === TimeLineEventType.onboardingResult ||
                item.metadata.event === TimeLineEventType.asset ||
                item.metadata.event === TimeLineEventType.recording ||
                item.metadata.event === TimeLineEventType.stepChange ||
                item.metadata.type === TimeLineType.operationResult ||
                item.metadata.type === TimeLineType.facialAuthenticate ||
                item.metadata.type === TimeLineType.facialLiveness ||
                item.metadata.type === TimeLineType.securityInfoData
              );
            })
        : [],
    [data]
  );

  const getMessageTimeline = (
    value: TimeLineEventType,
    screen: string,
    data: string,
    type: string,
    reason: string,
    diagnostic?: FacialLivenessDiagnostic,
    authStatus?: FacialAuthenticationStatus,
    operationStatus?: string
  ) => {
    if (type === TimeLineEventType.facialAuthenticate && authStatus) {
      return t('Authentication status', { status: t(authStatus) });
    }

    if (type === TimeLineEventType.facialLiveness && diagnostic) {
      return t('Facial Liveness status', { status: t(diagnostic) });
    }

    if (type === TimeLineType.asset) {
      return t('1 new document uploaded');
    } else {
      if (value === TimeLineEventType.screenAccess) {
        return t(value, { screen: screen });
      } else if (value === TimeLineEventType.onboardingResult) {
        return t(value, { result: t(data) });
      } else if (value === TimeLineEventType.stepChange) {
        return t(value, { result: t(data) });
      } else if (type === TimeLineType.operationResult) {
        return t(reason) || t(operationStatus as string);
      }

      console.log(value);
    }

    return t(value);
  };

  return {
    events,
    eventMessage: getMessageTimeline,
    loadingEvents: loading,
  };
}
