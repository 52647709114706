import { ReactElement, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useFlags, useCommon } from '@facephi/inphinite-common';
import {
  ButtonIcon,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  Table,
  TData,
  ToastType,
  useToast,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { useGroups } from '../../hooks';
import { RoutesName } from '../../state/constants';
import { GroupDto } from '../../state/model';
import { deleteGroupMutation } from '../../state/mutations';
import { getGroups, getUsers } from '../../state/queries';
import { GroupBox } from '../GroupBox';

type GroupTableData = {
  groupContent: ReactElement;
  id: string;
};

export const UserManagementTableGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { demo } = useFlags();
  const {
    tenant: { id: tenantId },
  } = useCommon();
  const { groups, loadingGroups, loadingUsers } = useGroups();

  const [deleteGroup] = useMutation(deleteGroupMutation);

  const { toastManager } = useToast();

  const onDeleteGroup = async (id: string) => {
    await deleteGroup({
      variables: { groupId: id, tenantId },
      refetchQueries: [
        {
          query: getUsers,
        },
      ],
      update: (cache) => {
        const response = cache.readQuery<{
          listGroups: { groups: GroupDto[] };
        }>({
          query: getGroups,
          variables: {
            tenantId,
          },
        });

        if (response) {
          const newData = {
            listGroups: {
              groups: response.listGroups.groups.filter(
                (group: GroupDto) => group.id !== id
              ),
            },
          };

          cache.writeQuery({
            query: getGroups,
            variables: {
              tenantId,
            },
            data: newData,
          });
        }
      },
    });

    toastManager({
      $type: ToastType.SUCCESS,
      message: t('Group deleted'),
      testId: 'toast-delete-group',
    });
  };

  const columns: TData[] = useMemo(
    () => [
      {
        Header: t('Group name'),
        accessor: 'groupContent',
      },
      {
        Header: t('Actions'),
        maxWidth: 60,
        Cell: (props: CellProps<GroupTableData>) => (
          <span data-tour="settings_user-action">
            <OptionMenu
              hidePanelOnClick
              renderItem={<ButtonIcon text iconName="ListPlus" />}
              positionX={PositionX.end}
              testId={`action-group-${props.row.id}`}
            >
              {demo && (
                <OptionMenuItem
                  onClick={() =>
                    navigate(
                      generatePath(RoutesName.permissions, {
                        type: 'groups',
                        id: props.row.original.id,
                      })
                    )
                  }
                >
                  {t('Set up permission')}
                </OptionMenuItem>
              )}

              <OptionMenuItem
                onClick={() => onDeleteGroup(props.row.original.id)}
                testId="button-delete-group"
              >
                {t('Delete')}
              </OptionMenuItem>
            </OptionMenu>
          </span>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    return groups
      ? groups.map((item: GroupDto) => ({
          id: item.id,
          groupContent: <GroupBox name={item.name} id={item.id} />,
        }))
      : [];
  }, [groups]);

  return (
    <Table
      columns={columns as Column<TData>[]}
      data={data}
      testId="table-groups"
      hasMore={false}
      loading={loadingUsers || loadingGroups}
    />
  );
};
