import { Label, Switch } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useAnonymize } from '../../providers';
import { AnonymizedIcon, AnonymizedStyles } from './Styles';

export const Anonymized = () => {
  const { t } = useTranslation();
  const { anonymized, setAnonymized } = useAnonymize();

  const handleAnonymized = (value?: boolean) => {
    setAnonymized(!!value);
  };

  return (
    <AnonymizedStyles alignItems="center" columnGap="1" checked={anonymized}>
      <AnonymizedIcon size="24" iconName={anonymized ? 'Lock' : 'LockOpen'} />
      <Label size="14">
        {t(anonymized ? t('Privacy mode on') : t('Privacy mode off'))}
      </Label>
      <Switch
        id="anonymized-button"
        checked={anonymized}
        onChange={handleAnonymized}
      />
    </AnonymizedStyles>
  );
};
