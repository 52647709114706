import { Dropdown, InputUpload, WrapperDrag } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';
import { RulesTabsWrapper } from '../antiFraud';

export const AITabsWrapper = styled(RulesTabsWrapper)`
  height: 100%;
`;

export const ModalDocumenAIUpload = styled(InputUpload)`
  ${WrapperDrag} {
    height: 14rem;
    width: 23.5rem;
  }
`;

export const ModalDocumenAIDropdown = styled(Dropdown)`
  width: 28rem;
`;
