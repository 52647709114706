import { IconName, ThemeColorType } from '@facephi/inphinite-ui';

export type PermissionsBoxStyleProps = {
  isDraggable?: boolean;
  isEdit?: boolean;
  isClosable?: boolean;
  disabled?: boolean;
};

export type RawPermissionType = {
  id: string;
  label: string;
  parent?: string;
  name?: string;
  permissions?: Array<RawPermissionType>;
};

export type PermissionType = {
  iconName: IconName;
  color: ThemeColorType;
  label: string;
} & RawPermissionType;

export const TYPES_MANAGEMENT = ['users', 'groups'];
