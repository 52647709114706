import styled from 'styled-components';
import { SpinnerStylesProps } from './model';
import { LottieAnimation } from '../lottieAnimation';

export const SpinnerStyles = styled(LottieAnimation)<SpinnerStylesProps>`
  position: ${(props) =>
    props.type === 'block'
      ? 'initial'
      : props.insideComponent
      ? 'absolute'
      : 'fixed'};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  svg {
    width: ${(props) =>
      props.size === 'S'
        ? '4rem'
        : props.size === 'M'
        ? '10rem'
        : '14rem'} !important;
    height: ${(props) =>
      props.size === 'S'
        ? '4rem'
        : props.size === 'M'
        ? '10rem'
        : '14rem'} !important;
  }
`;

export const SpinnerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const SpinnerGeneric = styled.div<SpinnerStylesProps>`
  position: ${(props) =>
    props.type === 'block'
      ? 'initial'
      : props.insideComponent
      ? 'absolute'
      : 'fixed'};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  > span {
    box-sizing: border-box;
    display: block;
    width: ${(props) =>
      props.size === 'S' ? '2.8rem' : props.size === 'M' ? '8rem' : '14rem'};
    height: ${(props) =>
      props.size === 'S' ? '2.8rem' : props.size === 'M' ? '8rem' : '14rem'};
    border: solid transparent;
    border-width: ${(props) =>
      props.size === 'S' ? '0.4rem' : props.size === 'M' ? '0.8rem' : '1rem'};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #7189fc #8839fd #8839fd #8839fd;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
