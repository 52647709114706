import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSpring } from 'react-spring';
import { CardCollapsableProps } from './model';
import {
  CardCollapsableStyles,
  CARD_BASE_SMALL_HEADER_HEIGHT,
  CollapsableContent,
  CollapsableHeader,
} from './Styles';
import { useHeight } from '../../../hooks/useHeight';
import { useTheme } from '../../../providers';
import { ButtonIcon } from '../../buttonIcon';
import { FlexContainer } from '../../flexContainer';
import { Icon } from '../../icon';
import { IconWrapper } from '../../iconWrapper';
import { Label } from '../../label';
import { CARD_BASE_HEADER_HEIGHT } from '../Styles';

export const CardCollapsable = ({
  children,
  className,
  onChange,
  title,
  isOpen = false,
  isLock = false,
  testId,
  iconLeft,
  iconLeftBackground,
  loading,
  small,
  style,
  withShadow,
  rightHeaderChildren,
  iconLeftColor,
  iconLeftWeight,
  iconLeftIntensity,
}: CardCollapsableProps) => {
  const [selected, setSelected] = useState<boolean>(isOpen);
  const theme = useTheme();

  const { ref, height } = useHeight();

  const handleSelected = () => {
    if (!isLock) {
      setSelected(!selected);
    }
  };

  const slideInStyles = useSpring({
    from: {
      height: small ? CARD_BASE_SMALL_HEADER_HEIGHT : CARD_BASE_HEADER_HEIGHT,
    },
    to: {
      height: selected
        ? `${height / 10}rem`
        : small
        ? CARD_BASE_SMALL_HEADER_HEIGHT
        : CARD_BASE_HEADER_HEIGHT,
    },
  });

  useEffect(() => {
    setSelected(isOpen);
  }, [isOpen]);

  useEffect(() => {
    onChange &&
      selected !== null &&
      selected !== undefined &&
      onChange(selected);
  }, [selected]);

  const getLoadingHeader = () => (
    <>
      {iconLeft && <Skeleton width={18} height={18} borderRadius={8} />}
      <div style={{ width: '100%', textAlign: 'left' }}>
        <Skeleton width="60%" height={14} borderRadius={8} />
      </div>
    </>
  );

  const getHeader = () => (
    <>
      {iconLeftBackground && iconLeft && (
        <IconWrapper
          size="XS"
          iconName={iconLeft}
          backgroundColor={iconLeftBackground}
        />
      )}
      {!iconLeftBackground && iconLeft && (
        <Icon
          iconName={iconLeft}
          size="24"
          color={iconLeftColor ? iconLeftColor : 'greys'}
          weight={iconLeftWeight ? iconLeftWeight : 'regular'}
          intensity={iconLeftIntensity ? iconLeftIntensity : 600}
        />
      )}
      <Label size="14" semibold>
        {title}
      </Label>
    </>
  );

  const getCollapseButton = () => (
    <ButtonIcon
      iconName={isLock ? 'Lock' : selected ? 'Minus' : 'Plus'}
      onClick={handleSelected}
      color="greys"
      intensity={600}
      disabled={isLock}
      text
      testId="collapsable-button"
    />
  );

  const getHeaderRight = () =>
    rightHeaderChildren ? (
      <FlexContainer alignItems="center" columnGap="0.8">
        {rightHeaderChildren}
        {getCollapseButton()}
      </FlexContainer>
    ) : (
      getCollapseButton()
    );

  return (
    <CardCollapsableStyles
      style={{
        ...style,
        ...slideInStyles,
        boxShadow: withShadow
          ? `0 2px 14px 0 ${theme.color.greys[100]}`
          : 'none',
      }}
      className={className}
      data-test={testId}
    >
      <FlexContainer ref={ref} flexDirection="column">
        <CollapsableHeader
          alignItems="center"
          justifyContent="space-between"
          as="section"
          small={small}
        >
          <FlexContainer alignItems="center" columnGap="1" flex="1">
            {loading ? getLoadingHeader() : getHeader()}
          </FlexContainer>
          {!loading && getHeaderRight()}
        </CollapsableHeader>
        <CollapsableContent
          aria-expanded={selected}
          data-test={testId && `collapsable-${testId}`}
        >
          {React.cloneElement(children, {
            className: 'collapsable-list',
          })}
        </CollapsableContent>
      </FlexContainer>
    </CardCollapsableStyles>
  );
};
