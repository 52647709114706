import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  ColumnInstance,
  HeaderGroup,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useTable,
} from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { DEFAULT_ALIGN, NUMBER_ROWS_LOADING } from './constants';
import { TableProps } from './model';
import {
  TableCell,
  TableFooter,
  TableMainHeader,
  TableRow,
  TableStyles,
  TableWrapper,
} from './Styles';
import { DEFAULT_LOCALE } from '../../utils/translations';
import { EmptyState } from '../emptyState';
import { Label } from '../label';

type RowProps = {
  index: number;
  style: React.CSSProperties;
};

export const Table = forwardRef(
  (
    {
      columns,
      data,
      fetchMore,
      hasMore = true,
      onClickCell,
      error,
      hiddenHeader = false,
      newRow,
      className,
      loading,
      testId,
      scrollPosition,
      emptyState,
      onClickEmptyState,
      locale = DEFAULT_LOCALE,
      withTooltip,
    }: TableProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: Ref<any>
  ) => {
    const [hasScroll, setHasScroll] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [heightTable, setHeightTable] = useState<number>(0);

    const defaultColumn = useMemo(
      () => ({
        width: 'auto',
      }),
      []
    );

    useEffect(() => {
      scrollPosition !== undefined &&
        list.current &&
        list.current.scrollToItem(scrollPosition, 'smart');
    }, [heightTable, scrollPosition]);

    const instance = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useBlockLayout,
      useFilters,
      useGlobalFilter
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      instance;

    const itemCount =
      loading && !rows.length
        ? NUMBER_ROWS_LOADING
        : hasMore
        ? rows.length + 1
        : rows.length;

    useImperativeHandle(ref, () => instance);

    const RenderRow = React.useCallback(
      ({ index, style }: RowProps) => {
        const row = rows[index];

        if (!row) {
          return (
            <TableRow style={style}>
              {headerGroups.map((headerGroup) =>
                headerGroup.headers.map((column, count) => (
                  <TableCell
                    key={count}
                    style={{
                      flex: 1,
                      padding: '0 0.5rem',
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      width: column.width,
                    }}
                  >
                    <div style={{ width: '100%', display: 'inline-block' }}>
                      <Skeleton height={20} borderRadius={8} />
                    </div>
                  </TableCell>
                ))
              )}
            </TableRow>
          );
        }

        prepareRow(row);
        return (
          <div
            {...row.getRowProps({
              style,
            })}
            data-test="table-row"
          >
            <TableRow
              error={error}
              newRow={newRow}
              data-test={`table-row-${index}`}
              withTooltip={withTooltip}
            >
              {row.cells.map((cell) => {
                const className: string =
                  cell.column['className' as keyof ColumnInstance] ??
                  DEFAULT_ALIGN;
                const cellStyle =
                  cell.column['style' as keyof ColumnInstance] ?? {};
                return (
                  <TableCell
                    {...cell.getCellProps({
                      className,
                      style: {
                        ...cellStyle,
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                        width: cell.column.width,
                      },
                    })}
                    active={onClickCell ? true : false}
                    onClick={() => onClickCell && onClickCell(row.original)}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          </div>
        );
      },
      [prepareRow, rows]
    );

    const infiniteLoaderRef = useRef<InfiniteLoader>(null);
    const element = useRef<HTMLDivElement>(null);
    const list = useRef<FixedSizeList | null>(null);

    useEffect(() => {
      if (element.current && element.current.scrollHeight > heightTable) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    }, [element.current, heightTable]);

    return (
      <TableWrapper ref={containerRef} hiddenHeader={hiddenHeader}>
        <AutoSizer
          style={{ width: '100%' }}
          onResize={(size) => setHeightTable(size.height)}
        >
          {({ height }) => {
            return (
              <>
                <TableStyles
                  {...getTableProps()}
                  className={className}
                  data-test={testId}
                >
                  {hiddenHeader === false &&
                    headerGroups.map((headerGroup) => (
                      <TableMainHeader
                        alignItems="center"
                        {...headerGroup.getHeaderGroupProps()}
                        hasScroll={hasScroll}
                      >
                        {headerGroup.headers.map((column, index) => {
                          const className: string =
                            column['headerClassName' as keyof HeaderGroup] ||
                            DEFAULT_ALIGN;
                          return (
                            <div
                              {...column.getHeaderProps({
                                className,
                                style: {
                                  minWidth: column.minWidth,
                                  maxWidth: column.maxWidth,
                                  width: column.width,
                                },
                              })}
                              key={index}
                            >
                              <Label size="10" black>
                                {column.render('Header')}
                              </Label>
                            </div>
                          );
                        })}
                      </TableMainHeader>
                    ))}

                  <div {...getTableBodyProps()}>
                    <InfiniteLoader
                      isItemLoaded={(index) => index < rows.length}
                      itemCount={itemCount}
                      loadMoreItems={fetchMore ? fetchMore : () => {}}
                      ref={infiniteLoaderRef}
                    >
                      {({ onItemsRendered, ref: setRef }) => (
                        <FixedSizeList
                          height={emptyState ? 0 : height}
                          itemCount={itemCount}
                          innerRef={element}
                          itemSize={72}
                          width={'100%'}
                          onItemsRendered={onItemsRendered}
                          ref={(listRef) => {
                            setRef(listRef);
                            list.current = listRef;
                          }}
                        >
                          {RenderRow}
                        </FixedSizeList>
                      )}
                    </InfiniteLoader>
                    {emptyState && (
                      <EmptyState
                        testId="empty-state"
                        style={{
                          height,
                          overflow: 'auto',
                        }}
                        type={emptyState}
                        locale={locale}
                        onClick={onClickEmptyState}
                      />
                    )}
                  </div>
                  {hiddenHeader === false && <TableFooter />}
                </TableStyles>
              </>
            );
          }}
        </AutoSizer>
      </TableWrapper>
    );
  }
);
