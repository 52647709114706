import React from 'react';
import { IconCustomProps } from '../model';

export const FraudIcon = ({ className, size, color }: IconCustomProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      className={className}
      width={size ? (size as number) : undefined}
      height={size ? (size as number) : undefined}
    >
      <path
        d="M8.297 8.8c-3.062 0-5.714 2.508-7.396 4.617-1.201 1.514-1.201 3.654 0 5.168 1.695 2.109 4.334 4.616 7.396 4.616 2.112 0 4.448-1.32 6.040-2.429 1-0.701 2.333-0.701 3.333 0 1.592 1.109 3.929 2.429 6.035 2.429 3.062 0 5.713-2.506 7.395-4.616 1.201-1.514 1.201-3.654 0-5.168-1.682-2.103-4.327-4.617-7.395-4.617h-15.407zM8.297 10.096h15.407c2.568 0 4.885 2.244 6.42 4.128 0.832 1.048 0.832 2.53 0 3.577-1.509 1.885-3.852 4.128-6.42 4.128-1.778 0-3.853-1.192-5.304-2.193-1.443-1.011-3.365-1.011-4.807 0-1.438 1-3.525 2.193-5.304 2.193-2.568 0-4.885-2.244-6.42-4.128-0.822-1.059-0.806-2.543 0.039-3.584l-0.031 0.006c1.509-1.885 3.852-4.128 6.42-4.128zM26.018 12.916c-0.201-0.002-0.4 0.036-0.587 0.11l-4.693 1.872c-0.571 0.226-0.953 0.768-0.976 1.38s0.32 1.18 0.873 1.447l0.013 0.025c1.411 0.775 2.977 1.226 4.584 1.321 0.613 0.016 1.202-0.237 1.611-0.692 0.693-0.776 0.931-2.147 0.7-4.071-0.078-0.785-0.735-1.385-1.525-1.392zM6.138 12.948c-0.795-0-1.46 0.601-1.54 1.391-0.225 1.923 0.001 3.295 0.701 4.071 0.417 0.445 1.008 0.687 1.617 0.661 1.604-0.093 3.168-0.543 4.576-1.315 0.554-0.264 0.899-0.83 0.879-1.443s-0.399-1.156-0.968-1.385l-4.693-1.872c-0.182-0.072-0.377-0.109-0.573-0.109v0zM6.137 14.224c0.034-0 0.068 0.006 0.099 0.019l4.706 1.872c0.121 0.027 0.207 0.133 0.207 0.257s-0.086 0.23-0.207 0.257c-1.236 0.685-2.608 1.090-4.019 1.186-0.243 0.015-0.479-0.082-0.642-0.263-0.437-0.5-0.578-1.616-0.405-3.097 0.016-0.131 0.128-0.23 0.261-0.23zM25.939 14.239c0.021-0.002 0.043-0 0.064 0.004 0.131 0.003 0.24 0.102 0.256 0.232 0.173 1.481 0.032 2.596-0.385 3.070-0.162 0.181-0.399 0.278-0.642 0.263-1.412-0.104-2.785-0.518-4.019-1.212-0.095-0.042-0.157-0.136-0.157-0.24s0.061-0.198 0.157-0.24l4.693-1.872c0.011-0.002 0.022-0.003 0.033-0.004v0z"
        fill={color}
      />
    </svg>
  );
};
