import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  ColumnInstance,
  HeaderGroup,
  useBlockLayout,
  useRowSelect,
  useTable,
} from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListOnScrollProps } from 'react-window';
import { DEFAULT_ALIGN, NUMBER_ROWS_LOADING } from './constants';
import { TableSelectionHandle, TableSelectionProps } from './model';
import {
  CheckboxStyles,
  TableCell,
  TableFooter,
  TableMainHeader,
  TableRow,
  TableStyles,
  TableWrapper,
} from './Styles';
import { TableHeader as Header } from './TableHeader';
import { DEFAULT_LOCALE } from '../../utils/translations';
import { EmptyState } from '../emptyState';
import { FlexContainer } from '../flexContainer';
import { Label } from '../label';

type RenderCheckboxProps = {
  onChange(e: { target: { checked: boolean } }): void;
  indeterminate?: boolean;
  name: string;
  testId?: string;
};
type RowProps = {
  index: number;
  style: React.CSSProperties;
};

export const TableSelection = forwardRef<
  TableSelectionHandle,
  TableSelectionProps
>(
  (
    {
      columns,
      data,
      fetchMore,
      hasMore = true,
      onClickCell,
      error,
      hiddenHeader = false,
      newRow,
      className,
      onSelectedRowsChange,
      selectedRows = [],
      loading,
      testId,
      scrollPosition,
      emptyState,
      onClickEmptyState,
      locale = DEFAULT_LOCALE,
      hasHeader,
      ...headerProps
    },
    ref
  ) => {
    const [hasScroll, setHasScroll] = useState<boolean>(false);
    const [heightTable, setHeightTable] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<number | undefined>();

    const containerRef = useRef<HTMLDivElement>(null);

    const defaultColumn = useMemo(
      () => ({
        width: 'auto',
      }),
      []
    );

    useEffect(() => {
      scrollPosition !== undefined &&
        list.current &&
        list.current.scrollToItem(scrollPosition);
    }, [heightTable, scrollPosition]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      state: { selectedRowIds },
      toggleAllRowsSelected,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          selectedRowIds: selectedRows.reduce(
            (acc, item: number) => ({
              ...acc,
              [item]: true,
            }),
            {}
          ),
        },
      },
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Header: ({ getToggleAllRowsSelectedProps }: any) => (
              <RenderCheckbox
                testId={'item-checkbox-header'}
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: ({ row }: any) => (
              <RenderCheckbox
                testId={`item-checkbox-${row.index}`}
                {...row.getToggleRowSelectedProps()}
              />
            ),
            maxWidth: 60,
            width: 60,
          },
          ...columns,
        ]);
      },
      useBlockLayout
    );

    useImperativeHandle(ref, () => ({
      reset: () => toggleAllRowsSelected(false),
    }));

    React.useEffect(() => {
      if (onSelectedRowsChange) {
        onSelectedRowsChange(selectedFlatRows.map(({ original }) => original));
      }
    }, [selectedRowIds]);

    React.useEffect(() => {
      const numberOfItems = Object.keys(selectedRowIds).length;
      setSelectedItems(numberOfItems);
    }, [selectedRowIds]);

    const RenderCheckbox = React.forwardRef<
      HTMLInputElement,
      RenderCheckboxProps
    >(({ onChange, testId, ...rest }, ref: React.Ref<HTMLInputElement>) => {
      const handleOnChange = (value: boolean) => {
        onChange({ target: { checked: value } });
      };

      return (
        <CheckboxStyles
          testId={testId}
          ref={ref}
          {...rest}
          onChange={handleOnChange}
        />
      );
    });

    const RenderRow = React.useCallback(
      ({ index, style }: RowProps) => {
        const row = rows[index];
        if (!row) {
          return (
            <TableRow style={style}>
              {headerGroups.map((headerGroup) =>
                headerGroup.headers.map((column) => (
                  <TableCell
                    style={{
                      flex: 1,
                      padding: '0 0.5rem',
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      width: column.width,
                    }}
                    key={column.id}
                  >
                    <div style={{ width: '100%', display: 'inline-block' }}>
                      <Skeleton height={20} borderRadius={8} />
                    </div>
                  </TableCell>
                ))
              )}
            </TableRow>
          );
        }

        prepareRow(row);
        return (
          <div
            {...row.getRowProps({
              style,
            })}
            data-test="table-row"
          >
            <TableRow error={error} newRow={newRow} isSelected={row.isSelected}>
              {row.cells.map((cell) => {
                const className: string =
                  cell.column['className' as keyof ColumnInstance] ??
                  DEFAULT_ALIGN;
                return (
                  <TableCell
                    {...cell.getCellProps({
                      className,
                      style: {
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                        width: cell.column.width,
                      },
                    })}
                    key={cell.column.id}
                    data-test="table-cell"
                    active={onClickCell ? true : false}
                    onClick={() => onClickCell && onClickCell(row.original)}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          </div>
        );
      },
      [prepareRow, rows, selectedRowIds]
    );

    const element = useRef<HTMLDivElement>(null);
    const list = useRef<FixedSizeList>(null);

    useEffect(() => {
      if (element.current && element.current.scrollHeight > heightTable) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    }, [element.current, heightTable]);

    const onScrollHandle = useCallback(
      async (data: ListOnScrollProps) => {
        if (element.current && containerRef.current) {
          const height = containerRef.current.clientHeight;
          if (element.current.clientHeight - height === data.scrollOffset) {
            if (fetchMore && hasMore) {
              await fetchMore();
              list.current && list.current.scrollToItem(rows.length, 'center');
            }
          }
        }
      },
      [element, rows, list, containerRef, hasMore, fetchMore]
    );

    const renderTableSelection = () => (
      <TableWrapper ref={containerRef} hiddenHeader={hiddenHeader}>
        <AutoSizer
          style={{ width: '100%' }}
          onResize={(size) => setHeightTable(size.height)}
        >
          {({ height }) => {
            return (
              <TableStyles
                {...getTableProps()}
                className={className}
                data-test={testId}
              >
                {hiddenHeader === false &&
                  headerGroups.map((headerGroup) => (
                    <TableMainHeader
                      alignItems="center"
                      {...headerGroup.getHeaderGroupProps()}
                      hasScroll={hasScroll}
                    >
                      {headerGroup.headers.map((column) => {
                        const className: string =
                          column['headerClassName' as keyof HeaderGroup] ||
                          DEFAULT_ALIGN;
                        return (
                          <div
                            {...column.getHeaderProps({
                              className,
                              style: {
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth,
                                width: column.width,
                              },
                            })}
                          >
                            {typeof column.render('Header') === 'string' ? (
                              <Label size="10" black>
                                {column.render('Header')}
                              </Label>
                            ) : loading ? (
                              ''
                            ) : (
                              column.render('Header')
                            )}
                          </div>
                        );
                      })}
                    </TableMainHeader>
                  ))}

                <div {...getTableBodyProps()}>
                  <FixedSizeList
                    height={emptyState ? 0 : height}
                    itemCount={loading ? NUMBER_ROWS_LOADING : rows.length}
                    innerRef={element}
                    onScroll={onScrollHandle}
                    ref={list}
                    itemSize={72}
                    width={'100%'}
                    style={{
                      overflow: loading ? 'hidden' : 'auto',
                    }}
                  >
                    {RenderRow}
                  </FixedSizeList>
                  {emptyState && (
                    <EmptyState
                      testId="empty-state"
                      style={{
                        height,
                        overflow: 'auto',
                      }}
                      type={emptyState}
                      locale={locale}
                      onClick={onClickEmptyState}
                    />
                  )}
                </div>
                {hiddenHeader === false && <TableFooter />}
              </TableStyles>
            );
          }}
        </AutoSizer>
      </TableWrapper>
    );

    return hasHeader ? (
      <FlexContainer flexDirection="column" flex="1">
        <Header itemsSelected={selectedItems} {...headerProps} />
        {renderTableSelection()}
      </FlexContainer>
    ) : (
      renderTableSelection()
    );
  }
);
