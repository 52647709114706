import {
  CardBase,
  CardBaseContent,
  CardBaseHeader,
  CardBaseHeaderOperations,
} from '@facephi/inphinite-ui';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { CardDashboardProps } from './model';
import {
  CardContainer,
  CardFooter,
  CardSubtitleStyle,
  CardTitleStyle,
} from './Styles';

export const CardDashboard = ({
  loading,
  children,
  title,
  subtitle,
  viewAllText = 'View all',
  viewAllLink,
  className,
  ref,
  footer,
  noPadding,
}: CardDashboardProps) => {
  const getLoadingComponent = () => (
    <>
      {title && (
        <>
          <CardBaseHeader
            noBorder
            flexDirection="column"
            justifyContent="center"
          >
            <CardTitleStyle as="h2" size="18" semibold>
              <Skeleton height={20} width="50%" borderRadius={14} />
            </CardTitleStyle>
            <CardSubtitleStyle as="h3" size="12">
              <Skeleton height={12} width="25%" borderRadius={14} />
            </CardSubtitleStyle>
          </CardBaseHeader>
        </>
      )}
      <CardContainer flexDirection="column" justifyContent="center">
        {children}
      </CardContainer>
      {viewAllLink && (
        <CardFooter alignItems="center" justifyContent="center">
          <Skeleton height={12} width={50} borderRadius={14} />
        </CardFooter>
      )}
    </>
  );

  const getComponent = () => (
    <>
      {title && (
        <CardBaseHeaderOperations alignItems="center" noBorder>
          <CardTitleStyle as="h2" size="18" semibold>
            {title}
          </CardTitleStyle>
        </CardBaseHeaderOperations>
      )}
      {subtitle && (
        <CardSubtitleStyle as="h3" size="12">
          {subtitle}
        </CardSubtitleStyle>
      )}
      <CardBaseContent
        justifyContent="center"
        flexDirection="column"
        noPadding={noPadding}
      >
        {children}
      </CardBaseContent>

      <CardFooter alignItems="center" justifyContent="center">
        {viewAllLink && <Link to={viewAllLink}>{viewAllText}</Link>}
        {footer && footer}
      </CardFooter>
    </>
  );

  return (
    <CardBase className={className} ref={ref}>
      {loading ? getLoadingComponent() : getComponent()}
    </CardBase>
  );
};
