import { animated } from 'react-spring';
import styled from 'styled-components';
import { FlexContainer } from '../flexContainer';

export const InputAutoSuggestMenu = styled(animated.div)`
  padding: 0.8rem 0;
  border-radius: 0.8rem;
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};
  border: 1px solid ${(props) => props.theme.color.greys[100]};
  background-color: ${(props) => props.theme.color.greys.white};
`;

export const InputAutoSuggestStyles = styled(FlexContainer)`
  width: 100%;
`;
