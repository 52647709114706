import React, { forwardRef, Ref, useMemo } from 'react';
import { ArrowProps, FlowIcons, UNKNOWN_ICON, UNKNOWN_STEP } from './model';
import { ArrowFlowBox, ArrowsFlowStyles } from './Styles';
import { useTheme } from '../../../providers';
import { ThemeColorIntensityRegularType } from '../../../themes';
import { Icon } from '../../icon';

export const ArrowsFlow = forwardRef(
  (
    { currentStep, flow, color, testId, className, unknownSteps }: ArrowProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();

    const elements = useMemo(
      () => (unknownSteps ? flow.concat(UNKNOWN_STEP) : flow),
      [flow, unknownSteps]
    );

    const lastActiveItem = useMemo(
      () => elements.indexOf(currentStep),
      [elements, currentStep]
    );

    return (
      <ArrowsFlowStyles
        className={className}
        data-test={testId}
        ref={ref}
        alignItems="center"
      >
        {elements?.map((item, index) => {
          const isActive = index <= lastActiveItem;
          const count = index < flow.length ? index : index - flow.length;
          const getColor = (500 -
            count * 100) as ThemeColorIntensityRegularType;

          return (
            <ArrowFlowBox
              key={index}
              alignItems="center"
              justifyContent="center"
              isActive={isActive}
              isUnknownSteps={unknownSteps && flow.length === index}
              style={{
                backgroundColor: isActive
                  ? theme.color[color][getColor]
                  : unknownSteps && flow.length === index
                  ? theme.color.greys.white
                  : theme.color.greys[getColor],
              }}
            >
              <Icon iconName={FlowIcons[item] || UNKNOWN_ICON} size="18" />
            </ArrowFlowBox>
          );
        })}
      </ArrowsFlowStyles>
    );
  }
);
