import styled from 'styled-components';
import { ButtonIconSizes, ButtonIconStyleProps } from './model';

const getSize = (size?: ButtonIconSizes) => {
  const SIZES: { [key in ButtonIconSizes]: string } = {
    XS: '2',
    S: '2.5',
    M: '4',
    L: '4',
  };

  return SIZES[size || 'M'];
};
// TODO: REVIEW SECONDARY DISABLED WITH THE NEW DESIGN
export const ButtonIconStyle = styled.button<ButtonIconStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) =>
    props.text || props.variant === 'secondary'
      ? 'none'
      : `0 5px 10px 0
    ${props.theme.color.greys[100]}`};
  border-radius: 0.8rem;
  width: ${(props) => `${getSize(props.size)}rem`};
  min-width: ${(props) => `${getSize(props.size)}rem`};
  height: ${(props) => `${getSize(props.size)}rem`};
  background-color: ${(props) =>
    props.text
      ? 'transparent'
      : props.variant === 'secondary'
      ? props.theme.color.greys[600]
      : props.theme.color.greys.white};
  cursor: pointer;

  svg {
    color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.color.greys[200]
        : props.theme.color.greys[600]};
  }

  &:hover {
    background-color: ${(props) =>
      props.text
        ? 'transparent'
        : props.variant === 'secondary'
        ? props.theme.color.greys[500]
        : props.theme.color.greys[100]};
    svg {
      color: ${(props) =>
        props.variant === 'secondary'
          ? props.theme.color.greys[200]
          : props.theme.color.greys[600]};
    }
  }
  &:focus,
  &:active {
    background-color: ${(props) =>
      props.text
        ? 'none'
        : props.variant === 'secondary'
        ? props.theme.color.greys[400]
        : props.theme.color.greys[200]};
    svg {
      color: ${(props) =>
        props.variant === 'secondary'
          ? props.theme.color.greys[200]
          : props.theme.color.greys[600]};
    }
  }

  &:disabled {
    background-color: ${(props) =>
      props.text
        ? 'transparent'
        : props.variant === 'secondary'
        ? props.theme.color.greys[700]
        : props.theme.color.greys[50]};
    box-shadow: none;
    cursor: default;

    svg {
      color: ${(props) =>
        props.variant === 'secondary'
          ? props.theme.color.greys[500]
          : props.theme.color.greys[100]};
    }
  }
`;
