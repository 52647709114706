import React from 'react';
import { EmptyStateTypes, EmptyMessagesType } from './model';
import { TableEmpty } from './TableEmpty';
import { TableError } from './TableError';

export const EmptyMessages: { [key in EmptyStateTypes]: EmptyMessagesType } = {
  error: {
    title: 'Alert!',
    message: 'Something went wrong. Please retry.',
    buttonText: 'Retry',
    image: <TableError />,
  },
  data: {
    title: 'There are no data yet.',
    buttonText: 'Reload page',
    image: <TableEmpty />,
  },
  filter: {
    title: 'Sorry, we don’t find what you are searching.',
    message: 'Try again changing the search parameters.',
    image: <TableEmpty />,
  },
  tenants: {
    title: 'Add tenants to this user.',
    message: 'Click on top and start adding tenants.',
    image: <TableEmpty />,
  },
  users: {
    title: 'Add users to this tenant.',
    message: 'Click on top and start adding users.',
    image: <TableEmpty />,
  },
};
