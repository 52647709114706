import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import { ZINDEX_PORTAL } from '../../styles';

export const StyledDatePicker = styled(DayPicker)`
  border-radius: 0.8rem;
  background: ${(props) => props.theme.color.greys.white};
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};
  position: relative;
  overflow: hidden;
  padding-top: 3.2rem;

  @media (max-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${ZINDEX_PORTAL};
  }

  .DayPicker-Caption {
    background: ${(props) => props.theme.color.greys[100]};
    position: absolute;
    height: 3.2rem;
    top: -3.2rem;
    left: 0;
    width: 100%;
    padding: 0 2rem;

    > div {
      font-size: 1rem;
      color: ${(props) => props.theme.color.greys[700]};
      line-height: 3.2rem;
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .DayPicker-Weekday {
    font-size: 1em;
    color: ${(props) => props.theme.color.greys[300]};
  }

  .DayPicker-NavBar {
    position: relative;
    z-index: 2;
    top: -3.2rem;
    left: 0;

    .DayPicker-NavButton--prev {
      position: absolute;
      top: 0.8rem;
      left: 1rem;
    }

    .DayPicker-NavButton--next {
      position: absolute;
      top: 0.8rem;
      right: 1rem;
    }
  }

  .DayPicker-Day {
    font-size: 1rem;
    color: ${(props) => props.theme.color.greys[600]};
    border-radius: 50%;
  }

  .DayPicker-Day--disabled {
    color: ${(props) => props.theme.color.greys[200]};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${(props) => props.theme.color.blue[400]};
    color: ${(props) => props.theme.color.greys.white};
    border-radius: 0;
  }
  .DayPicker-Day--selected.DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .DayPicker-Day--selected.DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected:before:not(.DayPicker-Day--outside) {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.color.blue[400]};
    border-radius: 50% !important;
  }
  .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected:before:not(.DayPicker-Day--outside) {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.color.blue[400]};
    border-radius: 50% !important;
  }
`;
