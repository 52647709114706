import { Asset, MimeType } from '@facephi/inphinite-common';
import { IconWrapper } from '@facephi/inphinite-ui';
import { ImageLoad } from './ImageLoad';

type IProps = {
  asset: Asset;
};

export const AssetFile = ({ asset }: IProps) => {
  const ImageComponent = () => <ImageLoad asset={asset} />;
  const PdfComponent = () => (
    <IconWrapper backgroundColor="greys" iconName="FilePdf" />
  );
  const VideoComponent = () => (
    <IconWrapper backgroundColor="greys" iconName="VideoCamera" />
  );

  const typeDocument = {
    [MimeType.jpg]: ImageComponent,
    [MimeType.png]: ImageComponent,
    [MimeType.pdf]: PdfComponent,
    [MimeType.jpeg]: ImageComponent,
    [MimeType.mp4]: VideoComponent,
  };

  const ComponentResource = typeDocument[asset.contentType];

  return typeDocument[asset.contentType] ? <ComponentResource /> : null;
};
