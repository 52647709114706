/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { LottienAnimationProps } from './model';

export const LottieAnimation = ({
  animation,
  className,
  style,
}: LottienAnimationProps) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const renderAnimation = () => {
    lottie.loadAnimation({
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
      container: innerRef.current as any,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });
  };

  useEffect(() => {
    renderAnimation();

    return () => lottie.stop();
  }, [innerRef]);

  return <div className={className} ref={innerRef} style={style} />;
};
