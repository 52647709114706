/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { useWS } from '@backoffice/hooks';
import { useAuth, useLayout } from '@backoffice/providers';
import { VideoCall } from '@backoffice/state/model';
import {
  Status,
  StatusType,
  Table,
  TableActions,
  TData,
  useTimezone,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { QueueDataType } from './model';

type Props = {
  onShowId(): void;
  setInnerInfo: React.Dispatch<React.SetStateAction<QueueDataType>>;
};

export const QueueTable = ({ onShowId, setInnerInfo }: Props) => {
  const { setTitle } = useLayout();
  const { t, i18n } = useTranslation();
  const { formatTimezone } = useTimezone();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Array<VideoCall>>([]);

  const {
    token,
    user,
    role,
    tenant: { id: tenantId },
  } = useAuth();

  const { lastMessage } = useWS({
    websocketUrl: (window as any)._env_.REACT_APP_WS_MONITORING,
    token: token || '',
    email: user?.email,
    role: role as string,
    tenantId,
  });

  useEffect(() => {
    setTitle(t('Video support'));
  }, [setTitle]);

  const statusColumnCell = (props: Record<string, unknown>) => {
    const statusType: StatusType = props.value as StatusType;

    return <Status type={statusType} withLabel locale={i18n.language} />;
  };

  const columns: Column<TData>[] = React.useMemo(
    () => [
      {
        id: 'new-col',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: <></>,
        style: { padding: 0 },
        minWidth: 8,
        maxWidth: 8,
        width: 8,
      },
      {
        Header: t('ID') as string,
        accessor: 'id',
        minWidth: 350,
      },
      {
        Header: t('Date') as string,
        accessor: 'creationDate',
        minWidth: 120,
      },
      {
        Header: t('Status') as string,
        accessor: 'status',
        Cell: statusColumnCell,
        minWidth: 120,
      },
      {
        Header: t('Actions') as string,
        accessor: 'actions',
        maxWidth: 100,
      },
    ],
    []
  );

  useEffect(() => {
    if (!lastMessage) return;
    const message = JSON.parse(lastMessage.data);
    setLoading(false);
    setData(message.data || []);
  }, [lastMessage]);

  const tData: Array<TData> = useMemo(
    () =>
      data
        ?.sort((a, b) => b.creationDate - a.creationDate)
        ?.map((node: VideoCall, index: number) => {
          const handleClick = () => {
            setInnerInfo({
              id: node.id,
              name: node.user,
              contactInfo: '',
            });
            onShowId && onShowId();
          };

          return {
            id: node.id,
            creationDate: formatTimezone(node.creationDate),
            a: node.creationDate,
            status: node.status,
            actions: (
              <TableActions
                locale={i18n.language}
                onSeeDetail={handleClick}
                testId={`callDetail/${node.id}`}
                dataTour={!index ? 'calls_detail' : ''}
              />
            ),
          };
        }) || [],
    [data]
  );

  return (
    <Table
      loading={loading}
      columns={columns}
      data={tData}
      hasMore={false}
      emptyState={!loading && !tData.length ? 'data' : undefined}
      locale={i18n.language}
      testId="video-calls"
    />
  );
};
