import {
  Button,
  Card,
  CardBaseContent,
  CardBaseHeader,
  FlexContainer,
  InputColorPicker,
  InputUpload,
} from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const AspectChangeRow = styled(FlexContainer)`
  margin-top: 2.1rem;
  height: 100%;

  > *:first-of-type {
    flex-grow: 0.8;
  }

  > *:last-of-type {
    flex-grow: 1;
  }
`;

export const AspectResetSettings = styled(Button)`
  margin-left: auto;
`;

export const PreviewAspectStyles = styled(Card)`
  height: 100%;
  ${CardBaseContent} {
    background-color: ${(props) => props.theme.color.greys[100]};
    padding: 3rem 0 0 3rem;
  }
`;

export const PreviewAspectWrapper = styled(FlexContainer)`
  background-color: ${(props) => props.theme.color.greys[50]};
  > ${FlexContainer} {
    margin: 3.6rem 0 0 2.6rem;
    padding: 2.8rem 2.4rem 2.6rem;
    background-color: ${(props) => props.theme.color.greys.white};
    border-radius: 0.8rem;
  }

  img {
    width: 5rem;
    height: 5rem;
  }
`;

export const AspectChangeHeader = styled(CardBaseHeader)`
  position: relative;
`;

export const AspectInputUpload = styled(InputUpload)`
  position: absolute;
  top: calc(-9.6rem / 2);
  left: 2.4rem;
`;

export const AspectColorPicker = styled(InputColorPicker)`
  width: 16rem;
  align-items: flex-end;
`;

// todo: eliminar

export const TestingColor = styled.div<{ background: string }>`
  width: 10rem;
  height: 4rem;
  background-color: ${(props) => props.background};
  display: grid;
  place-items: center;
  border-radius: 0.8rem;
`;
