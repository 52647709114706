import React from 'react';
import { IconCustomProps } from '../model';

export const NFCIcon = ({ className, size, color }: IconCustomProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      className={className}
      width={size ? (size as number) : undefined}
      height={size ? (size as number) : undefined}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.21 6C2.54174 6 2 6.54174 2 7.21V10.4C2 10.9523 2.44772 11.4 3 11.4H8.1087C8.5118 9.76361 9.98917 8.55 11.75 8.55C13.5108 8.55 14.9882 9.76361 15.3913 11.4H20.5C21.0523 11.4 21.5 10.9523 21.5 10.4V7C21.5 6.44772 21.0523 6 20.5 6H3.21Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.02672 12.75H3C2.44772 12.75 2 13.1977 2 13.75V17.15C2 17.7023 2.44772 18.15 3 18.15H20.5C21.0523 18.15 21.5 17.7023 21.5 17.15V13.75C21.5 13.1977 21.0523 12.75 20.5 12.75H15.4733C15.251 14.6089 13.6688 16.05 11.75 16.05C9.83122 16.05 8.24903 14.6089 8.02672 12.75Z"
        fill={color}
      />
      <circle cx="11.75" cy="12.3" r="2.55" fill={color} />
    </svg>
  );
};
