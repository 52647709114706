import React from 'react';
import { CardProps } from './model';
import { Button } from '../../button';
import { ButtonIcon } from '../../buttonIcon';
import { FlexContainer } from '../../flexContainer';
import { Icon } from '../../icon';
import { IconWrapper } from '../../iconWrapper';
import { Label } from '../../label';
import {
  CardBaseContent,
  CardBaseFooter,
  CardBaseHeader,
  CardBaseStyle,
} from '../Styles';

export const Card = ({
  titleHeader,
  noBorder,
  onCloseCard,
  className,
  style,
  children,
  iconHeader,
  buttonFooterLeftLabel,
  buttonFooterRightLabel,
  onButtonFooterLeft,
  onButtonFooterRight,
  noPaddingContent,
  testId,
  testIdButton,
  iconBackgroundColor,
  flexDirectionContent,
  alignItemsContent,
  justifyContent,
  rowGapContent,
  columnGapContent,
  isForm,
}: CardProps) => {
  const buttonRight = () => (
    <Button
      onClick={onButtonFooterRight}
      testId={testIdButton}
      type={isForm ? 'submit' : 'button'}
    >
      {buttonFooterRightLabel}
    </Button>
  );

  return (
    <CardBaseStyle
      className={className}
      style={style}
      flexDirection="column"
      data-test={testId}
    >
      <CardBaseHeader
        noBorder={noBorder}
        alignItems="center"
        justifyContent={titleHeader ? 'space-between' : 'flex-end'}
        data-test="card-header"
        as="section"
      >
        {titleHeader && (
          <FlexContainer alignItems="center" columnGap="1.2">
            {iconHeader && !iconBackgroundColor && (
              <Icon
                iconName={iconHeader}
                size="24"
                color="greys"
                intensity={600}
              />
            )}
            {iconBackgroundColor && iconHeader && (
              <IconWrapper
                size="M"
                iconName={iconHeader}
                backgroundColor={iconBackgroundColor}
              />
            )}
            <Label semibold size="14">
              {titleHeader}
            </Label>
          </FlexContainer>
        )}
        {onCloseCard && (
          <ButtonIcon
            text
            onClick={onCloseCard}
            iconName="X"
            testId="card-close-button"
          />
        )}
      </CardBaseHeader>

      <CardBaseContent
        as="section"
        data-test="card-content"
        noPadding={noPaddingContent}
        flexDirection={flexDirectionContent}
        alignItems={alignItemsContent}
        justifyContent={justifyContent}
        rowGap={rowGapContent}
        columnGap={columnGapContent}
      >
        {children}
      </CardBaseContent>

      {buttonFooterRightLabel && (
        <CardBaseFooter
          as="section"
          columnGap="2"
          noPadding={!buttonFooterLeftLabel}
          data-test="card-footer"
        >
          {buttonFooterLeftLabel ? (
            <>
              <Button variant="text" onClick={onButtonFooterLeft}>
                {buttonFooterLeftLabel}
              </Button>
              {buttonRight()}
            </>
          ) : (
            buttonRight()
          )}
        </CardBaseFooter>
      )}
    </CardBaseStyle>
  );
};
