import styled from 'styled-components';
import { TextAreaRows } from './model';
import { InputStyles } from '../input/Styles';

export const TextAreaStyles = styled(InputStyles)<TextAreaRows>`
  min-height: 4rem;
  height: ${(props) => (props.rows ? `${props.rows * 1.6}rem` : '4rem')};
  padding: 0.6rem 0.8rem;

  ${(props) =>
    props.variant === 'primary' &&
    `
    > textarea {
        color: inherit;
        &::placeholder {
          color: ${props.theme.color.greys[400]};
        }

    }
  `}

  ${(props) =>
    props.variant === 'secondary' &&
    `
    > textarea {
        color: inherit;
        &::placeholder {
          color: ${props.theme.color.greys[500]};
        }
    }
  `}

  > textarea {
    border: 0;
    height: 100%;
    flex: 1;
    outline: none;
    background: transparent;
    font-size: 1.2rem;
    width: 100%;
    /* avoid autofill styles in firefox */
    filter: none;
    /* avoid autofill styles in firefox */
    resize: none;
    font-family: inherit;
    padding: 0;
  }
`;
