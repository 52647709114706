import styled from 'styled-components';
import { ZINDEX_PORTAL } from '../../styles';

export const PortalContainer = styled.div`
  position: fixed;
  z-index: ${ZINDEX_PORTAL};
  min-height: fit-content;
`;

export const PortalOverlayStyles = styled.div<{ blur?: boolean }>`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.blur ? 'rgba(103, 110, 131, 0.2)' : 'transparent'};
  backdrop-filter: ${(props) => (props.blur ? 'blur(4px)' : 'none')};
`;

export const PortalContent = styled.div`
  position: relative;
  z-index: 2;
`;
