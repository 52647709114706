import { FlexContainer, ModalLateral } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const MODAL_TENANT_WIDTH = 35.2;

export const ModalTenantStyles = styled(ModalLateral)`
  background-color: ${(props) => props.theme.color.greys[800]};
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
`;

export const ModalTenantHeader = styled(FlexContainer)`
  height: 9.2rem;
  padding-right: 1.8rem;
  padding-left: 3.4rem;
`;

export const ModalTenantElement = styled(FlexContainer)<{ isActive?: boolean }>`
  min-height: 5.6rem;
  height: 5.6rem;
  padding-right: 0.6rem;
  padding-left: 1.4rem;
  margin-bottom: 0.8rem;
  border-radius: 0.8rem;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    `
      background-color: ${props.theme.color.greys[700]};

  `}
  &:hover {
    background-color: ${(props) => props.theme.color.greys[700]};
  }
  > * {
    flex: 1;
  }
`;

export const ModalTenantContent = styled(FlexContainer)`
  overflow-y: auto;
  padding: 0 2rem;
`;
