import { useState, useEffect } from 'react';
import {
  Button,
  DeleteItem,
  FlexContainer,
  InputDatePicker,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { HeaderRulesStyles } from './Styles';

type Props = {
  onAddNew?(): void;
  itemsDelete?: number;
  deleteItemsSelected?(): void;
  onChange?(value?: Date): void;
};

export const HeaderRules = ({
  onAddNew,
  deleteItemsSelected,
  itemsDelete,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | undefined>();

  const handleDate = (value: Date | undefined) => {
    setDate(value);
  };

  useEffect(() => {
    onChange && onChange(date);
  }, [date]);

  return (
    <HeaderRulesStyles alignItems="center" justifyContent="space-between">
      <FlexContainer columnGap="0.8">
        <InputDatePicker
          maxDate={new Date()}
          value={date}
          onChange={handleDate}
        />
      </FlexContainer>

      <FlexContainer columnGap="2.4">
        <DeleteItem
          testId="delete-item-button"
          onClick={deleteItemsSelected}
          items={itemsDelete || 0}
        />
        <Button testId="add" iconName="Plus" onClick={onAddNew}>
          {t('Add new')}
        </Button>
      </FlexContainer>
    </HeaderRulesStyles>
  );
};
