import React, { useState } from 'react';
import { IconWrapperProps } from './model';
import { IconWrapperStyle } from './Styles';
import { Icon } from '../icon/Icon';

export const IconWrapper = ({
  backgroundColor,
  className,
  iconName,
  testId,
  size = 'L',
  shape = 'square',
  backgroundIntensity = 100,
  hasHoverStyle = false,
}: IconWrapperProps) => {
  const darkColor = backgroundIntensity !== 100;

  const [isHovering, setIsHovering] = useState(false);
  return (
    <IconWrapperStyle
      onMouseEnter={() => hasHoverStyle && setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={className}
      data-test={testId}
      size={size}
      shape={shape}
      backgroundColor={backgroundColor}
      backgroundIntensity={isHovering ? 400 : backgroundIntensity}
    >
      <Icon
        iconName={iconName}
        color={darkColor || isHovering ? 'greys' : backgroundColor}
        intensity={darkColor || isHovering ? 'white' : 400}
      />
    </IconWrapperStyle>
  );
};
