export const TimingImage = () => {
  return (
    <svg
      width="56"
      height="57"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="56" height="56" rx="8" fill="#E4D7FE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0002 18.1666C22.2932 18.1666 17.6668 22.793 17.6668 28.5C17.6668 34.2069 22.2932 38.8333 28.0002 38.8333C33.7071 38.8333 38.3335 34.2069 38.3335 28.5C38.3335 22.793 33.7071 18.1666 28.0002 18.1666ZM16.3335 28.5C16.3335 22.0567 21.5568 16.8333 28.0002 16.8333C34.4435 16.8333 39.6668 22.0567 39.6668 28.5C39.6668 34.9433 34.4435 40.1666 28.0002 40.1666C21.5568 40.1666 16.3335 34.9433 16.3335 28.5Z"
        fill="#243760"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4216 23.0786C33.6819 23.3389 33.6819 23.761 33.4216 24.0214L28.4716 28.9714C28.2112 29.2317 27.7891 29.2317 27.5288 28.9714C27.2684 28.711 27.2684 28.2889 27.5288 28.0286L32.4788 23.0786C32.7391 22.8182 33.1612 22.8182 33.4216 23.0786Z"
        fill="#243760"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3335 13.5C24.3335 13.1318 24.632 12.8333 25.0002 12.8333H31.0002C31.3684 12.8333 31.6668 13.1318 31.6668 13.5C31.6668 13.8682 31.3684 14.1666 31.0002 14.1666H25.0002C24.632 14.1666 24.3335 13.8682 24.3335 13.5Z"
        fill="#243760"
      />
    </svg>
  );
};
