export const years: string[] = [
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009',
  '2008',
  '2007',
];

export const ips = [
  '150.142.0.0',
  '199.196.0.0',
  '143.144.0.0',
  '164.121.0.0',
  '136.160.0.0',
  '184.121.0.0',
  '152.160.0.0',
];

export const updatesIP = [
  '11/07/11 03:48:59',
  '15/04/19 16:05:15',
  '30/09/21 04:57:37',
  '29/12/10 22:02:06',
  '20/10/12 10:41:54',
  '06/05/15 01:34:24',
  '14/08/20 11:34:42',
];

export const updatesFace = [
  '08/05/12 04:02:59',
  '16/04/19 16:05:15',
  '02/09/21 04:57:37',
  '21/12/10 22:02:06',
  '14/10/23 10:22:54',
  '21/05/15 01:34:24',
  '09/08/15 15:34:42',
];

export const updatesDevice = [
  '11/07/08 03:48:59',
  '15/04/19 16:05:15',
  '12/09/21 15:57:37',
  '29/12/10 22:31:06',
  '12/10/08 10:41:54',
  '06/05/15 01:34:24',
  '07/08/20 11:34:42',
];

export const updatesDocument = [
  '11/07/11 03:48:59',
  '21/04/12 16:05:15',
  '30/09/21 04:21:33',
  '29/12/10 22:02:06',
  '15/10/08 10:41:54',
  '14/05/15 01:34:24',
  '14/08/20 11:34:42',
];

export const updatesField = [
  '21/07/11 10:48:33',
  '15/04/19 14:05:02',
  '28/09/21 09:57:22',
  '29/12/10 05:05:06',
  '20/10/12 22:41:54',
  '09/05/15 01:34:12',
  '03/08/20 11:34:42',
];

export const documentYears: string[] = [
  '2012',
  '2021',
  '2011',
  '2007',
  '2016',
  '2019',
  '2018',
];

export const documentTypes: string[] = [
  'Passport',
  'ID',
  'License card',
  'ID',
  'Passport',
  'License card',
  'ID',
];

type country = {
  value: string;
  image: string;
};

export const countries: country[] = [
  {
    value: 'Australia',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg',
  },
  {
    value: 'Bulgaria',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg',
  },
  {
    value: 'Chile',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg',
  },
  {
    value: 'Cameroon',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg',
  },
  {
    value: 'Denmark',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg',
  },
  {
    value: 'Spain',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg',
  },
  {
    value: 'United Kingdom',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg',
  },
];

export const descIp: string[] = [
  'Involved in spamming or other unwanted online behavior',
  'Suspicious activity',
  'Identification card not verified',
  'Unwanted online behavior',
  '',
  '',
  '',
];

export const descFace: string[] = [
  'The face and the selfie not correspond',
  'Face is not real',
  '',
  '',
  '',
  '',
  '',
];

export const descDevice: string[] = [
  'Unwanted online behavior',
  'Device not clear',
  'Model incorrect',
  'Involved in spamming',
  '',
  '',
  '',
];

export const descDocument: string[] = [
  'Document is a specimen',
  'Document is expired',
  'Document is not valid',
  'Involved in spamming',
  '',
  '',
  '',
];

export const descField: string[] = [
  'Involved in spamming',
  'Field data is not real',
  'Field data does not exit',
  '',
  '',
  '',
  '',
];

export const descPopular: string[] = [
  'Most used document worldwide',
  'Mainly belongs to BankX customer',
  '',
  '',
  '',
  '',
  '',
];
