import {
  CardBase,
  CardCollapsable,
  FlexContainer,
  TextArea,
} from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';
import { getLayoutBackground } from '../layout';

export const VideoCardsCollapsable = styled(CardCollapsable)`
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};
`;

export const VideoTimelineContent = styled.div`
  padding: 4em;
  display: flex;
  overflow-y: auto;
  flex: 1;
`;

export const QueueIdStyles = styled(CardBase)<{ innerShow?: boolean }>`
  flex: unset;
  transition: width 0.5s ease-in-out;
  overflow: hidden;
  width: ${(props) => (props.innerShow ? '35rem' : 0)};
`;

export const QueueIdTextArea = styled(TextArea)`
  flex: 1;
  display: flex;
  flex-direction: column;

  > div[data-test='TextArea-container'] {
    height: 100%;
  }
`;

export const VideoCallTabsWrapper = styled(FlexContainer)`
  height: 100%;
  overflow: hidden;
  background-color: ${(props) => getLayoutBackground(props.theme)};
`;
