import { AppsIconsType } from '@backoffice/state/model';
import { IconWrapper, Label, useTheme } from '@facephi/inphinite-ui';
import { CardUpgradeStyles } from './Styles';

type Props = AppsIconsType & {
  className?: string;
  disabled?: boolean;
};

export const CardUpgrade = ({
  label,
  color,
  iconName,
  className,
  disabled,
}: Props) => {
  const theme = useTheme();

  return (
    <CardUpgradeStyles
      className={className}
      style={{
        borderLeft: `8px solid ${
          disabled ? theme.color.greys[200] : theme.color[color][400]
        }`,
      }}
      asRow
      columnGap="1.2"
      alignItems="center"
      disabled={disabled}
    >
      <IconWrapper
        iconName={iconName}
        backgroundColor={disabled ? 'greys' : color}
        size="M"
      />
      <Label size="14" semibold>
        {label}
      </Label>
    </CardUpgradeStyles>
  );
};
