/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useCommon, getOperationDetails } from '@facephi/inphinite-common';
import {
  Button,
  CardBaseContent,
  CardBaseFooter,
  CardBaseStyle,
  FlexContainer,
  Label,
  SwitchController,
  useTimezone,
} from '@facephi/inphinite-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useOperationDetails } from '../../hooks';
import {
  OperationStatus,
  ReviewDto,
  reviewSchema,
  StatusMessages,
} from '../../state/model';
import { createReview } from '../../state/mutations';
import { ModalReject } from './ModalReject';
import { ModalReview } from './ModalReview';
import { ReviewCardListElement, ReviewTextArea } from './Styles';

export const ReviewForm = () => {
  const { t } = useTranslation();
  const [showModalAccepted, setModalAccepted] = useState<boolean>(false);
  const [showModalRejected, setModalRejected] = useState<boolean>(false);
  const [lastResult, setLastResult] = useState<string>();
  const { id } = useParams<{ id: string }>() as { id: string };
  const { formatTimezone } = useTimezone();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(reviewSchema),
    defaultValues: {
      sediaData: false,
      debtData: false,
      evidenceData: false,
      observations: '',
    },
  });

  const {
    tenant: { id: tenantId },
  } = useCommon();
  const [saveReview] = useMutation(createReview);

  const { detailsData } = useOperationDetails(id, tenantId);

  useEffect(() => {
    const details = detailsData?.details;
    if (details) {
      const changeList = details?.statusChangeList || [];

      if (changeList.length > 0) {
        const dataList: string[] = [];
        changeList.forEach((element) => {
          (element?.status === OperationStatus.succeeded ||
            element?.status === OperationStatus.denied) &&
            dataList.push(
              `${t(StatusMessages[element?.status])} ${
                element?.timestamp ? formatTimezone(element?.timestamp) : ''
              }`
            );
        });
        setLastResult(dataList.pop() || '');
      }
    }
  }, [detailsData]);

  useEffect(() => reset(), [showModalRejected]);

  const onSubmit = async (data: ReviewDto) => {
    const response = await saveReview({
      variables: {
        input: {
          checks: [
            {
              name: 'sediaData',
              isChecked: true,
            },
            {
              name: 'debtData',
              isChecked: true,
            },
            {
              name: 'evidenceData',
              isChecked: true,
            },
          ],
          comment: data.observations,
          approved: true,
        },
        operationId: id,
      },
      refetchQueries: [
        {
          query: getOperationDetails,
          variables: {
            operationId: id,
            tenantId,
          },
        },
      ],
    });

    if (response.data) {
      setModalAccepted(true);
      reset();
    }
  };

  return (
    <>
      <CardBaseStyle flexDirection="column">
        <FlexContainer
          as="form"
          flexDirection="column"
          flex="1"
          onSubmit={handleSubmit(onSubmit)}
        >
          <CardBaseContent flexDirection="column" rowGap="1.6">
            <ReviewCardListElement
              alignItems="center"
              justifyContent="space-between"
              as="li"
            >
              <Label size="12">{t('Has SEDIA data been reviewed?')}</Label>
              <SwitchController
                name="sediaData"
                control={control}
                testId="sedia-input"
              />
            </ReviewCardListElement>
            <ReviewCardListElement
              alignItems="center"
              justifyContent="space-between"
              as="li"
            >
              <Label size="12">
                {t('Has International debt been reviewed?')}
              </Label>
              <SwitchController
                name="debtData"
                control={control}
                testId="debt-input"
              />
            </ReviewCardListElement>
            <ReviewCardListElement
              alignItems="center"
              justifyContent="space-between"
              as="li"
            >
              <Label size="12">{t('Have evidences been reviewed?')}</Label>
              <SwitchController
                name="evidenceData"
                control={control}
                testId="evidence-input"
              />
            </ReviewCardListElement>
            <FlexContainer alignItems="center" justifyContent="space-between">
              <ReviewTextArea
                label={t('Observations')}
                {...register('observations')}
                rows={10}
                iconRight="PencilSimpleLine"
                testId="observations-input"
                errorLabel={
                  errors.observations?.message && t(errors.observations.message)
                }
              ></ReviewTextArea>
            </FlexContainer>
            <Label black semibold size="12">
              {lastResult}
            </Label>
          </CardBaseContent>
          <CardBaseFooter as="section" columnGap="1.6">
            <Button
              onClick={() => setModalRejected(true)}
              color="error"
              testId="review-reject"
            >
              {t('Reject')}
            </Button>
            <Button type="submit" testId="review-approve" disabled={!isValid}>
              {t('Approve')}
            </Button>
          </CardBaseFooter>
        </FlexContainer>
      </CardBaseStyle>
      <ModalReview
        onChangeShow={(showModalAccepted) =>
          setModalAccepted(showModalAccepted)
        }
        show={showModalAccepted}
        onCreate={() => {}}
        onClose={() => setModalAccepted(false)}
      />
      <ModalReject
        onChangeShow={(showModalRejected) =>
          setModalRejected(showModalRejected)
        }
        show={showModalRejected}
        operationId={id}
      />
    </>
  );
};
