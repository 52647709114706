import {
  OperationsDataContent,
  OperationsTabsContent,
} from '@facephi/inphinite-operations';
import { FlexContainer, Modal } from '@facephi/inphinite-ui';
import { DropdownUx } from '@facephi/inphinite-ux';
import styled from 'styled-components/macro';

export const DropdownAntiFraud = styled(DropdownUx)`
  width: 7.4rem;
`;

export const HeaderBanned = styled(FlexContainer)`
  margin-bottom: 6rem;
`;

export const BannedTabsContent = styled(OperationsTabsContent)``;

export const BannedDataContent = styled(OperationsDataContent)``;

export const HeaderRulesStyles = styled(FlexContainer)`
  margin: 2rem 1.6rem;
`;

export const RulesTabsWrapper = styled(FlexContainer)`
  height: 100%;
`;

export const ModalIPStyles = styled(Modal)`
  width: 52rem;
`;

export const ModalIPContent = styled(FlexContainer)`
  margin: 3rem 5rem;
`;

export const ModalFaceStyles = styled(ModalIPStyles)``;

export const ModalFaceContent = styled(ModalIPContent)``;

export const ModalDeviceStyles = styled(ModalIPStyles)``;

export const ModalDeviceContent = styled(ModalIPContent)``;

export const ModalDocumentStyles = styled(ModalIPStyles)``;

export const ModalDocumentContent = styled(ModalIPContent)``;

export const ModalFieldStyles = styled(ModalIPStyles)``;

export const ModalFieldContent = styled(ModalIPContent)``;

export const TableFaceImg = styled.img`
  width: 5rem;
  aspect-ratio: 1;
  object-fit: cover;
`;

export const TablePopImg = styled.img`
  width: 5rem;
  object-fit: cover;
`;
