import styled from 'styled-components';
import { Label } from '../../label';
import {
  TabPanelContent,
  TabPanelStyles,
  TabWrapper,
  TabHeader,
} from '../../tabs/Styles';
import { CardBase } from '../CardBase';

export const CardTabsTitle = styled(Label)`
  padding: 1.4rem 1.6rem;
  border-bottom: 1px solid ${(props) => props.theme.color.greys[200]};
`;

export const CardTabsStyles = styled(CardBase)`
  ${TabHeader} {
    border-bottom: 1px solid ${(props) => props.theme.color.greys[200]};
  }

  ${TabWrapper} {
    align-items: flex-end;
    width: 100%;
  }

  ${TabPanelContent} {
    padding: 3rem 3.2rem;
  }

  ${TabPanelStyles} {
    height: 100%;
  }
`;
