import { useMemo, useState } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  DataBarChart,
  DataDonutChart,
  DonutChart,
  PercentageStatictics,
  PeriodStatictics,
  RingChart,
  StackedBarChart,
  getAggregateStatistics,
} from '@facephi/inphinite-common';
import { useTimezone } from '@facephi/inphinite-ui';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { RangeModifier } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import {
  CardDashboard,
  CardKpi,
  colorType,
  DashboardDatePicker,
  DashboardGrid,
  DashboardHeader,
} from '../components';
import { updateAggregateStatistics } from '../state/subscriptions';

export const DashboardPage = () => {
  const { timezone } = useTimezone();

  const getInitialRange = (): RangeModifier => {
    const to = new Date();
    const from = new Date();
    to.setHours(23, 59, 59, 999);
    from.setHours(0, 0, 0, 0);
    from.setFullYear(from.getFullYear() - 1);
    return { from, to };
  };

  const getVariables = () => ({
    timeZone: timezone,
    fromTimestamp: range?.from?.toISOString(),
    toTimestamp: range?.to?.toISOString(),
  });

  const { t } = useTranslation();
  const [range, setRange] = useState<RangeModifier>(getInitialRange());
  const { loading, data: statisticsData } = useQuery(getAggregateStatistics, {
    variables: getVariables(),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useSubscription(updateAggregateStatistics, {
    variables: getVariables(),
    onSubscriptionData: ({ client, subscriptionData }) => {
      client.writeQuery({
        query: getAggregateStatistics,
        variables: subscriptionData.variables,
        data: subscriptionData.data,
      });
    },
  });

  const overallData: DataDonutChart[] = useMemo(
    () =>
      statisticsData?.statistics?.overallPercentage?.percentages?.map(
        ({ status, percentage }: PercentageStatictics) => ({
          name: status,
          value: percentage,
        })
      ),
    [statisticsData?.statistics?.overallPercentage?.percentages]
  );

  const allOperations: DataBarChart = useMemo(
    () =>
      statisticsData?.statistics?.byPeriodPercentage.reduce(
        (acc: DataBarChart, period: PeriodStatictics) => {
          const { percentages, fromTimestamp } = period.percentages;
          percentages.forEach(({ status, value }: PercentageStatictics) => {
            if (!acc.data[status]) {
              acc.data[status] = [];
            }
            acc.data[status].push(value);
          });
          acc.periods.push(dayjs(fromTimestamp).format('MMM YY'));
          return acc;
        },
        {
          periods: [] as DataBarChart['periods'],
          data: {} as DataBarChart['data'],
        }
      ),
    [statisticsData?.statistics?.byPeriodPercentage]
  );

  const handleRange = (values: RangeModifier) => {
    if (!isEqual(values, range)) {
      setRange(values);
    }
  };

  return (
    <>
      <DashboardHeader justifyContent="flex-end">
        <DashboardDatePicker
          isRange
          maxDate={new Date()}
          value={range}
          onChange={handleRange}
        />
      </DashboardHeader>
      <DashboardGrid
        gap="2.4"
        gridTemplateColumns="repeat(4, 1fr)"
        gridTemplateRows="auto 1fr 1fr 1fr"
      >
        <CardKpi
          loading={loading}
          count={statisticsData?.statistics?.newOnboardings}
          title={t('New onboardings')}
          iconName="ArrowFatLinesRight"
          color={colorType.violet}
          testId="newOnboardings"
        />

        <CardKpi
          loading={loading}
          count={statisticsData?.statistics?.totalSucceededAuthentications}
          title={t('Daily access')}
          iconName="CalendarCheck"
          color={colorType.purple}
          testId="dailyAccess"
        />

        <CardKpi
          loading={loading}
          count={statisticsData?.statistics?.totalSucceededOnboardings}
          title={t('All users')}
          iconName="UserSquare"
          color={colorType.blue}
          testId="allUsers"
        />
        <CardDashboard title={t('Conversion rates')} loading={loading}>
          <RingChart
            value={statisticsData?.statistics?.conversionRate}
            loading={loading}
            subtitle={t('Started / Succeeded')}
          />
        </CardDashboard>
        <CardDashboard
          title={t('All Operations')}
          loading={loading}
          noPadding={true}
        >
          <StackedBarChart {...allOperations} loading={loading} />
        </CardDashboard>

        <CardDashboard title={`${t('Operation Rates')} (%)`} loading={loading}>
          <DonutChart data={overallData} loading={loading} />
        </CardDashboard>
      </DashboardGrid>
    </>
  );
};
