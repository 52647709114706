/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import {
  useTimeLine,
  Asset,
  TimelineEvent,
  TimeLineEventType,
  TimeLineType,
  EntityStatus,
  TrackingFamily,
} from '@facephi/inphinite-common';
import {
  Spinner,
  Timeline,
  TimelineResources,
  TimelineStep,
  useTimezone,
  Label,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { AssetFile } from './AssetFile';
import { ModalOperationsDetail } from './ModalOperationsDetail';
import { FamilyTimeline } from './Styles';

type IProps = {
  onboardingId: string;
  disabled?: boolean;
  family?: string;
};

export const OperationTimeline = ({
  onboardingId,
  disabled,
  family,
}: IProps) => {
  const { events, eventMessage, loadingEvents } = useTimeLine(
    onboardingId,
    family
  );
  const [item, setItem] = useState<Asset | null>();
  const { formatTimezone } = useTimezone();
  const { t } = useTranslation();

  if (loadingEvents) {
    return <Spinner insideComponent />;
  }

  return (
    <>
      <Timeline>
        <FamilyTimeline
          color={family === TrackingFamily.ONBOARDING ? 'purple' : 'blue'}
        >
          <Label
            color={family === TrackingFamily.ONBOARDING ? 'purple' : 'blue'}
            size="12"
            semibold={true}
          >
            {t(family as string)}
          </Label>
        </FamilyTimeline>
        {events.map((item: TimelineEvent, index: number) => {
          const itemDate = item?.date || item?.clientTimestamp;
          return (
            <TimelineStep
              testId="timeline-step"
              message={eventMessage(
                item.metadata.event as TimeLineEventType,
                item.metadata.screen,
                item.metadata.value,
                item.metadata.type,
                item.metadata.reason,
                item.metadata.diagnostic as any,
                item.metadata.authStatus as any,
                item.metadata.status as string
              )}
              isSuccess={
                item.metadata.type === TimeLineType.operationResult &&
                !item.metadata.reason &&
                item.metadata.status === EntityStatus.succeeded
              }
              isError={
                (item.metadata.type === TimeLineType.operationResult &&
                  item.metadata.reason !== null) ||
                (item.metadata.type === TimeLineType.operationResult &&
                  item.metadata.status !== EntityStatus.succeeded)
              }
              timeUpdate={formatTimezone(itemDate as string)}
              separation={'20px'}
              key={index}
            >
              {item.metadata.type === TimeLineType.asset && (
                <TimelineResources
                  imageComponent={<AssetFile asset={item.metadata as Asset} />}
                  name={item.metadata.type}
                  onClick={() => setItem(item.metadata as Asset)}
                  disabled={disabled}
                />
              )}
            </TimelineStep>
          );
        })}
      </Timeline>
      {item && (
        <ModalOperationsDetail
          source={item.url}
          show={!!item}
          mimeType={item.contentType}
          title={item.type}
          onChangeShow={(value) => !value && setItem(null)}
        />
      )}
    </>
  );
};
