import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { TableOptionsStyles } from './Styles';
import { ButtonIcon } from '../buttonIcon';
import { FlexContainer } from '../flexContainer';
import { Input } from '../input';
import { InputDatePicker } from '../inputDatePicker';

export type OptionsType = {
  limit?: number;
  fromTimestamp?: string;
  text?: string;
};

type TableOptionsProps = {
  values?: OptionsType;
  onChange?(value: OptionsType): void;
  children?: ReactNode;
  filterComponent?: ReactNode;
};

export const TableOptions = ({
  values,
  onChange,
  children,
  filterComponent,
}: TableOptionsProps) => {
  const [date, setDate] = useState<Date | undefined>(
    values?.fromTimestamp ? new Date(values?.fromTimestamp) : undefined
  );
  const [text, setText] = useState<string | undefined>(values?.text);

  const handleDate = (value: Date | undefined) => {
    setDate(value);
  };
  const handleText = (e: ChangeEvent<HTMLInputElement>) =>
    setText(e.target.value);

  useEffect(() => {
    onChange &&
      onChange({
        limit: 100,
        text: text || undefined,
        fromTimestamp: date?.toISOString(),
      });
  }, [date, text]);

  return (
    <TableOptionsStyles alignItems="center" columnGap="2.4">
      <div>
        <FlexContainer data-tour="filter-1">
          <InputDatePicker
            maxDate={new Date()}
            value={date}
            onChange={handleDate}
          />
        </FlexContainer>
        <Input
          type="text"
          iconLeft="MagnifyingGlass"
          onChange={handleText}
          value={text}
        />
        <FlexContainer data-tour="filter-2">{filterComponent}</FlexContainer>
      </div>
      <div>
        {children}
        <ButtonIcon iconName="DownloadSimple" />
      </div>
    </TableOptionsStyles>
  );
};
