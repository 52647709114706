import styled from 'styled-components';
import { Input } from '../input/Input';
import { Portal } from '../portal';

export const InputColorPickerBox = styled.span`
  width: 3.2rem;
  height: 3.2rem;
  border: solid 1px ${(props) => props.theme.color.greys[100]};
  border-radius: 100%;
`;

export const InputColorPickerInput = styled(Input)`
  width: 10rem;
`;

export const InputColorPickerPortal = styled(Portal)`
  margin: 1rem 0;

  .sketch-picker {
    .hue-horizontal {
      border-radius: 0.8rem;
    }
    > div:nth-of-type(2) {
      > div:last-of-type {
        display: none !important;
      }
    }

    > div:nth-of-type(3) {
      display: none !important;
    }

    > div:last-of-type {
      border: none !important;
      margin: 0 !important;
      padding: 0 !important;
      column-gap: 1rem;
      row-gap: 1rem;

      > div {
        margin: 0 !important;
        padding: 0 !important;
        width: 2rem !important;
        height: 2rem !important;
        span {
          div {
            width: 2rem !important;
            height: 2rem !important;
            border-radius: 100% !important;
            border: 1px solid ${(props) => props.theme.color.greys[100]};
            box-shadow: none !important;
          }
        }
      }
    }
  }
`;
