import { useEffect } from 'react';
import { Input, Label } from '@facephi/inphinite-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalIPStyles } from './Styles';
import { DESCRIPTION, IP } from '../state/constants';
import { DataIpModal, modalIPSchema } from '../state/model';

type Props = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  className?: string;
  onSave(ip: DataIpModal): void;
  data?: DataIpModal;
};

export const ModalIP = ({
  show,
  onChangeShow,
  className,
  onSave,
  data,
}: Props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(modalIPSchema),
    mode: 'onChange',
  });

  const onSubmit = (newIp: DataIpModal) => {
    if (newIp) {
      onSave(newIp);
    }
  };

  const setInnerData = () => {
    if (show && data) {
      setValue(IP, data.ip);
      setValue(DESCRIPTION, data.description);
      setValue('id', data.id);
    }
  };

  useEffect(() => {
    reset({});
    setInnerData();
  }, [show]);

  return (
    <ModalIPStyles
      className={className}
      show={show}
      testId="modal-ip"
      onChangeShow={onChangeShow}
      iconHeader={data ? 'Pencil' : 'PlusCircle'}
      title={
        data ? t('Update a blocked IP address') : t('Add a blocked IP address')
      }
      closeLabel={t('Cancel')}
      submitLabel={t('Save')}
      testIdSave="save-button"
      onCreate={handleSubmit(onSubmit)}
      flexDirectionContent="column"
      rowGapContent="1.6"
    >
      {!data && (
        <Label size="14">
          {t(
            'Insert an IP address manually to avoid new attempts of fraud and an optional description to the rule'
          )}
        </Label>
      )}
      <Input
        testId="ip-input-ip"
        id="ip"
        label={t('IP')}
        {...register(IP, { required: true })}
        errorLabel={errors.ip && t(errors.ip.message)}
      />
      <Input
        testId="ip-input-desc"
        label={t('Description (optional)')}
        {...register(DESCRIPTION)}
        errorLabel={errors.description && t(errors.description.message)}
      />
    </ModalIPStyles>
  );
};
