import {
  CardBase,
  CardBaseHeader,
  FlexContainer,
  IconWrapper,
  Label,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { CardTipsBullet, CardTipsContent, CardTipsSubtitle } from './Styles';

export const CardUXTips = () => {
  const { t } = useTranslation();

  return (
    <CardBase>
      <CardBaseHeader columnGap="2.1" alignItems="center">
        <IconWrapper iconName="Lightbulb" backgroundColor="yellow" size="M" />
        <Label size="14" semibold>
          {t('UX tips')}
        </Label>
      </CardBaseHeader>
      <CardTipsContent flexDirection="column">
        <Label size="14" semibold>
          {t('Better usability')}
        </Label>
        <CardTipsSubtitle size="12">
          {t(
            'If something goes wrong with your operation, click on the phone icon to be connected with an assistant'
          )}
        </CardTipsSubtitle>
        <FlexContainer columnGap="1" justifyContent="center">
          <CardTipsBullet />
          <CardTipsBullet />
          <CardTipsBullet />
        </FlexContainer>
      </CardTipsContent>
    </CardBase>
  );
};
