import React, {
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { animated, Transition } from 'react-spring';
import { OptionMenuProps } from './model';
import { OptionsMenuWrapper } from './Styles';
import { Portal } from '../portal';

export const OptionMenu = ({
  children,
  className,
  positionX,
  positionY,
  renderItem,
  widthAuto = true,
  open = false,
  testId,
  updateOpen,
  isActive = false,
  noPadding,
  hidePanelOnClick,
}: OptionMenuProps) => {
  const buttonMenuRef = useRef(null);

  const [openPortal, setOpenPortal] = useState<boolean>(open);
  const [active, setActive] = useState<boolean>(isActive);

  useEffect(() => {
    updateOpen && updateOpen(openPortal);
  }, [openPortal]);

  useEffect(() => {
    setOpenPortal(open);
  }, [open]);

  const showPortal = (event: MouseEvent<HTMLDivElement>) => {
    setOpenPortal(true);
    setActive(true);
    event.stopPropagation();
  };
  const hiddenPortal = () => {
    setOpenPortal(false);
    setActive(false);
  };

  const handleClickItem = () => {
    hidePanelOnClick && hiddenPortal();
  };

  return (
    <>
      {cloneElement(renderItem as ReactElement, {
        onClick: (ev: MouseEvent<HTMLDivElement>) => {
          if (isValidElement(renderItem) && renderItem.props.onClick) {
            renderItem.props.onClick();
          }
          showPortal(ev);
        },
        testId,
        ref: buttonMenuRef,
        isActive: active,
      })}
      <Transition
        items={openPortal}
        from={{ opacity: 0, transform: 'translate3d(0%, 20px, 0px)' }}
        enter={{ opacity: 1, transform: 'translate3d(0%, 0%, 0px)' }}
        leave={{ opacity: 0, transform: 'translate3d(0%, 20px, 0px)' }}
      >
        {(styles, openPortal) =>
          openPortal && (
            <Portal
              positionX={positionX}
              positionY={positionY}
              actionRef={buttonMenuRef}
              onClickOutside={hiddenPortal}
              widthAuto={widthAuto}
              overlay
              show
            >
              <animated.div style={{ ...styles }}>
                <OptionsMenuWrapper
                  noPadding={noPadding}
                  id="option-menu"
                  className={className}
                  onClick={handleClickItem}
                >
                  {children}
                </OptionsMenuWrapper>
              </animated.div>
            </Portal>
          )
        }
      </Transition>
    </>
  );
};
