import React, { useMemo } from 'react';
import { EmptyMessages } from './constants';
import { EmptyMessagesType, EmptyStateTypes } from './model';
import { EmptyDataStyles, ReloadButton } from './Styles';
import { DEFAULT_LOCALE, translate } from '../../utils/translations';
import { Label } from '../label';

type Props = {
  className?: string;
  testId?: string;
  type: EmptyStateTypes;
  onClick?: () => void;
  style?: React.CSSProperties;
  locale?: string;
};

export const EmptyState = ({
  className,
  testId,
  type,
  onClick,
  style,
  locale = DEFAULT_LOCALE,
}: Props) => {
  const info: EmptyMessagesType = useMemo(() => EmptyMessages[type], [type]);

  return (
    <EmptyDataStyles
      className={className}
      data-test={testId}
      style={style}
      flexDirection="column"
      alignItems="center"
      rowGap="2.4"
    >
      {info.image}

      <Label size="16" semibold>
        {translate(info.title, locale)}
      </Label>
      {info.message && (
        <Label data-test="message" size="14">
          {translate(info.message, locale)}
        </Label>
      )}
      {info.buttonText && onClick && (
        <ReloadButton onClick={onClick} size="L" testId="button-reload">
          {translate(info.buttonText, locale)}
        </ReloadButton>
      )}
    </EmptyDataStyles>
  );
};
