import React, { forwardRef, Ref } from 'react';
import { TagStatusProps } from './model';
import { TagStatusStyle } from './Styles';

export const TagStatus = forwardRef(
  (
    { type, testId, className, height = 'M' }: TagStatusProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <TagStatusStyle
        size={height === 'L' ? '12' : '10'}
        semibold
        type={type}
        className={className}
        data-test={testId}
        ref={ref}
        height={height}
      >
        {type}
      </TagStatusStyle>
    );
  }
);
