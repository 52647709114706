import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { getOperationDetails } from '@facephi/inphinite-common';
import { OperationDetailsDto } from '../state/model';

export function useOperationDetails(operationId: string, tenantId: string) {
  const { data, refetch } = useQuery<{ operationDetails: OperationDetailsDto }>(
    getOperationDetails,
    {
      variables: {
        operationId,
        tenantId: tenantId,
      },
      nextFetchPolicy: 'network-only',
    }
  );

  const detailsData = useMemo(() => data?.operationDetails, [data]);

  return {
    detailsData,
    refetch,
  };
}
