import React, { forwardRef, Ref } from 'react';
import { StatusProps } from './model';
import { selectDotColor, StatusBullet, StatusStyles } from './Styles';
import { useTheme } from '../../providers';
import { DEFAULT_LOCALE, translate } from '../../utils/translations';
import { Label } from '../label';

export const Status = forwardRef(
  (
    {
      type,
      withLabel,
      testId,
      className,
      withShadow,
      locale = DEFAULT_LOCALE,
    }: StatusProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();
    return (
      <StatusStyles
        className={className}
        data-test={testId}
        ref={ref}
        alignItems="center"
        columnGap="0.6"
      >
        <StatusBullet
          type={type}
          withShadow={withShadow}
          style={{
            background: selectDotColor(theme, type),
            boxShadow: `${
              withShadow
                ? `0 0 0.5rem 0 ${selectDotColor(theme, type)}`
                : 'none'
            }`,
          }}
        />
        {withLabel && (
          <Label size="10" semibold>
            {translate(type, locale)}
          </Label>
        )}
      </StatusStyles>
    );
  }
);
