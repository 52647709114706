import React, { DragEvent } from 'react';
import { CardBaseHeader, FlexContainer, Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  PermissionAddedStyles,
  PermissionApplicationContent,
  PermissionsEmptyBoxStyle,
} from './Styles';
import { RawPermissionType } from '../model';
import { PermissionsBox } from '../PermissionsBox';

type Props = {
  permissions: RawPermissionType[];
  addPermission(node: string): void;
  deletePermission(node: string): void;
};

export const PermissionAdded = ({
  permissions,
  addPermission,
  deletePermission,
}: Props) => {
  const { t } = useTranslation();

  const allowDrop = (event: DragEvent<HTMLElement>) => event.preventDefault();

  const drop = (event: DragEvent<HTMLElement>) => {
    event.preventDefault();
    const node = event.dataTransfer.getData('application/permissions');
    addPermission(node);
  };

  return (
    <>
      <PermissionAddedStyles>
        <CardBaseHeader alignItems="center" noBorder>
          <Label size="14" semibold>
            {t('Permissions')}
          </Label>
        </CardBaseHeader>
        <PermissionApplicationContent
          flexDirection="column"
          onDragOver={allowDrop}
          onDrop={drop}
        >
          <FlexContainer flexDirection="column" rowGap="2.4">
            <PermissionsEmptyBoxStyle>
              {t('Add permission')}
            </PermissionsEmptyBoxStyle>
            {permissions.map((item, index) => (
              <PermissionsBox
                key={index}
                title={t(item.name || '')}
                isClosable
                onClick={() => deletePermission(item.id)}
              />
            ))}
          </FlexContainer>
        </PermissionApplicationContent>
      </PermissionAddedStyles>
    </>
  );
};
