import {
  Button,
  ButtonIcon,
  FlexContainer,
  Label,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { ModalApps } from './model/guide';
import {
  LottieStyle,
  ModalGuideButtonClose,
  ModalGuidePanelRightActions,
  ModalGuidePanelRightContent,
  ModalGuidePanelRightStyles,
} from './Styles';

type Props = {
  source: Record<string, unknown>;
  title: string;
  message: string;
  onNext?(): void;
  onPrev?(): void;
  disableNextButton?: boolean;
  menu: ModalApps | undefined;
  onCancel?(): void;
};

export const ModalGuidePanelRight = ({
  source,
  title,
  message,
  onNext,
  onPrev,
  disableNextButton,
  menu,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalGuidePanelRightStyles flexDirection="column">
      {menu && (
        <ModalGuideButtonClose alignItems="center" justifyContent="center">
          <ButtonIcon
            iconName="XCircle"
            onClick={onCancel}
            text
            size="M"
            color="greys"
            intensity="white"
          />
        </ModalGuideButtonClose>
      )}
      <LottieStyle animation={source} />
      <ModalGuidePanelRightContent flexDirection="column" rowGap="1.6">
        <Label size="18" semibold>
          {t(`${title}`)}
        </Label>
        <Label size="14">{t(`${message}`)}</Label>
        {menu ? (
          <ModalGuidePanelRightActions
            columnGap="4"
            flex="1"
            justifyContent="flex-end"
          >
            <Button
              size="L"
              onClick={onPrev}
              variant="text"
              testId="cancel-guide"
            >
              {t('Prev')}
            </Button>
            <Button
              size="L"
              color="primary"
              onClick={!disableNextButton ? onNext : onCancel}
            >
              {!disableNextButton ? t('Next') : t('Done')}
            </Button>
          </ModalGuidePanelRightActions>
        ) : (
          <ModalGuidePanelRightActions
            columnGap="4"
            flex="1"
            justifyContent="space-between"
            alignItems="center"
          >
            <Label size="21" semibold>
              {t('Get started?')}
            </Label>
            <FlexContainer columnGap="4">
              <Button
                size="L"
                onClick={onCancel}
                variant="text"
                testId="cancel-guide"
              >
                {t('No, Thanks')}
              </Button>
              <Button size="L" color="primary" onClick={onNext} iconName="Flag">
                {t('Start guide')}
              </Button>
            </FlexContainer>
          </ModalGuidePanelRightActions>
        )}
      </ModalGuidePanelRightContent>
    </ModalGuidePanelRightStyles>
  );
};
