import { FlexContainer, Input } from '@facephi/inphinite-ui';
import { OptionMenu, OptionMenuItem } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const HeaderStyles = styled(FlexContainer)``;

export const AvatarImage = styled.img`
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 5.2rem;
  position: relative;
`;

export const ButtonUser = styled(FlexContainer)`
  > p {
    padding-left: 1rem;
  }
  > svg {
    margin-left: 0.8rem;
  }
`;

export const OptionMenuHeader = styled(OptionMenu)`
  max-width: 20rem;
  margin-top: 1rem;
`;

export const SearchInput = styled(Input)`
  margin-right: 1.6rem;
`;

export const OptionMenuItemStyles = styled(OptionMenuItem)<{ dark?: boolean }>`
  justify-content: flex-start;
  background: ${(props) =>
    props.dark ? props.theme.color.secondary[100] : ''};
  &:hover {
    background: ${(props) => (props.dark ? '' : props.theme.color.greys[100])};
  }
  &:last-of-type {
    height: 4.2rem;
  }
`;
