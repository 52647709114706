import React, {
  forwardRef,
  FunctionComponent,
  Ref,
  useEffect,
  useState,
} from 'react';
import { CheckBoxProps } from './model';
import {
  CheckboxContainer,
  CheckBoxRow,
  CheckboxStyles,
  CheckedBullet,
  IndeterminateBullet,
} from './Styles';
import { Label } from '../label';
import { HiddenCheckbox } from '../switch/Styles';

export const Checkbox: FunctionComponent<CheckBoxProps> = forwardRef(
  (
    {
      labelLeft,
      labelRight,
      checked = false,
      disabled,
      id,
      className,
      testId,
      name,
      value,
      onChange,
      spaceBetween,
      indeterminate,
      smallLabel,
      centered,
      small,
    },
    ref: Ref<HTMLInputElement>
  ) => {
    const [check, setCheck] = useState<boolean>(checked);

    useEffect(() => {
      setCheck(checked);
    }, [checked]);

    const handleCheck = () => {
      setCheck(!check);
      onChange && onChange(!check);
    };

    return (
      <CheckboxContainer
        disabled={disabled}
        className={className}
        checked={check}
        onClick={handleCheck}
        spaceBetween={spaceBetween}
        type="button"
      >
        <CheckBoxRow
          alignItems={centered ? 'center' : 'flex-start'}
          columnGap="0.8"
        >
          {labelLeft && (
            <Label
              as="label"
              size={smallLabel ? '10' : '12'}
              id={id}
              textAlign="end"
            >
              {labelLeft}
            </Label>
          )}
          <HiddenCheckbox
            type="checkbox"
            disabled={disabled}
            checked={check}
            data-test={testId}
            id={id}
            name={name}
            ref={ref}
            value={value}
            onChange={() => {}}
          />
          <CheckboxStyles
            checked={check}
            alignItems="center"
            justifyContent="center"
            small={small}
          >
            {check && <CheckedBullet />}
            {indeterminate && <IndeterminateBullet />}
          </CheckboxStyles>
          {labelRight && (
            <Label
              as="label"
              size={smallLabel ? '10' : '12'}
              id={id}
              textAlign="start"
            >
              {labelRight}
            </Label>
          )}
        </CheckBoxRow>
      </CheckboxContainer>
    );
  }
);
