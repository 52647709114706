import {
  ButtonIcon,
  CardUX,
  FlexContainer,
  Label,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  CardAnalytic,
  CardUXTips,
  HeaderUX,
  TableKPIs,
  UXGrid,
} from '../components';
import { RoutesName } from '../state/constants';

export const UXPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderUX />
      <UXGrid>
        <CardUX
          info="00.19 sec"
          title={t('Time on task')}
          subtitle={t('+ {{percentage}}% last month', { percentage: 20 })}
          backgroundColor="purple"
          iconName="Timer"
          href={RoutesName.uxTask}
        />
        <CardUX
          info="230 users"
          title={t('Task Success Rate')}
          subtitle={t('+ {{percentage}}% last month', { percentage: 20 })}
          backgroundColor="green"
          iconName="ThumbsUp"
          href={RoutesName.uxTask}
        />
        <CardUX
          info="20%"
          title={t('User error rate')}
          subtitle={t('+ {{percentage}}% last month', { percentage: 20 })}
          backgroundColor="coral"
          iconName="SmileyXEyes"
          href={RoutesName.uxTask}
        />
        <CardUXTips />
        <CardUX
          info="Good"
          title={t('Usability')}
          subtitle={t('+ {{percentage}}% last month', { percentage: 20 })}
          backgroundColor="yellow"
          iconName="ClipboardText"
          href={RoutesName.uxTask}
        />
        <CardUX
          info="Quite bad"
          title={t('Accessibility')}
          subtitle={t('+ {{percentage}}% last month', { percentage: 20 })}
          backgroundColor="skyBlue"
          iconName="ClipboardText"
          href={RoutesName.uxTask}
        />
        <CardUX
          info="100 t/h "
          title={t('Other KPI')}
          subtitle={t('+ {{percentage}}% last month', { percentage: 20 })}
          backgroundColor="violet"
          iconName="ClipboardText"
          href={RoutesName.uxTask}
        />
        <CardAnalytic />
        <FlexContainer flexDirection="column" columnGap="1.6">
          <FlexContainer justifyContent="space-between">
            <Label size="18" semibold>
              {t('Recent activities')}
            </Label>
            <ButtonIcon iconName="Faders" text />
          </FlexContainer>
          <TableKPIs />
        </FlexContainer>
      </UXGrid>
    </>
  );
};
