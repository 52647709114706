import {
  FlexContainer,
  IconWrapper,
  Label,
  ThemeColorType,
  ThemeGradientsScheme,
} from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';
import { colorType } from './model';

type getColorType = {
  [key in colorType]: {
    gradient: ThemeGradientsScheme;
    color: ThemeColorType;
  };
};

const getColor: getColorType = {
  [colorType.blue]: {
    gradient: 'lightBlue',
    color: 'blue',
  },
  [colorType.purple]: {
    gradient: 'blueViolet',
    color: 'purple',
  },
  [colorType.violet]: {
    gradient: 'peach',
    color: 'violet',
  },
};

export const CardKpiWrapper = styled(FlexContainer)`
  height: 100%;
`;

export const CardKpiIconContent = styled.div`
  width: 7rem;
  display: flex;
  margin-right: 3.4rem;
`;

export const CardKpiContent = styled(FlexContainer).attrs({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1.8',
})``;

export const CardCount = styled(Label)<{ $color: colorType }>`
  color: ${(props) => props.theme.color[getColor[props.$color].color][400]};
`;

export const CardIcon = styled(IconWrapper)<{ $color: colorType }>`
  background: none;
  background-image: ${(props) =>
    props.theme.gradients[getColor[props.$color].gradient]};
  opacity: 0.7;
`;
