import React, { cloneElement, useEffect, useMemo, useState } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  Anonymized,
  Asset,
  FINISH_STEP,
  Operation,
  TrackingFamily,
  useAnonymize,
  useArrayNavigation,
  useCommon,
  useFlags,
  searchOperations,
  Roles,
} from '@facephi/inphinite-common';
import {
  ArrowsFlow,
  Status,
  StatusType,
  Table,
  TableActions,
  TagStatus,
  TagStatusType,
  TData,
  useTimezone,
  Tooltip,
  Label,
  useToast,
  ToastType,
} from '@facephi/inphinite-ui';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import {
  CellNewRow,
  FiltersType,
  ModalOperationsDetail,
  TableAssets,
  TableOptionsOperations,
} from '../components';
import { CustomerIdTooltipContainer } from '../components/Styles';
import { operationsSearch } from '../state/cache';
import { OrderBy } from '../state/constants';
import { OperationsFilter } from '../state/model/operations';
import { updateOperations } from '../state/subscriptions';

type Edge = { node: Operation };
type State = { from?: string; id?: string };
type IProps = {
  filterBy?: OperationsFilter;
  detailRoute: string;
  customActions?: React.ReactNode;
  role?: Roles | null;
};

const mergeEdges = (
  existingEdges: Edge[],
  subscriptionEdges: Edge[]
): Edge[] => {
  const edgesMap = new Map<string, Edge>();
  const newReceivedEdges: Edge[] = [];
  const newEdges: Edge[] = [];
  const lastUpdateTime = new Date(
    existingEdges[existingEdges.length - 1].node.lastUpdateTime
  );
  // create a Map with the existing edges
  existingEdges.forEach((edge: Edge) => {
    edgesMap.set(edge.node.operationId, edge);
  });

  // delete, update, or set the new edges for insertion
  subscriptionEdges.forEach((edge: Edge) => {
    const deleted = edge.node.deleted;
    const operationId = edge.node.operationId;
    const existing = edgesMap.get(operationId);
    if (existing) {
      if (deleted) edgesMap.delete(operationId);
      else edgesMap.set(operationId, edge);
    } else if (new Date(edge.node.lastUpdateTime) > lastUpdateTime) {
      newReceivedEdges.push(edge);
    }
  });

  // sort the new edges to insert
  newReceivedEdges.sort((a: Edge, b: Edge) => {
    return (
      new Date(b.node.lastUpdateTime).getTime() -
      new Date(a.node.lastUpdateTime).getTime()
    );
  });

  // add  merge existingEdges and newReceivedEdges both sorted descending into newEdges
  existingEdges.forEach((edge: Edge) => {
    const lastUpdateTime: Date = new Date(edge.node.lastUpdateTime);
    const operationId: string = edge.node.operationId;
    const existing = edgesMap.get(operationId);
    if (existing) {
      while (
        newReceivedEdges.length &&
        new Date(newReceivedEdges[0].node.lastUpdateTime) >= lastUpdateTime
      ) {
        const firstNewReceivedEdges = newReceivedEdges.shift();
        if (firstNewReceivedEdges) {
          newEdges.push(firstNewReceivedEdges);
          edgesMap.set(operationId, firstNewReceivedEdges);
        }
      }
      newEdges.push(existing);
    }
  });
  return newEdges;
};

export const OperationsPage = ({
  filterBy,
  detailRoute,
  customActions,
}: IProps) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { anonymized } = useAnonymize();
  const { demo } = useFlags();
  const { formatTimezone } = useTimezone();
  const { setOperation, tenant, token } = useCommon();
  const { toastManager } = useToast();

  const comesFromDetail = () => {
    const state = location.state as State;

    return state?.from === detailRoute;
  };

  const truncateCustomerId = (
    customerId: string,
    index: number
  ): React.ReactNode => (
    <CustomerIdTooltipContainer>
      <Tooltip
        label={customerId}
        id="customerId-tooltip"
        variant="secondary"
        place={index === 0 ? 'bottom' : 'top'}
        effect="float"
        displayBlock
        noWrap
      >
        <Label size="12" ellipsis>
          {customerId}
        </Label>
      </Tooltip>
    </CustomerIdTooltipContainer>
  );

  const getSearch = () =>
    comesFromDetail()
      ? operationsSearch()
      : { orderBy: OrderBy.lastUpdateTime };

  const [variables, setVariables] = useState<FiltersType>(getSearch());
  const [scrollPosition, setScrollPosition] = useState();
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [newRows, setNewRows] = useState<string[]>([]);
  const [position, setPosition, { prev, next, itemSelected }] =
    useArrayNavigation<Asset>(selectedAssets);
  const reportsUrl = `${
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)._env_.REACT_APP_API_URL
  }reports/operations`;

  useEffect(() => {
    if (comesFromDetail()) {
      window.history.replaceState(null, '');
      const operationId = (location.state as State).id;
      const position = data?.operations?.edges?.findIndex(
        ({ node }: { node: Operation }) => node.operationId === operationId
      );
      if (position >= 0) {
        setScrollPosition(position);
      }
    }
  }, []);

  useEffect(() => {
    variables && operationsSearch(variables);
  }, [variables]);

  const { loading, data, fetchMore, error, refetch } = useQuery(
    searchOperations,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...variables,
        ...filterBy,
      },
      skip: !Object.keys(variables).length,
      fetchPolicy: comesFromDetail() ? 'cache-first' : 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  );

  useEffect(() => {
    forceUpdateQuery();
  }, [tenant]);

  useSubscription(updateOperations, {
    variables,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (subscriptionData.data.operations.edges.length) {
        const queryData = client.readQuery({
          query: searchOperations,
          variables: subscriptionData.variables,
        });
        const newData = cloneDeep(queryData);
        newData.operations.edges = mergeEdges(
          newData.operations.edges,
          subscriptionData.data.operations.edges
        );

        setNewRows(
          subscriptionData.data.operations.edges.map(
            ({ node }: { node: Operation }) => node.operationId
          )
        );

        client.writeQuery({
          query: searchOperations,
          variables: subscriptionData.variables,
          data: newData,
        });
      }
    },
  });

  const currentStepColumnCell = (props: Record<string, unknown>) => {
    const step = TagStatusType[props.value as keyof typeof TagStatusType];
    return <TagStatus type={step || props.value} />;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stepsColumnCell = (props: Record<string, any>) => {
    const isAuthentication =
      props.cell.row.original.family === TrackingFamily.AUTHENTICATION;
    const unknownSteps =
      !props.cell.row.original.stepsFlow &&
      !FINISH_STEP.includes(props.cell.row.original.currentStep);
    return (
      <ArrowsFlow
        color={isAuthentication ? 'purple' : 'blue'}
        flow={
          props.cell.row.original.stepsFlow ||
          Array.from(new Set(props.cell.row.original.steps))
        }
        currentStep={props.cell.row.original.currentStep}
        unknownSteps={unknownSteps}
      />
    );
  };

  const statusColumnCell = (props: Record<string, unknown>) => {
    const statusType: StatusType = props.value as StatusType;

    return <Status type={statusType} withLabel locale={i18n.language} />;
  };

  const getInfoPosition = () =>
    position !== null ? `${position + 1}/${selectedAssets.length}` : '';

  const columns: TData[] = React.useMemo(
    () => [
      {
        id: 'new-col',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: ({ cell }: Record<string, any>) => {
          return newRows.includes(cell.row.original.operationId) ||
            cell.row.original.hasError ? (
            <CellNewRow hasError={cell.row.original.hasError} />
          ) : (
            <></>
          );
        },
        style: { padding: 0 },
        minWidth: 8,
        maxWidth: 8,
        width: 8,
      },
      {
        Header: t('Update') as string,
        accessor: 'update',
        maxWidth: 130,
      },
      {
        Header: t('Customer ID') as string,
        accessor: 'customId',
      },
      {
        Header: t('Current Step') as string,
        accessor: 'currentStep',
        Cell: currentStepColumnCell,
        maxWidth: 180,
      },
      {
        Header: t('Step') as string,
        accessor: 'step',
        Cell: stepsColumnCell,
      },
      {
        Header: t('Assets') as string,
        accessor: 'assets',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: ({ cell }: Record<string, any>) => {
          return (
            <TableAssets
              assets={cell.value}
              disabled={anonymized}
              onClick={(position) => {
                setSelectedAssets(cell.value);
                setPosition(position);
              }}
              dataTour={!cell.row.index ? 'operations_download-assets' : ''}
            />
          );
        },
      },
      {
        Header: t('Status') as string,
        accessor: 'status',
        Cell: statusColumnCell,
        maxWidth: 200,
      },
      {
        Header: t('Actions') as string,
        accessor: 'actions',
        maxWidth: 150,
      },
    ],
    [anonymized, newRows]
  );

  const tData: Array<TData> = useMemo(
    () =>
      data?.operations?.edges?.map(
        ({ node }: { node: Operation }, index: number) => {
          const hasError = node.results?.some(({ reason }) => reason);
          return {
            update: formatTimezone(node.lastUpdateTime),
            customId: truncateCustomerId(node.customerId, index),
            currentStep: node.currentStep,
            operationId: node.operationId,
            steps: node.steps,
            stepsFlow: node.stepsFlow,
            assets: node.assets,
            status: node.status,
            family: node.family,
            deleted: node.deleted,
            hasError: hasError,
            actions: (
              <>
                {!customActions ? (
                  <TableActions
                    locale={i18n.language}
                    hasError={hasError}
                    onClickError={() => {
                      navigate(
                        generatePath(detailRoute, {
                          id: node.operationId,
                        }),
                        {
                          state: {
                            activeTab: '4',
                          },
                        }
                      );
                    }}
                    onSeeDetail={() => {
                      navigate(
                        generatePath(detailRoute, {
                          id: node.operationId,
                        })
                      );
                    }}
                    testId={`operationDetail/${node.operationId}`}
                    dataTour={!index ? 'operations_detail' : ''}
                  />
                ) : (
                  cloneElement(customActions as React.ReactElement, {
                    onClick: () => {
                      setOperation(node);
                    },
                    onViewDetail: () => {
                      navigate(
                        generatePath(detailRoute, {
                          id: node.operationId,
                        })
                      );
                    },
                  })
                )}
              </>
            ),
          };
        }
      ) || [],
    [data]
  );

  const handlePagination = async (): Promise<boolean> => {
    if (fetchMore && !loading) {
      await fetchMore({
        variables: {
          ...variables,
          cursor: data?.operations?.pageInfo?.endCursor,
        },
      });
    }
    return Promise.resolve(true);
  };

  const hasEmptyData = () =>
    !!Object.keys(variables).length &&
    !loading &&
    !error &&
    !data?.operations?.edges?.length;

  const hasFilterActive = () =>
    Object.entries(variables).some(([key, value]) =>
      key === 'limit' ? false : value
    ) &&
    !loading &&
    !data?.operations?.edges?.length;

  const forceUpdateQuery = () => {
    refetch(variables);
  };

  const timeDiffAcceptable = () => {
    if (variables.fromTimestamp && variables.toTimestamp) {
      const timeDiff = Math.abs(
        new Date(variables.fromTimestamp).getTime() -
          new Date(variables.toTimestamp).getTime()
      );
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      return diffDays <= 30;
    } else {
      return true;
    }
  };

  const handleDownload = async (): Promise<void> => {
    try {
      return await new Promise<void>((resolve, reject) => {
        // Check if time difference is acceptable
        if (!timeDiffAcceptable()) {
          const error = new Error('Too many operations to download');
          // Show time difference error toast
          toastManager({
            $type: ToastType.ERROR,
            message: t('Reports must be of 30 days max.'),
          });
          reject(error);
          return;
        }

        // Request the report
        axios({
          method: 'get',
          url: reportsUrl,
          headers: {
            Authorization: `Bearer ${token}`,
            'x-inphinite-tenantid': tenant.id,
          },
          responseType: 'blob',
          params: variables,
        })
          .then((response) => {
            // Create a download link and trigger click event
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${t('Operations')}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            resolve();
          })
          .catch((err) => {
            // Handle server error and show error toast
            const error = new Error(err.message);
            toastManager({
              $type: ToastType.ERROR,
              message: err.message,
            });
            reject(error);
          });
      });
    } catch (err) {
      // Log any unhandled errors
      console.error(err);
    }
  };

  return (
    <>
      <TableOptionsOperations
        variables={variables}
        onChange={setVariables}
        onDownload={handleDownload}
      >
        {demo && <Anonymized />}
      </TableOptionsOperations>
      <Table
        loading={loading}
        columns={columns as Column<TData>[]}
        data={tData}
        hasMore={!loading && data?.operations?.pageInfo?.hasNextPage}
        fetchMore={handlePagination}
        scrollPosition={scrollPosition}
        emptyState={
          error
            ? 'error'
            : hasFilterActive()
            ? 'filter'
            : hasEmptyData()
            ? 'data'
            : undefined
        }
        locale={i18n.language}
        onClickEmptyState={forceUpdateQuery}
        withTooltip
      />
      {itemSelected && (
        <ModalOperationsDetail
          source={itemSelected.url}
          show={!!itemSelected}
          mimeType={itemSelected.contentType}
          title={itemSelected.type}
          subtitle={`[${getInfoPosition()}]`}
          onChangeShow={(value) => !value && setPosition(null)}
          onGoLeft={prev}
          onGoRight={next}
        />
      )}
    </>
  );
};
