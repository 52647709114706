/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState, useEffect } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
  Anonymized,
  Asset,
  DetailHeader,
  FacialAuthenticationStatus,
  NoFilesUploaded,
  OcrData,
  Operation,
  OperationResult,
  OperationResultReason,
  SCOPES,
  StepsFilter,
  TrackingFamily,
  useAnonymize,
  useArrayNavigation,
  useCommon,
  useFlags,
  usePermissions,
  VerificationStatusValues,
  getOperation,
} from '@facephi/inphinite-common';
import {
  ButtonIcon,
  Card,
  Dropdown,
  DropdownOption,
  FlexContainer,
  Icon,
  Label,
  Map,
  TabPanel,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  DetailError,
  DropdownFilesCard,
  FacialLiveness,
  ModalOperationsDetail,
  OperationCardElement,
  OperationCardOCR,
  OperationCardTimeline,
  OperationContainer,
  OperationContentAnonymized,
  OperationFaceContent,
  OperationsCard,
  OperationsCardDevice,
  OperationsCardFiles,
  OperationsCardStatus,
  OperationsContentDevice,
  OperationsDataContent,
  OperationsDocumentCard,
  OperationsEmptyData,
  OperationsTabs,
  OperationsTabsContent,
  OperationTimeline,
  OperationTimelineWrapper,
  ReactSimpleMapsStyles,
  ReviewCard,
  ReviewForm,
  ReviewsDataContent,
  SelfieValidations,
  ReviewTimeline,
  OperationsCardIcon,
  OperationsFileCard,
  OperationsCardDocuments,
  OperationsFilesColumn,
  OperationsSecurityTab,
} from '../components';
import {
  CustomDropdownAsset,
  FacialValidationState,
} from '../components/operations/model';
import { FULL_FRAME } from '../state/constants';
import { OperationResultDto, OperationStatus } from '../state/model';
import { updateOperationDetails } from '../state/subscriptions';

const FileCard = ({
  index,
  asset,
  anonymized,
  onClick,
  image,
  video,
  mimeType,
}: {
  index: number;
  asset: Asset;
  anonymized?: boolean;
  onClick: (position: number) => void;
  image: boolean;
  video?: boolean;
  mimeType?: string;
}) => {
  const hasError = false;
  return (
    <OperationsFileCard
      key={index}
      title={asset.type}
      hasError={hasError}
      onClick={() => onClick(index)}
      icon={
        <ButtonIcon
          text
          size="M"
          iconName={video ? 'Play' : 'MagnifyingGlassPlus'}
          onClick={() => onClick(index)}
          disabled={anonymized || hasError}
        />
      }
      image={image}
      source={asset.url}
      iconLeft={video ? 'VideoCamera' : 'FilePdf'}
      mimeType={asset.contentType}
    />
  );
};

type StateProps = {
  activeTab?: string;
};

type IProps = {
  hideReviewTab?: boolean;
  emptyBackRoute: string;
  emptyBackText: string;
};

export const OperationsIDPage = ({
  hideReviewTab,
  emptyBackRoute,
  emptyBackText,
}: IProps) => {
  const { demo } = useFlags();
  const { t } = useTranslation();
  const { anonymized, anonymize } = useAnonymize();
  const location = useLocation();
  const { permissionGranted } = usePermissions([SCOPES.canWriteOperations]);

  const haveReviewTab = useMemo(() => {
    if (hideReviewTab) {
      return false;
    } else {
      if (!permissionGranted) {
        return false;
      }
      return true;
    }
  }, [permissionGranted]);

  const [operationInProgress, setOperationInProgress] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>(
    (location.state as StateProps)?.activeTab || '1'
  );
  const [ocrActive, setOcrActive] = useState<string[] | undefined>();

  const [facialAuthenticateResult, setFacialAuthenticateResult] =
    useState<FacialValidationState>();

  const {
    tenant: { id: tenantId },
  } = useCommon();
  const { id } = useParams<{ id: string }>() as { id: string };

  const { data, loading } = useQuery(getOperation, {
    variables: { id, tenantId },
    skip: !id,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useSubscription(updateOperationDetails, {
    variables: { id },
    onSubscriptionData: ({ client, subscriptionData }) => {
      client.writeQuery({
        query: getOperation,
        variables: { id, tenantId },
        data: subscriptionData.data,
      });
    },
  });

  const reasonBlacklisted = useMemo(
    () =>
      data
        ? data.operation.results?.find(
            (result: OperationResultDto) =>
              result.reason && result.status === OperationStatus.blacklisted
          )
          ? data.operation.results?.find(
              (result: OperationResultDto) =>
                result.reason && result.status === OperationStatus.blacklisted
            ).reason
          : null
        : null,
    [data]
  );

  const errorSecurity = (errorData: any) =>
    errorData &&
    errorData['verificationStatus'] !== VerificationStatusValues.verified;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getOperationData = (info: any) => ({
    ...info.operation,
    ...info.operation.details,
    reasonBlacklisted,
    securityList: info.operation.details.securityInfoDataList
      ? [...info.operation.details.securityInfoDataList]
      : info.operation.details.securityData
      ? [
          {
            succeed: !errorSecurity(info.operation.details.securityData),
            data: { ...info.operation.details.securityData },
            mode: 'Security',
          },
        ]
      : [],
  });

  const operation: Operation = useMemo(
    () => (data ? getOperationData(data) : { assets: [], currentStep: '' }),
    [data]
  );

  useEffect(() => {
    setOperationInProgress(
      data ? data.operation.status === OperationStatus.started : false
    );
  }, [data]);

  const logError: OperationResult[] = useMemo(
    () => operation.results?.filter(({ reason }) => reason) || [],
    [operation]
  );

  const noHaveResults = useMemo(
    () =>
      !loading &&
      operation.family === TrackingFamily.ONBOARDING &&
      !operation.facialAuthenticateResult &&
      !operation.assets?.length &&
      !operation.ocrData?.length &&
      !operation.facialAuthenticateResult &&
      !(operation?.securityList!.length > 0) &&
      !operation.device
        ? true
        : operation.family === TrackingFamily.AUTHENTICATION &&
          !operation.facialAuthenticateResult &&
          !operation.assets?.length &&
          !operation.ocrData?.length &&
          !operation.facialAuthenticateResult &&
          !(operation?.securityList!.length > 0) &&
          !operation.device
        ? true
        : false,

    [loading, operation]
  );

  const documents: Asset[] = useMemo(
    () => (operation ? operation.assets : []),
    [operation]
  );

  const otherTypesOCR: Operation['ocrData'] = useMemo(
    () => operation.ocrData?.filter((data) => !data.default) || [],
    [operation]
  );

  const ocrDropdown: DropdownOption[] = useMemo(
    () =>
      operation.ocrData?.map((data) => ({
        name: data.source,
        value: data.source,
        disabled: data.default,
      })) || [],
    [operation]
  );

  const ocrData = useMemo(
    () => operation.ocrData?.find((data) => data.default) || ({} as OcrData),
    [operation]
  );

  const isAuthentication: boolean = useMemo(
    () => operation.family === TrackingFamily.AUTHENTICATION,
    [operation]
  );

  const [position, setPotision, { prev, next, itemSelected }] =
    useArrayNavigation<Asset>(documents);

  const OperationsHeaderPanel = () => {
    return (
      <DetailHeader
        id={operation.operationId}
        date={operation.lastUpdateTime}
        currentStep={operation.currentStep}
        status={operation.status}
        loading={loading}
        family={operation.family}
      />
    );
  };

  const facialAuthResultsIds: FacialValidationState | undefined = useMemo(
    () =>
      operation.facialAuthenticateResult?.map((result) => ({
        ...result,
        id: uuidv4(),
      })),
    [operation]
  );

  const renderFacialResultListDropdown = () => {
    const changeHandler = (provider: string) => {
      const selectedProvider = facialAuthResultsIds?.find(
        (option) => option.id === provider
      );
      selectedProvider && setFacialAuthenticateResult([selectedProvider]);
    };

    if (facialAuthResultsIds && facialAuthResultsIds?.length >= 2) {
      const dropdownOptions = facialAuthResultsIds?.map((option) => {
        return {
          name: option.source,
          value: option.id,
        };
      });

      return (
        <Dropdown
          options={dropdownOptions}
          onChange={changeHandler}
          placeholder={facialAuthResultsIds[0].source}
        />
      );
    }
  };

  useEffect(() => {
    if (facialAuthResultsIds?.length)
      setFacialAuthenticateResult([facialAuthResultsIds[0]]);
  }, [operation]);

  const getInfoPostion = () =>
    position !== null ? `${position + 1}/${documents.length}` : '';

  const getLoadingDocuments = () =>
    Array.from({ length: 6 }).map((_, index) => (
      <OperationsCard
        title={' '}
        key={index}
        loading={true}
        icon={<ButtonIcon text size="S" iconName="MagnifyingGlassPlus" />}
      >
        <Skeleton height={70} width={70} borderRadius={8} />
      </OperationsCard>
    ));

  const checkAvailableElements = (type: string) =>
    documents.filter((el) => el.contentType.includes(type)).length !== 0;

  const getElementsByType = (type: string): CustomDropdownAsset[] =>
    documents
      .filter((document) => document.contentType.includes(type))
      .map((file) => ({
        type: file.type,
        url: file.url,
        contentType: file.contentType,
        position: documents.findIndex((document) => document.url === file.url),
      }));

  const getDocuments = (type: string) => {
    if (type === 'video') {
      const elementsBytype = getElementsByType(type);
      if (elementsBytype.length > 2) {
        return (
          <DropdownFilesCard
            assets={elementsBytype}
            onClick={setPotision}
            contentType={type}
            loading={loading}
          />
        );
      }
    }
    return documents.length && checkAvailableElements(type) ? (
      documents.map(
        (item, index) =>
          item.contentType.includes(type) &&
          !item.type.includes(FULL_FRAME) && (
            <FileCard
              key={index}
              asset={item}
              index={index}
              onClick={setPotision}
              anonymized={anonymized}
              video={type === 'video' ? true : false}
              image={type === 'image' ? true : false}
            />
          )
      )
    ) : (
      <NoFilesUploaded />
    );
  };

  const getLoadingMap = () => (
    <>
      <Skeleton height={200} borderRadius={8} />
      <FlexContainer
        flexDirection="column"
        rowGap="0.8"
        style={{ marginTop: '3rem' }}
      >
        <Skeleton height={18} width="35%" borderRadius={8} />
        <Skeleton height={18} width="50%" borderRadius={8} />
      </FlexContainer>
    </>
  );

  const getMap = () => (
    <>
      <ReactSimpleMapsStyles>
        <Map
          markers={
            operation.coordinates
              ? [
                  {
                    lat: operation.coordinates.latitude,
                    lng: operation.coordinates.longitude,
                    name: 'marker',
                  },
                ]
              : undefined
          }
        />
      </ReactSimpleMapsStyles>
      <FlexContainer flexDirection="column" rowGap="0.8">
        {ocrData?.personalInformation?.nationality && (
          <Label size="14" semibold>
            {anonymize(ocrData?.personalInformation?.nationality)}
          </Label>
        )}
        {operation.coordinates && (
          <Label size="14">
            {`${t('Lat')}: ${anonymize(operation.coordinates?.latitude)}, ${t(
              'Long'
            )}: ${anonymize(operation.coordinates?.longitude)}`}
          </Label>
        )}
      </FlexContainer>
    </>
  );

  const checkNfcWidget: boolean = useMemo(
    () =>
      operation.steps?.includes(StepsFilter.NFC_WIDGET) &&
      !operation.results?.some(
        (result) => result.reason === OperationResultReason.nfcInternalError
      ),
    [operation]
  );

  const checkLivenessError: boolean = useMemo(
    () =>
      operation.facialAuthenticateResult?.[0]?.authStatus !==
      FacialAuthenticationStatus.positive,
    [operation]
  );

  const checkLivenessData: boolean = useMemo(
    () =>
      !loading &&
      (Boolean(operation?.facialLivenessResult) ||
        Boolean(operation?.facialAuthenticateResult?.length)),
    [operation]
  );

  const personalInformation = 'PersonalInformation';

  const errorOnPersonalInformation = useMemo(
    () =>
      !ocrData ||
      !ocrData.personalInformation ||
      (ocrData.personalInformation &&
        Object.values(ocrData?.personalInformation)
          .filter((item) => item !== personalInformation)
          .every((value) => value === null)),
    [ocrData.personalInformation]
  );

  return (
    <OperationContainer data-test="operations-id">
      <OperationContentAnonymized>
        {demo && <Anonymized />}
      </OperationContentAnonymized>
      <OperationsTabs
        noPadding
        noBackground
        active={activeTab}
        onChangeActive={(activeTab: string) => setActiveTab(activeTab)}
      >
        <TabPanel name={t('Data')} id="1">
          <OperationsHeaderPanel />

          {noHaveResults ? (
            <OperationsEmptyData
              backRoute={emptyBackRoute}
              backText={emptyBackText}
            />
          ) : (
            <>
              {itemSelected && (
                <ModalOperationsDetail
                  source={itemSelected.url}
                  show={!!itemSelected}
                  mimeType={itemSelected.contentType}
                  title={t(itemSelected.type)}
                  subtitle={`[${getInfoPostion()}]`}
                  onChangeShow={(value) => !value && setPotision(null)}
                  onGoLeft={prev}
                  onGoRight={next}
                  disabledGoLeft={position === 0}
                  disabledGoRight={position === documents.length - 1}
                />
              )}
              <OperationsDataContent rowGap="1.6" columnGap="1.6">
                <OperationsCard
                  title={t('Personal information')}
                  loading={loading}
                  icon={
                    !loading && !errorOnPersonalInformation ? (
                      <OperationsCardIcon
                        bgColor="success"
                        borderRadius="0.8rem"
                        bgIntensity={100}
                      >
                        <Icon
                          iconName="Check"
                          size="16"
                          color="success"
                          intensity={400}
                        />
                      </OperationsCardIcon>
                    ) : null
                  }
                >
                  {!loading && errorOnPersonalInformation ? (
                    <NoFilesUploaded messageType="information" />
                  ) : (
                    <OperationCardElement
                      loading={loading}
                      data={ocrData?.personalInformation}
                      anonymize={anonymize}
                    />
                  )}
                </OperationsCard>
                <OperationsCardFiles
                  title={t('Images')}
                  loading={loading}
                  isAuthentication={isAuthentication}
                  empty={!checkAvailableElements('image')}
                >
                  <OperationsDocumentCard disabled={anonymized} rowGap="1">
                    {loading ? getLoadingDocuments() : getDocuments('image')}
                  </OperationsDocumentCard>
                </OperationsCardFiles>
                <OperationsFilesColumn rowGap="1.6">
                  <OperationsCardDocuments
                    title={t('Documents')}
                    loading={loading}
                  >
                    <OperationsDocumentCard disabled={anonymized} rowGap="1">
                      {loading ? getLoadingDocuments() : getDocuments('pdf')}
                    </OperationsDocumentCard>
                  </OperationsCardDocuments>
                  <OperationsCardDocuments
                    title={t('Videos')}
                    loading={loading}
                  >
                    <OperationsDocumentCard disabled={anonymized} rowGap="1">
                      {loading ? getLoadingDocuments() : getDocuments('video')}
                    </OperationsDocumentCard>
                  </OperationsCardDocuments>
                </OperationsFilesColumn>
                <OperationsCard
                  title={t('Face verification')}
                  hasError={checkLivenessError && checkLivenessData}
                  loading={loading}
                  dropdown={renderFacialResultListDropdown()}
                >
                  <OperationFaceContent
                    flexDirection="column"
                    rowGap="0.8"
                    disabled={anonymized}
                  >
                    {checkLivenessData ? (
                      <>
                        {(loading || operation.facialAuthenticateResult) && (
                          <SelfieValidations
                            loading={loading}
                            assets={ocrData?.documentImages}
                            state={facialAuthenticateResult}
                          />
                        )}
                        {(loading || operation.facialLivenessResult) && (
                          <FacialLiveness
                            loading={loading}
                            type={operation.facialLivenessResult?.diagnostic}
                          />
                        )}
                      </>
                    ) : (
                      <NoFilesUploaded />
                    )}
                  </OperationFaceContent>
                </OperationsCard>
                <OperationsCard
                  title={t('Nationality')}
                  loading={loading}
                  icon={
                    !loading &&
                    (operation.coordinates ||
                      ocrData?.personalInformation?.nationality) ? (
                      <OperationsCardIcon
                        bgColor="success"
                        borderRadius="0.8rem"
                        bgIntensity={100}
                      >
                        <Icon
                          iconName="Check"
                          size="16"
                          color="success"
                          intensity={400}
                        />
                      </OperationsCardIcon>
                    ) : null
                  }
                >
                  {loading ? getLoadingMap() : getMap()}
                </OperationsCard>
                <OperationsContentDevice
                  flexDirection="column"
                  rowGap="0.8"
                  isAuthentication={isAuthentication}
                >
                  <OperationsCardDevice
                    title={t('Device information')}
                    loading={loading}
                  >
                    {!loading && !operation.device ? (
                      <NoFilesUploaded messageType="information" />
                    ) : (
                      <OperationCardElement
                        loading={loading}
                        data={{
                          type: operation.device?.type,
                          model: operation.device?.model,
                          OS: operation.device?.osVersion,
                          Brand: operation.device?.brand,
                          DeviceID: operation.device?.deviceId,
                        }}
                        anonymize={anonymize}
                      />
                    )}
                  </OperationsCardDevice>
                  {checkNfcWidget && (
                    <OperationsCardStatus
                      loading={loading}
                      type={{ iconColor: 'success', iconName: 'Check' }}
                      label={'NFC'}
                    />
                  )}
                  {demo && (
                    <OperationsCardStatus
                      loading={loading}
                      type={{ iconColor: 'success', iconName: 'Check' }}
                      label={t('Government services')}
                    />
                  )}
                </OperationsContentDevice>
              </OperationsDataContent>
            </>
          )}
        </TabPanel>

        {isAuthentication ? (
          <></>
        ) : (
          <TabPanel name={t('Security')} id="2" testIdTab="security">
            <OperationsHeaderPanel />
            {operation?.securityList && operation?.securityList?.length > 0 && (
              <OperationsSecurityTab>
                {operation?.securityList?.map((element: any, index: number) => (
                  <OperationsCard
                    key={index}
                    title={t(element.mode)}
                    loading={loading}
                    icon={
                      element.succeed ? (
                        <OperationsCardIcon bgColor={'success'}>
                          <Icon
                            iconName="Check"
                            size="16"
                            color="greys"
                            intensity="white"
                          />
                        </OperationsCardIcon>
                      ) : (
                        <OperationsCardIcon bgColor={'error'}>
                          <Icon
                            iconName="X"
                            size="16"
                            color="greys"
                            intensity="white"
                          />
                        </OperationsCardIcon>
                      )
                    }
                  >
                    <OperationCardElement
                      loading={loading}
                      data={element.data}
                    />
                  </OperationsCard>
                ))}
              </OperationsSecurityTab>
            )}
            {!loading &&
              (!operation?.securityList ||
                operation?.securityList.length < 1) && (
                <OperationsEmptyData
                  backRoute={emptyBackRoute}
                  backText={emptyBackText}
                />
              )}
          </TabPanel>
        )}

        {isAuthentication ? (
          <></>
        ) : (
          <TabPanel name={'OCR'} id="3" testIdTab="ocr">
            <OperationsHeaderPanel />
            {loading || ocrData.source ? (
              <OperationsTabsContent flexDirection="column" columnGap="2">
                {!!otherTypesOCR?.length && (
                  <FlexContainer>
                    <Dropdown
                      onChange={(source: string[]) => setOcrActive(source)}
                      options={ocrDropdown}
                      label={t('OCR')}
                      placeholder={t('Select OCR')}
                      value={ocrActive}
                      multiple
                      tagPosition="right"
                    />
                  </FlexContainer>
                )}
                <OperationCardOCR>
                  {[
                    ocrData,
                    ...otherTypesOCR.filter(({ source }) =>
                      ocrActive?.includes(source)
                    ),
                  ]
                    .filter((item) => item)
                    .map((ocr, index) => (
                      <OperationsCard
                        key={index}
                        title={ocr?.source}
                        loading={loading}
                      >
                        <OperationCardElement
                          loading={loading}
                          data={(ocr?.documentDataFields || []).reduce(
                            (obj, item) =>
                              Object.assign(obj, { [item.key]: item.value }),
                            {}
                          )}
                          anonymize={anonymize}
                        />
                      </OperationsCard>
                    ))}
                </OperationCardOCR>
              </OperationsTabsContent>
            ) : (
              <OperationsEmptyData
                backRoute={emptyBackRoute}
                backText={emptyBackText}
              />
            )}
          </TabPanel>
        )}

        <TabPanel name={t('Timeline')} id="4" testIdTab="timeline">
          <OperationsHeaderPanel />
          <OperationTimelineWrapper columnGap="4">
            <OperationCardTimeline
              titleHeader={t('Operation timeline')}
              disabled={anonymized}
            >
              <OperationTimeline
                onboardingId={id}
                disabled={anonymized}
                family={operation.family}
              />
            </OperationCardTimeline>
            {!!logError.length && (
              <Card
                titleHeader={t('Error detail')}
                flexDirectionContent="column"
                rowGapContent="1"
              >
                {logError.map((log, index) => (
                  <DetailError key={index} title={log.reason} />
                ))}
              </Card>
            )}
          </OperationTimelineWrapper>
        </TabPanel>

        {!haveReviewTab || operationInProgress ? (
          <></>
        ) : (
          <TabPanel
            name={t('Review')}
            id="5"
            testIdTab="review"
            textHighlight={true}
          >
            <OperationsHeaderPanel />
            <ReviewsDataContent>
              <ReviewCard titleHeader={t('Check information')} noPaddingContent>
                <ReviewForm />
              </ReviewCard>
              <ReviewCard titleHeader={t('Timeline')} noPaddingContent>
                <ReviewTimeline />
              </ReviewCard>
            </ReviewsDataContent>
          </TabPanel>
        )}
      </OperationsTabs>
    </OperationContainer>
  );
};
