import { CardBaseContent, FlexContainer, Label } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const CardTitleStyle = styled(Label)`
  padding-bottom: 0.4rem;
`;

export const CardSubtitleStyle = styled(Label)`
  padding-bottom: 0.4rem;
  color: ${(props) => props.theme.color.greys[300]};
`;

export const CardContainer = styled(CardBaseContent)`
  position: relative;
`;

export const CardFooter = styled(FlexContainer)`
  margin-top: auto;

  > a {
    font-size: 1.2rem;
    display: inline-block;
    font-weight: 600;
    color: ${(props) => props.theme.color.greys[700]};
  }
`;
