import { animated } from 'react-spring';
import styled from 'styled-components';
import { CardCollapsableStyleProps } from './model';
import { FlexContainer } from '../../flexContainer';
import { Label } from '../../label';
import { CARD_BASE_HEADER_HEIGHT } from '../Styles';

export const CARD_BASE_SMALL_HEADER_HEIGHT = '4.8rem';

export const CardCollapsableStyles = styled(animated.div)`
  background-color: ${(props) => props.theme.color.greys.white};
  border-radius: 0.8rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CollapsableHeader = styled(
  FlexContainer
)<CardCollapsableStyleProps>`
  height: ${(props) =>
    props.small ? CARD_BASE_SMALL_HEADER_HEIGHT : CARD_BASE_HEADER_HEIGHT};
  min-height: ${(props) =>
    props.small ? CARD_BASE_SMALL_HEADER_HEIGHT : CARD_BASE_HEADER_HEIGHT};
  padding: 0 2rem;

  &:disabled {
    cursor: default;
    background-color: ${(props) => props.theme.color.greys[100]};
    ${Label} {
      color: ${(props) => props.theme.color.greys[600]};
    }
  }
`;

export const CollapsableContent = styled.div`
  flex: 1 1 auto;
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;

  .collapsable-list:not(:empty) {
    padding: 2rem;
    border-top: 1px solid ${(props) => props.theme.color.greys[200]};
  }
`;
