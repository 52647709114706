import styled from 'styled-components';
import { LogoSquareProps } from './model';

export const LogoContainer = styled.div<LogoSquareProps>`
  width: ${(props) =>
    props.size === 'M' ? '4.4rem' : props.size === 'L' ? '6rem' : '3.5rem'};
  height: ${(props) =>
    props.size === 'M' ? '4.4rem' : props.size === 'L' ? '6rem' : '3.5rem'};
  border-radius: 0.8rem;
  background-image: ${(props) => props.gradientColor};
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    fill: ${(props) => props.theme.color.greys.white};
    height: ${(props) =>
      props.size === 'M' ? '3rem' : props.size === 'L' ? '3.4rem' : '2rem'};
  }
`;
