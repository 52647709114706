import React, { MouseEvent, RefObject } from 'react';

export type PortalOverlayProps = {
  testId?: string;
  className?: string;
  overlayBlur?: boolean;
  onClickOutside?(ev?: globalThis.MouseEvent | MouseEvent): void;
  children?: React.ReactNode;
};

export type PortalProps = PortalOverlayProps & {
  isMobile?: boolean;
  show: boolean;
  positionX?: PositionX;
  positionY?: PositionY;
  overlay?: boolean;
  widthAuto?: boolean;
  actionRef?: RefObject<
    HTMLDivElement | HTMLButtonElement | HTMLLIElement | HTMLSpanElement
  > | null;
};

export enum PositionY {
  top = 'top',
  bottom = 'bottom',
  center = 'center',
}

export enum PositionX {
  left = 'left',
  right = 'right',
  center = 'center',
  start = 'start',
  end = 'end',
}

export type IPosition = {
  top: number;
  left: number;
  right: number;
  width?: number | string;
  height: number;
};

export type IStyles = {
  top: number | string;
  left?: number;
  right: number | undefined;
  width?: number | string;
  bottom?: number | string;
  height?: number | string;
  transform?: string;
};

export type ITransformStyles = {
  transform?: string;
};
