import { ASIDENAV_WIDTH } from '@facephi/inphinite-common';
import { FlexContainer, OptionMenu } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const AsideNavBottomPanel = styled(OptionMenu)<{
  active?: boolean;
  itemsLength: number;
}>`
  margin-left: calc(${ASIDENAV_WIDTH} + 3.4rem - 0.8rem);
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.itemsLength < 4 ? props.itemsLength : 4)},
    7rem
  );
  grid-row-gap: 1.6rem;
  grid-column-gap: 2.4rem;
  background: ${(props) => props.theme.color.greys.white};
  border-radius: 0.8rem;
  padding: 3.6rem 2.4rem;
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};

  li {
    cursor: pointer;
    height: 8rem;
  }
`;

export const AsideNavBottomButton = styled(FlexContainer)<{
  isActive?: boolean;
}>`
  div {
    ${(props) => !props.isActive && 'background-color: transparent !important'}
  }
`;

export const AsideNavBottomStyles = styled.nav`
  border-top: 2px solid ${(props) => props.theme.color.greys[500]};
  margin-top: auto;
  justify-content: center;
  display: flex;
  padding-top: 1.6rem;
`;
