import React from 'react';
import { TimelineProps } from './model';
import { TimelineStyles } from './Styles';

export const Timeline = ({ children, className, testId }: TimelineProps) => {
  return (
    <TimelineStyles
      flexDirection="column"
      className={className}
      data-test={testId}
    >
      {children}
    </TimelineStyles>
  );
};
