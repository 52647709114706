import styled from 'styled-components';
import { FlexContainer } from '../flexContainer';
import { Label } from '../label';

export const RadioButtonStyles = styled(FlexContainer)`
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + ${Label} {
      &:before {
        content: '';
        border-radius: 100%;
        background: ${(props) => props.theme.color.greys.white};
        border: 0.1rem solid ${(props) => props.theme.color.primary[400]};
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        position: relative;
        top: 0;
        margin-right: 0.8rem;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + ${Label} {
        &:before {
          background-color: ${(props) => props.theme.color.primary[400]};
          box-shadow: inset 0 0 0 0.25rem
            ${(props) => props.theme.color.greys.white};
        }
      }
    }
    &:disabled {
      + ${Label} {
        cursor: default;
        &:before {
          border-color: ${(props) => props.theme.color.greys[100]};
        }
      }
    }
    &:focus {
      + ${Label} {
        &:before {
          outline: none;
          border-color: ${(props) => props.theme.color.primary[400]};
          border-width: 0.2rem;
        }
      }
    }
    &:checked {
      &:disabled + ${Label} {
        &:before {
          box-shadow: inset 0 0 0 0.25rem
            ${(props) => props.theme.color.greys[100]};
          background-color: ${(props) => props.theme.color.greys[300]};
        }
      }
    }
  }

  ${Label} {
    cursor: pointer;
  }
`;
