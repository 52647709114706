/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  forwardRef,
  Fragment,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DropdownOption, DropdownProps } from './model';
import {
  DropdownItem,
  DropdownMultipleValues,
  DropdownStyles,
  DropdownSubMenuWrapperStyles,
  DropdownWrapperStyles,
} from './Styles';
import { useDeviceSize } from '../../hooks';
import { Icon, IconName } from '../icon';
import { Label } from '../label';
import { Portal } from '../portal';
import { UserTag } from '../userTag';

export const Dropdown = forwardRef(
  (
    {
      placeholder,
      options = [],
      value,
      onChange,
      testId,
      disabled,
      label,
      className,
      iconLeft,
      errorLabel,
      overlay = true,
      multiple = false,
      helpLabel,
      subMenuPosition = 'left',
      size = 'M',
      autoComplete,
      tagPosition = 'bottom',
    }: DropdownProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const itemRef = useRef([]);
    const [show, setShow] = useState<boolean>(false);
    const [showSubMenu, setShowSubMenu] = useState<string | number>();
    const [innerValue, setInnerValue] = useState<string | number | undefined>(
      value as string | number | undefined
    );
    const [innerIconLeft, setInnerIconLeft] = useState<string>('');
    const [innerValues, setInnerValues] = useState<string[] | number[]>(
      value && multiple ? (value as string[] | number[]) : []
    );
    const { isMobile } = useDeviceSize();

    const onSelectItem = (value: string | number, icon?: string) => {
      if (multiple) {
        setInnerValues((values: any) =>
          values.includes(value)
            ? values.filter((v: any) => v !== value)
            : [...values, value]
        );
      } else {
        setInnerValue(value);
        setInnerIconLeft(icon as IconName);
        setShow(false);
        setShowSubMenu('');
      }
    };

    useEffect(() => {
      onChange && innerValue && onChange(innerValue);
    }, [innerValue]);

    useEffect(() => {
      if (multiple) {
        onChange && onChange(innerValues);
      }
    }, [innerValues]);

    useEffect(() => {
      if (!multiple) {
        setInnerValue(value as any);
      } else if (value) {
        setInnerValues(value as string[] | number[]);
      }
    }, [value]);

    const valueText = useMemo(() => {
      if (innerValues.length && multiple) {
        return options
          .filter((option: DropdownOption) =>
            innerValues.includes(option.value as never)
          )
          .map((item) => item.name)
          .join(', ');
      }

      const customFind = (
        opt: DropdownOption[]
      ): DropdownOption | undefined => {
        let item;

        for (let i = 0; i < opt.length && !item; i++) {
          if (opt[i].subMenu) {
            item = customFind(opt[i].subMenu as DropdownOption[]);
          } else if (opt[i].value === innerValue) {
            item = opt[i];
          }
        }
        return item;
      };
      return customFind(options)?.name;
    }, [innerValue, options, innerValues]);

    const handleShowMenus = () => {
      setShow(false);
      setShowSubMenu('');
    };

    if (itemRef.current.length !== options.length) {
      itemRef.current = Array(options.length)
        .fill('')
        .map((_, i) => itemRef.current[i] || React.createRef());
    }

    const renderItem = (name: string, icon?: IconName) => {
      return (
        <>
          {icon && <Icon iconName={icon} size="16" />}
          <Label size="12" ellipsis>
            {name}
          </Label>
        </>
      );
    };

    return (
      <>
        <DropdownStyles
          ref={ref}
          label={label}
          readOnly
          iconLeft={iconLeft ? iconLeft : (innerIconLeft as IconName)}
          iconRight="CaretDown"
          placeholder={placeholder}
          onClick={() => !disabled && setShow(true)}
          divRef={inputRef}
          value={valueText ? valueText : placeholder || ''}
          testId={testId}
          disabled={disabled}
          className={className}
          errorLabel={errorLabel}
          onChange={() => {}}
          helpLabel={helpLabel}
          size={size}
          autoComplete={autoComplete}
          tagPosition={tagPosition}
        >
          {multiple && (
            <DropdownMultipleValues
              wrap="wrap"
              rowGap="0.6"
              columnGap="0.6"
              as="ul"
            >
              {options.map((item: DropdownOption, index) => (
                <Fragment key={index}>
                  {innerValues.includes(item.value as never) && (
                    <UserTag
                      id={item.name}
                      userName={item.name}
                      onClick={() => onSelectItem(item.value, item.icon)}
                      testId={`selected-item-${item.name}`}
                      key={item.name}
                    />
                  )}
                  {item.subMenu &&
                    item.subMenu.map(
                      (subitem) =>
                        innerValues.includes(subitem.value as never) && (
                          <UserTag
                            id={subitem.name}
                            userName={subitem.name}
                            onClick={() =>
                              onSelectItem(subitem.value, subitem.icon)
                            }
                            testId={`selected-item-${subitem.name}`}
                            key={subitem.name}
                          />
                        )
                    )}
                </Fragment>
              ))}
            </DropdownMultipleValues>
          )}
        </DropdownStyles>
        <Portal
          actionRef={inputRef}
          show={show}
          onClickOutside={handleShowMenus}
          overlay={overlay}
          isMobile={isMobile}
          overlayBlur={isMobile}
        >
          <DropdownWrapperStyles>
            <ul>
              {options.map((item: DropdownOption, index) => {
                const handleClickItem = () => {
                  if (!item.disabled && !item.subMenu) {
                    item.onClick && item.onClick();
                    onSelectItem(item.value, item.icon);
                  }
                };

                return (
                  <DropdownItem
                    disabled={item.disabled}
                    onMouseEnter={() => setShowSubMenu(item.value)}
                    onMouseLeave={() => setShowSubMenu('')}
                    onClick={handleClickItem}
                    alignItems="center"
                    columnGap="1.2"
                    data-test={`option-${item.value}`}
                    key={item.value}
                    as="li"
                    ref={itemRef.current[index]}
                    subMenuIsActive={item.subMenu && showSubMenu === item.value}
                    active={
                      multiple
                        ? innerValues.includes(item.value as never)
                        : innerValue === item.value
                    }
                  >
                    {renderItem(item.name, item.icon)}
                    {item.subMenu && showSubMenu === item.value && (
                      <Portal actionRef={itemRef.current[index]} show>
                        <DropdownSubMenuWrapperStyles
                          data-test="dropdown-subMenu"
                          subMenuPosition={subMenuPosition}
                        >
                          <ul>
                            {item.subMenu.map((subItem) => {
                              const handleClickItem = () => {
                                if (!item.disabled) {
                                  subItem.onClick && subItem.onClick();
                                  onSelectItem(subItem.value, subItem.icon);
                                }
                              };
                              return (
                                <DropdownItem
                                  disabled={item.disabled}
                                  onClick={handleClickItem}
                                  alignItems="center"
                                  columnGap="1.2"
                                  as="li"
                                  data-test={`option-subMenu-${subItem.value}`}
                                  active={
                                    multiple
                                      ? innerValues.includes(
                                          subItem.value as never
                                        )
                                      : innerValue === subItem.value
                                  }
                                  key={subItem.value}
                                >
                                  {renderItem(subItem.name, subItem.icon)}
                                </DropdownItem>
                              );
                            })}
                          </ul>
                        </DropdownSubMenuWrapperStyles>
                      </Portal>
                    )}
                  </DropdownItem>
                );
              })}
            </ul>
          </DropdownWrapperStyles>
        </Portal>
      </>
    );
  }
);
