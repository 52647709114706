import React from 'react';
import { TimelineResourcesProps } from './model';
import { TimelineResourcesName, TimelineResourcesStyles } from './Styles';

export const TimelineResources = ({
  imageComponent,
  name,
  onClick,
  disabled,
}: TimelineResourcesProps) => {
  return (
    <TimelineResourcesStyles
      flexDirection="column"
      rowGap="0.4"
      onClick={() => !disabled && onClick && onClick()}
    >
      {imageComponent}
      <TimelineResourcesName size="8">{name}</TimelineResourcesName>
    </TimelineResourcesStyles>
  );
};
