import { useState, useEffect } from 'react';
import { ButtonIcon } from '@facephi/inphinite-ui';
import { UXDevices, UXDevicesIcons } from '../../../state/constants';
import { DeviceToggleStyles } from '../Styles';

type DeviceToggleProps = {
  defaultDevice?: UXDevices;
  onChange: (device: UXDevices) => void;
};

export const DeviceToggle = ({
  defaultDevice = UXDevices.desktop,
  onChange,
}: DeviceToggleProps) => {
  const [selectedDevice, setSelectedDevice] = useState(defaultDevice);

  useEffect(() => {
    onChange(selectedDevice);
  }, [selectedDevice]);

  return (
    <DeviceToggleStyles columnGap="1.2" alignItems="center">
      {Object.values(UXDevices).map((device) => (
        <ButtonIcon
          key={device}
          onClick={() => setSelectedDevice(device)}
          color={selectedDevice === device ? 'primary' : undefined}
          iconName={UXDevicesIcons[device]}
          text
          size="S"
        ></ButtonIcon>
      ))}
    </DeviceToggleStyles>
  );
};
