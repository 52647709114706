import React from 'react';
import { ProgresBarProps } from './model';
import { ProgresBarStatus, ProgresBarStyles } from './Styles';
import { Label } from '../label';

export const ProgresBar = ({
  percentage,
  className,
  testId,
  big,
}: ProgresBarProps) => {
  return (
    <ProgresBarStyles
      flexDirection={big ? 'row' : 'column'}
      className={className}
      data-test={testId}
      columnGap="2.6"
    >
      <Label size={big ? '14' : '10'} textAlign="end" semibold>
        {percentage}%
      </Label>
      <ProgresBarStatus percentage={percentage} big={big} />
    </ProgresBarStyles>
  );
};
