import { Suspense } from 'react';
import { timeZoneVar } from '@backoffice/state/cache';
import { AnonymizeProvider, FlagsProvider } from '@facephi/inphinite-common';
import {
  InphiniteDarkTheme,
  InphiniteTheme,
  InphiniteThemeProvider,
  Spinner,
  TimezoneProvider,
  ToastProvider,
} from '@facephi/inphinite-ui';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { createRoot } from 'react-dom/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import keycloak from './keycloak';
import {
  ApiProvider,
  AuthProvider,
  TourProvider,
  TranslationProvider,
} from './providers';
import { Routes } from './routes/Routes';
import { GlobalStyles } from './styles/GlobalStyles';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Suspense fallback={<Spinner />}>
    <InphiniteThemeProvider
      theme={InphiniteTheme}
      themeDark={InphiniteDarkTheme}
    >
      <SkeletonTheme baseColor="#bfc5d4" highlightColor="#dbdee6">
        <TimezoneProvider setTimezone={timeZoneVar} timezone={timeZoneVar()}>
          <ToastProvider>
            <GlobalStyles />
            <TranslationProvider>
              <ReactKeycloakProvider authClient={keycloak}>
                <AuthProvider>
                  <ApiProvider>
                    <FlagsProvider>
                      <TourProvider>
                        <AnonymizeProvider>
                          <Routes />
                        </AnonymizeProvider>
                      </TourProvider>
                    </FlagsProvider>
                  </ApiProvider>
                </AuthProvider>
              </ReactKeycloakProvider>
            </TranslationProvider>
          </ToastProvider>
        </TimezoneProvider>
      </SkeletonTheme>
    </InphiniteThemeProvider>
  </Suspense>
);
