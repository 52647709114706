import { lazy, useEffect } from 'react';
import { BrowserNoValidate } from '@backoffice/components';
import { useAuth } from '@backoffice/providers';
import { AppName, browserList, RoutesName } from '@backoffice/state/constants';
import { PermissionsGate, SCOPES, useFlags } from '@facephi/inphinite-common';
import { compare } from 'compare-versions';
import dayjs from 'dayjs';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter,
  generatePath,
  Navigate,
  Route,
  Routes as ReactRoutes,
} from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';

require('dayjs/locale/es');
require('dayjs/locale/en');

const DashboardPage = lazy(() => import('@backoffice/pages/Dashboard'));
const OperationsPage = lazy(() => import('@backoffice/pages/Operations'));
const OperationsIDPage = lazy(() => import('@backoffice/pages/OperationsID'));
const Login = lazy(() => import('@backoffice/pages/Login'));
const WorkflowsPage = lazy(() => import('@backoffice/pages/Workflows'));
const Studio = lazy(() => import('@backoffice/pages/Studio'));
const TemplatePage = lazy(() => import('@backoffice/pages/Template'));
const SettingsPage = lazy(() => import('@backoffice/pages/Settings'));
const Page404 = lazy(() => import('@backoffice/pages/Error404'));
const Page500 = lazy(() => import('@backoffice/pages/Error500'));
const ReportingPage = lazy(() => import('@backoffice/pages/Reporting'));
const Video = lazy(() => import('@backoffice/pages/Video'));
const UXPage = lazy(() => import('@backoffice/pages/UX'));
const UXTask = lazy(() => import('@backoffice/pages/UXTask'));
const UXAnalytic = lazy(() => import('@backoffice/pages/UXAnalytic'));
const Banned = lazy(() => import('@backoffice/pages/Banned'));
const BannedID = lazy(() => import('@backoffice/pages/BannedID'));
const Rules = lazy(() => import('@backoffice/pages/Rules'));
const AIPage = lazy(() => import('@backoffice/pages/AI'));
const UpgradePage = lazy(() => import('@backoffice/pages/Upgrade'));
const AspectPage = lazy(() => import('@backoffice/pages/Aspect'));
const ProfilePage = lazy(() => import('@backoffice/pages/Profile'));
const UserProfilePage = lazy(() => import('@backoffice/pages/UserPanel'));
const PermissionPage = lazy(() => import('@backoffice/pages/Permissions'));
const ManagementPage = lazy(() => import('@backoffice/pages/Management'));
const UserManagementPage = lazy(
  () => import('@backoffice/pages/UserManagement')
);
const Home = lazy(() => import('@backoffice/pages/Home'));
const PlanPage = lazy(() => import('@backoffice/pages/Upgrade'));
const VideoCallsPage = lazy(() => import('@backoffice/pages/VideoCalls'));
const AuditPage = lazy(() => import('@backoffice/pages/Audit'));
const AuditIdPage = lazy(() => import('@backoffice/pages/AuditId'));

export const Routes = () => {
  const { isAuthenticated } = useAuth();
  const { demo } = useFlags();
  const { i18n } = useTranslation();

  useEffect(() => {
    dayjs.locale(i18n.language?.split('-')[0]);
  }, [i18n.language]);

  const validateBrowser = () => {
    const currentBrowser = browserList.find(
      (item) => item.name === browserName
    );
    return (
      currentBrowser &&
      compare(fullBrowserVersion, currentBrowser.version, '>=')
    );
  };

  if (isAuthenticated === undefined) {
    return null;
  }

  return validateBrowser() ? (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route
          path={RoutesName.dashboard}
          element={
            <PermissionsGate scopes={[SCOPES.canAccessOperations]} redirect>
              <ProtectedRoute
                component={DashboardPage}
                app={AppName.operations}
              />
            </PermissionsGate>
          }
        />
        <Route
          path={RoutesName.operations}
          element={
            <PermissionsGate scopes={[SCOPES.canAccessOperations]} redirect>
              <ProtectedRoute
                component={OperationsPage}
                app={AppName.operations}
              />
            </PermissionsGate>
          }
        />
        <Route
          path={RoutesName.operationsId}
          element={
            <ProtectedRoute
              component={OperationsIDPage}
              app={AppName.operations}
            />
          }
        />
        <Route
          path={RoutesName.workflows}
          element={
            <ProtectedRoute component={WorkflowsPage} app={AppName.studio} />
          }
        />
        <Route
          path={RoutesName.template}
          element={
            <ProtectedRoute component={TemplatePage} app={AppName.studio} />
          }
        />
        <Route
          path={RoutesName.workflow}
          element={<ProtectedRoute component={Studio} app={AppName.studio} />}
        />
        <Route
          path={RoutesName.studio}
          element={
            <ProtectedRoute
              path={RoutesName.studio}
              component={Studio}
              app={AppName.studio}
            />
          }
        />
        <Route
          path={RoutesName.studioId}
          element={<ProtectedRoute component={Studio} app={AppName.studio} />}
        />
        <Route
          path={RoutesName.reporting}
          element={
            <ProtectedRoute
              component={ReportingPage}
              app={AppName.operations}
              flags={[demo]}
            />
          }
        />
        <Route
          path={RoutesName.ux}
          element={<ProtectedRoute component={UXPage} app={AppName.ux} />}
        />
        <Route
          path={RoutesName.uxTask}
          element={<ProtectedRoute component={UXTask} app={AppName.ux} />}
        />
        <Route
          path={RoutesName.uxAnalytic}
          element={<ProtectedRoute component={UXAnalytic} app={AppName.ux} />}
        />
        <Route path={RoutesName.antifraud}>
          <Route
            path={RoutesName.antifraud}
            element={<Navigate to={RoutesName.banned} />}
          />
          <Route
            path={RoutesName.rules}
            element={
              <ProtectedRoute component={Rules} app={AppName.antiFraud} />
            }
          />
          <Route
            path={RoutesName.banned}
            element={
              <ProtectedRoute component={Banned} app={AppName.antiFraud} />
            }
          />
          <Route
            path={RoutesName.bannedId}
            element={
              <ProtectedRoute component={BannedID} app={AppName.antiFraud} />
            }
          />
        </Route>
        <Route
          path={RoutesName.banned}
          element={
            <ProtectedRoute component={Banned} app={AppName.antiFraud} />
          }
        />
        <Route
          path={RoutesName.bannedId}
          element={
            <ProtectedRoute component={BannedID} app={AppName.antiFraud} />
          }
        />
        <Route
          path={RoutesName.rules}
          element={<ProtectedRoute component={Rules} app={AppName.antiFraud} />}
        />
        <Route
          path={RoutesName.ai}
          element={<ProtectedRoute component={AIPage} app={AppName.ai} />}
        />
        <Route
          path={RoutesName.privacy}
          element={
            <ProtectedRoute component={UpgradePage} app={AppName.privacy} />
          }
        />
        <Route
          path={RoutesName.audit}
          element={<ProtectedRoute component={AuditPage} app={AppName.audit} />}
        />
        <Route
          path={RoutesName.auditId}
          element={
            <ProtectedRoute component={AuditIdPage} app={AppName.audit} />
          }
        />
        <Route
          path={RoutesName.videoId}
          element={<ProtectedRoute component={Video} />}
        />
        <Route
          path={`${RoutesName.settings}/*`}
          element={<ProtectedRoute component={SettingsPage} />}
        >
          <Route index element={<Navigate to={RoutesName.profile} />} />
          <Route
            path={RoutesName.profile.replace(RoutesName.settings, '')}
            element={<ProfilePage />}
          />
          <Route
            path={RoutesName.aspect.replace(RoutesName.settings, '')}
            element={<AspectPage />}
          />
          <Route
            path={RoutesName.mainManagement.replace(RoutesName.settings, '')}
            element={
              <Navigate
                to={generatePath(RoutesName.management, { type: 'users' })}
              />
            }
          />
          <Route
            path={RoutesName.management.replace(RoutesName.settings, '')}
            element={
              <PermissionsGate scopes={[SCOPES.canViewUserManagment]} redirect>
                <ManagementPage />
              </PermissionsGate>
            }
          >
            <Route
              path={RoutesName.permissions.replace(RoutesName.management, '')}
              element={<PermissionPage />}
            />
          </Route>
          <Route
            path={RoutesName.myPlan.replace(RoutesName.settings, '')}
            element={<PlanPage />}
          />
          <Route path="*" element={<Navigate to={RoutesName.notFound} />} />
        </Route>
        <Route
          path={RoutesName.userManagement}
          element={
            <PermissionsGate scopes={[SCOPES.canViewUserManagment]} redirect>
              <ProtectedRoute
                component={UserManagementPage}
                app={AppName.userManagement}
              ></ProtectedRoute>
            </PermissionsGate>
          }
        ></Route>
        <Route
          path={RoutesName.userManagementProfile}
          element={
            <ProtectedRoute
              component={UserProfilePage}
              app={AppName.userManagement}
            ></ProtectedRoute>
          }
        />
        <Route
          path={RoutesName.videoCalls}
          element={
            <ProtectedRoute
              component={VideoCallsPage}
              app={AppName.videoAssistance}
            />
          }
        />
        <Route path={RoutesName.error} element={<Page500 />} />
        <Route path="*" element={<Page404 />} />
      </ReactRoutes>
    </BrowserRouter>
  ) : (
    <BrowserNoValidate />
  );
};
