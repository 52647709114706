import { useEffect } from 'react';
import { Asset, useLoadAsset } from '@facephi/inphinite-common';
import { Spinner, WidgetItem } from '@facephi/inphinite-ui';

type IProps = {
  asset: Asset;
  onClick?(): void;
};

export const ImageLoad = ({ asset, onClick }: IProps) => {
  const { resourceUrl, loadingResource, cancelSource, hasError } = useLoadAsset(
    asset.url,
    asset.contentType
  );

  useEffect(() => {
    return () => cancelSource();
  }, []);

  return loadingResource ? (
    <Spinner size="S" insideComponent />
  ) : hasError ? (
    <WidgetItem
      iconName="FileX"
      backgroundColor="error"
      size="L"
      direction="column"
      labelSize="14"
      labelColor="error"
    />
  ) : (
    <img src={resourceUrl} alt={asset.type} onClick={onClick} />
  );
};
