import React from 'react';
import { UserTagProps } from './model';
import { UserTagButton, UserTagStyles } from './Styles';
import { Icon } from '../icon';
import { Label } from '../label';

export const UserTag = ({
  userName,
  avatar,
  className,
  testId,
  noBorder,
  selected,
  onClick,
  icon,
  id,
  size = 'M',
}: UserTagProps) => {
  return (
    <UserTagStyles
      columnGap="0.8"
      alignItems="center"
      className={className}
      data-test={testId}
      noBorder={noBorder}
      selected={selected}
      id={id}
    >
      {icon && !avatar && <Icon iconName={icon} size="20" />}
      {!icon && avatar && <img src={avatar} alt="user-avatar" />}
      <Label size={size === 'L' ? '10' : '8'}>{userName}</Label>
      {onClick && (
        <UserTagButton iconName="XCircle" onClick={onClick} text size="S" />
      )}
    </UserTagStyles>
  );
};
