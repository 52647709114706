import styled from 'styled-components';
import { FlexContainer } from '../../flexContainer';

export const ArrowFlowBox = styled(FlexContainer)<{
  isActive?: boolean;
  isUnknownSteps?: boolean;
}>`
  height: 100%;
  width: 4.8rem;
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  margin-left: -1.2rem;
  padding: 0 0.4rem 0 0.8rem;
  svg {
    color: ${(props) =>
      props.isUnknownSteps
        ? props.theme.color.greys[500]
        : props.theme.color.greys.white};
  }
`;

export const ArrowsFlowStyles = styled(FlexContainer)`
  height: 3.2rem;
  max-height: 3.2rem;
  ${ArrowFlowBox} {
    &:nth-child(1) {
      width: 4.4rem;
      clip-path: polygon(73% 0%, 100% 50%, 73% 100%, 0% 100%, 0 50%, 0% 0%);
      border-radius: 0.4rem;
      margin-left: 0;
      padding-left: 0;
    }
  }
`;
