import * as React from 'react';
import { IconCustomProps } from '../model';

export const ReniecIcon = ({ className, size, color }: IconCustomProps) => {
  return (
    <svg
      id="icon-group-43"
      viewBox="0 0 32 32"
      className={className}
      width={size ? (size as number) : undefined}
      height={size ? (size as number) : undefined}
    >
      <path fill="#d3d9ed" d="M20.034 6.746h3.387v-6.186h-3.387z"></path>
      <path
        fill={color}
        d="M23.349 3.407c0-0 0-0.001 0-0.001 0-1.86-1.508-3.367-3.367-3.367v0c-1.86 0-3.381 1.507-3.367 3.368 0.009 1.191 0.041 1.756 0.041 3.329h6.681c0-1.219 0.012-1.645 0.012-3.329zM26.839 3.368c0-0 0-0 0-0.001 0-1.86-1.508-3.368-3.368-3.368s-3.367 1.507-3.368 3.367v0c0 1.86 1.508 3.368 3.368 3.368s3.368-1.508 3.368-3.368v0zM11.717 7.826l0.086-0.002c0.799 0.001 1.446 0.648 1.446 1.447 0 0.082-0.007 0.162-0.020 0.241l0.001-0.008v6.858l1.429-0.942v-6.036c-0.003-0.036-0.005-0.078-0.005-0.121 0-0.799 0.648-1.447 1.447-1.447 0.048 0 0.096 0.002 0.143 0.007l-0.006-0 10.609-0.005c0.811 0.026 1.458 0.69 1.458 1.504 0 0 0 0.001 0 0.001v-0 8.9h-1.429l-0.010 9.691-21.728-0.006v-9.725h-1.435v-8.698c-0.005-0.047-0.008-0.101-0.008-0.155 0-0 0-0.001 0-0.001v0c0-0.070-0.001-0.14 0.008-0.209 0.106-0.716 0.702-1.263 1.432-1.291l0.003-0 6.58-0.002zM5.41 3.454c0-1.86 1.508-3.368 3.368-3.368s3.368 1.508 3.368 3.368v0c0 1.86-1.508 3.368-3.368 3.368s-3.368-1.508-3.368-3.368v0z"
      ></path>
      <path
        fill="#fefefe"
        d="M11.094 18.011c3.491-3.879 8.649-4.926 11.523-2.336s2.373 7.833-1.118 11.714c-3.491 3.878-7.374 5.732-11.523 2.333-4.361-3.57-2.373-7.832 1.118-11.712z"
      ></path>
      <path
        fill="none"
        stroke="#d3d9ed"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.691"
        d="M19.832 29.553c0.14-1.916-0.854-3.17-1.357-4.997-0.272-0.95-0.754-1.791-0.574-2.936 0.134-0.859 1.295-1.314 0.837-2.044-0.548-0.875-1.601 0.37-2.499 0.881-2.585 0.839-4.494 0.272-6.572-1.204"
      ></path>
      <path
        fill="none"
        stroke="#d3d9ed"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.691"
        d="M8.968 20.106c0.147 0.15 0.895 0.516 1.050 0.656 1.584 1.427 2.245 1.407 4.359 1.112 0.89-0.13 1.549-0.319 1.982 0.257 0.956 1.269 0.543 2.153 1.264 3.895 0.603 1.442 1.511 2.638 1.035 4.145"
      ></path>
      <path
        fill="none"
        stroke="#d3d9ed"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.691"
        d="M8.297 21.117c0.37 0.333 1.59 1.009 1.96 1.342 1.661 1.498 3.419-0.481 5.080 1.016 0.952 0.859 0.963 1.875 1.223 3.13 0.149 0.715 0.233 0.945 0.524 1.618 0.385 0.895 0.457 1.762 0.359 2.888"
      ></path>
      <path
        fill="none"
        stroke="#d3d9ed"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.691"
        d="M7.818 22.419c1.139 0.709 2.412 1.761 3.745 1.765 0.898-0.001 1.892-0.015 2.624 0.492 0.95 0.66 1.105 1.351 1.54 2.926 0.38 1.377 0.736 2.724 0.273 4.12"
      ></path>
      <path
        fill="none"
        stroke="#d3d9ed"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.691"
        d="M6.582 23.301c1.602 1.056 5.199 1.742 6.608 3.011 1.855 1.673 2.38 3.281 1.709 5.688"
      ></path>
      <path
        fill="none"
        stroke="#d3d9ed"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.691"
        d="M11.271 17.234c1.512 1.434 2.449 1.195 5.087-0.082 1.729-0.835 3.455-0.964 4.655 0.54 0.863 1.085 0.142 3.391 0.618 5.528 0.285 1.278 0.88 2.612 0.643 3.932"
      ></path>
      <path
        fill="none"
        stroke="#d3d9ed"
        strokeLinejoin="miter"
        strokeLinecap="butt"
        strokeMiterlimit="4"
        strokeWidth="0.691"
        d="M12.603 16.219c0.501 1.532 2.893-0.401 4.049-0.653 2.438-0.531 4.393-0.429 5.985 1.479 1.262 1.517 0.149 4.954 1.028 7.796"
      ></path>
    </svg>
  );
};
