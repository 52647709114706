import { useMutation } from '@apollo/client';
import { useCommon, getOperationDetails } from '@facephi/inphinite-common';
import { Spinner } from '@facephi/inphinite-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { rejectSchema, RejectDto } from '../../state/model';
import { createReview } from '../../state/mutations';
import {
  ModalManagementRejectStyles,
  ModalManagementRejectWrapper,
  ReviewTextArea,
  SpinnerAbsolute,
} from './Styles';

type Props = {
  show: boolean;
  onChangeShow(show: boolean): void;
  operationId: string;
};

export const ModalReject = ({ show, onChangeShow, operationId }: Props) => {
  const { t } = useTranslation();
  const {
    tenant: { id: tenantId },
  } = useCommon();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rejectSchema),
    defaultValues: {
      rejectNote: '',
    },
  });

  const loading = false;
  const [saveReview] = useMutation(createReview);

  const onSubmit = async (rejection: RejectDto) => {
    const response = await saveReview({
      variables: {
        input: {
          checks: [
            {
              name: 'sediaData',
              isChecked: false,
            },
            {
              name: 'debtData',
              isChecked: false,
            },
            {
              name: 'evidenceData',
              isChecked: false,
            },
          ],
          comment: rejection.rejectNote,
          approved: false,
        },
        operationId: operationId,
      },
      refetchQueries: [
        {
          query: getOperationDetails,
          variables: { operationId, tenantId },
        },
      ],
    });

    if (response.data) {
      setValue('rejectNote', '');
      onChangeShow(!show);
    }
  };

  return (
    <ModalManagementRejectStyles
      show={show}
      title={t('Reject reason')}
      onChangeShow={onChangeShow}
      onCreate={() => !loading && handleSubmit(onSubmit)()}
      closeLabel={t('Cancel')}
      submitLabel={t('Send')}
      testIdSave="button-save-reject"
    >
      <ModalManagementRejectWrapper
        flexDirection="column"
        rowGap="2.4"
        flex="1"
      >
        {loading && (
          <SpinnerAbsolute>
            <Spinner />
          </SpinnerAbsolute>
        )}

        <ReviewTextArea
          label={t('Add a reason')}
          {...register('rejectNote')}
          rows={10}
          iconRight="PencilSimpleLine"
          testId="reject-input"
          errorLabel={
            errors.rejectNote?.message && t(errors.rejectNote.message)
          }
        ></ReviewTextArea>
      </ModalManagementRejectWrapper>
    </ModalManagementRejectStyles>
  );
};
