import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './model';
import { iconsObject } from './Object';
import {
  ThemeColorIntensityGreyType,
  ThemeColorIntensityRegularType,
} from '../../themes';

export const Icon = ({
  iconName,
  weight = 'regular',
  color,
  size,
  className,
  intensity = 400,
  testId,
}: IconProps) => {
  const SelectedIcon = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    () => iconsObject[iconName] as (props: any) => JSX.Element,
    [iconName]
  );
  const theme = useTheme();

  return (
    <SelectedIcon
      data-test={testId}
      weight={weight}
      color={
        color
          ? color === 'greys'
            ? theme.color[color][intensity as ThemeColorIntensityGreyType]
            : theme.color[color]
            ? theme.color[color][intensity as ThemeColorIntensityRegularType]
            : color
          : 'currentColor'
      }
      size={size}
      className={className}
    />
  );
};
