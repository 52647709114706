import {
  CardBaseStyle,
  FlexContainer,
  ThemeColorType,
  ThemeColorScheme,
} from '@facephi/inphinite-ui';
import Magnifier from 'react-magnifier';
import styled from 'styled-components/macro';

type CardIconProps = {
  bgColor: ThemeColorType;
  bgIntensity?: keyof ThemeColorScheme[ThemeColorType];
  remSize?: string;
  borderRadius?: string;
};

const MaxHeightAssetsModal = '76.8rem';
export const OperationsCardStatusStyles = styled(FlexContainer)<{
  statusColor: ThemeColorType;
}>`
  height: 4.8rem;
  border-radius: 0.8rem;
  background-color: ${({ theme, statusColor }) =>
    theme.color[statusColor][100]};
  padding: 0 1.6rem 0 2rem;
`;

const PADDING_CONTENT = '1.8rem';

export const OperationCardContent = styled.ul`
  padding: ${PADDING_CONTENT};
  flex: 1;
`;

export const OperationCardDocumentContent = styled.ul<{
  empty?: boolean;
}>`
  padding: ${PADDING_CONTENT};
  flex: 1;
  display: flex;
  flex-grow: ${(props) => (props.empty ? 'inherit' : 'initial')};
  justify-content: center;
  flex-direction: column;
`;

export const OperationsCardStyles = styled(CardBaseStyle)<{
  hasError?: boolean;
}>`
  ${(props) =>
    props.hasError && `border: 1px solid ${props.theme.color.error[200]};`}
`;

export const OperationsCardDocumentsStyles = styled(OperationsCardStyles)<{
  hasError?: boolean;
}>`
  ${(props) =>
    props.hasError && `border: 1px solid ${props.theme.color.error[200]};`}

  height: 6rem;
`;

export const OperationCardElementStyles = styled(FlexContainer)`
  border-bottom: 1px solid ${(props) => props.theme.color.greys[200]};
  padding: 1.2rem 0.8rem;

  > p {
    word-break: break-word;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const OperationSourceStyles = styled(FlexContainer)`
  flex: 1;
  width: 100%;
  position: relative;
  height: 100%;

  video {
    height: calc(min(100vh, ${MaxHeightAssetsModal}) - 20rem);
    min-height: 38rem;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MagnifierStyles = styled(Magnifier as any)`
  margin: auto;
  img {
    object-fit: cover;
    max-width: 67rem;
    min-height: 38rem;
    max-height: calc(min(100vh, ${MaxHeightAssetsModal}) - 20rem);
  }
`;

export const ImageContentStyle = styled.div`
  text-align: center;
`;

export const PDFViewerStyles = styled.div`
  width: 100%;
  min-height: 38rem;
  height: calc(min(100vh, ${MaxHeightAssetsModal}) - 20rem);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const OperationsCardIcon = styled.div<CardIconProps>`
  width: ${({ remSize }) => (remSize ? remSize : '2.4rem')} !important;
  height: ${({ remSize }) => (remSize ? remSize : '2.4rem')};
  border-radius:${({ borderRadius }) => borderRadius || '50%'};
  background-color: ${({ theme, bgColor, bgIntensity }) =>
    bgColor && theme.color[bgColor][bgIntensity || 400]};
  display: flex;
  align-items: center;
  justify-content: center;
}`;

export const ImageWrapper = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
`;

export const ItemWrapper = styled.div`
  display: flex;
`;

export const TextWrapper = styled.div`
  margin: auto;
  margin-left: 1rem;
`;
