import { useState } from 'react';
import { useAuth } from '@backoffice/providers';
import { AppName, RoutesName } from '@backoffice/state/constants';
import { useFlags } from '@facephi/inphinite-common';
import {
  Button,
  ButtonIcon,
  Icon,
  Label,
  PositionX,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AvatarImage,
  ButtonUser,
  HeaderStyles,
  OptionMenuHeader,
  OptionMenuItemStyles,
  SearchInput,
} from './Styles';
import useModalGuide from '../../hooks/useModalGuide';
import { ModalTenant } from '../tenant';
import { ModalTutorial } from '../tutorial';
import { ModalGuide } from '../tutorial/ModalGuide';

type HeaderProps = {
  image?: string;
  app?: AppName;
};

export const Header = ({ image = '/Avatar.png', app }: HeaderProps) => {
  const { t } = useTranslation();
  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [showTenant, setShowTenant] = useState<boolean>(false);

  const { demo } = useFlags();

  const navigate = useNavigate();
  const { user, logout, tenant } = useAuth();

  const { value, setFalse, setTrue, toggle } = useModalGuide();

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <HeaderStyles
        alignItems="center"
        justifyContent="space-between"
        columnGap="1"
      >
        {demo && <SearchInput type="text" iconLeft="MagnifyingGlass" />}
        <Button
          iconName="Question"
          variant="text"
          onClick={() => setShowTutorial(true)}
        >
          {t('Need help?')}
        </Button>
        <Button
          iconName="GlobeHemisphereEast"
          variant="text"
          onClick={() => setShowTenant(true)}
        >
          {tenant.name}
        </Button>
        {demo && <ButtonIcon iconName="Bell" text />}
        <OptionMenuHeader
          widthAuto
          hidePanelOnClick
          positionX={PositionX.end}
          renderItem={
            <ButtonUser
              as="button"
              alignItems="center"
              justifyContent="center"
              data-test="user-menu"
            >
              {image && <AvatarImage src={image} />}
              <Label size="14" semibold>
                {user?.name}
              </Label>
              <Icon iconName="CaretDown" size="24" />
            </ButtonUser>
          }
        >
          <OptionMenuItemStyles
            iconName="UserGear"
            onClick={() => navigate(RoutesName.profile)}
          >
            {t('Profile & Account')}
          </OptionMenuItemStyles>
          <OptionMenuItemStyles
            iconName="CheckSquareOffset"
            onClick={() => navigate(RoutesName.myPlan)}
          >
            {t('My plan')}
          </OptionMenuItemStyles>
          {demo && (
            <OptionMenuItemStyles
              iconName="Palette"
              onClick={() => navigate(RoutesName.aspect)}
            >
              {t('Aspect')}
            </OptionMenuItemStyles>
          )}
          <OptionMenuItemStyles
            dark
            iconName="SignOut"
            onClick={handleLogout}
            testId="logOut"
          >
            {t('Logout')}
          </OptionMenuItemStyles>
        </OptionMenuHeader>
      </HeaderStyles>
      <ModalTutorial
        app={app}
        show={showTutorial}
        onChangeShow={(showTutorial) => setShowTutorial(showTutorial)}
        onGuide={setTrue}
        onCloseModal={() => setShowTutorial(false)}
      />
      <ModalTenant
        show={showTenant}
        onChangeShow={(showTenant) => setShowTenant(showTenant)}
        onCloseModal={() => setShowTenant(false)}
      />
      <ModalGuide show={value} onCancel={setFalse} onChangeShow={toggle} />
    </>
  );
};
