import React, { Children, useEffect, useState } from 'react';
import { TabsProps } from './model';
import {
  TabPanelContent,
  TabsStyle,
  TabStytles,
  TabWrapper,
  TabHeader,
} from './Styles';
import { TabHeaderActions } from './TabHeaderActions';
import { TabPanel } from './TabPanel';
import { FlexContainer } from '../flexContainer';
import { Icon } from '../icon';

export const Tabs = ({
  children,
  active = '1',
  onChangeActive,
  className,
  testId,
  noPadding,
  noBackground,
}: TabsProps) => {
  const [selected, setSelected] = useState<string>(active);

  useEffect(() => {
    setSelected(active);
  }, [active]);

  useEffect(() => {
    onChangeActive && onChangeActive(selected);
  }, [selected]);

  return (
    <TabStytles className={className} flexDirection="column">
      <TabHeader justifyContent="space-between" alignItems="end">
        <TabWrapper data-test={testId} as="ul">
          {Children.map(children, (child: React.ReactElement) => {
            const onClickTab = () => {
              setSelected(child.props.id);
            };

            const name = child.props.name
              ? child.props.name.charAt(0).toUpperCase() +
                child.props.name.slice(1)
              : null;

            return (
              name &&
              child.type === TabPanel &&
              child.props.visible !== false && (
                <li role="tab" key={child.props.id} id={child.props.testIdTab}>
                  <TabsStyle
                    as="button"
                    data-test={child.props.testIdTab}
                    disabled={child.props.disabled}
                    active={child.props.id === selected}
                    onClick={onClickTab}
                    type="button"
                    size={child.props.size || '18'}
                    semibold
                    hasError={child.props.hasError}
                    textHighlight={child.props.textHighlight}
                  >
                    {child.props.icon && <Icon iconName={child.props.icon} />}
                    {name}
                  </TabsStyle>
                </li>
              )
            );
          })}
        </TabWrapper>

        {Children.map(children, (child: React.ReactElement) => {
          return (
            child.type === TabHeaderActions &&
            child.props.visible !== false && (
              <FlexContainer>{child}</FlexContainer>
            )
          );
        })}
      </TabHeader>

      {Children.map(children, (child: React.ReactElement) => {
        return child.type === TabPanel && selected === child.props.id ? (
          <TabPanelContent
            noPadding={noPadding}
            role="tabpanel"
            noBackground={noBackground}
          >
            {child}
          </TabPanelContent>
        ) : null;
      })}
    </TabStytles>
  );
};
