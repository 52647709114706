export enum Roles {
  Admin = 'ADMIN',
  User = 'USER',
  Audit = 'AUDIT',
  Metrics = 'METRICS',
  Operations = 'OPERATIONS',
  Demo = 'DEMO',
  UserManagement = 'USER_MANAGEMENT',
}

export enum SCOPES {
  canAccessOperations = 'canAccessOperations',
  canAccessDashboard = 'canAccessDashboard',
  canViewOperations = 'canViewOperations',
  canViewAudit = 'canViewAudit',
  canViewUX = 'canViewUX',
  canViewUserManagment = 'canViewUserManagment',
  canViewVideo = 'canViewVideo',
  canViewAntifraud = 'canViewAntifraud',
  canViewStudio = 'canViewStudio',
  canViewPrivacy = 'canViewPrivacy',
  canViewAI = 'canViewAI',
  canWriteOperations = 'canWriteOperations',
}

export const PERMISSIONS = {
  [Roles.Admin]: [
    SCOPES.canViewOperations,
    SCOPES.canAccessDashboard,
    SCOPES.canAccessOperations,
    SCOPES.canViewVideo,
    SCOPES.canViewAntifraud,
    SCOPES.canViewUserManagment,
  ],
  [Roles.UserManagement]: [SCOPES.canViewUserManagment],
  [Roles.User]: [
    SCOPES.canViewOperations,
    SCOPES.canAccessDashboard,
    SCOPES.canAccessOperations,
    SCOPES.canViewVideo,
    SCOPES.canViewAntifraud,
  ],
  [Roles.Audit]: [
    SCOPES.canViewOperations,
    SCOPES.canAccessDashboard,
    SCOPES.canAccessOperations,
    SCOPES.canWriteOperations,
    SCOPES.canViewVideo,
  ],
  [Roles.Operations]: [
    SCOPES.canViewOperations,
    SCOPES.canAccessDashboard,
    SCOPES.canAccessOperations,
  ],
  [Roles.Metrics]: [SCOPES.canViewUX],
  [Roles.Demo]: [
    SCOPES.canViewOperations,
    SCOPES.canAccessDashboard,
    SCOPES.canAccessOperations,
    SCOPES.canViewVideo,
    SCOPES.canViewAntifraud,
    SCOPES.canViewPrivacy,
    SCOPES.canViewStudio,
    SCOPES.canViewAI,
    SCOPES.canViewUserManagment,
    SCOPES.canViewUX,
  ],
};
