import React, { ElementType } from 'react';
import { CardBaseHeader, IconName, Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { OperationCardContent, OperationsCardStyles } from './Styles';

type Props = {
  title?: string;
  children?: React.ReactNode;
  contentElementType?: ElementType;
  className?: string;
  iconName?: IconName;
  icon?: React.ReactNode;
  loading?: boolean;
  hasError?: boolean;
  dropdown?: React.ReactNode;
};

export const OperationsCard = ({
  title,
  children,
  contentElementType,
  className,
  iconName,
  icon,
  loading,
  hasError,
  dropdown,
}: Props) => {
  const { t } = useTranslation();

  const getLoadingItem = () => (
    <>
      <div style={{ width: '100%' }}>
        <Skeleton height={18} width="40%" borderRadius={8} />
      </div>
      {iconName && <Skeleton height={18} width={18} borderRadius={8} />}
    </>
  );

  const getItem = () => (
    <>
      {title && (
        <Label size="14" semibold ellipsis>
          {t(title)}
        </Label>
      )}
      {icon && <>{icon}</>}
      {dropdown && <>{dropdown}</>}
    </>
  );

  return (
    <OperationsCardStyles
      flexDirection="column"
      className={className}
      hasError={hasError}
    >
      {title && (
        <CardBaseHeader
          alignItems="center"
          justifyContent="space-between"
          columnGap="0.8"
        >
          {loading ? getLoadingItem() : getItem()}
        </CardBaseHeader>
      )}
      <OperationCardContent as={contentElementType}>
        {children}
      </OperationCardContent>
    </OperationsCardStyles>
  );
};
