import * as echarts from 'echarts';
import { LinearGradientObject } from 'echarts';

export const COLORS: LinearGradientObject[] = [
  // Started
  new echarts.graphic.LinearGradient(0, 1, 1, 1, [
    {
      offset: 0,
      color: '#5F7EFE',
    },
    {
      offset: 1,
      color: '#5AB6FA',
    },
  ]),
  // Denied
  new echarts.graphic.LinearGradient(0, 1, 1, 1, [
    {
      offset: 0,
      color: '#F575A0',
    },

    {
      offset: 1,
      color: '#D459DA',
    },
  ]),
  // Expired
  new echarts.graphic.LinearGradient(0, 1, 1, 1, [
    {
      offset: 0,
      color: '#7189FC',
    },
    {
      offset: 1,
      color: '#8839FD',
    },
  ]),
  // Success
  new echarts.graphic.LinearGradient(0, 1, 1, 1, [
    {
      offset: 0,
      color: '#55CDFC',
    },
    {
      offset: 1,
      color: '#92EBAB',
    },
  ]),
  // Cancelled
  new echarts.graphic.LinearGradient(0, 1, 1, 1, [
    {
      offset: 0,
      color: '#C7C7C7',
    },
    {
      offset: 1,
      color: '#ECEDF1',
    },
  ]),
  // Error
  new echarts.graphic.LinearGradient(0, 1, 1, 1, [
    {
      offset: 0,
      color: '#FF6460',
    },
    {
      offset: 1,
      color: '#F18D7B',
    },
  ]),
];
