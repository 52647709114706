import { useState } from 'react';
import {
  OperationsPage,
  OperationsFilter,
  OperationStatus,
} from '@facephi/inphinite-operations';

import { CustomActions, ModalSendRule } from '../components';
import { RoutesNameAntifraud } from '../state/constants';

export const BannedPage = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const filters = {
    status: OperationStatus.denied,
  } as OperationsFilter;

  const onShowModalRules = () => {
    setShowModal(true);
  };

  return (
    <>
      <OperationsPage
        filterBy={filters}
        detailRoute={RoutesNameAntifraud.bannedId}
        customActions={<CustomActions onSendRules={onShowModalRules} />}
      />

      <ModalSendRule
        show={showModal}
        onChangeShow={(showModaRule) => setShowModal(showModaRule)}
      />
    </>
  );
};
