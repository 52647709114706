import React, { forwardRef, Ref } from 'react';
import { ButtonProps } from './model';
import { ButtonStyle } from './Styles';
import { useThemeProvider } from '../../providers';
import { Icon } from '../icon';

export const Button = forwardRef(
  (
    {
      disabled = false,
      children,
      type = 'button',
      testId,
      onClick,
      className,
      iconName,
      active,
      as,
      href,
      ellipsis,
      variant = 'filled',
      size = 'M',
      color,
      semiBold = true,
      fullWidth,
      iconPosition = 'left',
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const { isLightTheme } = useThemeProvider();

    return (
      <ButtonStyle
        disabled={disabled}
        type={type}
        data-test={testId}
        onClick={onClick}
        className={className}
        ref={ref}
        aria-selected={active}
        as={as}
        href={href}
        ellipsis={ellipsis}
        semiBold={semiBold}
        variant={variant}
        fullWidth={fullWidth}
        size={size}
        color={
          variant !== 'text' && !color
            ? 'secondary'
            : !isLightTheme && color === 'secondary'
            ? 'primary'
            : color
        }
      >
        {iconName && iconPosition === 'left' && (
          <Icon iconName={iconName} size={size === 'S' ? 16 : 24} />
        )}
        {children}
        {iconName && iconPosition === 'right' && (
          <Icon iconName={iconName} size={size === 'S' ? 16 : 24} />
        )}
      </ButtonStyle>
    );
  }
);
