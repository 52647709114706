import React, { Fragment, useState } from 'react';
import { TooltipProps } from './model';
import { TooltipStyles, TooltipWrapper } from './Styles';
import { useTheme } from '../../providers';
import { Label } from '../label';

export const Tooltip = ({
  label,
  children,
  id,
  type,
  place,
  className,
  variant = 'primary',
  effect,
  displayBlock,
  noWrap,
}: TooltipProps) => {
  const [show, setShow] = useState<boolean>(false);

  const hiddenTooltip = () => {
    setShow(false);
  };

  const seeTooltip = () => {
    setShow(true);
  };

  const theme = useTheme();

  return (
    <Fragment>
      <TooltipWrapper
        data-tip
        data-for={id}
        onMouseEnter={seeTooltip}
        onMouseLeave={hiddenTooltip}
        className={className}
        displayBlock={displayBlock}
      >
        {children}
      </TooltipWrapper>
      {show && (
        <TooltipStyles
          id={id}
          type={type}
          place={place}
          effect={effect || 'solid'}
          backgroundColor={
            variant === 'primary'
              ? theme.color.greys[100]
              : theme.color.greys[700]
          }
        >
          <Label size="10" negative={variant === 'secondary'} noWrap={noWrap}>
            {label}
          </Label>
        </TooltipStyles>
      )}
    </Fragment>
  );
};
