import { AsideNav } from '@facephi/inphinite-common';
import { FlexContainer, useTheme } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  PreviewAspectStyles,
  PreviewAspectWrapper,
  TestingColor,
} from './Styles';

export const PreviewAspect = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <PreviewAspectStyles titleHeader={t('Preview')} noPaddingContent>
      <PreviewAspectWrapper flex="1">
        <AsideNav isPreviewMode menu={[]} />
        <FlexContainer justifyContent="space-between" flex="1">
          <FlexContainer flexDirection="column">
            <TestingColor background={theme.color.primary[100]}>
              100
            </TestingColor>
            <TestingColor background={theme.color.primary[400]}>
              400
            </TestingColor>
            <TestingColor background={theme.color.primary[500]}>
              500
            </TestingColor>
          </FlexContainer>
          <FlexContainer flexDirection="column">
            <TestingColor background={theme.color.secondary[100]}>
              100
            </TestingColor>
            <TestingColor background={theme.color.secondary[400]}>
              400
            </TestingColor>
            <TestingColor background={theme.color.secondary[500]}>
              500
            </TestingColor>
          </FlexContainer>
          <img src={theme.images?.logoAside} alt="aside-logo" />
          <img src={theme.images?.favicon} alt="favicon" />
        </FlexContainer>
      </PreviewAspectWrapper>
    </PreviewAspectStyles>
  );
};
