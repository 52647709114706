import styled from 'styled-components';
import { AvatarProps } from './model';

export const Avatar = styled.img<AvatarProps>`
  width: ${(props) => `${props.size}rem`};
  height: ${(props) => `${props.size}rem`};
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
`;
