import React from 'react';
import { ButtonIcon, Table, FlexContainer } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  updatesIP,
  countries,
  documentYears,
  documentTypes,
  descPopular,
} from './../antiFraud/dataRules';
import { TablePopImg } from './../antiFraud/Styles';

export const TablePopular = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t('Image'),
        accessor: 'image',
        maxWidth: 150,
      },
      {
        Header: t('Type'),
        accessor: 'type',
        maxWidth: 150,
      },
      {
        Header: t('Country'),
        accessor: 'country',
        maxWidth: 200,
      },
      {
        Header: t('Year'),
        accessor: 'year',
        maxWidth: 100,
      },
      {
        Header: t('Update'),
        accessor: 'update',
        maxWidth: 130,
      },
      {
        Header: t('Description'),
        accessor: 'description',
      },
      {
        Header: t('Actions'),
        accessor: 'action',
        Cell: <ButtonIcon text iconName="ListPlus" onClick={() => {}} />,
        maxWidth: 150,
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    const data = [];
    for (let i = 0; i < 7; i++) {
      data.push({
        image: <TablePopImg src={`/ia-pop-${i}.png`} alt="face-user" />,
        type: documentTypes[i],
        country: (
          <FlexContainer
            justifyContent="flex-start"
            alignItems="center"
            columnGap="0.8"
            style={{ width: '100%' }}
          >
            <img
              style={{ width: '4.5rem' }}
              alt={countries[i].value}
              src={countries[i].image}
            />
            <span>{countries[i].value}</span>
          </FlexContainer>
        ),
        year: documentYears[i],
        update: updatesIP[i],
        description: descPopular[i],
        action: '',
      });
    }
    return data;
  }, []);

  return <Table columns={columns} data={data} />;
};
