import React from 'react';
import { IconCustomProps } from '../model';

export const DocumentVerification = ({
  className,
  size,
  color,
}: IconCustomProps) => {
  return (
    <svg
      id="icon-icon-identification-card-check"
      viewBox="0 0 32 32"
      className={className}
      width={size ? (size as number) : undefined}
      height={size ? (size as number) : undefined}
    >
      <path
        fill={color}
        d="M26.105 17.69c0 0 0.001 0 0.001 0 0.465 0 0.842 0.377 0.842 0.842 0 0.412-0.296 0.755-0.687 0.828l-0.005 0.001-0.151 0.013h-6.316c-0 0-0.001 0-0.001 0-0.465 0-0.842-0.377-0.842-0.842 0-0.412 0.296-0.755 0.687-0.828l0.005-0.001 0.152-0.013h6.315zM10.332 10.105c-0.024-0-0.051-0.001-0.079-0.001-2.558 0-4.632 2.074-4.632 4.632s2.074 4.632 4.632 4.632c0.028 0 0.056-0 0.083-0.001l-0.004 0c2.524-0.044 4.553-2.101 4.553-4.632s-2.029-4.587-4.549-4.632l-0.004-0zM10.332 11.789c1.628 0 2.947 1.32 2.947 2.947s-1.32 2.947-2.947 2.947v0c-1.628 0-2.947-1.32-2.947-2.947s1.32-2.947 2.947-2.947v0z"
      ></path>
      <path
        fill={color}
        d="M10.332 17.684c0 0 0 0 0.001 0 2.732 0 5.029 1.857 5.699 4.378l0.009 0.041c0.017 0.063 0.027 0.136 0.027 0.212 0 0.465-0.377 0.842-0.842 0.842-0.39 0-0.718-0.265-0.814-0.625l-0.001-0.006c-0.484-1.832-2.126-3.16-4.079-3.16s-3.595 1.329-4.072 3.131l-0.007 0.029c-0.102 0.359-0.426 0.618-0.812 0.618-0.465 0-0.842-0.377-0.842-0.842 0-0.070 0.009-0.138 0.025-0.203l-0.001 0.006c0.678-2.563 2.976-4.421 5.709-4.421 0 0 0 0 0 0v0z"
      ></path>
      <path
        fill={color}
        d="M29.474 2.526h-26.947c-1.396 0-2.526 1.132-2.526 2.526v21.895c0 1.396 1.132 2.526 2.526 2.526h26.947c1.396 0 2.526-1.132 2.526-2.526v-21.895c0-1.396-1.132-2.526-2.526-2.526zM2.526 4.21h26.947c0.465 0 0.842 0.377 0.842 0.842v21.895c0 0.465-0.377 0.842-0.842 0.842h-26.947c-0.465 0-0.842-0.377-0.842-0.842v0-21.895c0-0.465 0.377-0.842 0.842-0.842z"
      ></path>
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        fill={color}
        strokeMiterlimit="4"
        strokeWidth="1.0667"
        d="M26.526 9.263l-4.912 4.631-2.666-2.526"
      ></path>
    </svg>
  );
};
