import { CSSProperties, useMemo } from 'react';
import { Label, useTheme } from '@facephi/inphinite-ui';
import ReactECharts from 'echarts-for-react';
import Skeleton from 'react-loading-skeleton';
import { COLORS } from './model';
import { IconWrapper, RingContainer } from './Styles';

type IProps = {
  value: number;
  subtitle?: string;
  className?: string;
  style?: CSSProperties;
  loading?: boolean;
  color?: string;
  icon?: JSX.Element;
  width?: number;
  radius?: string;
  fontSize?: number;
};

export const RingChart = ({
  value = 0,
  subtitle,
  className,
  style,
  loading,
  color,
  icon,
  width = 15,
  radius = '75%',
  fontSize = 30,
}: IProps) => {
  const theme = useTheme();

  const data = useMemo(() => {
    return loading
      ? []
      : [
          {
            value,
            detail: {
              offsetCenter: ['0%', '0%'],
            },
            itemStyle: { color: color || COLORS[2] },
          },
        ];
  }, [loading, value, color]);

  const options = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        radius: radius,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
        },
        axisLine: {
          lineStyle: {
            width,
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          distance: 50,
        },
        data,
        title: {
          fontSize: 14,
          show: false,
        },
        detail: {
          show: !icon,
          marginTop: 40,
          fontSize: fontSize,
          formatter: '{value}%',
          color: theme.color.greys[700],
        },
      },
    ],
  };

  return (
    <div className={className}>
      <RingContainer>
        <ReactECharts option={options} style={style} />
        {icon && <IconWrapper>{icon}</IconWrapper>}
      </RingContainer>
      {loading ? (
        <Skeleton height={10} width="25%" borderRadius={14} />
      ) : (
        <Label size="10" textAlign="center">
          {subtitle}
        </Label>
      )}
    </div>
  );
};
