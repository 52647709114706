import React, { createContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { default as relativeTime } from 'dayjs/plugin/relativeTime';
import timezoneDay from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Timezones from '../assets/json/timezone.json';

dayjs.extend(relativeTime);

export const DEFAULT_FORMAT_DATE = 'DD/MM/YY HH:mm:ss';
const DEFAULT_TIMEZONE = 'Europe/Madrid';

type TimezoneProps = {
  children: React.ReactNode;
  timezone?: string;
  setTimezone(timezone: string): void;
};

type ContextProps = {
  options: string[];
  timezone: string;
  setTimezone(timeZone: string): void;
  formatTimezone(date: string | Date | number, format?: string): string;
  getDateTimezone(date: string | number): dayjs.Dayjs;
  getTimeFromNow: (date: string) => string;
};

const TimezoneContext = createContext<ContextProps>({
  options: [],
  timezone: '',
  setTimezone: () => {},
  formatTimezone: () => '',
  getDateTimezone: () => dayjs(),
  getTimeFromNow: () => '',
});

export const TimezoneProvider = ({
  children,
  timezone,
  setTimezone: setTimezoneOutside,
}: TimezoneProps) => {
  const [innerTimezone, setInnerTimezone] = useState<string>(
    timezone || DEFAULT_TIMEZONE
  );

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezoneDay);
  }, []);

  useEffect(() => {
    setTimezoneOutside(innerTimezone);
  }, [innerTimezone]);

  const setTimezone = (newTimezone: string) => {
    setInnerTimezone(newTimezone);
    dayjs.tz.setDefault(newTimezone);
  };

  const formatTimezone = (
    date: string | Date | number | dayjs.Dayjs,
    format = DEFAULT_FORMAT_DATE
  ) => {
    return dayjs(date).tz(innerTimezone).format(format);
  };

  const getDateTimezone = (date: string | number) => {
    return dayjs(date).tz(innerTimezone);
  };

  const getTimeFromNow = (date: string) => {
    const now = getDateTimezone(new Date().getTime());
    return getDateTimezone(date).from(now);
  };

  return (
    <TimezoneContext.Provider
      value={{
        options: Timezones,
        timezone: innerTimezone,
        setTimezone,
        formatTimezone,
        getDateTimezone,
        getTimeFromNow,
      }}
    >
      {children}
    </TimezoneContext.Provider>
  );
};

export const useTimezone = () => React.useContext(TimezoneContext);
