import { FlexContainer, Label, ThemeColorType } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';
import { RoleInfoBoxStyleProps } from './model';

export const RoleInfoBoxStyles = styled(FlexContainer)<
  RoleInfoBoxStyleProps & {
    backgroundColor: ThemeColorType;
  }
>`
  background-color: ${({ theme, backgroundColor }) =>
    theme.color[backgroundColor][100]};
  border-radius: 0.8rem;
  padding: ${(props) =>
    props.paddingBig ? '2.1rem 2.1rem 1.6rem' : '1.2rem 1.4rem 1.8rem'};

  ${Label} {
    transition: none;
  }
`;
