import React, { ChangeEvent, forwardRef, Ref, useRef, useState } from 'react';
import { animated, Transition } from 'react-spring';
import { TextAreaProps } from './model';
import { TextAreaStyles } from './Styles';
import { useThemeProvider } from '../../providers';
import { ButtonIcon } from '../buttonIcon';
import { FlexContainer } from '../flexContainer';
import { Icon } from '../icon';
import {
  ErrorLabel,
  HelpLabelStyles,
  InputContainer,
  InputIcon,
} from '../input/Styles';
import { Label } from '../label';
import { Portal } from '../portal';

export const TextArea = forwardRef(
  (
    {
      name,
      className,
      placeholder,
      value,
      onChange,
      disabled = false,
      testId,
      readOnly = false,
      onClick,
      divRef,
      label,
      errorLabel,
      iconLeft,
      autoFocus,
      onRemoveValue,
      children,
      elementLeft,
      variant = 'primary',
      helpLabel,
      size = 'M',
      autoComplete,
      rows = 2,
      onBlur,
      iconRight,
      onClickIconRight,
    }: TextAreaProps,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    const [showHelpLabel, setShowHelpLabel] = useState<boolean>(false);

    const onChangeHandle = (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange(event);
    };

    const { isLightTheme } = useThemeProvider();

    const helpLabelRef = useRef(null);

    const closeHelpLabel = () => {
      setShowHelpLabel(false);
    };

    return (
      <InputContainer className={className}>
        {(label || helpLabel) && (
          <FlexContainer>
            {label && (
              <Label
                as="label"
                size="12"
                semibold
                negative={
                  !isLightTheme || variant === 'secondary' ? true : false
                }
              >
                {label}
              </Label>
            )}

            {helpLabel && (
              <>
                <button
                  type="button"
                  onClick={() => setShowHelpLabel(!showHelpLabel)}
                  ref={helpLabelRef}
                  data-test="help-button"
                >
                  <Icon
                    iconName="Info"
                    size="14"
                    color="greys"
                    intensity={500}
                  />
                </button>
                <Transition
                  items={showHelpLabel}
                  from={{
                    opacity: 0,
                    transform: 'translate3d(0%, 20px, 0px)',
                  }}
                  enter={{
                    opacity: 1,
                    transform: 'translate3d(0%, 0%, 0px)',
                  }}
                  leave={{
                    opacity: 0,
                    transform: 'translate3d(0%, 20px, 0px)',
                  }}
                >
                  {(styles, showHelpLabel) =>
                    showHelpLabel && (
                      <Portal
                        actionRef={helpLabelRef}
                        show
                        widthAuto
                        overlay
                        onClickOutside={closeHelpLabel}
                      >
                        <animated.div style={{ ...styles }}>
                          <HelpLabelStyles size="12" data-test="help-label">
                            {helpLabel}
                          </HelpLabelStyles>
                        </animated.div>
                      </Portal>
                    )
                  }
                </Transition>
              </>
            )}
          </FlexContainer>
        )}

        <TextAreaStyles
          disabled={disabled}
          onClick={() => onClick?.()}
          ref={divRef}
          alignItems="flex-start"
          data-test="TextArea-container"
          columnGap="0.6"
          size={size}
          rows={rows}
          variant={
            !isLightTheme && variant === 'primary' ? 'secondary' : variant
          }
        >
          {!iconLeft && elementLeft}
          {iconLeft && (
            <InputIcon
              iconName={iconLeft}
              size={24}
              color="greys"
              intensity={disabled ? 200 : 400}
            />
          )}

          <textarea
            autoFocus={autoFocus}
            name={name}
            ref={ref}
            disabled={disabled}
            onChange={onChangeHandle}
            onBlur={onBlur}
            placeholder={placeholder}
            data-test={testId}
            readOnly={readOnly}
            onClick={() => onClick?.()}
            autoComplete={autoComplete}
            rows={rows}
          >
            {value}
          </textarea>

          {iconRight ? (
            <>
              {onClickIconRight ? (
                <ButtonIcon
                  iconName={iconRight}
                  disabled={disabled}
                  text
                  size="S"
                  onClick={onClickIconRight}
                />
              ) : (
                <Icon iconName={iconRight} size={24} />
              )}
            </>
          ) : (
            value &&
            onRemoveValue && (
              <ButtonIcon
                iconName="X"
                onClick={onRemoveValue}
                text
                size="S"
                testId="textarea-remove-value"
              />
            )
          )}
        </TextAreaStyles>

        {children}
        {errorLabel && <ErrorLabel size="10">{errorLabel}</ErrorLabel>}
      </InputContainer>
    );
  }
);
