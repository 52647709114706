import React from 'react';
import { IconCustomProps } from '../model';

export const DocumentRecognition = ({
  className,
  size,
  color,
}: IconCustomProps) => {
  return (
    <svg
      id="icon-document-recognition-2"
      viewBox="0 0 32 32"
      className={className}
      width={size ? (size as number) : undefined}
      height={size ? (size as number) : undefined}
    >
      <path
        fill={color}
        d="M26.263 12.584c0.472 0 0.855 0.383 0.855 0.855 0 0.42-0.303 0.769-0.702 0.841l-0.154 0.014h-6.415c-0.472 0-0.855-0.383-0.855-0.855 0-0.42 0.303-0.769 0.702-0.841l0.154-0.014h6.415z"
      ></path>
      <path
        fill={color}
        d="M10.243 10.013c2.598 0 4.704 2.106 4.704 4.704 0 1.323-0.546 2.519-1.426 3.374l2.144 2.143c0.334 0.334 0.334 0.876 0 1.21-0.297 0.297-0.758 0.33-1.091 0.099l-0.118-0.099-2.389-2.389c-0.561 0.236-1.177 0.366-1.823 0.366-2.598 0-4.704-2.106-4.704-4.704s2.106-4.704 4.704-4.704zM10.243 11.724c-1.653 0-2.993 1.34-2.993 2.993s1.34 2.993 2.993 2.993c1.653 0 2.993-1.34 2.993-2.993s-1.34-2.993-2.993-2.993z"
      ></path>
      <path
        fill={color}
        d="M26.263 17.716c0.472 0 0.855 0.383 0.855 0.855 0 0.42-0.303 0.769-0.702 0.841l-0.154 0.014h-6.415c-0.472 0-0.855-0.383-0.855-0.855 0-0.42 0.303-0.769 0.702-0.841l0.154-0.014h6.415z"
      ></path>
      <path
        fill={color}
        d="M29.684 2.316h-27.369c-1.417 0-2.566 1.149-2.566 2.566v22.237c0 1.417 1.149 2.566 2.566 2.566h27.369c1.417 0 2.566-1.149 2.566-2.566v-22.237c0-1.417-1.149-2.566-2.566-2.566zM2.316 4.026h27.369c0.472 0 0.855 0.383 0.855 0.855v22.237c0 0.472-0.383 0.855-0.855 0.855h-27.369c-0.472 0-0.855-0.383-0.855-0.855v-22.237c0-0.472 0.383-0.855 0.855-0.855z"
      ></path>
    </svg>
  );
};
