import { Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { EmptyFilesUpload } from './EmptyFilesUpload';
import { NoFilesUploadedStyles } from './Styles';

type Props = {
  messageType?: 'information' | 'files';
};

export const NoFilesUploaded = ({ messageType = 'files' }: Props) => {
  const { t } = useTranslation();

  return (
    <NoFilesUploadedStyles
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowGap="1.2"
    >
      <EmptyFilesUpload />
      <Label size="14" semibold>
        {t(
          messageType === 'files'
            ? 'There aren’t uploaded files'
            : 'There is not information'
        )}
      </Label>
      <Label size="12">{t('Please, retry the operation.')}</Label>
    </NoFilesUploadedStyles>
  );
};
