import styled from 'styled-components';
import { ToastStyleProps, ToastType } from './model';
import { FlexContainer } from '../flexContainer';

export const ToastStyle = styled(FlexContainer)<ToastStyleProps>`
  background-color: ${(props) =>
    props.$type === ToastType.ERROR
      ? props.theme.color.error[400]
      : props.$type === ToastType.SUCCESS
      ? props.theme.color.success[400]
      : props.$type === ToastType.WARNING
      ? props.theme.color.warning[400]
      : props.theme.color.greys['white']};
  min-height: 8.8rem;
  padding: 1.6rem 2.4rem;
  border-radius: 0.8rem;
  box-shadow: 0px 2px 14px ${(props) => props.theme.color.greys[100]};

  svg {
    min-width: 1.8rem;
  }
`;
