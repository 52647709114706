import {
  CardBase,
  CardCollapsable,
  FlexContainer,
} from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const PermissionsWrapper = styled(FlexContainer)`
  flex: 1;
`;

export const PermissionsPanel = styled(FlexContainer)`
  flex: 1;
  width: 100%;
  border-radius: 0.8rem;
  overflow: hidden;
  background-color: ${(props) => props.theme.color.greys.white};

  > * {
    box-shadow: none;
    border-radius: 0;
  }
`;

export const PermissionApplicationStyles = styled(CardBase)`
  flex: 1.5;
  min-width: 33rem;
  background-color: ${(props) => props.theme.color.greys[100]};
`;

export const PermissionUserStyles = styled(CardBase)`
  position: relative;
  flex: 1;
  min-width: 31rem;
  background-color: ${(props) => props.theme.color.greys[100]};
`;

export const PermissionsEmptyBoxStyle = styled(FlexContainer)`
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  border-radius: 0.8rem;
  border-width: 1px;
  border-style: dashed;
  border-color: ${(props) => props.theme.color.greys[500]};
  color: ${(props) => props.theme.color.greys[500]};
`;

export const PermissionAddedStyles = styled(CardBase)`
  flex: 2;
`;

export const PermissionApplicationContent = styled(FlexContainer)`
  padding: 2.4rem 1.6rem;
  flex: 1 1 0;
  overflow-y: auto;
`;

export const AddUserGroupPanelStyles = styled(CardCollapsable)<{
  openMenu: boolean;
}>`
  border-radius: 0.8rem;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.color.greys[100]};
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;

  > div {
    > button {
      border: 1px solid ${(props) => props.theme.color.greys[100]};
      background-color: ${(props) =>
        props.openMenu
          ? props.theme.color.greys.white
          : props.theme.color.blue[200]};
      transition: all 0.25s ease-in-out;
    }
    max-height: inherit;
  }

  .collapsable-list:not(:empty) {
    padding: 0;
    border: 1px solid ${(props) => props.theme.color.greys[100]};
    border-top: 0;
    border-bottom: none;
  }
`;

export const PermissionApplicationCollapsable = styled(CardCollapsable)`
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};

  .collapsable-list:not(:empty) {
    border: none;
    border-top: 1px solid ${(props) => props.theme.color.greys[100]};
    padding: 2.1rem 1.6rem;
  }
`;

export const AddUserGroupPanelContent = styled(FlexContainer)`
  padding: 1.6rem 1.6rem 4.8rem;
  overflow-y: auto;
`;

export const AddUserGroupPanelList = styled(FlexContainer)`
  > * {
    margin-top: 2rem;
  }
  > [aria-hidden='true'] {
    margin-top: 0;
    height: 0;
    min-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: min-height, height, opacity, margin-top 0.25s ease-in-out;
    transition-delay: 0.25s;
  }
`;

export const PermissionPanelApplicationContent = styled(FlexContainer)`
  padding: 2.4rem;
  flex: 1 1 0;
  overflow-y: auto;
`;
