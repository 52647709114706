import { DropdownOption, FlexContainer, Modal } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { ModalDocumenAIDropdown, ModalDocumenAIUpload } from './Styles';
import { years } from '../antiFraud';
import Country from '../antiFraud/country.json';

type Props = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  className?: string;
  onSave?(): void;
  onChangeYear?(): void;
};

const countryOptions: DropdownOption[] = Object.entries(Country).map(
  (item) => ({
    name: item[1].name,
    value: item[1].name,
  })
);

const documentOptions: DropdownOption[] = [
  {
    name: 'Passport',
    value: 'Passport',
  },
  {
    name: 'ID',
    value: 'ID',
  },
  {
    name: 'License card',
    value: 'License card',
  },
];

export const ModalDocumentAI = ({
  show,
  onChangeShow,
  className,
  onSave,
  onChangeYear,
}: Props) => {
  const { t } = useTranslation();

  const yearOptions = years.map((year) => ({
    name: year,
    value: year,
  }));

  return (
    <Modal
      className={className}
      show={show}
      testId="modal-device"
      onChangeShow={onChangeShow}
      iconHeader="PlusCircle"
      title={t('Add document')}
      closeLabel={t('Cancel')}
      submitLabel={t('Save')}
      onCreate={onSave}
      overlay
      columnGapContent="8.9"
      flexDirectionContent="row"
      alignItemsContent="center"
    >
      <ModalDocumenAIUpload
        secondary
        labelDrop={t('Upload your document here')}
      />
      <FlexContainer flexDirection="column" rowGap="4.4">
        <ModalDocumenAIDropdown
          options={documentOptions}
          label={t('Document Type')}
          overlay
        />
        <ModalDocumenAIDropdown
          options={countryOptions}
          label={t('Country')}
          overlay
        />
        <ModalDocumenAIDropdown
          testId="document-input-year"
          onChange={onChangeYear}
          options={yearOptions}
          label={t('Year')}
        />
      </FlexContainer>
    </Modal>
  );
};
