import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  IPosition,
  IStyles,
  ITransformStyles,
  PortalProps,
  PositionX,
  PositionY,
} from './model';
import { PortalOverlay } from './PortalOverlay';
import { PortalContainer, PortalContent } from './Styles';

const createElement = () => {
  let popupRoot: HTMLElement | null = document.getElementById('popup-root');

  if (!popupRoot) {
    const div = document.createElement('div');
    div.id = 'popup-root';
    document.body.appendChild(div);

    popupRoot = document.getElementById('popup-root');
  }

  return popupRoot;
};

export const Portal = ({
  children,
  className,
  overlayBlur = false,
  show = false,
  actionRef = {
    current: null,
  },
  widthAuto = false,
  positionY = PositionY.bottom,
  positionX = PositionX.start,
  onClickOutside,
  testId,
  overlay = false,
  isMobile,
}: PortalProps) => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const onClickOutsideRef = useRef<typeof onClickOutside>(onClickOutside);
  const [position, setPosition] = useState<IPosition>({
    top: 0,
    left: 0,
    right: 0,
    width: 0,
    height: 0,
  });
  const [positionStyles, setPositionStyles] = useState<IStyles>();
  const [transformStyles, setTransformStyles] = useState<ITransformStyles>();
  const timeoutRef = useRef<number | null>();
  const actionWidth = actionRef?.current?.clientWidth || 0;
  const visualViewport = window.visualViewport;

  const updatePosition = () => {
    if (actionRef?.current) {
      const { left, top, right, height } =
        actionRef.current.getBoundingClientRect();

      setPosition({
        top:
          visualViewport?.scale !== 1
            ? top + (visualViewport?.offsetTop || 0)
            : top,
        left:
          visualViewport?.scale !== 1
            ? left + (visualViewport?.offsetLeft || 0)
            : left,
        right: right,
        width: widthAuto ? 'auto' : actionRef.current.clientWidth,
        height,
      });
    }
  };

  const styles: IStyles = {
    top: position.top,
    right: undefined,
    width: actionRef?.current ? (widthAuto ? 'auto' : position.width) : '100%',
    bottom: undefined,
  };

  const stylesMobile: IStyles = {
    width: '100%',
    height: '100%',
    top: '0%',
    right: 0,
  };

  const transformSetStyle: ITransformStyles = {
    transform: undefined,
  };

  const computedPositions = {
    top: position.top + position.height - 1,
    bottom: `calc(100% - ${position.top}px)`,
    transform: `translateX(calc(-${actionWidth / 2}px))`,
  };

  const mapPositionXStyles = {
    left: () => {
      styles.left = position.left;
      transformSetStyle.transform = 'translateX(-100%)';
    },
    right: () => {
      styles.left = position.right;
      transformSetStyle.transform = 'translateX(0)';
    },
    center: () => {
      styles.left = position.left;
      transformSetStyle.transform = computedPositions.transform;
    },
    start: () => {
      styles.left = position.left;
    },
    end: () => {
      styles.right = window.innerWidth - position.right;
    },
  };

  const assignPositionStyles = {
    top: (positionX: PositionX) => {
      styles.top = 'initial';
      styles.bottom = computedPositions.bottom;
      mapPositionXStyles[positionX]();
    },
    bottom: (positionX: PositionX) => {
      styles.top = computedPositions.top;
      mapPositionXStyles[positionX]();
    },
    center: () => {
      styles.top = position.top - position.height;
      styles.left = position.left;
    },
  };

  const checkSizeConstraints = (popupHeight: number, popupWidth: number) => {
    const hasSpaceBelow =
      position.top + popupHeight + position.height < window.innerHeight;
    const hasSpaceAbove = position.top > popupHeight;
    const hasSpaceLeft = popupWidth < position.left;
    const hasSpaceRight = popupWidth < window.innerWidth - position.right;

    if (!hasSpaceBelow && positionY === PositionY.bottom) {
      positionY = PositionY.top;
    } else if (!hasSpaceAbove && positionY === PositionY.top) {
      positionY = PositionY.bottom;
    }

    if (!hasSpaceLeft && positionX === PositionX.left) {
      positionX = PositionX.right;
    } else if (!hasSpaceRight && positionX === PositionX.right) {
      positionX = PositionX.left;
    }
  };

  useEffect(() => {
    onClickOutsideRef.current = onClickOutside;
    updatePosition();
  }, [onClickOutside]);

  useEffect(() => {
    const popupRoot = createElement();
    setElement(popupRoot);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (
        popupRef?.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        !overlay && onClickOutsideRef.current?.(event);
      }
    };

    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      if (show) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [show]);

  useEffect(() => {
    const handleScroll = () => {
      if (timeoutRef.current) {
        window.cancelAnimationFrame(timeoutRef.current);
        timeoutRef.current = null;
      }

      timeoutRef.current = window.requestAnimationFrame(() => {
        updatePosition();
      });
    };

    const handleWindowResize = () => {
      updatePosition();
    };

    if (show && actionRef) {
      updatePosition();
      visualViewport?.addEventListener('resize', handleWindowResize);
      visualViewport?.addEventListener('scroll', handleScroll, true);
      document.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      if (show && actionRef) {
        visualViewport?.removeEventListener('resize', handleWindowResize);
        visualViewport?.removeEventListener('scroll', handleScroll, true);
        document.removeEventListener('scroll', handleScroll, true);
      }
    };
  }, [show, actionRef]);

  useEffect(() => {
    if (!show) {
      return;
    }

    if (actionRef?.current && popupRef.current) {
      const { height, width } = popupRef.current.getBoundingClientRect();
      checkSizeConstraints(height, width);
    }

    assignPositionStyles[positionY](positionX);

    setPositionStyles(isMobile ? stylesMobile : styles);
    setTransformStyles(transformSetStyle);
  }, [position, show, widthAuto]);

  return element
    ? createPortal(
        <>
          {show && (
            <PortalContainer
              ref={popupRef}
              data-test={testId}
              className={className}
              style={{ ...positionStyles }}
            >
              {(overlay || overlayBlur) && (
                <PortalOverlay
                  testId={testId}
                  onClickOutside={onClickOutside}
                  overlayBlur={overlayBlur}
                />
              )}
              {isMobile ? (
                children
              ) : (
                <PortalContent style={{ ...transformStyles }}>
                  {children}
                </PortalContent>
              )}
            </PortalContainer>
          )}
        </>,
        element
      )
    : null;
};
