import React from 'react';
import { TableHeaderProps } from './model';
import { TableHeaderStyles } from './Styles';
import { Button } from '../button';
import { Dropdown } from '../dropdown';
import { FlexContainer } from '../flexContainer';
import { Label } from '../label';

export const TableHeader = ({
  leftChildren,
  rightChildren,
  actionsOnSelect,
  onClickAction,
  itemsSelected,
  hasPadding = true,
  className,
  testId,
  iconButton,
}: TableHeaderProps) => {
  const renderActions = () => {
    return actionsOnSelect && itemsSelected ? (
      <FlexContainer alignItems="center" columnGap="0.8">
        {actionsOnSelect?.length > 1 ? (
          <>
            <Label size="14" semibold color="primary">
              {itemsSelected} elements selected
            </Label>
            <Dropdown
              placeholder="Select an action"
              options={actionsOnSelect}
              testId="table-header-dropdown"
            />
          </>
        ) : (
          <Button
            variant="text"
            color="primary"
            onClick={onClickAction}
            iconName={iconButton}
            testId="table-header-button"
          >
            {itemsSelected} elements selected
          </Button>
        )}
      </FlexContainer>
    ) : null;
  };

  return (
    <TableHeaderStyles
      alignItems="center"
      justifyContent="space-between"
      hasPadding={hasPadding}
      as="section"
      data-test={testId}
      className={className}
    >
      {/* LEFT */}
      <FlexContainer alignItems="center" columnGap="1.6">
        {leftChildren && leftChildren}
      </FlexContainer>
      {/* RIGHT WITH ACTIONS */}
      {actionsOnSelect && !rightChildren && renderActions()}
      {actionsOnSelect && rightChildren && (
        <FlexContainer alignItems="center" columnGap="1.6">
          {renderActions()}
          {rightChildren}
        </FlexContainer>
      )}
      {/* RIGHT */}
      {!actionsOnSelect && rightChildren && (
        <FlexContainer alignItems="center" columnGap="1.6">
          {rightChildren}
        </FlexContainer>
      )}
    </TableHeaderStyles>
  );
};
