export enum RoutesName {
  ux = '/ux',
  uxTask = '/ux/task',
  uxAnalytic = '/ux/analytic',
}

export enum UXDevices {
  desktop = 'desktop',
  mobile = 'mobile',
}

export enum UXDevicesIcons {
  desktop = 'Desktop',
  mobile = 'DeviceMobile',
}
