import { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  ButtonIcon,
  TableSelection,
  useTimezone,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  useToast,
  ToastType,
  TData,
} from '@facephi/inphinite-ui';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CellProps, Column } from 'react-table';
import { HeaderRules, ModalIP } from '.';
import { useDelete, usePagination } from '../hooks';
import { MAX_ITEMS_PAGE } from '../state/constants';
import { checkEmptyState } from '../state/emptyState';
import { IPsDto, DataIpModal } from '../state/model';
import { insertIP, updateIP, removeIP, undoRemoveIP } from '../state/mutations';
import { searchIPs } from '../state/queries';

export type IpProps = {
  id: string;
  ip: string;
  description: string;
  update: string;
  action: string;
};

type listIPsDto = {
  searchIPs: {
    ips: IPsDto[];
    count: number;
  };
};

export const RulesIP = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { formatTimezone } = useTimezone();
  const [dateFilter, setDateFilter] = useState<Date | undefined>();
  const [createIP] = useMutation(insertIP);
  const [editIP] = useMutation(updateIP);
  const { toastManager } = useToast();
  const [editableIP, setEditableIP] = useState<DataIpModal | undefined>();
  const [itemsDelete, setItemsDelete] = useState<string[]>([]);

  const getDateRange = () => {
    return dateFilter
      ? {
          _gte: dateFilter,
          _lt: new Date(
            new Date(dateFilter).setDate(dateFilter.getDate() + 1)
          ).toISOString(),
        }
      : {};
  };

  const { data, loading, fetchMore, error, refetch } = useQuery<listIPsDto>(
    searchIPs,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: MAX_ITEMS_PAGE,
        offset: 0,
        updatedAt: getDateRange(),
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  );

  const IpsData = useMemo(() => (data ? data.searchIPs.ips : []), [data]);
  const total = useMemo(() => (data ? data.searchIPs.count : 0), [data]);

  const { handlePagination, hasMore } = usePagination(
    IpsData,
    loading,
    fetchMore,
    total
  );

  const { handleDelete } = useDelete(removeIP, undoRemoveIP, 'IpDto', refetch);

  const columns: TData[] = useMemo(
    () => [
      {
        Header: t('IP'),
        accessor: 'ip',
        maxWidth: 200,
      },
      {
        Header: t('Update'),
        accessor: 'update',
        maxWidth: 130,
      },
      {
        Header: t('Description'),
        accessor: 'description',
      },

      {
        Header: t('Actions'),
        accessor: 'action',
        maxWidth: 150,
        Cell: (props: CellProps<IpProps>) => {
          return (
            <OptionMenu
              hidePanelOnClick
              renderItem={<ButtonIcon text iconName="ListPlus" />}
              positionX={PositionX.end}
              testId="option-menu-user"
            >
              <OptionMenuItem
                testId="button-edit-IP"
                onClick={() => {
                  onEditHandle(props.row.original);
                }}
                iconName="Pencil"
              >
                {t('Edit')}
              </OptionMenuItem>
              <OptionMenuItem
                testId="button-delete-IP"
                onClick={() => {
                  handleDelete([props.row.original.id]);
                }}
                iconName="Trash"
                color="error"
              >
                {t('Delete')}
              </OptionMenuItem>
            </OptionMenu>
          );
        },
      },
    ],
    []
  );

  const onCreate = async (ip: DataIpModal) => {
    await createIP({
      variables: {
        ip: ip,
      },
      update: (cache, { data: { createIP: newIP } }) => {
        console.log(data);
        console.log(newIP);

        if (newIP && data) {
          const queryData = cloneDeep(data);

          queryData.searchIPs.ips.unshift(newIP);
          queryData.searchIPs.count = queryData.searchIPs.count + 1;

          cache.writeQuery({
            query: searchIPs,
            variables: {
              limit: MAX_ITEMS_PAGE,
              offset: 0,
              updatedAt: getDateRange(),
            },
            data: queryData,
          });
        }
      },
      onCompleted: () => {
        toastManager({
          $type: ToastType.SUCCESS,
          message: t('IP created successfully'),
          duration: 3000,
          testId: 'toast-create-IP',
        });
      },
    });

    setShowModal(false);
  };

  const onEdit = async (data: DataIpModal) => {
    await editIP({
      variables: {
        id: data.id,
        ip: data,
      },
      onCompleted: () => {
        toastManager({
          $type: ToastType.SUCCESS,
          message: t('IP updated successfully'),
          duration: 3000,
          testId: 'toast-update-IP',
        });
      },
    });

    setShowModal(false);
  };

  const tableData = useMemo(() => {
    return (
      data?.searchIPs.ips.map((IP: IPsDto) => ({
        id: IP.id,
        update: formatTimezone(IP.updated_at),
        ip: IP.ip,
        description: IP.description,
      })) || []
    );
  }, [data]);

  const setItemsDeleteState = (items: IPsDto[]) => {
    setItemsDelete(items.map((item) => item.id));
  };

  const onEditHandle = (ip: DataIpModal) => {
    setShowModal(true);
    setEditableIP(ip);
  };

  useEffect(() => {
    if (!showModal) {
      setEditableIP(undefined);
    }
  }, [showModal]);

  return (
    <>
      <HeaderRules
        onAddNew={() => setShowModal(true)}
        onChange={(date: Date) => setDateFilter(date)}
        itemsDelete={itemsDelete.length}
        deleteItemsSelected={() => handleDelete(itemsDelete)}
      />
      <TableSelection
        columns={columns as Column<TData>[]}
        data={tableData}
        loading={loading}
        fetchMore={handlePagination}
        onSelectedRowsChange={setItemsDeleteState}
        hasMore={hasMore}
        emptyState={checkEmptyState(
          dateFilter ? dateFilter.toDateString() : '',
          loading,
          error,
          total
        )}
      />
      <ModalIP
        show={showModal}
        onSave={editableIP ? onEdit : onCreate}
        onChangeShow={(showModalIP) => setShowModal(showModalIP)}
        data={editableIP}
      />
    </>
  );
};
