import { Label } from '@facephi/inphinite-ui';
import Skeleton from 'react-loading-skeleton';
import { CardDashboard } from '../CardDashboard';
import { CardKpiProps } from './model';
import {
  CardCount,
  CardIcon,
  CardKpiContent,
  CardKpiIconContent,
  CardKpiWrapper,
} from './Styles';

export const CardKpi = ({
  iconName,
  title,
  count,
  subtitle,
  color,
  viewAllLink,
  viewAllText,
  testId,
  loading,
}: CardKpiProps) => {
  const getLoadingComponent = () => (
    <CardKpiWrapper flex="1">
      <CardKpiIconContent>
        <Skeleton height={70} width={70} borderRadius={8} />
      </CardKpiIconContent>
      <CardKpiContent data-test={testId}>
        <Skeleton height={33} width="40%" borderRadius={14} />
        <Skeleton height={16} width="80%" borderRadius={14} />
        <Skeleton height={12} width="80%" borderRadius={14} />
      </CardKpiContent>
    </CardKpiWrapper>
  );

  const getComponent = () => (
    <CardKpiWrapper flex="1">
      <CardKpiIconContent>
        <CardIcon
          $color={color}
          iconName={iconName}
          size="XL"
          backgroundColor="greys"
          backgroundIntensity={400}
        />
      </CardKpiIconContent>
      <CardKpiContent data-test={testId}>
        <CardCount size="32" as="h3" semibold $color={color}>
          {count}
        </CardCount>
        <Label size="16" as="h2" semibold>
          {title}
        </Label>
        <Label size="12">{subtitle}</Label>
      </CardKpiContent>
    </CardKpiWrapper>
  );

  return (
    <CardDashboard
      viewAllLink={viewAllLink}
      viewAllText={viewAllText}
      loading={loading}
    >
      {loading ? getLoadingComponent() : getComponent()}
    </CardDashboard>
  );
};
