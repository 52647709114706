import { useMutation } from '@apollo/client';
import { ToastType, useToast } from '@facephi/inphinite-ui';
import { DocumentNode } from 'graphql';
import { useTranslation } from 'react-i18next';

export function useDelete(
  deleteMutation: DocumentNode,
  undoDeleteMutation: DocumentNode,
  typename: 'IpDto' | 'FaceDto',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch?: any
) {
  const { toastManager } = useToast();
  const { t } = useTranslation();

  const [deleteElement] = useMutation(deleteMutation);
  const [undoDeleteElement] = useMutation(undoDeleteMutation);

  const handleUndoDelete = async (ids: Array<string>) => {
    await undoDeleteElement({
      variables: { ids: ids },
      onCompleted: () => {
        refetch && refetch();
        toastManager({
          $type: ToastType.SUCCESS,
          message: t(`${typename} revert`),
          testId: `toast-revert-delete-${typename}`,
          duration: 3000,
        });
      },
      onError: () => {
        toastManager({
          $type: ToastType.ERROR,
          message: t('Something went wrong, try again'),
          testId: `toast-revert-delete-${typename}`,
        });
      },
    });
  };

  const handleDelete = async (ids: Array<string>) => {
    await deleteElement({
      variables: { ids: ids },
      update: (cache) => {
        ids.forEach((id) => {
          cache.evict({
            id: cache.identify({
              id: id,
              __typename: typename,
            }),
          });
        });
        cache.gc();
      },
      onCompleted: () => {
        toastManager({
          $type: ToastType.ERROR,
          message: t(`${typename} deleted`),
          testId: `toast-delete-${typename}`,
          iconName: 'Trash',
          buttonMessage: t('Undo'),
          onClick: () => handleUndoDelete(ids),
          duration: 3000,
        });
      },
      onError: () => {
        toastManager({
          $type: ToastType.ERROR,
          message: t('Something went wrong, try again'),
          testId: `toast-delete-${typename}`,
        });
      },
    });
  };

  return { handleDelete, handleUndoDelete };
}
