import styled from 'styled-components';
import { ChipsStyleProps } from './model';
import { IThemeProps } from '../../themes';
import { FlexContainer } from '../flexContainer';
import { getSizeLabel } from '../label';

const selectedStyles = (theme: IThemeProps) => `
  background-color: ${theme.color.primary[100]};
  color: ${theme.color.primary[400]};
`;

export const ChipsStyles = styled(FlexContainer)<ChipsStyleProps>`
  background-color: ${(props) => props.theme.color.greys[100]};
  height: 4rem;
  padding: 0 1.6rem;
  border-radius: 0.8rem;
  width: fit-content;
  ${getSizeLabel('14')};
  color: ${(props) => props.theme.color.greys[700]};

  &:hover&:not(:disabled) {
    background-color: ${(props) => props.theme.color.greys[200]};
  }

  &:focus&:not(:disabled),
  &:active&:not(:disabled) {
    background-color: ${(props) => props.theme.color.greys[200]};
    box-shadow: inset 0 0 0 0.2rem ${(props) => props.theme.color.greys[600]};
  }

  ${(props) =>
    props.isSelected &&
    `
    ${selectedStyles(props.theme)};
    font-weight: 600;
    &:hover&:not(:disabled),
    &:focus&:not(:disabled),
    &:active&:not(:disabled) {
      ${selectedStyles(props.theme)};
    }
  `}

  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.color.greys[300]};

    img {
      filter: grayscale(100%);
    }
  }

  img {
    min-height: 2.4rem;
    height: 2.4rem;
    aspect-ratio: 1/1;
    border-radius: 100%;
  }
`;
