import { PropsWithChildren, useMemo } from 'react';
import { VerificationStatusValues } from '@facephi/inphinite-common';
import { Label } from '@facephi/inphinite-ui';
import { Property } from 'csstype';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { REFUSAL_REASON } from '../../../state/constants';
import { OperationCardElementStyles } from './Styles';

type Props = {
  data?: Record<string, string | boolean | undefined>;
  anonymize?: (text: string) => string;
  loading?: boolean;
};

const SkeletonWrapper = ({
  children,
  textAlign,
}: PropsWithChildren<unknown> & { textAlign?: Property.TextAlign }) => (
  <div style={{ width: '100%', textAlign }}>{children}</div>
);

export const OperationCardElement = ({ data, anonymize, loading }: Props) => {
  const { t } = useTranslation();

  const showValue = (value: string | boolean) => {
    if (typeof value === 'boolean') {
      return value ? 'OK' : 'KO';
    }

    const valueCopy = value.replaceAll('$', '').replaceAll('*', '');
    return anonymize ? anonymize(valueCopy) : t(valueCopy);
  };

  const elements = useMemo(() => {
    if (!loading) {
      return data
        ? Object.entries(data).filter(
            ([key, value]) => key !== '__typename' && value
          )
        : [];
    }
    return Array.from(
      { length: 8 },
      (_, i) => [i.toString(), i.toString()] as [string, string | boolean]
    );
  }, [data, loading]);

  const defineColor = (key: string, value: unknown) => {
    if (key === REFUSAL_REASON) return 'error';

    switch (value) {
      case VerificationStatusValues.verified:
      case VerificationStatusValues.done:
      case (value as string).includes('$'):
        return 'success';
      case VerificationStatusValues.error:
      case (value as string).includes('*'):
        return 'error';
      default:
        return undefined;
    }
  };

  return (
    <>
      {elements.map(([key, value]: [string, string | boolean | undefined]) => (
        <OperationCardElementStyles
          as="li"
          justifyContent="space-between"
          key={key}
          columnGap="1.2"
        >
          {loading ? (
            <>
              <SkeletonWrapper>
                <Skeleton height={18} width="50%" borderRadius={8} />
              </SkeletonWrapper>
              <SkeletonWrapper textAlign="right">
                <Skeleton height={18} width="60%" borderRadius={8} />
              </SkeletonWrapper>
            </>
          ) : (
            <>
              <Label size="14">{t(key)}</Label>
              {typeof value === 'string' || typeof value === 'boolean' ? (
                <Label
                  size="14"
                  semibold
                  textAlign="end"
                  color={defineColor(key, value)}
                >
                  {showValue(value)}
                </Label>
              ) : (
                value
              )}
            </>
          )}
        </OperationCardElementStyles>
      ))}
    </>
  );
};
