import styled from 'styled-components';
import { TabsStyleProps } from './model';
import { FlexContainer } from '../flexContainer';
import { Label } from '../label';

export const TabStytles = styled(FlexContainer)`
  height: 100%;
`;

export const TabHeader = styled(FlexContainer)``;

export const TabWrapper = styled(FlexContainer)``;

export const TabBar = styled.span``;

export const TabsStyle = styled(Label)<TabsStyleProps>`
  padding: 0 2.4rem;
  padding-bottom: 0.8rem;
  position: relative;
  min-width: 10rem;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  ${(props) => props.disabled && `cursor: default;`}

  &:before {
    content: '';
    position: absolute;
    height: 0.6rem;
    width: 8.5rem;
    left: 50%;
    background-color: ${(props) => props.theme.color.yellow[400]};
    bottom: -0.1rem;
    transform: translateX(-50%)
      ${(props) => (props.active ? `translateY(0)` : `translateY(100%)`)};
    border-radius: 0.8rem 0.8rem 0 0;
    transition: transform 0.25s ease-in-out;
  }
  &::first-letter {
    text-transform: uppercase;
  }

  ${(props) =>
    props.hasError &&
    `
    &:after {
      content: '';
      position: absolute;
      height: 1.2rem;
      width: 1.2rem;
      left: 0.6rem;
      top: 0.8rem;
      background-color: ${props.theme.color.error[400]};
      border-radius: 100%;
    }
  `}

  ${(props) => props.textHighlight && `color: ${props.theme.color.error[400]};`}
`;

export const TabPanelContent = styled.div<{
  noPadding?: boolean;
  noBackground?: boolean;
}>`
  flex-grow: 1;
  background-color: ${(props) =>
    !props.noBackground && props.theme.color.greys.white};
  border-radius: 0.8rem;
  ${(props) => !props.noPadding && `padding: 2.4rem 4.8rem;`}
  z-index: 1;
`;

export const TabPanelStyles = styled.div`
  height: 100%;
`;

export const TabHeaderActionsStyles = styled(FlexContainer)`
  height: 100%;
  padding-right: 2.4rem;
`;
