import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { SwitchControllerProps } from './model';
import { Switch } from './Switch';

export const SwitchController = <T extends FieldValues>({
  name,
  control,
  ...rest
}: SwitchControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render={({ field: { value, ...field } }: any) => (
      <Switch {...field} {...rest} checked={value} />
    )}
  />
);
