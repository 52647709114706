import { ASIDENAV_WIDTH } from '@facephi/inphinite-common';
import {
  FlexContainer,
  IThemeProps,
  Label,
  LottieAnimation,
} from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const getLayoutBackground = (theme: IThemeProps) =>
  `${theme.color.greys[50]}`;

export const LayoutStyles = styled(FlexContainer)`
  background-color: ${(props) => getLayoutBackground(props.theme)};
  flex: 1;
  min-width: 1280px;
  overflow-x: auto;
`;

export const LayoutWrapper = styled(FlexContainer)`
  flex: 1;
  margin-left: ${ASIDENAV_WIDTH};
  padding: 0 2.4rem;
`;

export const LayoutHeader = styled(FlexContainer)`
  padding-top: 3.6rem;
`;

export const LayoutContent = styled(FlexContainer)`
  flex: 1;
  padding-top: 3.2rem;
  padding-bottom: 3.6rem;
`;

export const LayoutError = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0;

  a {
    margin-top: 4.2rem;
  }
`;

export const LayoutErrorContent = styled(FlexContainer)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 5.2rem 0;

  > img {
    width: 20rem;
    height: 7rem;
    object-fit: cover;
  }
`;

export const LayoutErrorTitle = styled(Label)`
  margin: 2.1rem 0 2.4rem;
`;

export const ErrorStatusLabel = styled(Label)`
  font-size: 8.1rem;
  line-height: 11.3rem;
  color: ${(props) => props.theme.color.primary[400]};
  margin-top: 5rem;
`;

export const LayoutErrorAnimation = styled(LottieAnimation)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
  overflow: hidden;
`;
