/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { MAX_ITEMS_PAGE } from '../state/constants';

export type IPaginationProps = {
  hasMore?: boolean;
  scrollPosition?: number;
  fetchMore?(): Promise<boolean>;
};

export function usePagination(
  data: Array<any>,
  loading: boolean,
  fetchMore: any,
  total: number
) {
  const [scrollPosition, setScrollPosition] = useState<number>();
  const [offset, setOffset] = useState<number>(MAX_ITEMS_PAGE);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (data) {
      if (data.length > total) {
        setHasMore(false);
      }
    }
  }, [data]);

  const handlePagination = async (): Promise<boolean> => {
    if (hasMore && !loading) {
      await fetchMore({
        variables: {
          offset,
        },
      });

      const newOffset = offset + MAX_ITEMS_PAGE;

      if (newOffset >= total) {
        setHasMore(false);
      }

      setOffset((offset) => offset + MAX_ITEMS_PAGE);
    }

    return Promise.resolve(true);
  };

  return {
    scrollPosition,
    hasMore,
    handlePagination,
    setScrollPosition,
  };
}
