import { RangeModifier } from 'react-day-picker';
import { Control, Path, FieldValues } from 'react-hook-form';
import { InputBasicProps } from '../input/model';

export type InputDateProps = InputBasicProps & {
  value?: Date | RangeModifier;
  onChange?(value?: Date | RangeModifier): void;
  minDate?: Date;
  maxDate?: Date;
  isRange?: boolean;
  errorLabel?: string;
};

export type InputDateControllerProps<T extends FieldValues> = InputDateProps & {
  name: Path<T>;
  control: Control<T>;
};

export enum RangeType {
  from = 'from',
  to = 'to',
}
