import styled from 'styled-components';
import { UserTagStyleProps } from './model';
import { ButtonIcon } from '../buttonIcon';
import { FlexContainer } from '../flexContainer';

export const UserTagButton = styled(ButtonIcon)`
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
`;

export const UserTagStyles = styled(FlexContainer)<UserTagStyleProps>`
  height: 2.8rem;
  border-radius: 0.8rem;
  padding: 0.8rem;
  background-color: ${(props) => props.theme.color.greys.white};
  ${(props) =>
    !props.noBorder && `border: 1px solid ${props.theme.color.greys[100]};`}

  ${(props) =>
    props.selected && `background-color: ${props.theme.color.green[100]}`};

  img {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    object-fit: cover;
  }
  &:hover {
    ${UserTagButton} {
      opacity: 1;
    }
  }
`;
