import { FlexContainer, InputDatePicker } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const DashboardHeader = styled(FlexContainer)`
  margin-bottom: 2.4rem;
`;

export const DashboardDatePicker = styled(InputDatePicker)`
  width: 22rem;
`;
