import React from 'react';
import { PhoneProps } from './model';
import { Device } from './Styles';

export const Iphone8 = ({ children }: PhoneProps) => {
  return (
    <Device className="device iphone8 black">
      <div className="top-bar" />
      <div className="sleep" />
      <div className="volume" />
      <div className="camera" />
      <div className="sensor" />
      <div className="speaker" />
      <div className="screen">{children}</div>
      <div className="home" />
      <div className="bottom-bar" />
    </Device>
  );
};
