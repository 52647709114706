import { FileRejection } from 'react-dropzone';

export type ErrorLabelProps = {
  errorLabel?: string;
};

export type InputUploadStyleProps = {
  secondary?: boolean;
};

export type InputUploadProps = ErrorLabelProps &
  InputUploadStyleProps & {
    labelDrop?: string;
    id?: string;
    acceptFiles?: string[];
    maxSize?: number;
    onChange?(file: File | File[] | string): void;
    value?: string | Blob;
    valueName?: string | string[];
    fileName?: string;
    onDelete?(): void;
    testId?: string;
    className?: string;
    loading?: boolean;
    onErrors?(errorFiles: FileRejection[]): void;
    withUserLabel?: boolean;
    hasInfoMessage?: boolean;
    base64?: boolean;
    locale?: string;
  };

export enum TypeFiles {
  jpg = 'image/jpg',
  png = 'image/png',
  jpeg = 'image/jpeg',
  webp = 'image/webp',
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls = '.xls',
  ttf = '.ttf',
  otf = '.otf',
}
