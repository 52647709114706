import styled from 'styled-components';
import { Checkbox } from '../checkbox';
import { Label } from '../label';
import { OptionMenu } from '../optionMenu';

export const FilterMenuHeaderStyles = styled(Label)`
  background-color: ${(props) => props.theme.color.greys[100]};
  height: 3.2rem;
  display: flex;
  align-items: center;
`;

export const FilterMenuItemStyles = styled(Checkbox)`
  height: 3.2rem;
  border-bottom: 1px solid ${(props) => props.theme.color.greys[100]};
  width: 100%;
  align-items: center;
  cursor: pointer;

  label {
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const FilterMenuStyles = styled(OptionMenu)`
  padding: 0;
  width: 21.4rem;
  max-height: 39rem;
  overflow-y: auto;

  > * {
    padding: 0 1.6rem;
  }
`;
