export const EmptyFilesUploadError = () => (
  <svg width="161" height="136" viewBox="0 0 161 136">
    <path
      d="M29.4944 65.0933C31.1707 62.8817 33.7248 61.6138 36.5002 61.6138H45.6322C46.2769 57.3972 49.9286 54.155 54.3232 54.155H117.558L118.213 52.6131C119.595 49.3608 122.772 47.2606 126.304 47.2606H131.428C122.922 19.8818 97.3924 0 67.2225 0C30.0967 0 0 30.1034 0 67.2376C0 96.271 18.3988 121.006 44.1698 130.415L28.0349 72.7792C27.2861 70.1059 27.8195 67.305 29.4958 65.0933H29.4944Z"
      fill="#FCDAD9"
    />
    <path
      d="M156.604 60.0181H151.021C149.846 60.0181 148.783 59.316 148.324 58.2344L146.909 54.9087C146.45 53.827 145.388 53.125 144.212 53.125H126.303C125.129 53.125 124.066 53.827 123.606 54.9087L122.192 58.2344C121.732 59.316 120.671 60.0181 119.494 60.0181H54.3233C52.7041 60.0181 51.3926 61.3298 51.3926 62.9493V67.4768H36.5003C34.5602 67.4768 33.1549 69.3294 33.6781 71.1982L50.7933 132.333C51.1478 133.599 52.3011 134.474 53.6155 134.474H156.604C158.223 134.474 159.535 133.162 159.535 131.543V62.9493C159.535 61.3298 158.223 60.0181 156.604 60.0181Z"
      fill="white"
    />
    <path
      d="M156.604 58.5528H151.021C150.432 58.5528 149.904 58.2024 149.673 57.6602L148.258 54.3345C147.568 52.7091 145.98 51.6582 144.213 51.6582H126.304C124.539 51.6582 122.951 52.7091 122.259 54.3345L120.845 57.6602C120.615 58.2024 120.086 58.5528 119.497 58.5528H54.3232C51.8995 58.5528 49.9271 60.5255 49.9271 62.9497V66.0116H36.5002C35.1125 66.0116 33.8362 66.6463 32.9981 67.7514C32.1599 68.8565 31.8932 70.2576 32.2668 71.5943L49.382 132.728C49.911 134.619 51.6518 135.94 53.6154 135.94V135.882C53.8454 135.921 54.0828 135.94 54.3232 135.94H156.604C159.028 135.94 161 133.968 161 131.543V62.9497C161 60.5255 159.028 58.5528 156.604 58.5528ZM49.9271 123.804L35.0891 70.8043C34.9645 70.3588 35.0525 69.8926 35.3323 69.5233C35.6122 69.154 36.0371 68.9429 36.5002 68.9429H49.9271V123.804ZM158.069 131.543C158.069 132.351 157.411 133.009 156.604 133.009H54.3232C53.5158 133.009 52.8578 132.351 52.8578 131.543V62.9497C52.8578 62.1422 53.5158 61.484 54.3232 61.484H119.497C121.263 61.484 122.851 60.4333 123.543 58.8078L124.957 55.4822C125.187 54.9399 125.716 54.5896 126.304 54.5896H144.213C144.802 54.5896 145.331 54.9399 145.562 55.4822L146.975 58.8078C147.667 60.4333 149.253 61.484 151.021 61.484H156.604C157.411 61.484 158.069 62.1422 158.069 62.9497V131.543Z"
      fill="#FCDAD9"
    />
    <path
      d="M 124.481 110.397 C 125.229 111.162 125.647 112.195 125.647 113.27 C 125.647 114.346 125.229 115.38 124.481 116.145 C 123.719 116.889 122.703 117.305 121.644 117.305 C 120.585 117.305 119.571 116.889 118.81 116.145 L 105.725 102.837 L 92.642 116.145 C 91.881 116.889 90.863 117.305 89.805 117.305 C 88.747 117.305 87.73 116.889 86.97 116.145 C 86.223 115.38 85.804 114.346 85.804 113.27 C 85.804 112.195 86.223 111.162 86.97 110.397 L 100.105 97.14 L 86.97 83.884 C 86.336 83.101 86.011 82.104 86.06 81.093 C 86.109 80.081 86.528 79.122 87.236 78.406 C 87.943 77.688 88.887 77.265 89.887 77.215 C 90.886 77.166 91.867 77.494 92.642 78.138 L 105.725 91.444 L 118.81 78.138 C 119.582 77.494 120.564 77.166 121.564 77.215 C 122.562 77.265 123.508 77.688 124.214 78.406 C 124.922 79.122 125.341 80.081 125.39 81.093 C 125.439 82.104 125.114 83.101 124.481 83.884 L 111.346 97.14 L 124.481 110.397 Z"
      fill="#FCDAD9"
    />
  </svg>
);
