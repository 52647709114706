import React from 'react';
import { PortalOverlayProps } from './model';
import { PortalOverlayStyles } from './Styles';

export const PortalOverlay = ({
  className,
  children,
  testId,
  onClickOutside,
  overlayBlur = false,
}: PortalOverlayProps) => {
  return (
    <PortalOverlayStyles
      className={className}
      data-test={testId ? `${testId}-overlay` : undefined}
      onMouseDown={onClickOutside}
      blur={overlayBlur}
    >
      {children}
    </PortalOverlayStyles>
  );
};
