import React, {
  Dispatch,
  SetStateAction,
  ForwardedRef,
  useRef,
  useMemo,
} from 'react';
import { Icon, Label, PositionX, PositionY } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  FilesOptionListInfo,
  filesOptionMenuInfo,
  CustomDropdownAsset,
} from '../model';
import { OptionMenuStyles, OptionMenuItemStyles } from '../Styles';
import { OperationsFilesCardMenu } from './OperationsFilesCardMenu';

type DropdownFilesCardProps = {
  assets: CustomDropdownAsset[];
  onClick: Dispatch<SetStateAction<number | null>>;
  contentType: string;
  loading: boolean;
};

export const DropdownFilesCard = ({
  assets,
  onClick,
  contentType,
  loading,
}: DropdownFilesCardProps) => {
  const { t } = useTranslation();
  const filesCardMenuRef = useRef<null>(null);

  const infoByContent: FilesOptionListInfo = useMemo(
    () => filesOptionMenuInfo[contentType],
    [contentType]
  );

  const { menuTitle, menuIconLeft, menuIcon, assetName, assetIcon } =
    infoByContent;

  const getAsset = () => (
    <>
      {assetIcon && <Icon iconName={assetIcon} size={16} />}
      <Label size="14" semibold>
        {t(assetName)}
      </Label>
    </>
  );

  const ForwardedFilesCardMenu = React.forwardRef(
    (props, ref: ForwardedRef<null>) => (
      <OperationsFilesCardMenu
        innerRef={ref}
        title={menuTitle}
        iconLeft={menuIconLeft}
        icon={menuIcon && <Icon iconName={menuIcon} size={24} />}
        loading={loading}
        assetsNumber={assets.length}
        {...props}
      />
    )
  );
  return (
    <OptionMenuStyles
      positionX={PositionX.start}
      positionY={PositionY.bottom}
      renderItem={<ForwardedFilesCardMenu ref={filesCardMenuRef} />}
      widthAuto={false}
      hidePanelOnClick
      noPadding
    >
      {assets.map((asset: CustomDropdownAsset, index: number) => (
        <OptionMenuItemStyles
          key={index}
          onClick={() => onClick(asset.position)}
        >
          {getAsset()}
        </OptionMenuItemStyles>
      ))}
    </OptionMenuStyles>
  );
};
