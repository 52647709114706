import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import {
  Button,
  FlexContainer,
  Input,
  InputDatePicker,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { TableOptionsStyles } from './Styles';
import { OrderBy } from '../../state/constants';

type DateRange = {
  from: Date | null;
  to: Date | null;
};

export type OptionsType = {
  limit?: number;
  fromTimestamp?: string;
  text?: string;
  toTimestamp?: string;
  orderBy?: string;
};

type TableOptionsProps = {
  values?: OptionsType;
  onChange?(value: OptionsType): void;
  children?: ReactNode;
  filterComponent?: ReactNode;
  onDownload?(): Promise<void>;
};

export const TableOptions = ({
  values,
  onChange,
  children,
  filterComponent,
  onDownload,
}: TableOptionsProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<DateRange | undefined>(
    values?.fromTimestamp && values?.toTimestamp
      ? {
          from: new Date(values?.fromTimestamp),
          to: new Date(values?.toTimestamp),
        }
      : undefined
  );
  const [text, setText] = useState<string | undefined>(values?.text);

  const handleText = (e: ChangeEvent<HTMLInputElement>) =>
    setText(e.target.value);

  const handleDate = (value: DateRange | undefined) => {
    setDate(value);
  };

  useEffect(() => {
    onChange &&
      onChange({
        limit: 100,
        text: text || undefined,
        fromTimestamp: date?.from?.toISOString(),
        toTimestamp: date?.to?.toISOString(),
        orderBy: OrderBy.lastUpdateTime,
      });
  }, [date, text]);

  const startDownload = () => {
    if (onDownload) {
      setLoading(true);
      onDownload().finally(() => setLoading(false));
    }
  };

  return (
    <TableOptionsStyles alignItems="center" columnGap="2.4">
      <div>
        <FlexContainer data-tour="filter-1">
          <InputDatePicker
            maxDate={new Date()}
            value={date}
            onChange={handleDate}
            isRange={true}
          />
        </FlexContainer>
        <Input
          type="text"
          iconLeft="MagnifyingGlass"
          onChange={handleText}
          value={text}
          placeholder={t('Customer ID')}
          testId={'input-search'}
        />
        <FlexContainer data-tour="filter-2">{filterComponent}</FlexContainer>
      </div>
      <div>
        {children}
        {onDownload && (
          <Button
            iconName="DownloadSimple"
            onClick={startDownload}
            testId="download-operations"
            color="secondary"
            className={loading ? 'infinite-loading' : ''}
            disabled={loading}
          >
            {t('Generate report')}
          </Button>
        )}
      </div>
    </TableOptionsStyles>
  );
};
