import styled from 'styled-components';
import { Label } from '../../label';
import { CardBase } from '../CardBase';

export const CardUXStyle = styled(CardBase)`
  box-shadow: 8px 10px 10px 0 ${(props) => props.theme.color.greys[100]};
  padding: 3rem;
  padding-bottom: 1.2rem;
`;

export const CardUXTitle = styled(Label)`
  margin: 3.6rem 0 1rem;
  padding-left: 0.8rem;
`;

export const CardUXSubtitle = styled(Label)`
  padding-left: 0.8rem;
  margin-bottom: auto;
  padding-left: 0.8rem;
`;
