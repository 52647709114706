import { MenuItem } from '@facephi/inphinite-common';
import { useTranslation } from 'react-i18next';
import { RoutesNameAntifraud } from '../state/constants';

export function useAntifraud() {
  const { t } = useTranslation();

  const submenuAntifraud: MenuItem[] = [
    {
      icon: 'Prohibit',
      link: RoutesNameAntifraud.banned,
      id: '1',
      label: t('Rejected'),
      visible: true,
    },
    {
      icon: 'ClipboardText',
      link: RoutesNameAntifraud.rules,
      id: '2',
      label: t('Rules'),
      visible: true,
    },
  ];

  return {
    submenuAntifraud,
  };
}
