import { OperationsIDPage } from '@facephi/inphinite-operations';
import { useTranslation } from 'react-i18next';
import { RoutesNameAntifraud } from '../state/constants';

export const BannedIDPage = () => {
  const { t } = useTranslation();

  return (
    <OperationsIDPage
      hideReviewTab
      emptyBackRoute={RoutesNameAntifraud.banned}
      emptyBackText={t('Back to the banned list')}
    />
  );
};
