/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCommon } from '@facephi/inphinite-common';
import axios from 'axios';

export enum RequestMethods {
  post = 'post',
  get = 'get',
}

export function useApi() {
  const apiUrl: string = (window as any)._env_.REACT_APP_API_URL;

  const { token } = useCommon();

  const getData = async (resource: string) => {
    return await axios({
      method: RequestMethods.get,
      url: `${apiUrl}metrics/${resource}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error);
      });
  };

  return {
    getData,
  };
}
