export const checkEmptyState = (
  search: string,
  loading: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  length?: number
) => {
  const hasEmptyData = () => !search && !loading && !error && !length;

  const hasFilterActive = () => search && !loading && !error && !length;

  return error
    ? 'error'
    : hasFilterActive()
    ? 'filter'
    : hasEmptyData()
    ? 'data'
    : undefined;
};
