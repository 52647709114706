import { DragEvent } from 'react';
import { ButtonIcon, Icon, Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { PermissionsBoxStyleProps } from './model';
import { PermissionsBoxStyle } from './Styles';

type Props = PermissionsBoxStyleProps & {
  title: string;
  onClick?(): void;
  level?: number;
  onDragStart?(event: DragEvent<HTMLElement>): void;
};

export const PermissionsBox = ({
  title,
  onClick,
  isClosable,
  isDraggable,
  onDragStart,
  isEdit,
  disabled,
  level = 0,
}: Props) => {
  const { t } = useTranslation();

  return (
    <PermissionsBoxStyle
      alignItems="center"
      columnGap="1.2"
      onDragStart={onDragStart}
      draggable={isDraggable && !disabled}
      isClosable={isClosable}
      isEdit={isEdit}
      disabled={disabled}
      style={{ marginLeft: `${level * 2}rem` }}
    >
      {!level && (
        <Icon
          iconName="ListBullets"
          size="24"
          color="greys"
          intensity={isEdit ? 800 : isDraggable ? 600 : 'white'}
        />
      )}
      <Label
        size="14"
        semibold
        negative={isDraggable && isEdit ? true : !isDraggable && !isEdit}
      >
        {t(title)}
      </Label>
      {(isEdit || isClosable) && (
        <ButtonIcon
          text
          onClick={onClick}
          iconName="XCircle"
          color="greys"
          intensity={isClosable ? 'white' : 800}
        />
      )}
    </PermissionsBoxStyle>
  );
};
