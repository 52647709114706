import React from 'react';
import { PhoneProps } from './model';
import { Device } from './Styles';

export const Nexus5 = ({ children }: PhoneProps) => {
  return (
    <Device className="device nexus5">
      <div className="top-bar" />
      <div className="sleep" />
      <div className="volume" />
      <div className="camera" />
      <div className="screen">{children}</div>
    </Device>
  );
};
