import { gql } from '@apollo/client';

export const insertIP = gql`
  mutation createIP($ip: ips_insert_input!) {
    createIP(ip: $ip) {
      id
      ip
      description
      updated_at
    }
  }
`;

export const updateIP = gql`
  mutation updateIP($id: uuid!, $ip: ips_set_input!) {
    updateIP(id: $id, ip: $ip) {
      ip
      tenant_id
      created_at
      description
      id
      updated_at
    }
  }
`;

export const removeIP = gql`
  mutation deleteIPs($ids: [uuid!]!) {
    deleteIPs(ids: $ids) {
      returning {
        id
      }
    }
  }
`;

export const undoRemoveIP = gql`
  mutation undoDeleteIPs($ids: [uuid!]!) {
    undoDeleteIPs(ids: $ids) {
      returning {
        id
      }
    }
  }
`;
