import { RoutesName } from '@backoffice/state/constants';
import { PermissionsGate, Roles, SCOPES } from '@facephi/inphinite-common';
import { IconName, Label, ThemeColorType } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  PanelLayouContent,
  PanelLayoutLinks,
  PanelLayoutNav,
  PanelLayoutNavlink,
  PanelLayoutStyles,
  PanelLayoutTitle,
} from './Styles';

type LinksOptions = {
  id: number;
  to: string;
  name: string;
  iconName: IconName;
  backgroundColor: ThemeColorType;
  active?: string[];
  roles?: Roles[];
  demo?: boolean;
};

export const PanelLayout = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const panelLayoutLinksOptions: LinksOptions[] = [
    {
      id: 0,
      to: RoutesName.profile,
      name: t('Profile & Account'),
      iconName: 'UserGear',
      backgroundColor: 'violet',
    },
    {
      id: 2,
      to: RoutesName.myPlan,
      name: t('My plan'),
      iconName: 'CheckSquareOffset',
      backgroundColor: 'purple',
    },
  ];

  return (
    <PanelLayoutStyles flexDirection="column">
      <PanelLayoutTitle>
        <Label as="h2" size="18" semibold>
          {t('Settings')}
        </Label>
      </PanelLayoutTitle>
      <PanelLayouContent flexDirection="column" justifyContent="space-between">
        <PanelLayoutNav flexDirection="column">
          {panelLayoutLinksOptions
            .filter((item) => !item.demo)
            .map((item: LinksOptions) => (
              <PermissionsGate
                scopes={[SCOPES.canViewUserManagment]}
                key={item.id}
              >
                <PanelLayoutNavlink
                  to={item.to}
                  className={
                    item.active &&
                    item.active.some((item) =>
                      pathname.includes(item.slice(0, -1))
                    )
                      ? 'active'
                      : ''
                  }
                >
                  <PanelLayoutLinks
                    direction="row"
                    name={item.name}
                    iconName={item.iconName}
                    backgroundColor={item.backgroundColor}
                    size="M"
                  />
                </PanelLayoutNavlink>
              </PermissionsGate>
            ))}
        </PanelLayoutNav>
      </PanelLayouContent>
    </PanelLayoutStyles>
  );
};
