import { FlexContainer, Icon, Label, PositionX } from '@facephi/inphinite-ui';
import { GroupBox } from './GroupBox';
import {
  GroupDropdownContent,
  TableAssetsDropdown,
  TableAssetsDropdownWrapper,
} from './Styles';
import { GroupDto } from '../state/model';

type Props = {
  groups: GroupDto[];
};

const MAX_GROUPS = 2;

export const GroupBoxList = ({ groups }: Props) => {
  return (
    <FlexContainer columnGap="2">
      {groups.slice(0, MAX_GROUPS).map((group: GroupDto) => (
        <GroupBox name={group.name} id={group.id} key={group.id} />
      ))}
      {groups.length > MAX_GROUPS && (
        <TableAssetsDropdownWrapper
          positionX={PositionX.end}
          renderItem={
            <TableAssetsDropdown alignItems="center" columnGap="0.8">
              <Icon size="20" iconName="Plus" color="greys" intensity={500} />
              <Label size="10">{groups.length - MAX_GROUPS}</Label>
            </TableAssetsDropdown>
          }
        >
          <GroupDropdownContent flexDirection="column" rowGap="1.2">
            {groups.slice(MAX_GROUPS, groups.length).map((group: GroupDto) => (
              <GroupBox name={group.name} id={group.id} key={group.id} />
            ))}
          </GroupDropdownContent>
        </TableAssetsDropdownWrapper>
      )}
    </FlexContainer>
  );
};
