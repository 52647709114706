import { ASIDENAV_WIDTH } from '@facephi/inphinite-common';
import {
  Button,
  CardBaseFooter,
  CardBaseHeader,
  DropdownController,
  DropdownSearchController,
  FlexContainer,
  Input,
  InputAutoSuggestController,
  Label,
  Modal,
  OptionMenu,
  WidgetItem,
} from '@facephi/inphinite-ui';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { PermissionsBoxStyleProps } from './model';

export const TableAssetsDropdown = styled(FlexContainer)`
  border-radius: 2rem;
  padding: 0.3rem 0.8rem 0.3rem 0.6rem;
  border: 1px solid ${(props) => props.theme.color.greys[200]};
  margin-left: 0.8rem;

  &:hover {
    cursor: pointer;
  }
`;

export const FormComponentProfileStyles = styled(FlexContainer)`
  margin-top: 5.4rem;
`;

export const FormProfile = styled.form`
  padding-right: 15%;
`;

export const FormProfileInput = styled(Input)`
  flex: 1;
  max-width: 37rem;
`;

export const FormControlInputAutoSuggest = styled(InputAutoSuggestController)`
  flex: 1;
`;

export const FormControlDropdown = styled(DropdownController)`
  flex: 1;
  max-width: 37rem;
`;

export const FormControlDropdownSearch = styled(DropdownSearchController)`
  flex: 1;
  max-width: 37rem;
`;

export const ProfileMessage = styled(Label)`
  margin-top: 1rem;
`;

export const FormElement = styled(FlexContainer)`
  flex: 1;
  > * {
    width: 100%;
    max-width: 37rem;
  }
`;

export const FormProfileButtonSendMail = styled(Button)`
  color: ${(props) => props.theme.color.blue[400]};
`;

export const FormProfileFooter = styled(FlexContainer)`
  margin-top: 4.4rem;
`;

const PANELLEFT_WIDTH = '30rem';

export const PanelLayoutStyles = styled(FlexContainer)`
  background-color: ${(props) => props.theme.color.greys.white};
  border-radius: 0 0.8rem 0.8rem 0;
  width: calc(${PANELLEFT_WIDTH} + 2rem);
  box-shadow: 5px 0 10px 0 ${(props) => props.theme.color.greys[100]};
  padding-top: 3.6rem;
  padding-left: 2rem;
  margin-left: calc(${ASIDENAV_WIDTH} - 2rem);
  position: fixed;
  z-index: 10;
  left: 0;
  height: 100%;

  ~ div {
    margin-left: calc(${PANELLEFT_WIDTH} + ${ASIDENAV_WIDTH});
  }
`;

export const PanelLayoutTitle = styled.div`
  padding-left: 3.2rem;
  padding-bottom: 5.6rem;
  border-bottom: 1px solid ${(props) => props.theme.color.greys[200]};
`;

export const PanelLayoutLinks = styled(WidgetItem)`
  padding-left: 3.2rem;
  height: 8.4rem;
  border-bottom: 1px solid ${(props) => props.theme.color.greys[200]};
  cursor: pointer;
  width: 100%;
`;

export const PanelLayouContent = styled(FlexContainer)`
  flex: 1;
`;

export const PanelLayoutNav = styled(FlexContainer)`
  position: relative;
  flex: 1;
`;
export const PanelLayouToggleTheme = styled(FlexContainer)`
  margin-bottom: 4rem;
  padding-left: 3.2rem;
`;

export const ModalMailStyles = styled(Modal)`
  max-width: 52rem;

  img {
    width: 15.4rem;
    height: 14.2rem;
  }
`;

export const ModalMailLabel = styled(Label)`
  white-space: pre-line;
`;

export const PanelLayoutNavlink = styled(NavLink)`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    right: -0.6rem;
    height: 100%;
    width: 0.6rem;
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: ${(props) => props.theme.color.greys[200]};
    transition: right 0.2s ease-in-out;
  }
  &.active:before {
    right: 0;
  }
`;

export const UserManagementFormContent = styled(FlexContainer)`
  margin-top: 2.4rem;
  width: 100%;
  flex: 1;
`;

export const UserManagementFormTitle = styled(Label)`
  margin-top: 5.4rem;
  margin-bottom: 1rem;
`;

export const UserManagementAddUserButtons = styled(FlexContainer)`
  flex: 1;
`;

export const UserPanelAvatar = styled.img`
  width: 10rem;
  aspect-ratio: 1;
  object-fit: cover;
  position: absolute;
  left: 3.6rem;
  top: 0%;
  overflow: hidden;
  transform: translateY(-50%);
  border-radius: 100%;
`;

export const UserPanelPermissions = styled(FlexContainer)`
  margin-bottom: 2rem;
`;

export const UserPanelStyles = styled.form`
  margin: 6.8rem 4.6rem 2.4rem 1.8rem;
  display: flex;
  height: 100%;

  ${CardBaseFooter} {
    margin-top: auto;
  }

  ${CardBaseHeader} {
    position: relative;
  }

  ${FormProfileInput} {
    min-width: 32rem;
    max-width: 32rem;
  }

  ${FormControlDropdown} {
    min-width: 32rem;
    max-width: 32rem;
  }

  ${FormControlDropdownSearch} {
    min-width: 32rem;
    max-width: 32rem;
  }
`;

export const PermissionsBoxStyle = styled(
  FlexContainer
)<PermissionsBoxStyleProps>`
  height: 4.8rem;
  padding: 0 2rem;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.color.greys[50]
      : props.isClosable
      ? props.theme.color.primary[400]
      : props.draggable
      ? props.theme.color.greys.white
      : props.isEdit
      ? props.theme.color.greys[100]
      : props.theme.color.primary[400]};
  border-radius: 0.8rem;
  ${(props) =>
    props.draggable &&
    `  box-shadow: 0 5px 10px 0 ${props.theme.color.greys[100]};
  `}

  button {
    margin-left: auto;
    visibility: ${(props) => (props.isClosable ? 'hidden' : 'visible')};
  }
  &:hover button {
    visibility: visible;
  }
`;

export const PermissionsUserBoxStyle = styled(FlexContainer)`
  min-height: 6.2rem;
  padding: 0 2rem;
  background-color: ${(props) => props.theme.color.greys.white};
  border-radius: 0.8rem;
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};
  cursor: pointer;

  p:first-of-type {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  > div {
    min-width: 0;
  }

  img {
    width: 2.4rem;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 100%;
  }

  button {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
  }
`;

export const PermissionsUserBoxSelectedLabel = styled(Label)`
  color: ${(props) => props.theme.color.green[400]};
`;

export const GroupBoxStyle = styled(FlexContainer)`
  button {
    margin-left: auto;
    background-color: ${(props) => props.theme.color.greys[100]};
    border-radius: 100%;
  }
`;

export const OptionContentStyle = styled(FlexContainer)`
  padding: 0.8rem;
  max-height: 14rem;
  max-width: 34rem;
  min-width: 15rem;
  overflow: hidden;
  overflow-y: auto;
`;

export const EmptyUserBox = styled(FlexContainer)`
  padding: 2rem 2.6rem;
  text-align: center;

  ${Label}:first-child {
    padding-bottom: 2rem;
  }
`;

export const GroupDropdownContent = styled(FlexContainer)`
  padding: 0.6rem 0.2rem;
`;

export const TableAssetsDropdownWrapper = styled(OptionMenu)`
  max-height: 16rem;
  overflow-y: auto;
`;
