import { Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { FormComponentProfileStyles } from './Styles';

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
};

export const FormComponentProfile = ({ title, children, className }: Props) => {
  const { t } = useTranslation();

  return (
    <FormComponentProfileStyles
      flexDirection="column"
      rowGap="2.6"
      className={className}
      as="article"
    >
      <Label size="16" semibold as="h3">
        {t(`${title}`)}
      </Label>
      {children}
    </FormComponentProfileStyles>
  );
};
