import { CardBase } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const CardUpgradeStyles = styled(CardBase)<{ disabled?: boolean }>`
  padding: 3rem;
  ${(props) =>
    props.disabled &&
    `
    p {
      color: ${props.theme.color.greys[200]}
    }
    `}
`;

export const UpgradeGrid = styled.div<{ itemsLength: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.itemsLength < 3 ? props.itemsLength : 3)},
    25rem
  );
  grid-gap: 2.4rem;
  margin: 2rem 0;
`;
