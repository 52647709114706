import styled from 'styled-components';
import { InputSize, InputVariant } from './model';
import { FlexContainer } from '../flexContainer';
import { Icon } from '../icon';
import { Label } from '../label';

export const InputContainer = styled.div`
  label {
    padding: 0 0.6rem 0.6rem;
  }
`;

export const ErrorLabel = styled(Label)`
  color: ${(props) => props.theme.color.error[400]};
  padding: 0.4rem 0.6rem 0;
  display: block;
`;

export const InputStyles = styled(FlexContainer)<{
  disabled?: boolean;
  variant?: InputVariant;
  size?: InputSize;
}>`
  height: ${(props) => (props.size === 'L' ? '4.8rem' : '4rem')};
  border-radius: 0.8rem;
  padding: 0 0.8rem;

  ${(props) =>
    props.variant === 'primary' &&
    `
    border: 1px solid ${props.theme.color.greys[200]};
    background-color: ${
      props.disabled
        ? props.theme.color.greys[50]
        : props.theme.color.greys.white
    };
    color: ${
      props.disabled
        ? props.theme.color.greys[400]
        : props.theme.color.greys[700]
    };
    > input {
        color: inherit;
        &::placeholder {
          color: ${props.theme.color.greys[400]};
        }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        box-shadow: 0 0 0px 1000px ${props.theme.color.greys.white}
          inset;
        -webkit-text-fill-color: ${props.theme.color.greys[700]};
        border-radius: 0;
      }
    }
  `}

  ${(props) =>
    props.variant === 'secondary' &&
    `
    border: 1px solid ${
      props.disabled
        ? props.theme.color.greys[500]
        : props.theme.color.greys.white
    };
    background-color: ${props.theme.color.greys[700]};
    color: ${
      props.disabled
        ? props.theme.color.greys[500]
        : props.theme.color.greys.white
    };
    > input {
        color: inherit;
        &::placeholder {
          color: ${props.theme.color.greys.white};
        }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        box-shadow: 0 0 0px 1000px ${props.theme.color.greys[700]}
          inset;
        -webkit-text-fill-color: ${props.theme.color.greys.white};
        border-radius: 0;
      }
    }
  `}

  > input {
    border: 0;
    height: 100%;
    flex: 1;
    outline: none;
    background: transparent;
    font-size: 1.2rem;
    width: 100%;
    /* avoid autofill styles in firefox */
    filter: none;
    /* avoid autofill styles in firefox */
  }
`;

export const InputIcon = styled(Icon)`
  margin-right: 0.5rem;
  width: 2.4rem;
  height: 2.4rem;
`;

export const HelpLabelStyles = styled(Label)`
  background-color: ${(props) => props.theme.color.greys[200]};
  padding: 1.2rem;
  border-radius: 0.8rem;
  max-width: 16rem;
`;
