import styled from 'styled-components';
import { ButtonColor, ButtonStyleProps } from './model';
import { IThemeProps } from '../../themes';
import { getSizeLabel } from '../label';

const getColor = (theme: IThemeProps, color?: ButtonColor) => {
  switch (color) {
    case 'primary':
      return theme.color.primary[400];
    case 'secondary':
      return theme.color.secondary[400];
    case 'error':
      return theme.color.error[400];
    case 'warning':
      return theme.color.warning[400];
    case 'success':
      return theme.color.green[400];
    case 'white':
      return theme.color.greys.white;
  }
};

const getColorStates = (theme: IThemeProps, color?: ButtonColor) => {
  switch (color) {
    case 'primary':
      return theme.color.primary[500];
    case 'secondary':
      return theme.color.secondary[500];
    case 'error':
      return theme.color.error[500];
    case 'warning':
      return theme.color.warning[500];
    case 'success':
      return theme.color.green[500];
    case 'white':
      return theme.color.greys[50];
  }
};

export const ButtonStyle = styled.button<ButtonStyleProps>`
  height: ${(props) =>
    props.size === 'XL'
      ? '5.8rem'
      : props.size === 'L'
      ? '4.8rem'
      : props.size === 'S'
      ? '3.2rem'
      : '4rem'};
  padding: ${({ size }) =>
    size === 'XL' || size === 'L' ? '0 2.4rem' : '0 1.2rem'};
  border-radius: 0.8rem;
  ${(props) => props.size === 'XL' && getSizeLabel('16')};
  ${(props) => props.size === 'L' && getSizeLabel('16')};
  ${(props) => props.size === 'M' && getSizeLabel('14')};
  ${(props) => props.size === 'S' && getSizeLabel('12')};
  font-weight: ${(props) => (props.semiBold ? 600 : 400)};
  font-family: inherit;
  box-sizing: border-box;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.8rem;
  transition: all 0.25s ease-in-out;
  white-space: nowrap;
  position: relative;
  ${(props) => props.fullWidth && `width: 100%;`}
  ${(props) => props.upperCase && `text-transform: uppercase;`}


  svg {
    transition: fill 0.25s ease-in-out;

    &:hover&:not(:disabled),
    &:focus&:not(:disabled),
    &:active&:not(:disabled) {
      fill: ${(props) => props.theme.color.greys.white};
    }
  }

  ${(props) =>
    props.ellipsis &&
    `
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  `}

  /* Filled */
  ${(props) =>
    props.variant === 'filled' &&
    `
      background-color: ${getColor(props.theme, props.color)};
      color: ${
        props.color === 'white'
          ? props.theme.color.primary[500]
          : props.theme.color.greys.white
      };
      &:hover&:not(:disabled){
        background-color: ${getColorStates(props.theme, props.color)};
      }
      &:focus&:not(:disabled),
      &:active&:not(:disabled) {
        background-color: ${getColorStates(props.theme, props.color)};
      }
    `}

  /* Outline */
  ${(props) =>
    props.variant === 'outline' &&
    `
      background-color: transparent;
      color: ${getColor(props.theme, props.color)};
      border: 2px solid ${getColor(props.theme, props.color)};
      &:hover&:not(:disabled){
        background-color: ${getColorStates(props.theme, props.color)};
        color: ${props.theme.color.greys.white};
        border: 2px solid ${getColorStates(props.theme, props.color)};
      }
      &:focus&:not(:disabled),
      &:active&:not(:disabled) {
        background-color: ${getColorStates(props.theme, props.color)};
        color: ${props.theme.color.greys.white};
        border: 2px solid ${getColorStates(props.theme, props.color)};
      }
    `}

  /* Text */
  ${(props) =>
    props.variant === 'text' &&
    `
      color: ${
        props.color
          ? getColor(props.theme, props.color)
          : props.theme.color.greys[700]
      };
    `}

  &:hover&:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${({ theme, disabled, variant }) =>
      (disabled && variant === 'outline') || variant === 'text'
        ? 'transparent'
        : theme.color.greys[200]};
    color: ${({ theme }) => theme.color.greys[300]};
    cursor: default;
  }
`;
