import { useEffect, useState } from 'react';
import { useAssets } from '@facephi/inphinite-common';
import { InputUpload } from '@facephi/inphinite-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalFaceInput, ModalFaceStyles } from './Styles';
import { DESCRIPTION, ID, IMAGE_ID } from '../state/constants';
import { DataFaceModal, modalFaceSchema } from '../state/model';

type Props = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  className?: string;
  onSave(face: DataFaceModal): void;
  data?: DataFaceModal;
};

export const ModalFace = ({
  show,
  onChangeShow,
  className,
  onSave,
  data,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [face, setFace] = useState<File>();
  const [image, setImage] = useState<string | undefined>();

  const { uploadAsset, getAsset } = useAssets();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(modalFaceSchema),
    mode: 'onChange',
  });

  const onSubmit = async (newFace: DataFaceModal) => {
    if (newFace && face) {
      const response = await uploadAsset(face);
      onSave({ ...newFace, media_id: response.id });
    } else if (newFace && !face) {
      onSave({ ...newFace });
    }
  };

  const setInnerData = () => {
    if (show && data) {
      setValue(IMAGE_ID, data.media_id);
      setValue(DESCRIPTION, data.description);
      setValue(ID, data.id);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getAsset(data.faceId).then((response: any) =>
        setImage(URL.createObjectURL(response))
      );
    }
  };

  useEffect(() => {
    reset({});
    setImage(undefined);
    setInnerData();
  }, [show]);

  const onSaveFile = (file: File) => {
    setFace(file);
    setValue(IMAGE_ID, file);
  };

  return (
    <ModalFaceStyles
      className={className}
      show={show}
      testId="modal-face"
      onChangeShow={onChangeShow}
      iconHeader="PlusCircle"
      title={t('Add a blocked face')}
      closeLabel={t('Cancel')}
      submitLabel={t('Save')}
      onCreate={handleSubmit(onSubmit)}
      testIdSave="save-button"
      flexDirectionContent="column"
      rowGapContent="4.8"
      alignItemsContent="center"
    >
      <InputUpload
        locale={i18n.language}
        testId="face-input-image"
        onChange={onSaveFile}
        withUserLabel={false}
        errorLabel={errors.imageId?.message && t(errors.imageId.message)}
        value={image}
      />
      <ModalFaceInput
        testId="face-input-desc"
        label={t('Description (optional)')}
        {...register(DESCRIPTION)}
        errorLabel={
          errors.description?.message && t(errors.description.message)
        }
      />
    </ModalFaceStyles>
  );
};
