import styled from 'styled-components';
import { DisabledProps, SwitchBoxProps, SwitchProps } from './model';
import { Label } from '../label';

export const LabelSwitch = styled(Label)<DisabledProps>`
  margin-bottom: 0;
`;

export const SwitchBoxBox = styled.span<SwitchBoxProps>`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 100%;
  background-color: ${(props) =>
    props.checked
      ? props.theme.color.primary[400]
      : props.theme.color.greys[800]};
  position: absolute;
  top: 50%;
  transform: ${(props) =>
    props.checked ? 'translate(-15%, -50%)' : 'translate(-85%, -50%)'};
  transition: all 0.2s ease-in-out;
`;

export const SwitchBoxContainer = styled.div<SwitchBoxProps>`
  border: none;
  width: 4rem;
  height: 1.6rem;
  border-radius: 2rem;
  position: relative;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => props.theme.color.greys[400]};

  &[disabled] {
    background-color: ${(props) => props.theme.color.greys[200]};

    ${SwitchBoxBox} {
      background-color: ${(props) => props.theme.color.greys[300]};
    }
  }
`;

export const SwitchContainer = styled.button<SwitchProps>`
  display: flex;
  flex-direction: column;
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  position: absolute;
  width: 1px;
  opacity: 0;
  z-index: -1;
`;
