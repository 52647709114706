import React, { ReactNode, useEffect, useState } from 'react';
import {
  EntityStatus,
  TrackingFamily,
  StepsFilter,
} from '@facephi/inphinite-common';
import {
  FilterMenu,
  FilterMenuHeader,
  FilterMenuItem,
  FlexContainer,
  LabelBase,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { OptionsType, TableOptions } from './TableOptions';

type FilterType = Record<string, string[] | undefined>;
export type FiltersType = OptionsType & FilterType;

type Props = {
  variables?: FiltersType;
  onChange?(value: FiltersType): void;
  children?: ReactNode;
  onDownload?(): Promise<void>;
};

const LABEL: Record<string, string> = {
  family: 'Type',
  status: 'Status',
  currentStep: 'Step',
};

const options = {
  family: Object.values(TrackingFamily).map((value: string) => {
    return { label: value };
  }),
  status: Object.values(EntityStatus).map((value: string) => ({
    label: value,
  })),
  currentStep: Object.values(StepsFilter).map((value: string) => ({
    label: value,
  })),
};

export const TableOptionsOperations = ({
  variables = {},
  onChange,
  children,
  onDownload,
}: Props) => {
  const [optionFilters, setOptionFilters] = useState<OptionsType>(variables);
  const [filter, setFilter] = useState<FilterType>(
    Object.fromEntries(
      Object.entries(options).map(([header]) => [
        header,
        variables[header] ?? [],
      ])
    )
  );

  const { t } = useTranslation();

  const handleFilter = (header: string, value: string) => {
    setFilter((filter) => ({
      ...filter,
      [header]: filter[header]?.includes(value)
        ? filter[header]?.filter((key) => key !== value)
        : [...(filter[header] || []), value],
    }));
  };

  useEffect(() => {
    if (Object.keys(optionFilters).length) {
      onChange &&
        onChange({
          ...optionFilters,
          ...Object.entries(filter).reduce(
            (acc, [key, values]) => ({
              ...acc,
              [key]: values?.length ? values : undefined,
            }),
            {}
          ),
        } as FiltersType);
    }
  }, [filter, optionFilters]);

  return (
    <TableOptions
      values={variables}
      onChange={setOptionFilters}
      children={children}
      onDownload={onDownload}
      filterComponent={
        <>
          <FilterMenu testId="filters-options" labelTooltip={t('Filter')}>
            {Object.entries(options).map(([header, itemOption], index) => {
              return (
                <React.Fragment key={index}>
                  <FilterMenuHeader label={t(`${LABEL[header]}`)} />
                  {itemOption.map((value: { label: string }, count) => (
                    <FilterMenuItem
                      key={count}
                      testId={`filter-${value.label}`}
                      label={t(`${value.label.replaceAll('_', ' ')}`)}
                      onChange={() => handleFilter(header, value.label)}
                      checked={filter[header]?.includes(value.label)}
                    />
                  ))}
                </React.Fragment>
              );
            })}
          </FilterMenu>
          <FlexContainer
            alignItems="center"
            columnGap="1.6"
            rowGap="1.6"
            wrap="wrap"
          >
            {Object.entries(filter).map(([header, values]) =>
              values?.map((label, index) => (
                <LabelBase
                  key={index}
                  testId={`labelBase-${label}`}
                  label={t(`${label.replaceAll('_', ' ')}`)}
                  onClick={() => handleFilter(header, label)}
                />
              ))
            )}
          </FlexContainer>
        </>
      }
    ></TableOptions>
  );
};
