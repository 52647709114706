import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { InputAutoSuggest } from './InputAutoSuggest';
import { InputAutoSuggestControllerProps } from './model';

export const InputAutoSuggestController = <T extends FieldValues>({
  name,
  control,
  ...rest
}: InputAutoSuggestControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render={({ field }: any) => <InputAutoSuggest {...field} {...rest} />}
  />
);
