export enum RoutesName {
  antifraud = '/antifraud',
  rules = '/antifraud/rules',
  banned = '/antifraud/banned',
  bannedId = '/antifraud/banned/:id',
  video = '/video',
  videoId = '/video/:id',
  operations = '/operations',
  operationsId = '/operations/:id',
}

export const MAX_ITEMS_PAGE = 15;

export const REQUIRED_VALIDATION = 'This is a required field';
export const IP = 'ip';
export const IP_VALIDATION = 'The format of the IP field is invalid';
export const DESCRIPTION = 'description';
export const IMAGE_ID = 'media_id';
export const ID = 'id';

export const DOCUMENT_FACIAL_CAPTURE = 'DOCUMENT_FACIAL_CAPTURE';
export const CUSTOMER_PROVIDED_IMAGE = 'CUSTOMER_PROVIDED_IMAGE';
export const SELFIE = 'SELFIE';
export const REFUSAL_REASON = 'Refusal Reason';

export const updatesIP = [
  '11/07/11 03:48:59',
  '15/04/19 16:05:15',
  '30/09/21 04:57:37',
  '29/12/10 22:02:06',
  '20/10/12 10:41:54',
  '06/05/15 01:34:24',
  '14/08/20 11:34:42',
];

export const ZOOM = {
  minScale: 0.25,
  minZoom: 25,
  defaultFlow: 25,
  defaultScale: 100,
  previewVideo: 1.5,
  maxScale: 100,
  defaultZoom: 100,
};

export const INTERNAL_PROVIDERS = {
  default: 'default',
  facephi: 'facephi',
};

export const FULL_FRAME = 'FULLFRAME';

export enum OrderBy {
  lastUpdateTime = 'LAST_UPDATE_TIME',
  creationTime = 'CREATION_TIME',
}
