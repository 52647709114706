import {
  CardBase,
  CardBaseContent,
  CardBaseHeader,
  Label,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import {
  CardUXChart,
  HeaderUX,
  UXAnalyticGrid,
  DevicesCard,
  MapChart,
  GlobalTimeCard,
  TimingContainer,
  OperationsRateChart,
} from '../components';

export const UXAnalyticPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderUX />
      <UXAnalyticGrid>
        <CardUXChart>
          <CardBaseHeader alignItems="center" noBorder>
            <Label size="16" semibold>
              {t('Timing global')}
            </Label>
          </CardBaseHeader>
          <TimingContainer>
            <GlobalTimeCard />
          </TimingContainer>
        </CardUXChart>

        <CardBase>
          <CardBaseHeader alignItems="center">
            <Label size="14" semibold>
              {`${t('Operation Rates')} (%)`}
            </Label>
          </CardBaseHeader>
          <CardBaseContent flexDirection="column">
            <OperationsRateChart />
          </CardBaseContent>
        </CardBase>

        <CardBase>
          <CardBaseHeader alignItems="center">
            <Label size="14" semibold>
              {t('Locality')}
            </Label>
          </CardBaseHeader>
          <CardBaseContent flexDirection="column">
            <MapChart />
          </CardBaseContent>
        </CardBase>

        <DevicesCard />
      </UXAnalyticGrid>
    </>
  );
};
