import styled from 'styled-components';
import { NotificationType } from './model';
import { IThemeProps } from '../../themes';

const getColor = (theme: IThemeProps) => ({
  on: theme.color.green[400],
  off: theme.color.error[400],
  pause: theme.color.yellow[400],
});

export const NotificationStyle = styled.span<{ type: NotificationType }>`
  display: inline-block;
  background-color: ${({ theme, type }) => getColor(theme)[type]};
  box-shadow: 0 0 5px 2px ${({ theme, type }) => getColor(theme)[type]};
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.8rem;
`;
