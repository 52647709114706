import React, { MouseEvent } from 'react';
import { OptionMenuItemProps } from './model';
import { OptionMenuItemList, OptionMenuItemStyles } from './Styles';

export const OptionMenuItem = ({
  children,
  onClick,
  className,
  testId,
  active = false,
  disabled = false,
  semiBold = false,
  iconName,
  onMouseUp,
  size = '12',
  color,
}: OptionMenuItemProps) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const handleMouseDown = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <OptionMenuItemList
      className={className}
      active={active}
      as="li"
      alignItems="center"
      onMouseDown={handleMouseDown}
      onMouseUp={onMouseUp}
    >
      <OptionMenuItemStyles
        onClick={handleClick}
        testId={testId}
        active={active}
        type="button"
        disabled={disabled}
        variant="text"
        iconName={iconName}
        semiBold={semiBold}
        color={color}
        $size={size}
        size="S"
      >
        {children}
      </OptionMenuItemStyles>
    </OptionMenuItemList>
  );
};
