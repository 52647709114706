import styled from 'styled-components';
import { ButtonTabsStyleProps } from './model';
import { FlexContainer } from '../flexContainer';

export const ButtonTabStyles = styled(FlexContainer)`
  height: 4rem;
  border-radius: 0.8rem;
  border: solid 1px ${(props) => props.theme.color.greys[100]};
  background-color: ${(props) => props.theme.color.greys.white};
  overflow: hidden;
`;

export const ButtonTabButton = styled.button<ButtonTabsStyleProps>`
  padding: 0 0.6rem;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: solid 1px ${(props) => props.theme.color.greys[100]};
  font-size: 1rem;
  line-height: 1.4rem;

  ${(props) =>
    props.isActive &&
    `
    background-color: ${props.theme.color.blue[400]};
    color: ${props.theme.color.greys.white};
  `}

  &:first-of-type {
    border: none;
  }
`;
