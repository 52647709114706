import { gql } from '@apollo/client';

export const getTimeLine = gql`
  query searchOnboardingEvents($parameters: AuditSearchParameters) {
    timeline: searchAuditEvents(parameters: $parameters) {
      events {
        ... on OnboardingAudit {
          eventId
          clientTimestamp
          metadata: payload {
            ... on AssetPayload {
              type
              url
              contentType
            }
            ... on OnboardingPayload {
              type
              screen
              event
              value
            }
            ... on OperationResult {
              type
              status
              reason
            }
            ... on FacialAuthenticateResult {
              type
              similarity
              authStatus
              source
            }
            ... on FacialLivenessResult {
              type
              diagnostic
              description
            }
          }
        }
      }
    }
  }
`;

export const getTimeLineAuthentication = gql`
  query searchOnboardingEvents($parameters: AuditSearchParameters) {
    timeline: searchAuditEvents(parameters: $parameters) {
      events {
        ... on AuthenticationAudit {
          eventId
          date: clientTimestamp
          metadata: payload {
            ... on AssetPayload {
              type
              url
              contentType
            }
            ... on AuthenticationPayload {
              type
              screen
              event
              value
            }
            ... on OperationResult {
              type
              status
              reason
            }
          }
        }
      }
    }
  }
`;
