import { ipRegex } from '../../utils';
import yup from '../../utils/yup-extended';
import {
  REQUIRED_VALIDATION,
  IP,
  IP_VALIDATION,
  DESCRIPTION,
  IMAGE_ID,
} from '../constants';

yup.addMethod(yup.string, 'isIp', function () {
  return this.test('is-ip', IP_VALIDATION, function (value) {
    return value === undefined || value.trim() === ''
      ? true
      : ipRegex().test(value);
  });
});

export const modalIPSchema = yup.object().shape({
  [IP]: yup.string().isIp().required(REQUIRED_VALIDATION),
  [DESCRIPTION]: yup.string(),
});

export const modalFaceSchema = yup.object().shape({
  [DESCRIPTION]: yup.string(),
  [IMAGE_ID]: yup.string().required(REQUIRED_VALIDATION),
});

export const sendRuleSchema = yup.object().shape({
  type: yup.string().required(REQUIRED_VALIDATION),
  [DESCRIPTION]: yup.string(),
});
