import {
  FlexContainer,
  Label,
  LabelBase,
  Status,
  StatusType,
  useTimezone,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { TrackingFamily } from '../../state/model';
import { DetailHeaderStyles } from './Styles';

type Props = {
  date?: string;
  id?: string;
  currentStep?: string;
  status?: StatusType;
  loading?: boolean;
  family?: TrackingFamily;
  step?: string;
};
export const DetailHeader = ({
  date,
  id,
  currentStep,
  status,
  loading,
  family,
  step,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { formatTimezone } = useTimezone();

  return (
    <DetailHeaderStyles
      alignItems="center"
      justifyContent="space-between"
      columnGap="4.2"
    >
      {loading ? (
        <>
          <Skeleton height={18} width={130} borderRadius={8} />
          <Skeleton height={18} width={130} borderRadius={8} />
          <Skeleton height={18} width={130} borderRadius={8} />
        </>
      ) : (
        <>
          {date && (
            <Label size="10">
              <strong>{t('Date')}: </strong>
              {formatTimezone(date)}
            </Label>
          )}
          <Label size="10">
            <strong>ID: </strong> {id}
          </Label>
          <Label size="10">
            <strong>{t('Current Step')}: </strong> {currentStep}
          </Label>
          {step && (
            <Label size="10">
              <strong>{t('Step')}: </strong> {step}
            </Label>
          )}
        </>
      )}
      {loading ? (
        <Skeleton height={18} width={100} borderRadius={8} />
      ) : (
        <FlexContainer alignItems="center" columnGap="0.8">
          {family === TrackingFamily.AUTHENTICATION && (
            <LabelBase label={t('Authentication')} />
          )}
          {status && <Status type={status} withLabel locale={i18n.language} />}
        </FlexContainer>
      )}
    </DetailHeaderStyles>
  );
};
