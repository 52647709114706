import {
  Button,
  ButtonIcon,
  DeleteItem,
  DropdownOption,
  FlexContainer,
  Input,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { DropdownAntiFraud, HeaderRulesStyles } from './Styles';

const optionsDropdown: DropdownOption[] = [
  {
    name: '10',
    value: '10',
  },
];

type Props = {
  onAddNew?(): void;
  itemsDelete?: number;
  deleteItemsSelected?(): void;
};

export const HeaderRules = ({
  onAddNew,
  deleteItemsSelected,
  itemsDelete,
}: Props) => {
  const { t } = useTranslation();

  return (
    <HeaderRulesStyles alignItems="center" justifyContent="space-between">
      <FlexContainer columnGap="0.8">
        <DropdownAntiFraud options={optionsDropdown} />
        <Input iconLeft="MagnifyingGlass" />
        <ButtonIcon iconName="Faders" text />
      </FlexContainer>

      <DeleteItem
        testId="delete-item-button"
        onClick={deleteItemsSelected}
        items={itemsDelete || 0}
      />

      <FlexContainer columnGap="1.6">
        <ButtonIcon iconName="Printer" labelTooltip="Print" />
        <ButtonIcon iconName="DownloadSimple" labelTooltip="Download" />
        <Button testId="add" iconName="Plus" onClick={onAddNew}>
          {t('Add new')}
        </Button>
      </FlexContainer>
    </HeaderRulesStyles>
  );
};
