import React, {
  ChangeEvent,
  forwardRef,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Transition } from 'react-spring';
import { InputAutoSuggestProps } from './model';
import { InputAutoSuggestMenu, InputAutoSuggestStyles } from './Styles';
import { stringMatches } from '../../utils/strings';
import { FlexContainer } from '../flexContainer';
import { Input } from '../input';
import { Portal } from '../portal';
import { UserTag, UserTagProps } from '../userTag';
import { UserTagListContent } from '../userTagList/Styles';

export const InputAutoSuggest = forwardRef(
  (
    {
      data,
      className,
      value,
      onChange,
      testId,
      ...props
    }: InputAutoSuggestProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [showAutoSuggest, setShowAutoSuggest] = useState<boolean>(false);
    const [selectItem, setSelectedItem] = useState<string[]>(value || []);
    const [inputValue, setInputValue] = useState<string>();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const valueLength = event.target.value.length >= 3;

      valueLength ? setShowAutoSuggest(true) : setShowAutoSuggest(false);
      setInputValue(event.target.value);
    };

    const handleSelectItem = (id: string) => {
      setSelectedItem((selectItem) =>
        selectItem.includes(id)
          ? selectItem.filter((key) => key !== id)
          : [...selectItem, id]
      );
    };

    useEffect(() => {
      onChange && onChange(selectItem);
    }, [selectItem]);

    return (
      <>
        <InputAutoSuggestStyles
          flexDirection="column"
          rowGap="0.8"
          className={className}
          data-test={testId}
        >
          <Input
            onChange={handleChange}
            divRef={inputRef}
            ref={ref}
            {...props}
          />
          <FlexContainer wrap="wrap" rowGap="0.6" columnGap="0.6" as="ul">
            {data.map(
              (user: UserTagProps) =>
                selectItem.includes(user.id) && (
                  <li key={user.id}>
                    <UserTag
                      avatar={user.avatar}
                      userName={user.userName}
                      onClick={() => handleSelectItem(user.id)}
                      id={user.id}
                    />
                  </li>
                )
            )}
          </FlexContainer>
        </InputAutoSuggestStyles>
        <Transition
          items={showAutoSuggest}
          from={{ opacity: 0, transform: 'translate3d(0%, 20px, 0px)' }}
          enter={{ opacity: 1, transform: 'translate3d(0%, 0%, 0px)' }}
          leave={{ opacity: 0, transform: 'translate3d(0%, 20px, 0px)' }}
        >
          {(styles, showAutoSuggest) =>
            showAutoSuggest && (
              <Portal
                actionRef={inputRef}
                show={showAutoSuggest}
                onClickOutside={() => setShowAutoSuggest(false)}
              >
                <InputAutoSuggestMenu style={{ ...styles }}>
                  <UserTagListContent
                    wrap="wrap"
                    rowGap="0.6"
                    columnGap="0.6"
                    as="ul"
                    data-test="autosuggest-menu"
                  >
                    {data
                      .filter(
                        ({ userName }) =>
                          inputValue && stringMatches(userName, inputValue)
                      )
                      .map((user: UserTagProps) => (
                        <li
                          onClick={() => handleSelectItem(user.id)}
                          key={user.id}
                          id={user.id}
                        >
                          <UserTag
                            avatar={user.avatar}
                            userName={user.userName}
                            selected={selectItem.includes(user.id)}
                            id={user.id}
                          />
                        </li>
                      ))}
                  </UserTagListContent>
                </InputAutoSuggestMenu>
              </Portal>
            )
          }
        </Transition>
      </>
    );
  }
);
