import React from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { Dropdown } from './Dropdown';
import { DropdownControllerProps } from './model';

export const DropdownController = <T extends FieldValues>({
  name,
  control,
  ...rest
}: DropdownControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render={({ field }: any) => <Dropdown {...field} {...rest} />}
  />
);
