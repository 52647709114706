import React from 'react';
import { useTheme } from 'styled-components';
import { CardUXProps } from './model';
import { CardUXStyle, CardUXSubtitle, CardUXTitle } from './Styles';
import { Button } from '../../button';
import { FlexContainer } from '../../flexContainer';
import { IconWrapper } from '../../iconWrapper';
import { Label } from '../../label';

export const CardUX = ({
  iconName,
  backgroundColor,
  title,
  subtitle,
  info,
  href,
  testId,
  className,
}: CardUXProps) => {
  const theme = useTheme();

  return (
    <CardUXStyle
      style={{ borderLeft: `8px solid ${theme.color[backgroundColor][400]}` }}
      data-test={testId}
      className={className}
    >
      <FlexContainer columnGap="2.1" alignItems="center">
        <IconWrapper iconName={iconName} backgroundColor={backgroundColor} />
        <Label size="27" semibold as="h2">
          {info}
        </Label>
      </FlexContainer>
      <CardUXTitle size="16" semibold>
        {title}
      </CardUXTitle>
      <CardUXSubtitle size="14" semibold success>
        {subtitle}
      </CardUXSubtitle>
      <Button size="S" variant="text" as="a" href={href}>
        View all
      </Button>
    </CardUXStyle>
  );
};
