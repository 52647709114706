import { useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import {
  Dropdown,
  DropdownOption,
  Input,
  Spinner,
  ToastType,
  useToast,
} from '@facephi/inphinite-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGroups, useRoles } from '../../hooks';
import {
  createUserSchema,
  GroupDto,
  RoleDto,
  UserCreateDto,
} from '../../state/model';
import { createUser } from '../../state/mutations';
import {
  ModalManagementNewUserStyles,
  SpinnerAbsolutePlacement,
} from './Styles';

type Props = {
  show: boolean;
  onChangeShow(show: boolean): void;
};

export const ModalManagementNewUser = ({ show, onChangeShow }: Props) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createUserSchema),
  });

  useEffect(() => {
    if (!show) {
      setValue('username', undefined);
      setValue('groups', []);
      setValue('roleId', undefined);
    }
  }, [show]);

  const { groups } = useGroups();
  const { roles } = useRoles();

  const groupOptions: DropdownOption[] = useMemo(
    () =>
      groups
        ? groups.map((item: GroupDto) => ({
            name: item.name,
            value: item.id,
          }))
        : [],
    [groups]
  );

  const roleOptions: DropdownOption[] = useMemo(
    () =>
      roles
        ? roles.map((item: RoleDto) => ({
            name: t(item.name),
            value: item.id,
          }))
        : [],
    [roles]
  );

  const [insertUser, { loading }] = useMutation(createUser, {
    onError: (error) => {
      setError('username', {
        type: 'manual',
        message: error.message,
      });
    },
    onCompleted: () => {
      toastManager({
        $type: ToastType.SUCCESS,
        message: t('User successfully created'),
        duration: 3000,
        testId: 'create-user',
      });
    },
  });

  const { toastManager } = useToast();

  const onSubmit = async (user: UserCreateDto) => {
    const response = await insertUser({
      variables: {
        user,
      },
      refetchQueries: ['searchUsers'],
    });

    if (response.data) {
      onChangeShow(false);
    }
  };

  return (
    <ModalManagementNewUserStyles
      show={show}
      title={t('Create a new user')}
      iconHeader="PlusCircle"
      onChangeShow={onChangeShow}
      onCreate={() => !loading && handleSubmit(onSubmit)()}
      closeLabel={t('Cancel')}
      submitLabel={t('Save')}
      testIdSave="button-save-user"
      flexDirectionContent="column"
      rowGapContent="2.4"
    >
      {loading && (
        <SpinnerAbsolutePlacement>
          <Spinner />
        </SpinnerAbsolutePlacement>
      )}

      <Input
        type="text"
        label={t('Username (mandatory field)')}
        {...register('username')}
        testId="input-username"
        errorLabel={errors.username?.message && t(errors.username.message)}
        placeholder={t('Enter your email')}
      />

      <Controller
        control={control}
        name="groups"
        render={({ field, fieldState }) => (
          <Dropdown
            options={groupOptions}
            label={t('Group (optional)')}
            overlay
            {...field}
            {...fieldState}
            multiple
          />
        )}
      />

      <Controller
        control={control}
        name="roleId"
        render={({ field, fieldState }) => (
          <Dropdown
            testId="dropdown-role"
            options={roleOptions}
            label={t('Role (mandatory field)')}
            placeholder={t('Choose role...')}
            overlay
            errorLabel={errors.roleId?.message && t(errors.roleId.message)}
            {...field}
            {...fieldState}
          />
        )}
      />
    </ModalManagementNewUserStyles>
  );
};
