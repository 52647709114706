import {
  ButtonIcon,
  Card,
  CardBaseHeader,
  FlexContainer,
  GridContainer,
  InputDatePicker,
  Label,
  Modal,
  ModalHeader,
  OptionMenuItem,
  OptionMenu,
  Tabs,
  ThemeColorType,
} from '@facephi/inphinite-ui';
import styled, { keyframes } from 'styled-components/macro';
import { OperationsCard } from './operationsCards/OperationsCard';
import { OperationsCardDocument } from './operationsCards/OperationsCardDocument';
import { OperationSource } from './operationsCards/OperationSource';

const opacityTransition = keyframes`{
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.25;
  }
  40% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }`;

export const TableOptionsStyles = styled(FlexContainer)`
  padding-bottom: 2.7rem;

  > div:first-child {
    margin-right: auto;
    display: flex;
    grid-gap: 1rem;
  }

  > div:last-child {
    margin-left: auto;
    display: flex;
    grid-gap: 1.5rem;
  }

  .infinite-loading {
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      left: -100%;
      height: 0.4rem;
      background-color: ${(props) => props.theme.color.primary[400]};
      opacity: 0.75;
      border-radius: 0.8rem;
      animation: load 3s linear infinite;
      width: 100%;
    }
  }

  @keyframes load {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
`;

export const TableOptionsDatePicker = styled(InputDatePicker)`
  min-width: 15rem;
`;

export const DropdownSize = styled.div`
  width: 12.6rem;
`;

export const TableAssetsStyles = styled(FlexContainer)``;

export const TableAssetsDropdown = styled(FlexContainer)`
  margin-left: 0.8rem;

  &:hover {
    cursor: pointer;
  }
`;

export const TableAssetsVideoCall = styled(ButtonIcon)`
  margin-left: 1rem;
  background-color: ${(props) => props.theme.color.error[200]};
  min-width: 3.2rem;
  width: 3.2rem;
  height: 3.2rem;

  svg {
    width: 2rem;
    color: ${(props) => props.theme.color.error[400]};
    animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  }

  @keyframes shake {
    10%,
    90% {
      transform: rotate(-10deg);
    }

    20%,
    80% {
      transform: rotate(10deg);
    }

    30%,
    50%,
    70% {
      transform: rotate(-10deg);
    }

    40%,
    60% {
      transform: rotate(10deg);
    }
  } ;
`;

export const OperationsTabsContent = styled(FlexContainer)`
  margin: 2.4rem 0 0;

  &:first-child {
    margin-top: 0;
  }
`;

export const OperationsSecurityTab = styled(OperationsTabsContent)`
  flex-direction: column;
  margin: 0;

  > * {
    flex: 1;
    margin-top: 2rem;
  }
`;

export const OperationsCardFiles = styled(OperationsCardDocument)<{
  isAuthentication?: boolean;
}>``;

export const OperationsCardDocuments = styled(OperationsCard)`
  height: 100%;
`;

export const OperationsContentDevice = styled(FlexContainer)<{
  isAuthentication?: boolean;
}>``;

export const OperationsCardDevice = styled(OperationsCard)`
  flex: 1;
`;

export const OperationsDataContent = styled(OperationsTabsContent).attrs({
  rowGap: '2.4',
  columnGap: '2.4',
})`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  flex: 1;
`;

export const OperationsFilesColumn = styled(OperationsDataContent)`
  grid-template-columns: none;
  grid-template-rows: none;
  margin-top: 0;

  > *:nth-child(1) {
    grid-row: 1/2;
  }

  > *:nth-child(2) {
    grid-row: 2/2;
  }
`;

export const OperationsDocumentCard = styled(GridContainer)<{
  disabled?: boolean;
}>`
  height: 100%;

  img {
    filter: ${(props) => (props.disabled ? 'blur(0.3rem)' : 'none')};
  }
`;

export const OperationsVideoCard = styled(OperationsDocumentCard)<{
  empty?: boolean;
}>`
  height: ${(props) => (props.empty ? '100%' : '3rem')};
`;

export const OperationsCardSource = styled(OperationSource)`
  max-height: 6.8rem;
  text-align: left;
`;

export const OperationContentAnonymized = styled.div`
  position: absolute;
  right: 3rem;
  top: 0;
`;

export const OperationContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const ModalOperationTitle = styled(FlexContainer)`
  max-width: 12rem;

  ${Label} {
    white-space: nowrap;
  }
`;

export const OperationsTabs = styled(Tabs)`
  *[role='tabpanel'] {
    > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const OperationFaceContent = styled(FlexContainer)<{
  disabled?: boolean;
}>`
  height: 100%;
  img {
    filter: ${(props) => (props.disabled ? 'blur(0.3rem)' : 'none')};
  }

  #facial-match-icon {
    animation: ${opacityTransition} 0.8s ease-out 1;
  }
`;

export const ReactSimpleMapsStyles = styled.div`
  width: 100%;
  margin: auto;
`;

export const OperationsEmptyDataStyles = styled(FlexContainer)`
  margin-top: 10rem;
  padding-bottom: 1.2rem;

  @media (min-resolution: 1.5dppx) {
    margin-top: 6rem;
  }
`;

export const ModalOperationsDetailStyles = styled(Modal)`
  min-height: 50rem;
  width: 100rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  ${CardBaseHeader} {
    display: none;
  }
`;

export const ModalOperationsDetailSource = styled(OperationSource)`
  margin: 0 auto !important;
  max-height: 57rem;
  transition: transform 0.25s ease-in-out;

  &:is(video) {
    width: 100%;
  }
`;

export const ModalOperationsDetailHeader = styled(ModalHeader)`
  background-color: ${(props) => props.theme.color.greys[800]};
  border-radius: 0.8rem 0.8rem 0 0;
`;

export const ModalOperationsDetailFooter = styled(FlexContainer)`
  background-color: ${(props) => props.theme.color.greys[800]};
  border-radius: 0 0 0.8rem 0.8rem;
  height: 6rem;
`;

export const ModalOperationsDetailZoom = styled(Label)`
  background-color: ${(props) => props.theme.color.greys[600]};
  height: 4rem;
  padding: 0 1.6rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  width: 7.2rem;
`;

export const OperationTimelineWrapper = styled(FlexContainer)`
  margin: 2.4rem 0 0;
  height: 100%;
`;

export const OperationCardTimeline = styled(Card)<{ disabled?: boolean }>`
  flex-grow: 0.3;
  height: 100%;

  img {
    filter: ${(props) => (props.disabled ? 'blur(0.3rem)' : 'none')};
  }
`;

export const OperationCardTitle = styled(Label)`
  margin-bottom: 4.5rem;
`;

export const TableAssetsContainer = styled.div`
  max-width: 7rem;
`;

export const InfoContainer = styled(FlexContainer)<{
  infoColor?: ThemeColorType;
}>`
  margin: auto 0 0.4rem;
  border-radius: 0.8rem;
  background-color: ${({ infoColor, theme }) =>
    infoColor && theme.color[infoColor][100]};
  padding: 0.8rem 1.6rem;
  animation: ${opacityTransition} 0.8s ease-out 1;
  > p {
    color: ${({ infoColor, theme }) =>
      infoColor && theme.color[infoColor][400]};
    margin-left: 1.8rem;
  }
`;

export const CellNewRow = styled.div<{ hasError?: boolean }>`
  background: ${(props) =>
    props.hasError
      ? props.theme.color.error[400]
      : props.theme.color.green[400]};
  width: 8rem;
  height: 100%;
`;

export const OperationCardOCR = styled(FlexContainer)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
`;

export const FacialContainer = styled(FlexContainer)`
  height: 100%;
  justify-content: center;
  & > * {
    width: 11rem;
  }
  img {
    width: 10rem;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
`;

export const FacialResultIconWrapper = styled.div`
  min-width: 3.2rem;
  min-height: 3.2rem;
`;

export const OptionMenuItemStyles = styled(OptionMenuItem)`
  height: 4.5rem;
  > button {
    padding: 0 1.6rem;
  }
`;

export const OptionMenuStyles = styled(OptionMenu)`
  margin: 0.55rem 0 0 0;
`;

export const FamilyTimeline = styled.div<{ color: string }>`
  padding: 0.75rem 1rem;
  background: ${(props) =>
    props.theme.color[props.color as ThemeColorType][100]};
  border-radius: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  width: 14rem;
`;

export const FacialCheckGrid = styled(GridContainer)`
  .document-text {
    grid-column-start: 3;
  }
  .asset-text {
    align-self: start;
  }
`;
