import React from 'react';

type Props = {
  className?: string;
  size?: string | number | undefined;
};
export const FlagTemplate = ({ className, size }: Props) => {
  return (
    <svg
      className={className}
      width={size ? (size as number) : undefined}
      height={size ? (size as number) : undefined}
      viewBox="0 0 57 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="5" width="49" height="35" rx="6" fill="#7A6784" />
      <rect x="4" y="16" width="49" height="13" fill="#E8E2EB" />
    </svg>
  );
};
