import { IconName } from '@facephi/inphinite-ui';
import { CardLinkProps } from '../CardDashboard/model';

export type CardKpiProps = CardLinkProps & {
  count?: number;
  title: string;
  subtitle?: string;
  iconName: IconName;
  color: colorType;
  testId?: string;
  loading?: boolean;
  dataTour?: string;
};

export enum colorType {
  blue = 'blue',
  violet = 'violet',
  purple = 'purple',
}
