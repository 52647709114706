import React from 'react';
import { ButtonIcon } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { TableKPIsStyle } from './Styles';
import { ActivityType, Status, ValueStatus } from './TableKPIs';

export const TableUXTask = () => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t('Username'),
        accessor: 'username',
      },
      {
        Header: t('File name'),
        accessor: 'filename',
      },
      {
        Header: t('Group name'),
        accessor: 'groupname',
      },
      {
        Header: t('Action'),
        accessor: 'action',
        Cell: <ButtonIcon text iconName="ListPlus" />,
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    const data = [];
    for (let i = 0; i < 20; i++) {
      data.push({
        username: (
          <ActivityType iconName="Timer" color="purple" label="20/05/21" />
        ),
        filename: <Status label="Update" color="green" />,
        groupname: (
          <ValueStatus
            label="00.19 sec"
            color="green"
            iconName="ArrowFatLinesUp"
          />
        ),
        action: '',
      });
    }
    return data;
  }, []);

  return <TableKPIsStyle columns={columns} data={data} hiddenHeader />;
};
