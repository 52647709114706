import { Dispatch, SetStateAction, useState } from 'react';

type Return<T> = [
  number | null,
  Dispatch<SetStateAction<number | null>>,
  {
    itemSelected: T | null;
    prev: () => void;
    next: () => void;
  }
];

export function useArrayNavigation<T>(elements: T[]): Return<T> {
  const [position, setPosition] = useState<number | null>(null);

  const selectItem = (flow: number) => {
    if (position === null) return;
    let newPosition = position + flow;

    if (newPosition < 0) {
      newPosition = elements.length - 1;
    } else if (newPosition === elements.length) {
      newPosition = 0;
    }

    setPosition(newPosition);
  };

  const prev = () => selectItem(-1);
  const next = () => selectItem(1);

  return [
    position,
    setPosition,
    {
      prev,
      next,
      itemSelected: position !== null ? elements[position] : null,
    },
  ];
}
