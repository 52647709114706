import { Button, Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { OperationsEmptyDataStyles } from './Styles';

type IProps = {
  backRoute: string;
  backText: string;
};
export const OperationsEmptyData = ({ backRoute, backText }: IProps) => {
  const { t } = useTranslation();
  return (
    <OperationsEmptyDataStyles
      flexDirection="column"
      alignItems="center"
      rowGap="2.4"
    >
      <img src="/OperationsEmpty.svg" alt="empty-data" />
      <Label size="16" semibold>
        {t('You have no active processes')}
      </Label>
      <Label size="14">{t('Review another operation')}</Label>
      <Button as="a" href={backRoute} size="L">
        {backText}
      </Button>
    </OperationsEmptyDataStyles>
  );
};
