import { FlexContainer } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const DetailHeaderStyles = styled(FlexContainer)`
  height: 6rem;
  padding: 0 3.2rem;
  border-radius: 0.8rem;
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};
  background-color: ${(props) => props.theme.color.greys.white};
`;
