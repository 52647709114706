import React from 'react';
import { CardTabsProps } from './model';
import { CardTabsStyles, CardTabsTitle } from './Styles';
import { Tabs } from '../../tabs';

export const CardTabs = ({
  tabActive,
  children,
  className,
  testId,
  title,
}: CardTabsProps) => {
  return (
    <CardTabsStyles className={className} testId={testId}>
      {title && (
        <CardTabsTitle size="14" semibold>
          {title}
        </CardTabsTitle>
      )}

      <Tabs active={tabActive}>{children}</Tabs>
    </CardTabsStyles>
  );
};
