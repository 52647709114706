import { InphiniteTheme } from './InphiniteTheme';
import { IThemeProps } from './model';

export const CentralServicesTheme: IThemeProps = {
  ...InphiniteTheme,
  color: {
    ...InphiniteTheme.color,
    primary: {
      100: '#C0E6EF',
      200: '#80CDDF',
      300: '#40B4CF',
      400: '#009ABF',
      500: '#00748F',
      600: '#004D60',
      700: '#001E26',
    },
    secondary: {
      100: '#C0E6EF',
      200: '#80CDDF',
      300: '#40B4CF',
      400: '#009ABF',
      500: '#00748F',
      600: '#004D60',
      700: '#001E26',
    },
  },
  gradients: {
    ...InphiniteTheme.gradients,
    primary: 'linear-gradient(to left bottom, #54CDFF, #BAFF77)',
  },
  title: 'FacePhi Central Services',
};
