import React from 'react';
import toast from 'react-hot-toast';
import { ToastProps, ToastType } from './model';
import { ToastStyle } from './Styles';
import { Button } from '../button';
import { ButtonIcon } from '../buttonIcon';
import { FlexContainer } from '../flexContainer';
import { Icon } from '../icon';
import { Label } from '../label';

export const Toast = ({
  $type = ToastType.DEFAULT,
  message,
  subMessage,
  id,
  testId,
  onClick,
  hasIcon,
  canClose,
  messageSemiBold,
  buttonMessage,
  iconName,
  alignItems = 'center',
}: ToastProps) => {
  const hasStatus = () => {
    return (
      $type === ToastType.SUCCESS ||
      $type === ToastType.ERROR ||
      $type === ToastType.WARNING
    );
  };

  const getMessages = () => {
    return subMessage ? (
      <FlexContainer rowGap="0.8" flexDirection="column">
        <Label
          size="14"
          semibold
          negative={$type !== ToastType.DEFAULT}
          data-test="toast-message"
        >
          {message}
        </Label>
        <Label
          size="14"
          negative={$type !== ToastType.DEFAULT}
          data-test="toast-submessage"
        >
          {subMessage}
        </Label>
      </FlexContainer>
    ) : (
      <Label
        size="14"
        negative={$type !== ToastType.DEFAULT}
        semibold={messageSemiBold}
        data-test="toast-message"
      >
        {message}
      </Label>
    );
  };

  return (
    <>
      <ToastStyle
        data-test={testId}
        flexDirection="row"
        alignItems={alignItems}
        columnGap="1.6"
        $type={$type}
      >
        {(iconName || hasIcon) && (
          <Icon
            testId="toast-icon"
            color="greys"
            intensity={hasStatus() ? 'white' : 700}
            iconName={
              iconName
                ? iconName
                : $type === ToastType.SUCCESS || $type === ToastType.DEFAULT
                ? 'CheckCircle'
                : $type === ToastType.WARNING
                ? 'WarningCircle'
                : 'X'
            }
            size="18"
          />
        )}
        {getMessages()}
        {onClick && buttonMessage && (
          <Button
            variant={hasStatus() ? 'text' : 'filled'}
            onClick={() => {
              toast.dismiss(id);
              onClick && onClick();
            }}
            testId="toast-action-button"
            color={hasStatus() ? 'white' : 'secondary'}
          >
            {buttonMessage}
          </Button>
        )}
        {canClose && (
          <ButtonIcon
            iconName="X"
            text
            color="greys"
            intensity={hasStatus() ? 'white' : 400}
            onClick={() => toast.dismiss(id)}
            testId="toast-close-button"
          />
        )}
      </ToastStyle>
    </>
  );
};
