import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '@backoffice/providers';
import { AppName, AppsWithIcon } from '@backoffice/state/constants';
import { AppsIconsType } from '@backoffice/state/model';
import { IconWrapper, Label, LogoSquare } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { getLocaleLottie } from './lotties';
import { ModalGuidePanelRight } from './ModalGuidePanelRight';
import { ModalApps, modalGuideApps } from './model/guide';
import {
  ModalGuideElement,
  ModalGuideList,
  ModalGuidePanelLeft,
  ModalGuidePanelLeftHeader,
  ModalGuideStyles,
} from './Styles';

export type ModalGuideElementStyles = {
  isActive?: boolean;
};

type Props = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  className?: string;
  onCancel?(): void;
};

export const ModalGuide = ({
  show,
  onChangeShow,
  className,
  onCancel,
}: Props) => {
  const [menu, setMenu] = useState<ModalApps | undefined>();

  const { t, i18n } = useTranslation();
  const language = i18n.language.split('-')[0];
  const { user } = useAuth();

  const customApps = useMemo(
    () =>
      modalGuideApps({
        getLottie: (text) => getLocaleLottie(text, language),
      }).map((item) => {
        const config = AppsWithIcon.find(
          (app) => app.label === item.app
        ) as AppsIconsType;
        return { ...item, ...config };
      }),
    [modalGuideApps]
  );

  const handleMenu = (name?: AppName) => {
    const newApp = customApps.find((item) => item.app === name);
    setMenu(newApp);
  };

  useEffect(() => {
    if (!show) {
      setMenu(undefined);
    }
  }, [show]);

  return (
    <ModalGuideStyles
      className={className}
      show={show}
      testId="modal-walkthrough"
      onChangeShow={onChangeShow}
      noPaddingContent
    >
      <ModalGuidePanelLeft flexDirection="column">
        <ModalGuidePanelLeftHeader flexDirection="column" rowGap="3.6">
          <LogoSquare />
          <Label size="21" as="h2">
            <strong>{t('Welcome')},</strong> <span>{user?.name}</span>
          </Label>
        </ModalGuidePanelLeftHeader>
        <ModalGuideList as="ul" flexDirection="column">
          {customApps.map((item) => (
            <ModalGuideElement
              key={item.app}
              as="li"
              columnGap="1.2"
              alignItems="center"
              onClick={() => setMenu(item)}
              isActive={menu?.app === item.app}
            >
              <IconWrapper
                iconName={item.iconName}
                backgroundColor={item.color}
                size="M"
              />
              <Label size="12" semibold>
                {t(item.label)}
              </Label>
            </ModalGuideElement>
          ))}
        </ModalGuideList>
      </ModalGuidePanelLeft>
      {menu ? (
        <ModalGuidePanelRight
          source={menu.source}
          title={menu.title}
          message={menu.message}
          onNext={() => handleMenu(menu.onNext)}
          onPrev={() => handleMenu(menu.onPrev)}
          disableNextButton={!menu.onNext}
          menu={menu}
          onCancel={onCancel}
        />
      ) : (
        <ModalGuidePanelRight
          source={getLocaleLottie('welcome', language)}
          title="Welcome on board of FacePhi Identity Platform"
          message="You can use this tool to manage your operations, design your flows, and much more… We would like to invite you to join a quick tour inside the platform."
          onNext={() => handleMenu(AppName.operations)}
          menu={menu}
          onCancel={onCancel}
        />
      )}
    </ModalGuideStyles>
  );
};
