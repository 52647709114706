/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  DataDonutChart,
  DonutChart,
  PercentageStatictics,
  getAggregateStatistics,
} from '@facephi/inphinite-common';
import { useTimezone } from '@facephi/inphinite-ui';

export const OperationsRateChart = () => {
  const { timezone } = useTimezone();

  const { loading, data: statisticsData } = useQuery(getAggregateStatistics, {
    variables: { timeZone: timezone },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const overallData: DataDonutChart[] = useMemo(
    () =>
      statisticsData?.statistics?.overallPercentage?.percentages?.map(
        ({ status, percentage }: PercentageStatictics) => ({
          name: status,
          value: percentage,
        })
      ),
    [statisticsData?.statistics?.overallPercentage?.percentages]
  );

  return (
    <>
      <DonutChart data={overallData} loading={loading} />
    </>
  );
};
