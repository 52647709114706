import styled from 'styled-components';
import { ErrorLabelProps, InputUploadStyleProps } from './model';
import { ButtonIcon } from '../buttonIcon';
import { FlexContainer } from '../flexContainer';
import { ErrorLabel } from '../input/Styles';
import { Label } from '../label';

export const InputUploadStyles = styled(FlexContainer)``;

export const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
`;

export const WrapperDrag = styled(FlexContainer)<InputUploadStyleProps>`
  height: 9.6rem;
  width: ${(props) => (props.secondary ? '24rem' : '9.6rem')};
  background-color: ${(props) =>
    props.secondary
      ? props.theme.color.greys.white
      : props.theme.color.primary[100]};
  border-radius: ${(props) => (props.secondary ? '0.8rem' : '100%')};
  position: relative;
  cursor: pointer;

  ${(props) =>
    props.secondary &&
    `
     box-shadow: 0 5px 10px 0 ${props.theme.color.greys[100]};
     border: solid 1px ${props.theme.color.greys[100]};
     padding: 0 0.8rem;
  `}

  ${ImagePreview} {
    border-radius: ${(props) => (props.secondary ? 'unset' : '100%')};
    object-fit: ${(props) => (props.secondary ? 'contain' : 'cover')};
  }
`;

export const LabelUserName = styled(Label)`
  color: ${(props) => props.theme.color.primary[400]};
`;

export const ButtonChangePreview = styled(ButtonIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const InputUploadInfoLabel = styled(ErrorLabel)<ErrorLabelProps>`
  padding: 0;
  color: ${(props) =>
    props.errorLabel
      ? props.theme.color.error[400]
      : props.theme.color.greys[500]};
`;
