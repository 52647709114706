import { CSSProperties } from 'react';
import { FlexContainer, useTheme } from '@facephi/inphinite-ui';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { COLORS } from './model';
import { DonutChartLoading } from './Styles';

export type DataDonutChart = {
  name: string;
  value: number;
};

type IProps = {
  data: DataDonutChart[];
  subtitle?: string;
  style?: CSSProperties;
  loading?: boolean;
  center?: string[];
  radius?: string;
};
export const DonutChart = ({
  data = [],
  subtitle,
  style,
  loading,
  center = ['50%', '35%'],
  radius = '70%',
}: IProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const options = {
    tooltip: {
      trigger: 'item',
    },
    textStyle: {
      fontFamily: 'Poppins',
      color: theme.color.greys[700],
      fontSize: 10,
    },
    legend: {
      orient: 'horizontal',
      align: 'left',
      bottom: '0',
      itemGap: 20,
      textStyle: {
        color: theme.color.greys[700],
        fontFamily: 'Poppins',
        fontSize: 10,
      },
      itemStyle: {
        borderType: 'solid',
        borderWidth: 2,
        borderColor: theme.color.greys.white,
      },
    },
    series: [
      {
        type: 'pie',
        radius: radius,
        center: center,
        label: {
          show: false,
        },
        data: data.map((item: DataDonutChart, index) => ({
          ...item,
          name: t(item.name),
          itemStyle: {
            color: COLORS[index],
          },
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  return (
    <div>
      {loading ? (
        <>
          <DonutChartLoading>
            <Skeleton circle height={200} width={200} />
          </DonutChartLoading>
          <FlexContainer
            flexDirection="row"
            columnGap="2.2"
            justifyContent="center"
          >
            <Skeleton height={10} width={50} borderRadius={14} />
            <Skeleton height={10} width={50} borderRadius={14} />
            <Skeleton height={10} width={50} borderRadius={14} />
          </FlexContainer>
          <div style={{ marginTop: '2.2rem' }}>
            <Skeleton height={10} width="25%" borderRadius={14} />
          </div>
        </>
      ) : (
        <>
          <ReactECharts option={options} style={style} />
          <p>{subtitle}</p>
        </>
      )}
    </div>
  );
};
