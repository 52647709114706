import {
  FlexContainer,
  ICONWRAPPER_SIZE_M,
  Label,
  Logo,
  ZINDEX_TOAST,
} from '@facephi/inphinite-ui';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ASIDENAV_WIDTH } from '../../state/constants';

import { AsideNavType } from './model';

export const StylesMenu = styled.div<{ isPreviewMode?: boolean }>`
  width: ${ASIDENAV_WIDTH};
  border-radius: 2.4rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background-color: ${(props) => props.theme.color.greys[800]};
  display: flex;
  align-items: center;
  padding: 2.9rem 1.3rem;
  flex-direction: column;
  z-index: ${ZINDEX_TOAST};
  left: 0;
  top: 0;
  height: 100%;

  ${(props) =>
    props.isPreviewMode
      ? `
    position: relative;
  `
      : `
  position: fixed;
  box-shadow: 0 5px 10px -1px ${props.theme.color.greys.black};

  `}
`;

export const StylesMenuLink = styled(NavLink)`
  height: 6rem;
  width: 6rem;
  border-radius: 0.8rem;
  margin-bottom: 1.5rem;
  overflow: hidden;

  > * {
    height: 100%;
    width: 100%;
  }

  > img {
    object-fit: cover;
  }
`;

export const StylesMenuLogo = styled(Logo)`
  fill: ${(props) => props.theme.color.greys.white};
`;

export const StylesSeparator = styled.div`
  height: 0.2rem;
  width: 100%;
  background-color: ${(props) => props.theme.color.greys[500]};
`;

export const StylesButtonLogo = styled(FlexContainer)`
  background-image: ${(props) => props.theme.gradients.primary};
`;

export const StylesMenuNav = styled.nav`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  margin-top: 1rem;
`;

export const StyledMenuItem = styled(FlexContainer)<{ type?: AsideNavType }>`
  color: ${(props) => props.theme.color.greys.white};
  position: relative;
  margin-top: 4rem;

  > a,
  button {
    text-decoration: none;
    color: ${(props) => props.theme.color.greys.white};
    display: block;
    width: ${ICONWRAPPER_SIZE_M};
    height: ${ICONWRAPPER_SIZE_M};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.is-active,
    &:hover {
      background-color: ${(props) => props.theme.color.greys[600]};
      border-radius: 0.8rem;
    }
  }

  ${Label} {
    margin-top: 0.8rem;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
    fill: ${(props) => props.theme.color.greys.white};
  }
`;
