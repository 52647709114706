import React, { useEffect, useState } from 'react';
import { useTheme } from './../../providers';
import { Button } from './../button';
import { Icon } from './../icon';
import { Label } from './../label';

export type Props = {
  items: number;
  onClick?(): void;
  testId?: string;
};

export const DeleteItem = ({ items, onClick, testId }: Props) => {
  const theme = useTheme();
  const [itemsSelected, setItems] = useState<number>(items);
  useEffect(() => {
    setItems(items);
  }, [items]);

  return (
    <>
      {itemsSelected > 0 && (
        <Button testId={testId} variant="text" onClick={onClick}>
          <Icon color="primary" size="24" iconName="TrashSimple" />
          <Label style={{ color: theme.color.primary[400] }} size="16" semibold>
            Delete {itemsSelected} elements
          </Label>
        </Button>
      )}
    </>
  );
};
