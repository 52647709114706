import { PropsWithChildren } from 'react';
import { Label, Icon } from '@facephi/inphinite-ui';
import { Property } from 'csstype';
import Skeleton from 'react-loading-skeleton';
import { LivenessIconInfo } from '../model';
import { OperationsCardStatusStyles, OperationsCardIcon } from './Styles';

type Props = {
  label: string;
  type: LivenessIconInfo;
  loading?: boolean;
};

const SkeletonWrapper = ({
  children,
  textAlign,
}: PropsWithChildren<unknown> & { textAlign?: Property.TextAlign }) => (
  <div style={{ width: '100%', textAlign }}>{children}</div>
);

export const OperationsCardStatus = ({ label, type, loading }: Props) => {
  return (
    <OperationsCardStatusStyles
      statusColor={type.iconColor}
      alignItems="center"
      justifyContent="space-between"
      columnGap="1"
    >
      {loading ? (
        <>
          <SkeletonWrapper>
            <Skeleton height={18} width="40%" borderRadius={8} />
          </SkeletonWrapper>
          <SkeletonWrapper textAlign="right">
            <Skeleton height={18} width="40%" borderRadius={8} />
          </SkeletonWrapper>
        </>
      ) : (
        <>
          <Label size="14" semibold>
            {label}
          </Label>
          <OperationsCardIcon bgColor={type.iconColor}>
            <Icon
              iconName={type.iconName}
              size={16}
              color="greys"
              intensity="white"
              weight="fill"
            />
          </OperationsCardIcon>
        </>
      )}
    </OperationsCardStatusStyles>
  );
};
