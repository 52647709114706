import styled from 'styled-components';
import { FlowSteps, TagStatusStyleProps, TagStatusType } from './model';
import { IThemeProps } from '../../themes';
import { Label } from '../label';

const selectColor = (theme: IThemeProps, type: string) => {
  const step = FlowSteps.find(({ status }) =>
    status.includes(type as TagStatusType)
  );
  const hex = step ? theme.color[step.color] : theme.color.greys;
  return hex;
};

export const TagStatusStyle = styled(Label)<TagStatusStyleProps>`
  border-radius: 2rem;
  height: ${({ height }) => (height === 'L' ? '4rem' : '3rem')};
  background-color: ${({ theme, type }) => selectColor(theme, type)[100]};
  padding: ${({ height }) => (height === 'L' ? '0 2.4rem' : '0 1.8rem')};
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme, type }) => selectColor(theme, type)[400]};
  white-space: nowrap;
`;
