import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useCommon, useAssets } from '@facephi/inphinite-common';
import { Dropdown, Label, useToast, ToastType } from '@facephi/inphinite-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { ModalIPStyles, ModalFaceInput } from './Styles';
import { RoutesNameAntifraud, DESCRIPTION } from '../state/constants';
import { RulesModalDto, sendRuleSchema, TypeRulesDto } from '../state/model';
import { insertFace } from '../state/mutations/faces';

type Props = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  className?: string;
};

export const ModalSendRule = ({ show, onChangeShow, className }: Props) => {
  const { t } = useTranslation();

  const { operation } = useCommon();
  const { getAssetToFile, uploadAsset } = useAssets();
  const [createFace] = useMutation(insertFace);
  const { toastManager } = useToast();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(sendRuleSchema),
    mode: 'onChange',
  });

  const typeOptions = Object.keys(TypeRulesDto).map((item) => ({
    name: t(item),
    value: item,
  }));

  const onSubmit = async (newRule: RulesModalDto) => {
    if (newRule.type === TypeRulesDto.face) {
      const selphiAsset = operation?.assets.find(
        (item) => item.type === 'SELFIE'
      );

      if (selphiAsset) {
        const file = await getAssetToFile(selphiAsset.url, `${v4()}.jpg`);
        const response = await uploadAsset(file);
        await createFace({
          variables: {
            face: {
              media_id: response.id,
              description: newRule.description,
            },
          },
          onCompleted: () => {
            toastManager({
              $type: ToastType.SUCCESS,
              message: t('Rule created successfully'),
              duration: 3000,
              testId: 'toast-create-rule',
            });
            navigate(RoutesNameAntifraud.rules, { state: { tab: 'face' } });
          },
        });

        onChangeShow(false);
      } else {
        toastManager({
          $type: ToastType.ERROR,
          message: t('No selfie found in the operation'),
          duration: 3000,
          testId: 'toast-create-rule-error',
        });
      }
    }
  };

  useEffect(() => {
    reset({});
  }, [show]);

  return (
    <ModalIPStyles
      className={className}
      show={show}
      testId="modal-rule"
      onChangeShow={onChangeShow}
      iconHeader={'PlusCircle'}
      title={t('Add an antifraud rule')}
      closeLabel={t('Cancel')}
      submitLabel={t('Save')}
      testIdSave="save-button"
      onCreate={handleSubmit(onSubmit)}
      flexDirectionContent="column"
      rowGapContent="1.6"
    >
      <Label size="14">{t('Choose rule type')}</Label>
      <Controller
        control={control}
        name="type"
        render={({ field, fieldState }) => (
          <Dropdown
            testId="dropdown-type"
            options={typeOptions}
            label={t('Type')}
            placeholder={t('Choose type...')}
            overlay
            errorLabel={errors.typeId?.message && t(errors.typeId.message)}
            {...field}
            {...fieldState}
          />
        )}
      />
      <ModalFaceInput
        testId="face-input-desc"
        label={t('Description (optional)')}
        {...register(DESCRIPTION)}
        errorLabel={
          errors.description?.message && t(errors.description.message)
        }
      />
    </ModalIPStyles>
  );
};
