import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useFlags } from '@facephi/inphinite-common';
import {
  Dropdown,
  FlexContainer,
  HelpInfoTooltip,
  Label,
  PositionX,
  PositionY,
  TabHeaderActions,
  TabPanel,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoles } from '../../hooks';
import { usernameSearch } from '../../state/cache';
import { Roles, RoutesName } from '../../state/constants';
import { TYPES_MANAGEMENT } from '../model';
import { ModalManagementNewGroup } from './ModalManagementNewGroup';
import { ModalManagementNewUser } from './ModalManagementNewUser';
import {
  ButtonPermission,
  HelpInfoTooltipContent,
  RoleInfoItem,
  UserManagementFormWrapper,
  UserManagenentTabs,
  UserManagenentTabsWrapper,
} from './Styles';
import { UserManagementTableGroup } from './UserManagementTableGroup';
import { UserManagementTableUser } from './UserManagementTableUser';
import { UserManagenentTableHeader } from './UserManagenentTableHeader';

export const UserManagementForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roles: rawRoles } = useRoles();

  const actionOptions = useMemo(
    () => [
      {
        name: t('Assign role'),
        value: '0',
        subMenu: rawRoles.map(({ name, id }) => ({
          name: t(name),
          value: id,
        })),
      },
    ],
    [rawRoles]
  );

  const { type } = useParams<{ type: string }>() as { type: string };
  const { demo } = useFlags();

  const [activeTab, setActiveTab] = useState<string>(type || 'users');
  const [modal, setModal] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [userSearch, setUserSearch] = useState<string>(usernameSearch());

  const [selectedUsers, setSelectedUsers] = useState<number | null>();

  const handleOnChangeUser = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    usernameSearch(value);
    setUserSearch(value);
  };

  useEffect(() => {
    if (!selectedUsers) {
      setRole(null);
    }
  }, [selectedUsers]);

  return (
    <>
      <Label size="14">{t('Manage all your user with a glance.')}</Label>

      <UserManagementFormWrapper>
        <UserManagenentTabs
          noPadding
          active={activeTab}
          onChangeActive={(activeTab: string) => setActiveTab(activeTab)}
        >
          <TabHeaderActions>
            <HelpInfoTooltip
              title={t('About roles')}
              testId="showRoles-helpInfoTooltip"
              positionX={PositionX.left}
              positionY={PositionY.bottom}
              label={t('About roles')}
              iconName="Info"
            >
              <HelpInfoTooltipContent flexDirection="column" rowGap="1">
                <RoleInfoItem paddingBig role={Roles.Admin} smallLabel />
                <hr />
                <RoleInfoItem paddingBig role={Roles.User} smallLabel />
              </HelpInfoTooltipContent>
            </HelpInfoTooltip>

            {demo && (
              <ButtonPermission
                data-tour="settings_permissions"
                size="14"
                semibold
                onClick={() => navigate(RoutesName.newPermissions)}
              >
                {t('Set up permissions')}
              </ButtonPermission>
            )}
          </TabHeaderActions>

          <TabPanel name={t('Users')} id={TYPES_MANAGEMENT[0]}>
            <UserManagenentTabsWrapper flexDirection="column">
              <UserManagenentTableHeader
                searchValue={userSearch}
                onChange={handleOnChangeUser}
                showButton={!selectedUsers}
                onAddNew={() => {
                  setModal(TYPES_MANAGEMENT[0]);
                }}
              >
                {!!selectedUsers && (
                  <FlexContainer
                    alignItems="center"
                    columnGap="0.8"
                    data-test="element-selected"
                  >
                    <Label size="14">
                      {t('elementSelected', {
                        count: selectedUsers,
                      })}
                    </Label>
                    <Dropdown
                      testId="user-action"
                      placeholder={t('Select action')}
                      options={actionOptions}
                      onChange={(role?: string) => setRole(role || null)}
                    />
                  </FlexContainer>
                )}
              </UserManagenentTableHeader>
              <UserManagementTableUser
                userSearch={userSearch}
                onSelectedRows={setSelectedUsers}
                role={role}
              />
            </UserManagenentTabsWrapper>
          </TabPanel>

          <TabPanel
            name={t('Groups')}
            id={TYPES_MANAGEMENT[1]}
            testIdTab="tab-group"
          >
            <UserManagenentTabsWrapper flexDirection="column">
              <UserManagenentTableHeader
                onAddNew={() => {
                  setModal(TYPES_MANAGEMENT[1]);
                }}
              />

              <UserManagementTableGroup />
            </UserManagenentTabsWrapper>
          </TabPanel>
        </UserManagenentTabs>
      </UserManagementFormWrapper>

      <ModalManagementNewUser
        show={modal === TYPES_MANAGEMENT[0]}
        onChangeShow={(isShowing) => {
          !isShowing && setModal(null);
        }}
      />

      <ModalManagementNewGroup
        show={modal === TYPES_MANAGEMENT[1]}
        onChangeShow={(isShowing) => {
          !isShowing && setModal(null);
        }}
      />
    </>
  );
};
