import React, { useEffect, useState } from 'react';
import { Transition } from 'react-spring';
import { ModalLateralProps } from './model';
import { ModalLateralStyles } from './Styles';
import { Portal } from '../portal';

export const ModalLateral = ({
  show = false,
  onChangeShow,
  className,
  testId,
  width,
  children,
  overlayBlur,
}: ModalLateralProps) => {
  const [innerShow, setInnerShow] = useState<boolean>(show);

  useEffect(() => {
    setInnerShow(show);
  }, [show]);

  useEffect(() => {
    onChangeShow && onChangeShow(innerShow);
  }, [innerShow]);

  const onCloseModal = () => {
    setInnerShow(false);
  };

  return (
    <Transition
      items={innerShow}
      from={{ right: `-${width}rem` }}
      enter={{ right: '0rem' }}
      leave={{ right: `-${width}rem` }}
    >
      {(styles, innerShow) =>
        innerShow && (
          <Portal
            show
            onClickOutside={onCloseModal}
            actionRef={null}
            overlay
            testId="modal-lateral"
            overlayBlur={overlayBlur}
          >
            <ModalLateralStyles
              width={width}
              style={{ ...styles }}
              data-test={testId}
              className={className}
            >
              {children}
            </ModalLateralStyles>
          </Portal>
        )
      }
    </Transition>
  );
};
