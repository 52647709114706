import { ReactNode, useMemo } from 'react';
import {
  Asset,
  FacialAuthenticationStatus,
  EmptyFilesUpload,
  EmptyFilesUploadError,
} from '@facephi/inphinite-common';
import { Icon, Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
  DOCUMENT_FACIAL_CAPTURE,
  SELFIE,
  CUSTOMER_PROVIDED_IMAGE,
  INTERNAL_PROVIDERS,
} from '../../state/constants';
import { ImageLoad } from './ImageLoad';
import { infoFacial, tInfo, FacialValidationState } from './model';
import { OperationsCardIcon } from './operationsCards/Styles';
import {
  FacialContainer,
  InfoContainer,
  FacialResultIconWrapper,
  FacialCheckGrid,
} from './Styles';

type Props = {
  state?: FacialValidationState;
  assets?: Asset[];
  loading?: boolean;
};

const ImageSkeleton = () => (
  <div>
    <Skeleton width={120} height={120} borderRadius={8} />
    <div style={{ marginTop: '0.8rem' }}>
      <Skeleton width="50%" height={18} borderRadius={8} />
    </div>
  </div>
);

export const SelfieValidations = ({ state, assets = [], loading }: Props) => {
  const { t } = useTranslation();

  const firstImage = useMemo(
    () =>
      assets.length
        ? assets.find(
            ({ type }) =>
              type === DOCUMENT_FACIAL_CAPTURE ||
              type === CUSTOMER_PROVIDED_IMAGE
          )
        : undefined,
    [assets]
  );

  const secondImage = useMemo(
    () =>
      assets.length ? assets.find(({ type }) => type === SELFIE) : undefined,
    [assets]
  );

  const getLoadingImages = () => (
    <>
      <ImageSkeleton />
      <Skeleton width={40} height={40} circle />
      <ImageSkeleton />
    </>
  );

  const [{ authStatus, source, id }] = state || [{}];

  const info: tInfo = useMemo(() => {
    if (!authStatus) return infoFacial['notAble'];
    const type =
      authStatus === FacialAuthenticationStatus.positive
        ? 'match'
        : authStatus === FacialAuthenticationStatus.negative
        ? 'notMatch'
        : 'notAble';
    return infoFacial[type];
  }, [state]);

  const isInternalProvider = useMemo(
    () =>
      source &&
      (source === INTERNAL_PROVIDERS.default ||
        source === INTERNAL_PROVIDERS.facephi),
    [source]
  );

  const getEmptyImageItem = (isSecondImage?: boolean): ReactNode =>
    !isInternalProvider && isSecondImage ? (
      <OperationsCardIcon bgColor={'greys'} bgIntensity={200} remSize="8.9rem">
        <Icon
          iconName={'CameraSlash'}
          size={40}
          color="greys"
          intensity="white"
          weight={'fill'}
        />
      </OperationsCardIcon>
    ) : authStatus === FacialAuthenticationStatus.positive ? (
      <EmptyFilesUpload />
    ) : (
      <EmptyFilesUploadError />
    );

  const getImages = () => (
    <>
      <FacialContainer flexDirection="column" rowGap="0.8">
        {secondImage ? (
          <ImageLoad asset={secondImage} />
        ) : (
          getEmptyImageItem(!secondImage)
        )}
      </FacialContainer>

      {info && (
        <OperationsCardIcon
          bgColor={info.iconColor}
          remSize="6rem"
          key={`provider-${id}`}
          id="facial-match-icon"
          borderRadius="1.7rem"
          bgIntensity={100}
        >
          <Icon
            iconName={info.iconName}
            size={info.size}
            color={info.iconColor}
            intensity={400}
            weight={info.weight}
          />
        </OperationsCardIcon>
      )}
      <FacialContainer flexDirection="column" rowGap="0.8">
        {firstImage ? <ImageLoad asset={firstImage} /> : getEmptyImageItem()}
      </FacialContainer>
    </>
  );

  return (
    <>
      <FacialCheckGrid
        alignItems="center"
        columnGap="0.8"
        justifyContent="space-between"
        gridTemplateColumns="10rem auto 10rem"
        gridTemplateRows="10rem auto"
        rowGap="0.8"
      >
        {loading ? getLoadingImages() : getImages()}
        {!loading && (
          <>
            <Label className="asset-text" size="12">
              {t('SELFIE')}
            </Label>
            <Label size="12" className="asset-text document-text">
              {t('DOCUMENT_FACIAL_CAPTURE')}
            </Label>
          </>
        )}
      </FacialCheckGrid>
      {loading ? (
        <div style={{ marginTop: '2rem', textAlign: 'center' }}>
          <Skeleton width="80%" height={18} borderRadius={8} />
        </div>
      ) : (
        <InfoContainer
          infoColor={info?.iconColor}
          rowGap="0.8"
          flexDirection="row"
          alignItems="center"
          key={`provider-${id}`}
        >
          <FacialResultIconWrapper>
            <Icon
              iconName="ChatsCircle"
              color={info?.iconColor}
              size={32}
              intensity={400}
              weight="thin"
            />
          </FacialResultIconWrapper>
          <Label size="12" semibold>
            {t(info?.message)}
          </Label>
        </InfoContainer>
      )}
    </>
  );
};
