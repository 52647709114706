import React from 'react';
import { TableActionsProps } from './model';
import { TableActionsError } from './Styles';
import { DEFAULT_LOCALE, translate } from '../../utils/translations';
import { Button } from '../button';
import { ButtonIcon } from '../buttonIcon';
import { FlexContainer } from '../flexContainer';
import { Label } from '../label';
import { PositionX } from '../portal';

export const TableActions = ({
  onSeeDetail,
  testId,
  className,
  dataTour,
  onClickError,
  hasError,
  locale = DEFAULT_LOCALE,
}: TableActionsProps) => {
  const ErrorActions = () => {
    return (
      <FlexContainer flexDirection="column" rowGap="1" alignItems="center">
        <Label size="18" color="error" semibold>
          {translate('Error', locale)}
        </Label>
        <Label size="14">
          {translate(
            'Check the operation detail and review it. Click below to redirect.',
            locale
          )}
        </Label>
        <Button
          iconName="ArrowBendUpRight"
          color="secondary"
          variant="text"
          onClick={onClickError}
        >
          {translate('Go to detail', locale)}
        </Button>
      </FlexContainer>
    );
  };

  return (
    <FlexContainer columnGap="1" data-tour={dataTour}>
      <ButtonIcon
        text
        iconName="Eye"
        onClick={onSeeDetail}
        className={className}
        testId={testId}
      />
      {hasError && (
        <TableActionsError
          iconName="Warning"
          color="error"
          positionX={PositionX.left}
        >
          <ErrorActions />
        </TableActionsError>
      )}
    </FlexContainer>
  );
};
