import { useMemo, useState } from 'react';
import {
  ButtonIcon,
  TableSelection,
  useTimezone,
  useToast,
} from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { HeaderRules, ModalIP } from '.';
import { descIp, ips, updatesIP } from './dataRules';
import { reloadItems } from '../../utils';

export type IpProps = {
  IP: string;
  description: string;
  update: string;
  action: string;
};

export const RulesIP = () => {
  const initIP: IpProps = {
    IP: '',
    description: '',
    update: '',
    action: '',
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const [itemsDelete, setItemsDelete] = useState<IpProps[]>([initIP]);
  const { toastManager } = useToast();
  const { formatTimezone } = useTimezone();

  const columns = useMemo(
    () => [
      {
        Header: t('IP'),
        accessor: 'IP',
        maxWidth: 200,
      },
      {
        Header: t('Update'),
        accessor: 'update',
        maxWidth: 130,
      },
      {
        Header: t('Description'),
        accessor: 'description',
      },

      {
        Header: t('Actions'),
        accessor: 'action',
        Cell: <ButtonIcon text iconName="ListPlus" onClick={() => {}} />,
        maxWidth: 150,
      },
    ],
    []
  );

  const getData = (): IpProps[] => {
    const data: IpProps[] = [];
    for (let i = 0; i < 7; i++) {
      data.push({
        IP: ips[i],
        update: updatesIP[i],
        description: descIp[i],
        action: '',
      });
    }
    return data;
  };
  const [dataIP, setDataIP] = useState<IpProps[]>(getData());

  const onCreate = (ip: string, description: string): void => {
    setDataIP((prev: IpProps[]) =>
      [
        {
          IP: ip,
          update: formatTimezone(new Date()),
          description,
          action: '',
        },
      ].concat(prev)
    );

    setShowModal(false);
  };

  const deleteItemsIP = (): void => {
    setDataIP(reloadItems<IpProps>(dataIP, itemsDelete));

    toastManager({
      message: `Eliminados ${itemsDelete.length} items.`,
      iconName: 'TrashSimple',
    });
  };

  return (
    <>
      <HeaderRules
        deleteItemsSelected={deleteItemsIP}
        itemsDelete={itemsDelete.length}
        onAddNew={() => setShowModal(true)}
      />
      <TableSelection
        onSelectedRowsChange={(selected) => setItemsDelete(selected)}
        columns={columns}
        data={dataIP}
      />
      <ModalIP
        show={showModal}
        onSave={onCreate}
        onChangeShow={(showModalIP) => setShowModal(showModalIP)}
      />
    </>
  );
};
