import styled from 'styled-components';
import { BreadcrumbProps } from './model';
import { FlexContainer } from '../flexContainer';
import { getSizeLabel } from '../label';

export const BreadcrumbStyle = styled(FlexContainer)<BreadcrumbProps>`
  height: min-content;
  span {
    color: ${(props) => props.theme.color.greys[500]};
  }
  a {
    color: ${(props) => props.theme.color.secondary[400]};
    ${getSizeLabel('12')};

    &:hover,
    &:focus {
      color: ${(props) => props.theme.color.secondary[500]};
    }
    &:focus {
      text-decoration: underline 2px;
      text-underline-offset: 2px;
    }
    ${(props) =>
      props.separator &&
      `
      &:after {
      content: '${props.separator}';
      color:  ${props.theme.color.greys[500]};
      display:inline-block;
      margin-left:0.6rem;
      }
    `}
  }
`;
