import styled from 'styled-components';
import { StatusStyleProps, StatusType } from './model';
import { IThemeProps } from '../../themes';
import { FlexContainer } from '../flexContainer';
import { Label } from '../label';

export const selectDotColor = (
  theme: IThemeProps,
  type: StatusType,
  isBackground?: boolean
) => {
  let hex;
  switch (type) {
    case StatusType.BLACKLISTED:
      hex = theme.color.greys[isBackground ? 600 : 'black'];
      break;
    case StatusType.ACTIVE:
      hex = theme.color.violet[isBackground ? 100 : 400];
      break;
    case StatusType.EXPIRED:
      hex = theme.color.violet[isBackground ? 100 : 400];
      break;
    case StatusType.IN_PROGRESS:
      hex = theme.color.purple[isBackground ? 100 : 400];
      break;
    case StatusType.STARTED:
      hex = theme.color.skyBlue[isBackground ? 100 : 400];
      break;
    case StatusType.NOT_ACTIVE:
    case StatusType.READ_ONLY:
      hex = theme.color.greys[isBackground ? 100 : 400];
      break;
    case StatusType.PENDENT:
      hex = theme.color.yellow[isBackground ? 100 : 400];
      break;
    case StatusType.DENIED:
      hex = theme.color.error[isBackground ? 100 : 400];
      break;
    case StatusType.SUCCEEDED:
      hex = theme.color.green[isBackground ? 100 : 400];
      break;
    case StatusType.VALIDATE:
      hex = theme.color.blue[isBackground ? 100 : 400];
      break;
    case StatusType.SAVED:
      hex = theme.color.green[isBackground ? 100 : 400];
      break;
    case StatusType.CANCELLED:
      hex = theme.color.greys[isBackground ? 100 : 200];
      break;
  }
  return hex;
};

export const StatusStyles = styled(FlexContainer)`
  ${Label} {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const StatusBullet = styled.span<StatusStyleProps>`
  height: 1.1rem;
  aspect-ratio: 1;
  border-radius: 50%;
`;
