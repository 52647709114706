import React from 'react';
import { TabPanelProps } from './model';
import { TabPanelStyles } from './Styles';

const TabPanel = ({
  children,
  testIdPanel,
  className,
  visible = true,
}: TabPanelProps) => {
  return visible ? (
    <TabPanelStyles data-test={testIdPanel} className={className}>
      {children}
    </TabPanelStyles>
  ) : null;
};

TabPanel.displayName = 'TabPanel';

export { TabPanel };
