import React, { createContext, useEffect, useState } from 'react';
import { useTheme } from '@facephi/inphinite-ui';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Tour, { ReactourStep } from 'reactour';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  setSteps(steps: ReactourStep[]): void;
  setIsOpen(value: boolean): void;
};

const Ctx = createContext<ContextProps>({
  setSteps: () => {},
  setIsOpen: () => {},
});

export const TourProvider = ({ children }: IProps) => {
  const theme = useTheme();
  const [steps, setSteps] = useState<ReactourStep[]>([]);
  const [currentStep, setCurrentStep] = useState<number>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [update, setUpdate] = useState<string>();

  const handleClose = () => {
    setIsOpen(false);
  };

  const disableBody = (target: HTMLDivElement) => disableBodyScroll(target);

  const enableBody = (target: HTMLDivElement) => enableBodyScroll(target);

  const handleStep = (currentStep: number) => setCurrentStep(currentStep);

  useEffect(() => {
    if (isOpen) {
      setCurrentStep(0);
    } else {
      setCurrentStep(undefined);
    }
  }, [isOpen]);

  // Force the update to paint the highlight if the element of that step was not on the viewport
  useEffect(() => {
    if (currentStep !== undefined) {
      setTimeout(() => {
        setUpdate(currentStep.toString());
      }, 500);
    }
  }, [currentStep]);

  return (
    <Ctx.Provider
      value={{
        setSteps,
        setIsOpen,
      }}
    >
      {children}
      <Tour
        update={update}
        scrollDuration={10}
        getCurrentStep={handleStep}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        steps={steps}
        showCloseButton={false}
        isOpen={isOpen}
        onRequestClose={handleClose}
        closeWithMask={false}
        startAt={0}
        rounded={8}
        showNavigationNumber={false}
        showNumber={false}
        maskClassName="tour-mask"
        accentColor={theme.color.greys[200]}
      />
    </Ctx.Provider>
  );
};

export const useTour = () => React.useContext(Ctx);
