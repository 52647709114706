import React, { useEffect, useState } from 'react';
import { ChipsProps } from './model';
import { ChipsStyles } from './Styles';
import { Icon } from '../icon';

export const Chips = ({
  label,
  className,
  iconName,
  onClick,
  testId,
  closeable,
  imgSrc,
  disabled,
  isSelected = false,
}: ChipsProps) => {
  const [selected, setSelected] = useState<boolean>(isSelected);

  const handleClick = () => {
    setSelected(!selected);
    onClick && onClick();
  };

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  return (
    <ChipsStyles
      columnGap="1"
      alignItems="center"
      as="button"
      onClick={handleClick}
      className={className}
      disabled={disabled}
      data-test={testId}
      isSelected={selected}
    >
      {imgSrc && !iconName && (
        <img src={imgSrc} alt="chips-img" data-test="img-chips" />
      )}
      {iconName && <Icon iconName={iconName} size="24" testId="icon-chips" />}
      {label}
      {closeable && <Icon iconName="X" size="24" testId="closeable-chips" />}
    </ChipsStyles>
  );
};
