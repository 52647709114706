import { useTranslation } from 'react-i18next';
import ConfirmReviewImage from '../../assets/img/confirmReview.svg';
import {
  ModalReviewContent,
  ModalReviewLabel,
  ModalReviewStyles,
} from './Styles';

type Props = {
  show: boolean;
  onChangeShow(show: boolean): void;
  onCreate(): void;
  onClose(value: boolean): void;
};

export const ModalReview = ({
  show,
  onChangeShow,
  onCreate,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalReviewStyles
      show={show}
      onChangeShow={onChangeShow}
      onCreate={() => onClose(false)}
      submitLabel={t('Confirm')}
      noBorderHeader={true}
      closeLabel={' '}
      testIdSave="button-save-approved"
    >
      <ModalReviewContent
        flexDirection="column"
        rowGap="2.4"
        alignItems="center"
      >
        <img src={ConfirmReviewImage} alt="confirm" />
        <ModalReviewLabel size="14">
          {t('The review is ended!')}
        </ModalReviewLabel>
      </ModalReviewContent>
    </ModalReviewStyles>
  );
};
