import styled from 'styled-components';
import { DropdownSubMenuPosition } from './model';
import { ZINDEX_PORTAL } from '../../styles';
import { FlexContainer } from '../flexContainer';
import { Input } from '../input';
import { Label } from '../label';

export const DropdownStyles = styled(Input)<{
  tagPosition: string;
}>`
  ${(props) =>
    props.tagPosition === 'right' &&
    `
    grid-column: 2;
    display: grid;
    column-gap: 2rem;
      & > div {
        grid-column-start: 1;
      }
      ${DropdownMultipleValues} {
        grid-column-start: 2;
      }

    `}
  input {
    cursor: pointer;
  }
`;
export const DROPDOWN_ITEM_HEIGHT = '3.6rem';

export const DropdownWrapperStyles = styled.div`
  border-radius: 0.8rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};
  border: solid 1px ${(props) => props.theme.color.greys[100]};
  background-color: ${(props) => props.theme.color.greys.white};
  position: relative;

  @media (max-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 4.8rem);
    z-index: ${ZINDEX_PORTAL};
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }

  ul {
    max-height: calc(6 * ${DROPDOWN_ITEM_HEIGHT});
    overflow: auto;
  }
`;

export const DropdownSubMenuWrapperStyles = styled(
  DropdownWrapperStyles
)<DropdownSubMenuPosition>`
  position: absolute;
  top: -${DROPDOWN_ITEM_HEIGHT};

  ${(props) => props.subMenuPosition === 'left' && `right: 100%;`}
  ${(props) => props.subMenuPosition === 'right' && `left: 100%;`}
`;

export const DropdownInputSearchMobile = styled.input`
  padding: 0.8rem 0.8rem;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.color.greys[50]};
  height: ${DROPDOWN_ITEM_HEIGHT};
  width: 100%;
  overflow: hidden;
  outline: none;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;

  &:focus {
    outline: none;
  }
`;

export const DropdownMultipleValues = styled(FlexContainer)`
  margin-top: 1rem;
  max-height: 12rem;
  overflow-y: auto;
`;

export const DropdownItem = styled(FlexContainer)<{
  active?: boolean;
  subMenuIsActive?: boolean;
}>`
  cursor: pointer;
  padding: 0.8rem 0.8rem;
  border-bottom: 1px solid ${(props) => props.theme.color.greys[50]};

  height: ${DROPDOWN_ITEM_HEIGHT};
  background: ${(props) =>
    props.subMenuIsActive
      ? props.theme.color.greys[100]
      : props.active
      ? props.theme.color.primary[100]
      : 'transparent'};

  svg {
    color: ${(props) => props.theme.color.greys[400]};
    width: 1.6rem;
    height: 1.6rem;
  }

  ${(props) =>
    props.active &&
    `
      ${Label} {
        color: ${props.theme.color.primary[400]};
        font-weight: 600;
      }
      svg {
        color: ${props.theme.color.primary[400]};
      }
    `}

  ${(props) =>
    !props.disabled
      ? `
      &:hover {
        background: ${
          props.subMenuIsActive
            ? props.theme.color.greys[50]
            : props.theme.color.greys[100]
        };
    `
      : `
    cursor: default;
    pointer-events: none;
    ${Label} {
      color: ${props.theme.color.greys[400]};
    }
    `}
`;
