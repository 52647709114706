import { Icon, Label, useTheme } from '@facephi/inphinite-ui';
import { NavLink } from 'react-router-dom';
import { AsideNavItem, AsideNavProps } from './model';
import {
  StyledMenuItem,
  StylesButtonLogo,
  StylesMenu,
  StylesMenuLink,
  StylesMenuLogo,
  StylesMenuNav,
  StylesSeparator,
} from './Styles';

export const AsideNav = ({
  menu,
  children,
  className,
  isPreviewMode,
}: AsideNavProps) => {
  const theme = useTheme();

  return (
    <StylesMenu className={className} isPreviewMode={isPreviewMode}>
      <StylesMenuLink to="/">
        {theme.images?.logoAside ? (
          <img src={theme.images.logoAside} alt="logo" />
        ) : (
          <StylesButtonLogo alignItems="center" justifyContent="center">
            <StylesMenuLogo />
          </StylesButtonLogo>
        )}
      </StylesMenuLink>
      <StylesSeparator />

      <StylesMenuNav>
        <ul>
          {menu
            .filter(({ flags }) => !flags || !flags.some((el) => !el))
            .map((item: AsideNavItem) => (
              <StyledMenuItem
                key={item.id}
                flexDirection="column"
                alignItems="center"
              >
                <NavLink
                  to={item.link}
                  data-test={item.testId}
                  className={({ isActive }) => (isActive ? 'is-active' : '')}
                >
                  <Icon iconName={item.icon} />
                </NavLink>
                <Label negative size="10" textAlign="center">
                  {item.label}
                </Label>
              </StyledMenuItem>
            ))}
        </ul>
      </StylesMenuNav>
      {children && children}
    </StylesMenu>
  );
};
