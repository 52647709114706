/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  forwardRef,
  MouseEvent,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { InputUploadProps, TypeFiles } from './model';
import {
  ButtonChangePreview,
  ImagePreview,
  InputUploadInfoLabel,
  InputUploadStyles,
  LabelUserName,
  WrapperDrag,
} from './Style';
import { DEFAULT_LOCALE, translate } from '../../utils/translations';
import { FlexContainer } from '../flexContainer';
import { Icon } from '../icon';
import { Label } from '../label';

type Preview = {
  image?: string;
  fileName?: string;
};

function isFileImage(file: File | Blob) {
  return file && file['type'].split('/')[0] === 'image';
}

async function getFile(source: string | Blob, name?: string) {
  let file: Blob;
  if (typeof source === 'string') {
    file = await fetch(source).then((res) => res.blob());
  } else {
    file = source;
  }
  const isImage = isFileImage(file);

  return {
    image: isImage ? URL.createObjectURL(file) : undefined,
    fileName: name,
  };
}

async function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      reader.result && resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject(error);
    };
  });
}

export const InputUpload = forwardRef(
  (
    {
      errorLabel,
      labelDrop,
      acceptFiles = [TypeFiles.jpeg, TypeFiles.png],
      maxSize = 10485760,
      onChange,
      onDelete,
      onErrors,
      value,
      valueName,
      fileName,
      testId,
      className,
      id,
      withUserLabel = true,
      secondary,
      base64 = false,
      locale = DEFAULT_LOCALE,
      hasInfoMessage = true,
    }: InputUploadProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [preview, setPreview] = useState<Preview>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [files, setFiles] = useState<Preview[]>([]);
    const [label, setLabel] = useState<string>();

    useEffect(() => {
      setLabel(labelDrop || translate('Add your photo here', locale));
    }, [locale, labelDrop]);

    const updateFile = async (source: string | Blob, name?: string) => {
      const preview = await getFile(source, name);
      setPreview(preview);
    };

    useEffect(() => {
      if (value) {
        updateFile(value, fileName);
      } else {
        setPreview(undefined);
      }
    }, [value, fileName]);

    useEffect(() => {
      if (valueName) {
        if (typeof valueName === 'string') {
          setPreview({
            fileName: valueName,
          });
        } else {
          setFiles(() => valueName.map((item) => ({ fileName: item })));
        }
      }
    }, [valueName]);

    const onDrop = useCallback(
      async (files: File[], filesRejection: FileRejection[]) => {
        if (filesRejection.length) {
          onErrors && onErrors(filesRejection);
        }
        if (files.length) {
          const file = files[0];
          const isImage = isFileImage(file);

          setPreview({
            image: isImage ? URL.createObjectURL(file) : undefined,
            fileName: file.name,
          });

          if (base64) {
            const base64file = await getBase64(files[0]);
            onChange && onChange(base64file);
          } else {
            onChange && onChange(file);
          }
        }
      },
      []
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onDropRejected = (name: any) => {
      console.log(name);
    };

    const { getRootProps, getInputProps, inputRef } = useDropzone({
      onDrop,
      onDropRejected,
      accept: acceptFiles,
      maxSize,
    });

    const onDeleteFile = (event: MouseEvent<HTMLButtonElement>) => {
      setPreview(undefined);
      onDelete && onDelete();
      event.stopPropagation();
    };

    const onUploadFile = (event: MouseEvent<HTMLButtonElement>) => {
      if (inputRef.current) {
        inputRef.current.click();
      }
      event.stopPropagation();
    };

    return (
      <InputUploadStyles className={className} flexDirection="column">
        <FlexContainer columnGap="3" alignItems="center" data-test={testId}>
          <input ref={ref} {...(getInputProps() as any)} />
          <WrapperDrag
            justifyContent="center"
            alignItems="center"
            secondary={secondary}
            {...(getRootProps() as any)}
            tabIndex={0}
          >
            {preview ? (
              <ImagePreview src={preview.image} data-test="image-preview" />
            ) : withUserLabel && !secondary ? (
              <LabelUserName size="21" semibold>
                O
              </LabelUserName>
            ) : (
              <FlexContainer columnGap="0.8" alignItems="center">
                <Icon iconName="Plus" size="24" />
                <Label size="14">{label}</Label>
              </FlexContainer>
            )}
            {!secondary && (
              <ButtonChangePreview
                onClick={preview ? onDeleteFile : onUploadFile}
                iconName={preview ? 'Trash' : 'FileArrowUp'}
                size="S"
                testId={preview && 'delete-file'}
              />
            )}
          </WrapperDrag>
          {!secondary && hasInfoMessage && (
            <FlexContainer flexDirection="column" rowGap="1">
              <Label size="12" as="label" htmlFor={id}>
                {label}
              </Label>
              <InputUploadInfoLabel size="10" errorLabel={errorLabel}>
                {errorLabel
                  ? errorLabel
                  : translate('The image should be 96x96', locale)}
              </InputUploadInfoLabel>
            </FlexContainer>
          )}
        </FlexContainer>
      </InputUploadStyles>
    );
  }
);
