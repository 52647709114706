import { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import { useFlags } from '@facephi/inphinite-common';
import {
  Button,
  ButtonIcon,
  FlexContainer,
  Input,
} from '@facephi/inphinite-ui';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { UserManagenentTableHeaderStyles } from './Styles';

type Props = {
  onAddNew?(): void;
  onDownload?(): void;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  searchValue?: string;
  children?: ReactNode;
  showButton?: boolean;
};

export const UserManagenentTableHeader = ({
  onAddNew,
  onDownload,
  onChange,
  searchValue,
  children,
  showButton = true,
}: Props) => {
  const { t } = useTranslation();
  const { demo } = useFlags();
  const [search, setSearch] = useState<string | undefined>(searchValue);

  const debouncedOnChange = useMemo(
    () => onChange && debounce(onChange, 250),
    []
  );

  const handleOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSearch(ev.target.value);
    debouncedOnChange?.(ev);
  };

  return (
    <UserManagenentTableHeaderStyles justifyContent="space-between">
      <FlexContainer>
        {onChange && (
          <Input
            type="text"
            iconLeft="MagnifyingGlass"
            onChange={handleOnChange}
            value={search}
            testId="input-search"
          />
        )}
        {demo && <ButtonIcon iconName="Faders" text />}
      </FlexContainer>

      <FlexContainer columnGap="1.6">
        {children}
        {demo && <ButtonIcon iconName="DownloadSimple" onClick={onDownload} />}
        {showButton && (
          <span data-tour="settings_add-new">
            <Button testId="add-new-user" iconName="Plus" onClick={onAddNew}>
              {t('Add new')}
            </Button>
          </span>
        )}
      </FlexContainer>
    </UserManagenentTableHeaderStyles>
  );
};
