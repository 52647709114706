import styled from 'styled-components';
import { ProgresBarStyleProps } from './model';
import { FlexContainer } from '../flexContainer';

export const ProgresBarStyles = styled(FlexContainer)`
  width: 100%;
`;

export const ProgresBarStatus = styled.div<ProgresBarStyleProps>`
  width: 100%;
  height: ${(props) => (props.big ? '1.6rem' : '0.8rem')};
  background-color: ${(props) => props.theme.color.greys[100]};
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: ${(props) => props.percentage}%;
    height: 100%;
    border-radius: inherit;
    background: ${(props) => props.theme.gradients.lightBlue};
    transition: width 0.25s ease-in-out;
  }
`;
