import { useEffect, useRef, useState } from 'react';
import { isImage, MimeType, useLoadAsset } from '@facephi/inphinite-common';
import { ButtonIcon, FlexContainer, Label } from '@facephi/inphinite-ui';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { ZOOM } from '../../state/constants';
import {
  ModalOperationsDetailFooter,
  ModalOperationsDetailHeader,
  ModalOperationsDetailSource,
  ModalOperationsDetailStyles,
  ModalOperationsDetailZoom,
  ModalOperationTitle,
} from './Styles';

type Props = {
  onGoLeft?(): void;
  onGoRight?(): void;
  source: string;
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  show?: boolean;
  onChangeShow(show: boolean): void;
  testId?: string;
  className?: string;
  mimeType: MimeType;
  disabled?: boolean;
  disabledGoLeft?: boolean;
  disabledGoRight?: boolean;
};

export const ModalOperationsDetail = ({
  source,
  show = false,
  onChangeShow,
  testId,
  className,
  onGoRight,
  onGoLeft,
  title,
  subtitle,
  mimeType,
  disabledGoLeft,
  disabledGoRight,
}: Props) => {
  const { t } = useTranslation();

  const [zoom, setZoom] = useState<number>(ZOOM.defaultZoom);
  const [innerShow, setInnerShow] = useState<boolean>(show);

  const { resourceUrl, hasError } = useLoadAsset(source, mimeType);

  const imageRef = useRef<HTMLImageElement>(null);
  const printImage = useReactToPrint({
    content: () => imageRef.current,
  });

  useEffect(() => {
    setZoom(ZOOM.defaultZoom);
  }, [source]);

  useEffect(() => {
    setInnerShow(show);
  }, [show]);

  const onCloseModal = () => {
    setInnerShow(false);
  };

  const setNewZoom = (newZoom: number) => {
    let absoluteZoom = newZoom - (newZoom % ZOOM.defaultFlow);
    absoluteZoom = absoluteZoom < ZOOM.minZoom ? ZOOM.minZoom : absoluteZoom;
    absoluteZoom =
      absoluteZoom > ZOOM.defaultScale ? ZOOM.defaultScale : absoluteZoom;
    setZoom(absoluteZoom);
  };

  const increaseZoom = () => {
    setNewZoom(zoom + ZOOM.defaultFlow);
  };

  const decreaseZoom = () => {
    setNewZoom(zoom - ZOOM.defaultFlow);
  };

  const downloadSource = () => {
    saveAs(resourceUrl || '', title);
  };

  return (
    <ModalOperationsDetailStyles
      testId={testId}
      className={className}
      onChangeShow={onChangeShow}
      show={innerShow}
      flexDirectionContent="column"
      noPaddingContent
      justifyContent="space-between"
    >
      <ModalOperationsDetailHeader
        alignItems="center"
        justifyContent="space-between"
      >
        <ModalOperationTitle columnGap="1.6" alignItems="center">
          <ButtonIcon
            iconName="ArrowArcLeft"
            onClick={onCloseModal}
            variant="secondary"
          />
          <Label size="14" semibold negative>
            {`${t(title)} ${subtitle || ''}`}
          </Label>
        </ModalOperationTitle>
        <FlexContainer columnGap="1.6">
          <ButtonIcon
            iconName="MagnifyingGlassMinus"
            onClick={decreaseZoom}
            disabled={zoom === ZOOM.minZoom}
            variant="secondary"
          />
          <ModalOperationsDetailZoom size="12" negative>
            {Number(zoom.toFixed(2))}%
          </ModalOperationsDetailZoom>
          <ButtonIcon
            iconName="MagnifyingGlassPlus"
            onClick={increaseZoom}
            disabled={zoom === ZOOM.defaultScale}
            variant="secondary"
          />
        </FlexContainer>
        <FlexContainer columnGap="1.6">
          <ButtonIcon
            iconName="Printer"
            onClick={printImage}
            disabled={!resourceUrl || !isImage(mimeType)}
            variant="secondary"
          />
          <ButtonIcon
            iconName="DownloadSimple"
            onClick={downloadSource}
            disabled={!resourceUrl}
            variant="secondary"
          />
        </FlexContainer>
      </ModalOperationsDetailHeader>
      <ModalOperationsDetailSource
        ref={imageRef}
        resource={resourceUrl}
        title={title}
        zoom={zoom}
        mimeType={mimeType}
        hasAssetError={hasError}
        flexDirectionError="column"
        zoomable
        controls
        autoPlay
      />
      <ModalOperationsDetailFooter
        alignItems="center"
        justifyContent="center"
        columnGap="1.6"
      >
        {onGoLeft && onGoRight && (
          <>
            <ButtonIcon
              iconName="ArrowCircleLeft"
              onClick={onGoLeft}
              variant="secondary"
              disabled={disabledGoLeft}
            />
            <ButtonIcon
              iconName="ArrowCircleRight"
              onClick={onGoRight}
              variant="secondary"
              disabled={disabledGoRight}
            />
          </>
        )}
      </ModalOperationsDetailFooter>
    </ModalOperationsDetailStyles>
  );
};
